import { MenuOutlined } from "@ant-design/icons";
import { Table, Image, Button, Form, Select, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./LotsTable.module.scss";
import {
  attachListing,
  getUnassignedListings,
  reorderedListings
} from "app/api/auctionShowsApi";
import { formatNumber } from "app/helpers/numberFormatHelper";
import * as liveAuctionApi from "app/api/liveAuctionsApi";
import * as auctionShowsApi from "app/api/auctionShowsApi";
import CreateLotModal from "app/screens/LiveAuctionsScreen/components/CreateLotModal/CreateLotModal";
import { Tabs } from "app/types/LiveAuctionsType";
import EmergencyDeleteModal from "app/screens/LiveAuctionsScreen/components/EmergencyDeleteModal/EmergencyDeleteModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LotTabs } from "app/types/auctionShowsType";
import { auctionType } from "app/types/auctionType";
import { updateListing } from "app/api/listingsApi";

const LotsTable = ({ selectedEvent, onLoadDetails, path, load }) => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const [data, setData] = useState<LotTabs[]>([]);
  const [selectedLot, setSelectedLot] = useState<Tabs>();
  const [emergencyDelete, setEmergencyDelete] = useState(false);
  const [createLotModalVisible, setCreateLotModalVisible] = useState<boolean>(
    false
  );
  const [unassignedListings, setUnassignedListings] = useState<auctionType[]>(
    []
  );

  const [selectedItem, setSelectedItem] = useState<number>();

  useEffect(() => {
    setData(selectedEvent?.lotTabs);
  }, [selectedEvent]);

  const handleGetUnassignedListings = () => {
    getUnassignedListings()
      .then(payload => {
        setUnassignedListings(payload.data);
      })
      .catch(error => {
        console.error("Error fetching input fields:", error);
      });
  };

  const onFinish = values => {
    const body = new FormData();

    if (selectedEvent?.id) {
      body.append("liveAuctionId", selectedEvent.id);
    }

    if (values.listingId) {
      body.append("listingId", values.listingId);
    }

    if (selectedEvent?.id && values.listingId) {
      attachListing(body)
        .then(response => {
          const respData = response.data;
          const respStatus = response.status;

          if (respStatus === 201 && respData.listingId !== undefined) {
            handleUpdateListing(respData.listingId);
            form.resetFields();
            message.success("Listing attached");
          } else {
            message.error("Failed to attach listing. Please try again.");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleUpdateListing = listingId => {
    if (!listingId) {
      return;
    }
    const updateData = {
      endAt: selectedEvent.endAt,
      startAt: selectedEvent.startAt
    };

    updateListing(listingId, updateData)
      .then(() => {
        onLoadDetails();
      })
      .catch(error => {
        console.error("Error updating listing:", error);
      });
  };

  const columns = [
    {
      key: "sort",
      title: "Sort",
      dataIndex: "sort",
      width: "6%",
      render: () => <MenuOutlined className={styles.dragHandle} />
    },
    {
      title: "Media",
      key: "media",
      dataIndex: ["listing", "mediaPhotos", 0, "url"],
      render: media => (
        <Image
          style={{
            aspectRatio: "16/9",
            objectFit: "cover",
            width: "100%"
          }}
          alt="Events"
          height={50}
          src={media}
        />
      )
    },
    {
      title: "Title",
      dataIndex: ["listing", "title"],
      key: "title"
    },
    {
      title: "Category",
      dataIndex: ["listing", "listingType", "name"],
      key: "category"
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state"
    },
    {
      title: "Seller",
      dataIndex: ["listing", "seller", "email"],
      key: "seller",
      render: seller => (
        <Button
          type="link"
          onClick={() => alert(`Take me to seller details page!`)}
        >
          {seller}
        </Button>
      )
    },
    {
      title: "Starting Price",
      dataIndex: ["listing", "startingPrice", "amount"],
      key: "startingPrice",
      render: startingPrice => <>SAR {formatNumber(startingPrice)}</>
    },
    {
      title: "Current Price",
      dataIndex: ["listing", "highestBidPrice", "amount"],
      key: "highestBidPrice"
      // render: (item, record: any, currentPrice: any) =>
      //   currentPrice ? (
      //     <>SAR {formatNumber(currentPrice)}</>
      //   ) : (
      //     <>SAR {formatNumber(record?.listing?.startingPrice?.amount)}</>
      //   ),
    },
    {
      title: "Buy Now",
      dataIndex: ["listing", "buyNowOption"],
      key: "buyNow",
      render: buyNow => (buyNow ? "Yes" : "No")
    },
    {
      title: "Bids",
      dataIndex: ["listing", "totalBids"],
      key: "bids"
    },
    {
      hidden:
        selectedEvent?.auctionType !== "live-auction" && path === "in-progress",
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <>
          <Button
            hidden={selectedEvent?.auctionType !== "live-auction"}
            key="edit"
            type="link"
            onClick={() => toggleLotModal(record)}
          >
            {path === "in-progress" ? "View" : "Edit"}
          </Button>
          <Button
            key="delete"
            danger
            type="link"
            onClick={() => handleButtonClick(record)}
          >
            {selectedEvent?.auctionType !== "live-auction"
              ? "Remove"
              : "Delete"}
          </Button>
        </>
      )
    }
  ].filter(item => !item.hidden);

  const handleButtonClick = record => {
    if (selectedEvent?.auctionType !== "live-auction") {
      handleRemoveLot(record);
    } else if (path === "in-progress") {
      setEmergencyDelete(true);
    } else {
      handleDeleteLot(record);
    }
  };

  const handleDeleteLot = record => {
    Modal.confirm({
      title: "Are you sure you want to remove this lot?",
      content: `You’re about to remove ${record?.listing?.title}.`,
      okText: "Yes, remove lot",
      okType: "danger",
      onOk() {
        return liveAuctionApi
          .deleteLiveAuctionLot(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Live Auction Lot Deleted");
              return onLoadDetails();
            } else {
              message.error("Something Wrong happened, try again.");
              return false;
            }
          })
          .catch(e => {
            message.error(e.message);
            return false;
          });
      }
    });
  };

  const handleRemoveLot = record => {
    const body = {
      liveAuctionId: record?.liveAuctionId,
      listingId: record?.listingId
    };

    Modal.confirm({
      title: "Are you sure you want to remove this lot?",
      content: `You’re about to remove ${record?.listing?.title}.`,
      okText: "Yes, remove lot",
      okType: "danger",
      onOk() {
        return auctionShowsApi
          .unattachListing(body)
          .then(p => {
            if (p.status === 200) {
              message.success("Timed Auction Lot Removed");
              onLoadDetails();
            } else {
              message.error("Something Wrong happened, try again.");
              return false;
            }
          })
          .catch(e => {
            message.error(e.message);
            return false;
          });
      }
    });
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    try {
      const items = [...data];

      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setData(items);

      const newData = items.map((item: any) => item.id);

      if (!newData.length) return;

      const body = { columnOrder: newData };

      const response = await reorderedListings(body);

      if (response.status === 200) {
        onLoadDetails();
        message.success("Listings Reordered");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleLotModal = record => {
    setCreateLotModalVisible(true);
    setSelectedLot(record);
  };

  const DraggableContainer = (props: any) => (
    <Droppable droppableId="fieldsData" {...props}>
      {provided => (
        <tbody
          className={styles.draggableContainer}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.children}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { "data-row-key": dataRowKey } = restProps;
    const index = data?.findIndex(x => x.id === Number(dataRowKey));
    return (
      <Draggable
        key={`${dataRowKey}`}
        draggableId={`${dataRowKey}`}
        index={index !== -1 ? index : 0}
      >
        {provided => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...restProps}
            className={data?.length > 0 ? styles.draggableRow : ""}
          />
        )}
      </Draggable>
    );
  };

  return (
    <>
      {createLotModalVisible && (
        <CreateLotModal
          onClose={() => setCreateLotModalVisible(false)}
          isVisible={createLotModalVisible}
          onCreate={() => onLoadDetails()}
          selectedLiveAuction={selectedEvent}
          selectedLot={selectedLot}
          path={path}
        />
      )}

      {emergencyDelete && (
        <EmergencyDeleteModal
          isVisible={emergencyDelete}
          onClose={() => setEmergencyDelete(false)}
          selectedLot={selectedLot}
          selectedLiveAuction={selectedEvent}
          onCreate={onLoadDetails}
        />
      )}

      {selectedEvent?.auctionType !== "show" && (
        <div className={styles.lotsForm}>
          <h4>Assigned Lots ({selectedEvent?.lotTabs?.length})</h4>
          <div className={styles.formWrapper}>
            {selectedEvent?.auctionType !== "live-auction" ? (
              <>
                <Form
                  layout="horizontal"
                  form={form}
                  name="addLotsForm"
                  onFinish={onFinish}
                >
                  <Item
                    className={styles.listingInput}
                    name="listingId"
                    rules={[
                      {
                        required: true,
                        message: "Please select a listing."
                      }
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Type to search..."
                      optionFilterProp="children"
                      onChange={value => setSelectedItem(value)}
                      value={selectedItem}
                      onDropdownVisibleChange={open =>
                        open ? handleGetUnassignedListings() : null
                      }
                      filterOption={(input, option: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                      options={unassignedListings?.map((listing: any) => ({
                        value: listing.id,
                        label: listing.title
                      }))}
                    />
                  </Item>
                </Form>
                <Button
                  className={styles.listingAddButton}
                  form="addLotsForm"
                  type="primary"
                  htmlType="submit"
                >
                  Add Lot
                </Button>
              </>
            ) : (
              <Button
                hidden={path === "in-progress" || path === "history"}
                onClick={() => toggleLotModal(null)}
                type="primary"
              >
                Create new lot
              </Button>
            )}
          </div>
        </div>
      )}

      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Table
            key="index"
            pagination={false}
            size="small"
            loading={load}
            dataSource={data}
            columns={columns}
            rowKey={record => record.id}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </DragDropContext>
      </div>
    </>
  );
};

export default LotsTable;
