import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get listings
 *
 * @param
 * @returns
 * @export
 */

export const getListings = (search?: any) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * creates listing
 * @param search
 */
export const createListing = (body: any) => {
  return api.post(ENDPOINTS.DYNAMIC_CATEGORIES, body);
};

/**
 * updates listing
 * @param search
 */
export const updateListing = (id: string, body: any) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}`;

  return api.post(endpoint, body);
};

/**
 * delete listing
 * @param search
 */
export const deleteListing = (id: string) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}`;
  return api.delete(endpoint);
};

/**
/**
 * Get sub-listings
 *
 * @param
 * @returns
 * @export
 */

export const getSubListings = async (id: string, page?: any) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}/index?page=${page}`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
/**
 * Get input fields
 *
 * @param
 * @returns
 * @export
 */

export const getInputFields = async (id: any, search?: any) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}/custom-fields`;
  const resp = await api.get(endpoint, { params: search });
  return resp.data;
};

/**
 * Get field types
 *
 * @returns field types data
 * @export
 */

export const fetchFieldTypes = () => {
  const endpoint = ENDPOINTS.CUSTOM_FIELDS + "/input-types";
  return api.get(endpoint).then(({ data }) => data);
};

/**
 * updates field
 * @param search
 */
export const updateField = (id: string, body: any, fieldId: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}/custom-fields/${fieldId}`;

  return api.post(endpoint, body);
};

/**
 * creates
 * @param search
 */
export const createField = (id: string, body: any) => {
  return api.post(ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}/custom-fields`, body);
};

/**
 * delete field
 * @param search
 */
export const deleteField = (id: string, fieldId: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES + `/${id}/custom-fields/${fieldId}`;
  return api.delete(endpoint);
};

/**
/**
 * Get options
 *
 * @param
 * @returns
 * @export
 */

export const getOptions = async (id: string, page?: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES + `/custom-fields/${id}/options?page=${page}`;
  const resp = await api.get(endpoint);
  return resp.data;
};
/**
/**
 * Get options
 *
 * @param
 * @returns
 * @export
 */

export const getParentOptions = async (id: string) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES + `/custom-fields/${id}/options?limit=999`;
  const resp = await api.get(endpoint);
  return resp.data;
};
/**
/**
 * Get options
 *
 * @param
 * @returns
 * @export
 */

export const getChildFieldOptions = async (id: string, parent: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/custom-fields/${id}/options?parentId=${parent}`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
 * create option
 * @param search
 */
export const createOption = (fieldId: string, body: any) => {
  return api.post(
    ENDPOINTS.DYNAMIC_CATEGORIES + `/custom-fields/${fieldId}/options`,
    body
  );
};

/**
 * updates option
 * @param search
 */
export const updateOption = (fieldId: string, body: any, optionId: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/custom-fields/${fieldId}/options/${optionId}`;

  return api.post(endpoint, body);
};

/**
 * delete option
 * @param search
 */
export const deleteOption = (fieldId: any, optionId) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/custom-fields/${fieldId}/options/${optionId}`;
  return api.delete(endpoint);
};

/**
/**
 * Get child options
 *
 * @param
 * @returns
 * @export
 */

export const getChildOptions = async (id: any, page?: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES + `/custom-fields/${id}/options?withParents=1`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
/**
 * Get children options
 *
 * @param
 * @returns
 * @export
 */

export const getChildrenOptions = async (customField: any, parentId: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/custom-fields/${customField}/options?parentId=${parentId}`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
 * enable parent field
 */
export const enableParentField = (
  listingId: string,
  body: any,
  fieldId: string
) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/${listingId}/toggle-parent-custom-field/${fieldId}`;
  return api.post(endpoint, body);
};

/**
/**
 * Get child options
 *
 * @param
 * @returns
 * @export
 */

export const filterChildOptions = async (id: any, search: any) => {
  const endpoint =
    ENDPOINTS.DYNAMIC_CATEGORIES +
    `/custom-fields/${id}/options?withParents=1&search=${search}`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
 * reorder fields
 */
export const reorderFields = (body: any, fieldId: any) => {
  const endpoint = ENDPOINTS.DYNAMIC_CATEGORIES + `/${fieldId}`;

  return api.post(endpoint, body);
};
