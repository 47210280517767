import React, { useEffect, useState } from "react";
import { MenuOutlined, PlusCircleOutlined } from "@ant-design/icons";
import styles from "./CustomFieldsTable.module.scss";
import { Button, Card, Modal, Table, Tag, message } from "antd";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import { getInputFields, reorderFields } from "app/api/dynamicCategoryApi";
import { useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import qs from "query-string";
import CustomFieldsModal from "./CustomFieldsModal/CustomFieldsModal";

const CustomFieldsTable = ({
  fieldVisible,
  selectedCategory,
  childVisible
}) => {
  const location = useLocation();
  const [fieldId, setFieldId] = useState();
  const [isChild, setIsChild] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [fieldsData, setFieldsData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [isCreateFieldsVisible, setIsCreateFieldsVisible] = useState<boolean>(
    false
  );

  useEffect(() => {
    loadCustomFields();
  }, []);

  const loadCustomFields = async () => {
    try {
      setLoading(true);
      const payload = await getInputFields(
        selectedCategory.id,
        qs.parse(location.search)
      );
      setFieldsData(payload);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const DragHandle = () => <MenuOutlined className={styles.dragVisible} />;

  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      render: () => <DragHandle />
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record: any, index) => (
        <Button
          onClick={
            record?.inputType?.name === "List"
              ? () => fieldVisible(record)
              : () => toggleFieldModal(record, true, undefined)
          }
          type="link"
        >
          {text}
        </Button>
      )
    },
    {
      title: "Required",
      dataIndex: "isOptional",
      key: "isOptional",
      render: record => (
        <Tag
          color={record ? "red" : "blue"}
          key={record ? "required" : "optional"}
        >
          {record ? "required" : "optional"}
        </Tag>
      )
    },
    {
      title: "inputType",
      dataIndex: "inputType",
      key: "inputType",
      render: item => <>{item?.name}</>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "30%",
      render: (text, record, index) => (
        <Button danger type="link" onClick={() => handleDeleteField(record)}>
          Delete
        </Button>
      )
    }
  ];

  const handleDeleteField = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this field?",
      content: `You’re about to delete  ${record.name}`,
      okText: "Yes, delete field",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteField(selectedCategory.id, record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Category Deleted");
              loadCustomFields();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const toggleFieldModal = (record, isChild, fieldId) => {
    setIsCreateFieldsVisible(true);
    setSelectedField(record);
    setIsChild(isChild);
    setFieldId(fieldId);
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    try {
      const items = [...fieldsData];

      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setFieldsData(items);

      const newData = items.map((item: any) => item.id);

      if (!newData.length) return;

      const body = { customFieldsOrder: newData };

      const response = await reorderFields(body, selectedCategory.id);

      if (response.status === 200) {
        loadCustomFields();
        message.success("Fields Reordered");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DraggableContainer = (props: any) => (
    <Droppable droppableId="fieldsData" {...props}>
      {provided => (
        <tbody
          className={styles.draggableContainer}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.children}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { "data-row-key": dataRowKey } = restProps;
    const index = fieldsData?.findIndex(x => x?.id === Number(dataRowKey));
    return (
      <Draggable
        key={`${dataRowKey}`}
        draggableId={`${dataRowKey}`}
        index={index !== -1 ? index : 0}
      >
        {provided => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...restProps}
            className={fieldsData?.length > 0 ? styles.draggableRow : ""}
          />
        )}
      </Draggable>
    );
  };

  const Header = () => (
    <div className={styles.categoryOverview}>
      <h1>Custom Fields</h1>
      <Button
        type="primary"
        onClick={() => toggleFieldModal(null, false, undefined)}
      >
        Add Custom Fields
      </Button>
    </div>
  );

  const childColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (text, record, index) => (
        <>
          <Button
            key={"edit"}
            type="link"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </Button>
          <Button
            key={"delete"}
            danger
            type="link"
            onClick={() => handleDeleteField(record)}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  const handleEditClick = record => {
    setIsChild(true);
    childVisible(record, true);
  };

  const expandedRowRender = record => (
    <div key={record.id} className={styles.childWrapper}>
      <Button
        onClick={() => toggleFieldModal(null, true, record.id)}
        icon={<PlusCircleOutlined />}
        block
      >
        Add Child
      </Button>
      <Table
        key={record.id}
        rowKey={childRecord => childRecord.childId}
        columns={childColumns}
        dataSource={record.children}
        pagination={false}
        showHeader={false}
      />
    </div>
  );

  return (
    <>
      {isCreateFieldsVisible && (
        <CustomFieldsModal
          selectedCategory={selectedCategory}
          selectedField={selectedField}
          onUpdate={() => loadCustomFields()}
          isVisible={isCreateFieldsVisible}
          onClose={() => setIsCreateFieldsVisible(false)}
          fieldId={fieldId}
          isChild={isChild}
          fieldVisible={undefined}
        />
      )}

      <Card className={styles.fieldsWrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Table
            rowKey={record => record.id}
            title={Header}
            showHeader={false}
            dataSource={fieldsData}
            columns={columns}
            loading={loading}
            expandable={{
              expandedRowRender,
              expandedRowKeys,
              rowExpandable: record => record.inputType?.id === 4,
              childrenColumnName: "child",
              onExpandedRowsChange: (newExpandedRowKeys: any) => {
                setExpandedRowKeys(newExpandedRowKeys);
              }
            }}
            pagination={false}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </DragDropContext>
      </Card>
    </>
  );
};

export default CustomFieldsTable;
