import React, { useContext, useEffect, useState } from "react";
import styles from "./ListingsTable.module.scss";
import {
  Button,
  Card,
  PageHeader,
  Table,
  TablePaginationConfig,
  Image,
  Popconfirm,
  message,
  Checkbox,
  Typography
} from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { PaginationConfig } from "antd/lib/pagination";
import qs from "query-string";

import { cancelListing, getListings, updateListing } from "app/api/listingsApi";
import { getListings as getCategories } from "app/api/dynamicCategoryApi";

import { QueryHelper } from "app/helpers/QueryHelper";
import moment from "moment";
import ListingsCreate from "./components/ListingsCreate/ListingsCreate";
import { useTranslation } from "react-i18next";
import { UserContext } from "app/store/contexts/userContext";
import { ListingData } from "app/types/listingType";
import { Form, Input, Row, Col, DatePicker } from "antd";
import PriceBreakdownModal from "./components/PriceBreakdownModal/PriceBreakdownModal";
import DebounceSelect from "../../components/DebounceSelect/DebounceSelect";
import { SelectItem } from "../../components/DebounceSelect/types";

interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const { Text } = Typography;

const ListingsTable = () => {
  const { Item } = Form;

  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  const path = location.pathname.slice(1);
  const [data, setData] = useState<ListingData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isSuperAdmin: boolean | undefined = user.user.isSuperAdmin;
  const [createListing, setIsCreateListing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION);
  const [isPriceBreakdown, setIsPriceBreakdown] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<any>();
  const [pendingPaymentsChecked, setPendingPaymentsChecked] = useState<
    boolean
  >();
  const [paymentToSellerChecked, setPaymentToSellerChecked] = useState<
    boolean
  >();
  const [shippedChecked, setShippedChecked] = useState<boolean>();

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const { t } = useTranslation();

  const handleCheckboxChange = (checked: boolean) => {
    setPendingPaymentsChecked(checked);
  };

  const handlePaidToSeller = (checked: boolean) => {
    setPaymentToSellerChecked(checked);
  };

  const handleShippedCheckboxChange = (checked: boolean) => {
    setShippedChecked(checked);
  };

  const togglePriceBreakdown = record => {
    setSelectedListing(record);
    setIsPriceBreakdown(true);
  };

  const getTypeFromPath = path => {
    switch (path) {
      case "active-listings":
        return "active";
      case "auction-requests":
        return "new";
      case "successful-orders":
        return "successful";
      case "unsuccessful-orders":
        return "unsuccessful";
      case "finalized-orders":
        return "finalized";
      default:
        return "active";
    }
  };

  const type = getTypeFromPath(path);

  useEffect(() => {
    loadListings();
  }, [location.search]);

  const loadListings = async () => {
    try {
      setLoading(true);
      const payload = await getListings(qs.parse(location.search), type);

      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values: any) => {
    const currentSearch = qs.parse(location.search);

    const startRangeTimeValue = values["createToAndFrom"];
    const endRangeTimeValue = values["endToAndFrom"];

    delete values["createToAndFrom"];
    delete values["endToAndFrom"];

    values = {
      ...currentSearch,
      ...values,
      listingTypeId: values.listingTypeId?.value,
      pendingEftPayments: pendingPaymentsChecked ? 1 : "",
      paymentToSeller: paymentToSellerChecked ? 0 : "",
      type: shippedChecked ? "shipped" : "",
      createdAtFrom: startRangeTimeValue
        ? startRangeTimeValue[0].format(dateFormat)
        : undefined,
      createdAtTo: startRangeTimeValue
        ? startRangeTimeValue[1].format(dateFormat)
        : undefined,
      endAtFrom: endRangeTimeValue
        ? endRangeTimeValue[0].format(dateFormat)
        : undefined,
      endAtTo: endRangeTimeValue
        ? endRangeTimeValue[1].format(dateFormat)
        : undefined
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const toggleListing = (record, action) => {
    history.push(`/${path}/${action}/${record.id}`);
  };

  const handleUpdateListing = async (record, updateType) => {
    try {
      const body = { state: updateType };
      await updateListing(record.id, body);
      message.success(
        `Listing successfully ${updateType === "paid" ? "paid" : "shipped"}.`
      );
      loadListings();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelListing = async record => {
    console.log(record);
    try {
      const body = { listingId: record.id };
      const response = await cancelListing(body);
      if (response.status === 200) {
        message.success("Listing canceled successfully");
        loadListings();
      } else {
        message.error("Failed to cancel the listing");
      }
    } catch (error) {
      message.error("An error occurred while canceling the listing");
    }
  };

  const confirm = record => {
    if (record) {
      handleUpdateListing(record, "paid");
    }
  };

  const confirmShipped = record => {
    if (record && record.isAdminListing) {
      handleUpdateListing(record, "shipped");
    }
  };

  const formattedTitle = path.replace(/-/g, " ");

  const handleClose = () => {
    setIsCreateListing(false);
    loadListings();
  };

  const handleReset = () => {
    form.resetFields();

    // Reset checkbox states to their default values
    setPendingPaymentsChecked(false);
    setPaymentToSellerChecked(false);
    setShippedChecked(false);
  };

  const columns = [
    {
      key: "lot",
      title: "Order",
      dataIndex: "lot",
      render: (lot, record) => (
        <Button
          className={styles.actionsCta}
          type="link"
          onClick={() => toggleListing(record, "edit")}
        >
          {lot}
        </Button>
      )
    },
    {
      title: "Media",
      key: "mediaPhotos",
      dataIndex: ["mediaPhotos", 0, "url"],
      render: mediaPhotos => (
        <Image
          style={{
            aspectRatio: "16/9",
            objectFit: "cover",
            width: "100%"
          }}
          alt="Listing"
          height={50}
          src={mediaPhotos}
        />
      )
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title"
    },
    {
      key: "saleType",
      title: "Sale type",
      dataIndex: "saleType"
    },
    {
      key: "category",
      title: "Category",
      dataIndex: ["listingType", "name"]
    },
    {
      key: "seller",
      title: "Seller Name",
      dataIndex: ["seller", "name"]
    },
    {
      key: "shop",
      title: "Shop Name",
      dataIndex: ["shop", "name"]
    },
    {
      key: "paymentMethod",
      title: "Payment Method",
      dataIndex: "paymentMethod",
      hidden: path === "active-listings" || path === "auction-requests"
    },
    {
      title: "Request submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: startAt => moment(startAt).format("YYYY-MM-DD HH:mm:ss")
    },

    {
      title: "End Date",
      dataIndex: "endedAt",
      key: "endedAt",
      hidden: path === "active-listings" || path === "auction-requests",
      sorter: (a: any, b: any) =>
        moment(a.endedAt).unix() - moment(b.endedAt).unix(),
      render: endedAt => moment(endedAt).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "Shipment Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt, record) =>
        record.state === "shipped"
          ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")
          : "-",
      hidden: path !== "successful-orders"
    },
    {
      key: "state",
      title: "State",
      dataIndex: "state"
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          {record.actions.map((action, index) => {
            switch (action) {
              case "review_auction":
                return (
                  <Button
                    key="review_auction"
                    type="link"
                    onClick={() => toggleListing(record, "review")}
                    className={styles.actionsCta}
                  >
                    Review
                  </Button>
                );
              case "edit_auction":
                return (
                  <Button
                    key="edit_auction"
                    type="link"
                    onClick={() => toggleListing(record, "edit")}
                    hidden={!isSuperAdmin}
                    className={styles.actionsCta}
                  >
                    Edit
                  </Button>
                );
              case "activate":
                return (
                  <Button
                    key="activate"
                    type="link"
                    onClick={() => toggleListing(record, "activate")}
                    className={styles.actionsCta}
                  >
                    <Text type="secondary">Activate</Text>
                  </Button>
                );
              case "end":
                return (
                  <Button
                    key="end"
                    type="link"
                    onClick={() => toggleListing(record, "edit")}
                    hidden={!isSuperAdmin}
                    className={styles.actionsCta}
                  >
                    View
                  </Button>
                );
              case "relist":
                return (
                  <Button
                    disabled={
                      record.type === "live-auction" || record.type === "show"
                    }
                    key="relist"
                    type="link"
                    onClick={() => toggleListing(record, "relist")}
                    className={styles.actionsCta}
                  >
                    Relist
                  </Button>
                );
              case "delivered":
                return (
                  <Button
                    key="price"
                    className={styles.actionsCta}
                    type="link"
                    onClick={() => togglePriceBreakdown(record)}
                    hidden={record.paymentToSeller}
                  >
                    View Price Breakdown
                  </Button>
                );
              case "paid":
                return (
                  <>
                    <Popconfirm
                      title={`Confirm payment for this listing?`}
                      onConfirm={() => confirm(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className={styles.actionsCta} type="link">
                        Paid
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={`Cancel pending payment for this listing?`}
                      onConfirm={() => handleCancelListing(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        hidden={record.state !== "pending_payment"}
                        danger
                        className={styles.actionsCta}
                        type="link"
                      >
                        Cancel
                      </Button>
                    </Popconfirm>
                  </>
                );
              case "shipped":
                return (
                  <>
                    <Popconfirm
                      title={`Confirm shipping for this listing?`}
                      onConfirm={() => confirmShipped(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className={styles.actionsCta}
                        type="link"
                        hidden={!record.isAdminListing}
                      >
                        Shipped
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={`Cancel pending payment for this listing?`}
                      onConfirm={() => handleCancelListing(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        hidden={record.state !== "pending_payment"}
                        danger
                        className={styles.actionsCta}
                        type="link"
                      >
                        Cancel
                      </Button>
                    </Popconfirm>
                  </>
                );
              default:
                return null;
            }
          })}
        </>
      )
    }
  ].filter(item => !item.hidden);

  const Header = () => (
    <PageHeader
      title={formattedTitle}
      className={styles.pageHeader}
      extra={[
        <Button
          type="primary"
          key="create"
          hidden={path !== "auction-requests"}
          onClick={() => setIsCreateListing(true)}
        >
          Create Listing
        </Button>
      ]}
    />
  );

  async function fetchCategoriesList(category: string): Promise<SelectItem[]> {
    return getCategories({
      name: category
    }).then(res => {
      return res.data.map(({ id, name }) => ({
        label: name,
        value: id
      }));
    });
  }

  return (
    <>
      {createListing ? (
        <ListingsCreate visible={() => handleClose()} />
      ) : (
        <>
          <div className={styles.tableContainer}>
            {isPriceBreakdown && (
              <PriceBreakdownModal
                isVisible={isPriceBreakdown}
                handleClose={() => setIsPriceBreakdown(false)}
                selectedListing={selectedListing}
                loadListings={() => loadListings()}
              />
            )}
            <Card className={styles.filterAndSearchContainer}>
              <Form
                onFinish={(values: any) => handleSearch(values)}
                form={form}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={5}>
                    <Item name="lot" label="Order Number">
                      <Input placeholder="e.g 121234" />
                    </Item>
                  </Col>
                  <Col span={5}>
                    <Item name="shopName" label={"Shop Name"}>
                      <Input placeholder={"e.g. John"} />
                    </Item>
                  </Col>
                  <Col span={5}>
                    <Item name="sellerName" label={"Seller Name"}>
                      <Input placeholder={"e.g. John"} />
                    </Item>
                  </Col>
                  <Col span={5}>
                    <Item name="listingTypeId" label={"Category"}>
                      <DebounceSelect
                        placeholder="eg. Cars"
                        fetchOptions={fetchCategoriesList}
                      />
                    </Item>
                  </Col>
                  <Col span={4}>
                    <Button
                      className={styles.searchButton}
                      type="primary"
                      htmlType="submit"
                    >
                      {t("default.searchButton")}
                    </Button>
                    <Button onClick={handleReset}>
                      {t("default.resetButton")}
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Item name="createToAndFrom" label={t("Request Submitted")}>
                      <RangePicker showTime size="middle" />
                    </Item>
                  </Col>
                  <Col span={6}>
                    <Item name="endToAndFrom" label={t("End Date")}>
                      <RangePicker showTime size="middle" />
                    </Item>
                  </Col>
                  <Col span={3} hidden={path !== "successful-orders"}>
                    <Item
                      name="pendingEftPayments"
                      hidden={path !== "successful-orders"}
                    >
                      <Checkbox
                        value={pendingPaymentsChecked ? true : false}
                        onChange={e => handleCheckboxChange(e.target.checked)}
                      >
                        Pending Payment
                      </Checkbox>
                    </Item>
                  </Col>
                  <Col span={3} hidden={path !== "finalized-orders"}>
                    <Item
                      name="paymentToSeller"
                      hidden={path !== "finalized-orders"}
                    >
                      <Checkbox
                        value={paymentToSellerChecked ? true : false}
                        onChange={e => handlePaidToSeller(e.target.checked)}
                      >
                        Payment To Seller
                      </Checkbox>
                    </Item>
                  </Col>
                  <Col span={3}>
                    <Item name="shipped" hidden={path !== "successful-orders"}>
                      <Checkbox
                        value={shippedChecked ? true : false}
                        onChange={e =>
                          handleShippedCheckboxChange(e.target.checked)
                        }
                      >
                        Shipped
                      </Checkbox>
                    </Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
          <div className={styles.tableContainer}>
            <Card>
              <Table
                key="index"
                title={Header}
                dataSource={data}
                columns={columns}
                loading={loading}
                rowKey={record => record.id}
                pagination={pagination}
                onChange={pagination => handleTableChanges(pagination)}
              />
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default ListingsTable;
