import { Button, Form, Input, Modal, message } from "antd";
import React from "react";
import * as notificationsApi from "app/api/notificationsApi";

const TranslationsModal = ({
  selectedNotification,
  isVisible,
  handleClose,
  onLoad
}) => {
  const { Item } = Form;
  let [form] = Form.useForm();

  const onFinish = values => {
    const body = new FormData();

    if (selectedNotification?.id !== undefined) {
      body.append("id", selectedNotification?.id);
    }

    if (values.notificationAr !== undefined) {
      body.append("value", values.notificationAr);
    }
    if (selectedNotification?.id !== undefined) {
      notificationsApi
        .updateNotifications(body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            onLoad();
            message.success("Fee updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    }
  };

  return (
    <Modal
      title="Update Translation"
      visible={isVisible}
      onCancel={handleClose}
      destroyOnClose
      onOk={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button form="createFeeForm" type="primary" htmlType="submit">
          {"Update Translation"}
        </Button>
      ]}
    >
      <Form
        name="createFeeForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div>
          <Item
            name="notificationEn"
            label={"Key:"}
            rules={[
              {
                required: true,
                message: "Please enter a given title."
              }
            ]}
            initialValue={selectedNotification.key}
          >
            <Input.TextArea
              rows={2}
              showCount
              maxLength={100}
              data-panel-key="2"
              readOnly
            />
          </Item>
          <Item
            name="notificationAr"
            rules={[
              {
                required: true,
                message: "Please enter a given Arabic translation."
              }
            ]}
            label={`${selectedNotification.language} Translation:`}
            initialValue={selectedNotification.value}
          >
            <Input.TextArea
              rows={2}
              showCount
              maxLength={100}
              data-panel-key="2"
            />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default TranslationsModal;
