import { CloseOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import AuctionShowsForm from "../AuctionShowsForm/AuctionShowsForm";
import LotsTable from "../LotsTable/LotsTable";
import styles from "./AuctionShowsTabs.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getEventDetails } from "app/api/auctionShowsApi";
import RegisteredUsersList from "app/screens/LiveAuctionsScreen/components/RegisteredUsers/RegisteredUsersList";
import { AuctionData } from "app/types/auctionShowsType";

const AuctionShowsTabs = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<AuctionData>();
  const path = location.pathname.slice(1).replace(/\/\d+$/, "");
  const [activeTab, setActiveTab] = useState<string>("general");
  const formattedTitle = selectedEvent?.auctionType?.replace(/-/g, " ") ?? " ";

  useEffect(() => {
    loadEventDetails();
  }, [id]);

  const loadEventDetails = () => {
    setLoading(true);

    getEventDetails(id)
      .then(payload => {
        setSelectedEvent(payload);
      })
      .catch(error => {
        error("Something went wrong", error);
      })
      .finally(() => setLoading(false));
  };

  const tabList = [
    {
      key: "general",
      tab: "General"
    },
    {
      key: "lots",
      tab: "Manage Lots"
    },
    {
      key: "registeredUsers",
      tab: "Registered Users",
      hidden:
        selectedEvent?.auctionType !== "live-auction" || path === "new-events"
    }
  ].filter(item => !item.hidden);

  const handleClose = () => {
    history.goBack();
  };

  const contentList: Record<string, React.ReactNode> = {
    general: (
      <AuctionShowsForm
        key={selectedEvent?.id}
        selectedEvent={selectedEvent}
        handleClose={handleClose}
        path={path}
        onLoadDetails={loadEventDetails}
      />
    ),
    lots: (
      <LotsTable
        key={selectedEvent?.id}
        onLoadDetails={loadEventDetails}
        selectedEvent={selectedEvent}
        path={path}
        load={loading}
      />
    ),
    registeredUsers: <RegisteredUsersList selectedLiveAuction={selectedEvent} />
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className={styles.tableContainer}>
      <Card
        title={`edit ${formattedTitle}`}
        extra={
          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={() => history.goBack()}
          />
        }
        tabList={tabList}
        activeTabKey={activeTab}
        onTabChange={key => {
          onTabChange(key);
        }}
      >
        {contentList[activeTab]}
      </Card>
    </div>
  );
};

export default AuctionShowsTabs;
