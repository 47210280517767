import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";
import { WithdrawalType } from "app/types/userType";

/**
 * Gets a list of users, with an optional search parameter
 * @param search
 */
export const getBackOfficeUsers = (search: any) => {
  const endpoint = `${ENDPOINTS.ADMIN_USERS}?role=back-office-user`;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * Gets a list of users, with an optional search parameter
 * @param search
 */
export const getAppUsers = (search: any) => {
  const endpoint = ENDPOINTS.ADMIN_USERS;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * updates a user object
 * @param search
 */
export const editUser = (id: number, body: object) => {
  const endpoint = ENDPOINTS.ADMIN_USERS + `/${id}`;
  return api.patch(endpoint, { ...body });
};

/**
 * Gets a list of users, with an optional search parameter
 * @param search
 */
export const getUserRoles = () =>
  api.get(ENDPOINTS.ROLES).then(resp => resp.data);

/**
 * creates a user
 * @param search
 */
export const createUser = (body: object) => {
  return api.post(ENDPOINTS.ADMIN_USERS, { ...body });
};

/**
 * creates a deposit
 * @param search
 */
export const assignDeposit = (id: number, body: object) => {
  const endpoint = `${ENDPOINTS.ADMIN_USERS}/${id}`;
  return api.patch(endpoint, { ...body });
};

/**
 * delete a deposit
 * @param search
 */
export const removeDeposit = (id: number) => {
  const endpoint = ENDPOINTS.DEPOSITS + `/${id}`;
  return api.delete(endpoint);
};

/**
 * lists all deposits in the db
 * @param search
 */
export const listAllDeposits = (body: object) => {
  return api.get(ENDPOINTS.DEPOSITS, { ...body });
};

/**
 * lists a users deposits
 * @param search
 */
export const listUserDeposits = (id: number, body: any, page: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USERS}/${id}/deposits?page=${page}`;
  return api.get(endpoint, { ...body }).then(resp => resp.data);
};

export const getUser = (id: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USERS}/${id}`;
  return api.get(endpoint).then(resp => resp.data);
};

export const deleteUser = (id: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USERS}/${id}`;
  return api.delete(endpoint);
};

export const uploadIbanDoc = (body: any, isFormData: boolean = false) => {
  const endpoint = ENDPOINTS.UPLOAD_IBAN_DOC;

  if (isFormData) {
    return api.post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  return api.post(endpoint, body);
};

export const ConfirmIbanDoc = (body: any, isFormData: boolean = false) => {
  const endpoint = `${ENDPOINTS.UPLOAD_IBAN_DOC}/confirm`;

  if (isFormData) {
    return api.post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  return api.post(endpoint, body);
};

/**
 * Gets a list of user that have requests wallet withdrawals
 *
 * @param {*} search
 * @return {*}
 */
export const getWithdrawalRequests = (search: any) => {
  const endpoint = `${ENDPOINTS.ADMIN_USER_WITHDRAWAL}`;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * Action the wallet withdrawal request to processing
 *
 * @param {number} walletId
 * @return {*}
 */
export const withdrawalProcess = (walletId: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USER_WITHDRAWAL}/processing`;
  return api
    .patch(endpoint, {
      walletWithdrawId: walletId,
      state: WithdrawalType.Processing
    })
    .then(resp => resp.data);
};

/**
 * Action the wallet withdrawal request to approved
 *
 * @param {number} walletId
 * @return {*}
 */
export const withdrawalCompleted = (walletId: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USER_WITHDRAWAL}/approve`;
  return api
    .patch(endpoint, {
      walletWithdrawId: walletId,
      state: WithdrawalType.Completed
    })
    .then(resp => resp.data);
};

/**
 * Action the wallet withdrawal request to cancelled
 *
 * @param {number} walletId
 * @return {*}
 */
export const withdrawalCancel = (walletId: number) => {
  const endpoint = `${ENDPOINTS.ADMIN_USER_WITHDRAWAL}/cancel`;
  return api
    .patch(endpoint, {
      walletWithdrawId: walletId,
      state: WithdrawalType.Cancelled
    })
    .then(resp => resp.data);
};

/**
 * updates a user object
 * @param search
 */
export const updateShopTrusted = (id: number, body: any) => {
  const endpoint = ENDPOINTS.SHOPS + `/${id}/trusted`;
  return api.post(endpoint, { ...body });
};
