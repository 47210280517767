import React, { useEffect, useState } from "react";
import styles from "./ListOptionsTable.module.scss";
import {
  Button,
  Card,
  Modal,
  Table,
  TablePaginationConfig,
  message
} from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import OptionsCreateModal from "../OptionsCreateModal/OptionsCreateModal";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const ListOptionsTable = ({ selectedField }) => {
  const [data, setData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [isCreateOptions, setIsCreateOptions] = useState<boolean>(false);

  useEffect(() => {
    loadFieldOptions(1);
  }, []);

  const loadFieldOptions = async page => {
    try {
      setLoading(true);
      const payload = await dynamicCategoriesApi.getOptions(
        selectedField.id,
        page
      );
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadFieldOptions(newPagination.current);
  };

  const handleDeleteField = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this field?",
      content: `You’re about to delete  ${record.name}`,
      okText: "Yes, delete field",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteOption(selectedField.id, record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Option Deleted");
              loadFieldOptions(1);
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const handleToggleOption = (record, visible) => {
    setSelectedOption(record);
    setIsCreateOptions(visible);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "65%",
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => handleToggleOption(record, true)}>
            Edit
          </Button>
          <Button danger type="link" onClick={() => handleDeleteField(record)}>
            Delete
          </Button>
        </>
      )
    }
  ];

  const Header = () => (
    <div className={styles.categoryOverview}>
      <h1>Options</h1>
      <Button type="primary" onClick={() => handleToggleOption(null, true)}>
        Add Options
      </Button>
    </div>
  );

  return (
    <>
      {isCreateOptions && (
        <OptionsCreateModal
          isVisible={isCreateOptions}
          onClose={() => setIsCreateOptions(false)}
          selectedOption={selectedOption}
          selectedField={selectedField}
          childOption={undefined}
          fieldId={undefined}
          onUpdate={() => loadFieldOptions(1)}
        />
      )}

      <Card className={styles.optionsWrapper}>
        <Table
          title={Header}
          showHeader={false}
          size="small"
          dataSource={data}
          columns={columns}
          pagination={pagination}
          loading={loading}
          onChange={pagination => handleTableChanges(pagination)}
        />
      </Card>
    </>
  );
};

export default ListOptionsTable;
