import { AppstoreAddOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import PageLayoutTable from "app/screens/PageLayoutScreen/PageLayoutTable";

export const PAGE_LAYOUT_SCREEN: IMenuItemType = {
  id: "page-layout",
  path: "/page-layout",
  title: "Page Layout",
  icon: AppstoreAddOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const DISCOVER_SCREEN: IMenuItemType = {
  id: "discover",
  path: "/discover",
  component: PageLayoutTable,
  title: "Discover",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const SHOP_SCREEN: IMenuItemType = {
  id: "shop",
  path: "/shop",
  component: PageLayoutTable,
  title: "Shop",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const SHOWS_SCREEN: IMenuItemType = {
  id: "show",
  path: "/show",
  component: PageLayoutTable,
  title: "Show",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const LISTINGS_SCREEN: IMenuItemType = {
  id: "auction",
  path: "/auction",
  component: PageLayoutTable,
  title: "Auction",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

PAGE_LAYOUT_SCREEN.subMenuItems = [
  DISCOVER_SCREEN,
  SHOP_SCREEN,
  SHOWS_SCREEN,
  LISTINGS_SCREEN
];
