import { FundOutlined } from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
import ManageFees from "app/screens/ManageFees/ManageFees";
import { IMenuItemType } from "app/types/routerType";

export const FEE_SCREEN: IMenuItemType = {
  id: "manage-fees",
  path: "/manage-fees",
  title: "Manage Fees",
  icon: FundOutlined,
  component: ManageFees,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};
