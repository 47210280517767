/* eslint-disable react-hooks/exhaustive-deps */
import { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "./ReviewAuctionItems.module.scss";
import { Checkbox, Upload } from "antd";
import PreviewItem from "../../AuctionPreviewFormItems/components/PreviewItem";
import { InfoCircleOutlined } from "@ant-design/icons";
import { auctionType } from "app/types/auctionType";
import {
  getVehicleMake,
  getVehicleModel,
  getVehicleTrim
} from "app/api/auctionVehiclesApi";
import { formatNumber } from "app/helpers/numberFormatHelper";

interface FormItemProps {
  isActive: boolean;
  disableNext: (disabled: boolean) => void;
  isMachinery?: boolean;
  auctionInformationValue?: auctionType;
}

interface FormItemsWithContextProps extends FormItemProps {
  form: FormInstance;
}

export const ReviewAuctionItems = ({
  form,
  isActive,
  disableNext,
  isMachinery,
  auctionInformationValue
}: FormItemsWithContextProps) => {
  const { t } = useTranslation();
  const images = form.getFieldValue("vehicleImages");
  const comingSoon = form.getFieldValue("comingSoon");
  const saleType = form.getFieldValue("saleType");
  const downPayment = form.getFieldValue("downPayment");
  const advancedInspectionReports = form.getFieldValue(
    "advancedInspectionReports"
  );
  const initialInspectionReports = form.getFieldValue(
    "initialInspectionReports"
  );
  const vehicleVideo = form.getFieldValue("vehicleVideo");
  const startDate = moment(form.getFieldValue("startDate")).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const endDate = moment(form.getFieldValue("endDate")).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const upcomingDate = form.getFieldValue("upcomingDate")
    ? moment
        .utc(form.getFieldValue("upcomingDate"))
        .format("YYYY-MM-DD HH:mm:ss")
    : undefined;
  const completionDate = form.getFieldValue("completionDate")
    ? moment
        .utc(form.getFieldValue("completionDate"))
        .format("YYYY-MM-DD HH:mm:ss")
    : undefined;
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [vehicleMake, setVehicleMake] = useState<string>();
  const [vehicleModel, setVehicleModel] = useState<string>();
  const [vehicleTrim, setVehicleTrim] = useState<string>();
  const vehicleVin = form.getFieldValue("vinNumber");

  useEffect(() => {
    if (isActive) {
      setCheckboxChecked(false);
      disableNext(true);
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      if (checkboxChecked === true) {
        disableNext(false);
      } else {
        disableNext(true);
      }
    }
  }, [checkboxChecked]);

  useEffect(() => {
    const vehicleMakeField = form.getFieldValue("make");
    const vehicleModelField = form.getFieldValue("model");
    const vehicleTrimField = form.getFieldValue("trim");

    if (isActive && !isMachinery) {
      if (!vehicleMake && vehicleMakeField) {
        getVehicleMake(vehicleMakeField)
          .then(payload => setVehicleMake(payload.name))
          .catch(error => console.log(error));
      }

      if (!vehicleModel && vehicleModelField) {
        getVehicleModel(vehicleModelField)
          .then(payload => setVehicleModel(payload.name))
          .catch(error => console.log(error));
      }

      if (vehicleTrimField && !vehicleTrim) {
        getVehicleTrim(vehicleTrimField)
          .then(payload => setVehicleTrim(payload.name))
          .catch(error => console.log(error));
      }
    }
  });

  const handleCheckbox = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  return (
    <div>
      <div
        className={cx(styles.previewContainer, { [styles.isActive]: isActive })}
      >
        <div className={styles.reviewAndConfirmInfo}>
          <div>
            <InfoCircleOutlined className={styles.infoCircle} />
            <span className={styles.reviewAndConfirmTitle}>
              {t("newAuctions.reviewTitle")}
            </span>
          </div>
          <div>
            <span>{t("newAuctions.reviewInfo")}</span>
          </div>
        </div>

        <h3>{t("newAuctions.imagesTitle")}</h3>
        <div className={styles.previewImagesContainer}>
          {!!images &&
            images.map((image, index) => {
              const fileList: any = [
                { uid: index, url: image.url || image.thumbUrl }
              ];
              return (
                <div key={index}>
                  <Upload
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: false
                    }}
                    listType="picture-card"
                    fileList={fileList}
                  />
                </div>
              );
            })}
        </div>
        <h3>{t("newAuctions.sellerInformationPreviewLabel")}</h3>
        <PreviewItem label={"Title"} value={form.getFieldValue("title")} />
        {!isMachinery ? (
          <div className={styles.formPreviewItemsContainer}>
            <PreviewItem
              label={t("newAuctions.givenNameLabelTitle")}
              value={auctionInformationValue?.seller.firstName}
            />
            <PreviewItem
              label={t("newAuctions.surNameLabelTitle")}
              value={auctionInformationValue?.seller.lastName}
            />
            <PreviewItem
              label={t("newAuctions.addressLabelTitle")}
              value={auctionInformationValue?.seller.address}
            />

            <PreviewItem
              label={t("newAuctions.cityLabelTitle")}
              value={auctionInformationValue?.seller.city}
            />
            <PreviewItem
              label={t("newAuctions.regionLabelTitle")}
              value={form.getFieldValue("region")}
            />
            <PreviewItem
              label={t("newAuctions.emailLabelTitle")}
              value={auctionInformationValue?.seller.email}
            />
            <PreviewItem
              label={t("newAuctions.phoneLabelTitle")}
              value={auctionInformationValue?.seller.mobile}
            />
          </div>
        ) : (
          <PreviewItem
            label={t("newAuctions.appUserLabelTitle")}
            value={auctionInformationValue?.seller.firstName}
          />
        )}

        <h3>{t("newAuctions.vehicleInformationPreviewLabel")}</h3>

        {!isMachinery && auctionInformationValue?.auctionVehicleTypeId === 1 && (
          <div className={styles.formPreviewItemsContainer}>
            <PreviewItem
              label={t("newAuctions.makeLabelTitle")}
              value={vehicleMake}
            />

            <PreviewItem
              label={t("newAuctions.modelLabelTitle")}
              value={vehicleModel}
            />

            <PreviewItem
              label={t("newAuctions.yearLabelTitle")}
              value={form.getFieldValue("year")}
            />

            <PreviewItem
              label={t("newAuctions.mileageLabelTitle")}
              value={form.getFieldValue("mileage")}
            />

            <PreviewItem
              label={t("newAuctions.exteriorColorLabelTitle")}
              value={form.getFieldValue("exteriorColor")}
            />

            <PreviewItem
              label={t("newAuctions.interiorColorLabelTitle")}
              value={form.getFieldValue("interiorColor")}
            />

            {/* <PreviewItem
              label={t('newAuctions.trimLabelTitle')}
              value={vehicleTrim}
            /> */}

            {/* <PreviewItem
              label={t('newAuctions.seatLabelTitle')}
              value={form.getFieldValue('seats')}
            /> */}

            {/* <PreviewItem
              label={t('newAuctions.doorLabelTitle')}
              value={form.getFieldValue('doors')}
            /> */}

            {/* <PreviewItem
              label={t("newAuctions.horsePowerLabelTitle")}
              value={form.getFieldValue("horsepower")}
            /> */}

            <PreviewItem
              label={t("newAuctions.cylindersLabelTitle")}
              value={form.getFieldValue("cylinders")}
            />

            <PreviewItem
              label={t("newAuctions.transmissionLabelTitle")}
              value={form.getFieldValue("transmission")}
            />

            <PreviewItem
              label={t("newAuctions.fuelTypeLabelTitle")}
              value={form.getFieldValue("fuelType")}
            />

            {/* <PreviewItem
              label={t('newAuctions.bodyTypeLabelTitle')}
              value={form.getFieldValue('bodyType')}
            /> */}

            {vehicleVin && (
              <PreviewItem
                label={t("newAuctions.vinLabelTitle")}
                value={form.getFieldValue("vinNumber")}
              />
            )}

            {/* <PreviewItem
              label={t('newAuctions.interiorConditionLabelTitle')}
              value={form.getFieldValue('interiorCondition')}
            />

            <PreviewItem
              label={t('newAuctions.exteriorConditionLabelTitle')}
              value={form.getFieldValue('exteriorCondition')}
            /> */}
          </div>
        )}

        <PreviewItem
          label={t("newAuctions.sellerTypeLabelTitle")}
          value={form.getFieldValue("seller[type]")}
        />

        <PreviewItem
          label={t("newAuctions.saudiIdLabelTitle")}
          value={form.getFieldValue("seller[saudiId]")}
        />

        <PreviewItem
          label={t("newAuctions.corpVatLabelTitle")}
          value={form.getFieldValue("seller[companyVatNo]")}
        />

        <PreviewItem
          label={t("newAuctions.commercialRegistrationLabelTitle")}
          value={form.getFieldValue("seller[commercialRegistration]")}
        />

        <PreviewItem
          label={t("newAuctions.descriptionLabelTitle")}
          value={form.getFieldValue("description")}
        />

        <PreviewItem
          label={t("newAuctions.arabicStepTitle")}
          value={form.getFieldValue("descriptionLocalized")?.ar}
        />

        <h3>{t("newAuctions.uploadedDocumentsPreviewLabel")}</h3>

        <PreviewItem
          label={t("Initial Inspection Reports")}
          value={
            initialInspectionReports && (
              <Upload
                listType="text"
                multiple
                disabled
                fileList={initialInspectionReports.map(report => report.file)}
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false
                }}
              ></Upload>
            )
          }
        />

        <PreviewItem
          label={t("Advanced Inspection Reports")}
          value={
            advancedInspectionReports && (
              <Upload
                listType="text"
                multiple
                disabled
                fileList={advancedInspectionReports.map(report => report.file)}
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false
                }}
              ></Upload>
            )
          }
        />

        <PreviewItem
          label={t("newAuctions.videoUploadLabelTitle")}
          value={
            !!vehicleVideo &&
            vehicleVideo.map((video, index) => (
              <span key={`video-${index}`}>{video.name}</span>
            ))
          }
        />

        <h3>{t("newAuctions.auctionInformationPreviewLabel")}</h3>

        <PreviewItem
          label={t("newAuctions.buyNowLabelTitle")}
          value={
            formatNumber(form.getFieldValue("vehiclePrice")) +
            " " +
            (auctionInformationValue?.vehiclePrice?.currency?.code
              ? auctionInformationValue?.vehiclePrice?.currency?.code
              : "SAR")
          }
        />
        {downPayment && (
          <PreviewItem
            label="Down Payment Amount:"
            value={formatNumber(downPayment) + " SAR"}
          />
        )}

        {saleType !== "sale" && (
          <>
            <PreviewItem
              label={t("newAuctions.startPriceLabelTitle")}
              value={
                formatNumber(form.getFieldValue("startPrice")) +
                  " " +
                  auctionInformationValue?.startingPrice?.currency?.code ??
                "SAR"
              }
            />
            <PreviewItem
              label="Reserved Price:"
              value={formatNumber(form.getFieldValue("reservedPrice")) + " SAR"}
            />
            <PreviewItem
              label="Minimum bid increment amount:"
              value={
                formatNumber(form.getFieldValue("minimumBidAmount")) +
                  " " +
                  auctionInformationValue?.minimumBidAmount?.currency?.code ??
                "SAR"
              }
            />
          </>
        )}

        <PreviewItem
          label="Can accept offers"
          value={form.getFieldValue("canAcceptOffers") ? "True" : "False"}
        />
        <PreviewItem
          label="Featured:"
          value={form.getFieldValue("featured") ? "True" : "False"}
        />
        <PreviewItem
          label="Coming Soon Carousel:"
          value={form.getFieldValue("comingSoon") ? "True" : "False"}
        />

        <PreviewItem
          label={t("newAuctions.startDateLabelTitle")}
          value={startDate}
        />

        <PreviewItem label="End date:" value={endDate} />
        {comingSoon && (
          <>
            <PreviewItem label="Upcoming date:" value={upcomingDate} />
            <PreviewItem label="Completion date:" value={completionDate} />
          </>
        )}

        <PreviewItem
          label={t("newAuctions.latitudeLabelTitle")}
          value={form.getFieldValue("addOns[delivery][coordinates][latitude]")}
        />

        <PreviewItem
          label={t("newAuctions.longitudeLabelTitle")}
          value={form.getFieldValue("addOns[delivery][coordinates][longitude]")}
        />

        <PreviewItem
          label={t("newAuctions.sellerBankLabelTitle")}
          value={form.getFieldValue("seller[favouriteBankId]")}
        />
      </div>
      {isActive && (
        <div
          className={cx(styles.checkboxContainer, {
            [styles.isActive]: isActive
          })}
        >
          <Checkbox checked={checkboxChecked} onChange={handleCheckbox}>
            {t("newAuctions.confirmCheckboxText")}
          </Checkbox>
        </div>
      )}
    </div>
  );
};
