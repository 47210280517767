import React, { memo, useState, useEffect } from "react";
import styles from "./BuyNowRequests.module.scss";
import { Button, Card, DatePicker, Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import { Select, Checkbox } from "antd";
import { Form, Input, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import {
  cancelBuyNowRequest,
  confirmBuyNowRequest,
  getAuctionBuyNowRequests,
  getVehicleTypes
} from "app/api/auctionVehiclesApi";
import { vehicleConfigType } from "app/types/vehicleConfigType";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";
import { useHistory, useLocation } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { buyNowRequestType, vehicleType } from "app/types/auctionType";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { capitalize } from "app/helpers/capitalize";
import moment from "moment";
import cx from "classnames";

const BuyNowRequestsScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<buyNowRequestType[]>([]);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const [expandedText] = useState<string>("Unfold");
  const [featuredChecked, setFeaturedChecked] = useState<boolean>();
  const [vehicleTypesList, setVehicleTypesList] = useState<vehicleType[]>([]);
  const [vehiclesMakeData, setVehiclesMakeData] = useState<
    [{ id: string; name: string }]
  >();
  const [form] = Form.useForm();
  const { Item } = Form;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const handleCheckboxChange = event => {
    setFeaturedChecked(event.target.checked);
  };
  const vehicleConfigs: vehicleConfigType = getVehicleConfigsFromSessionStorage();

  useEffect(() => {
    getVehicleTypes()
      .then(res => {
        setVehicleTypesList(res);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getData();

    if (vehicleConfigs !== null) {
      setVehiclesMakeData(vehicleConfigs?.filterOptions?.vehicleMakes);
    }
  }, [location.search]);

  const getData = () => {
    setLoading(true);
    getAuctionBuyNowRequests(undefined, qs.parse(location.search))
      .then(payload => {
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values,
      featured: featuredChecked ? 1 : ""
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const getAuctionVehicleTypeName = id => {
    if (id) {
      const listItem = vehicleTypesList.filter(x => x.id === id);
      if (listItem && listItem[0]?.name) {
        return listItem[0].name;
      } else {
        return "";
      }
    }
    return "";
  };

  const handleRecordUpdate = (record, status) => {
    const currentSaleRequest = record;
    if (currentSaleRequest) {
      const body = { [status]: true };
      confirmBuyNowRequest(currentSaleRequest.id, body)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };

  const handleAcceptReject = record => {
    const currentSaleRequest = record;
    if (currentSaleRequest) {
      cancelBuyNowRequest(currentSaleRequest.id)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };

  return (
    <div className={styles.tableContainer}>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <Item name="lot" label={t("currentAuctions.lotTitle")}>
                <Input
                  type="number"
                  placeholder={t("currentAuctions.lotPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerName" label={t("currentAuctions.sellerTitle")}>
                <Input placeholder={t("currentAuctions.sellerPlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerEmail" label={t("currentAuctions.emailTitle")}>
                <Input
                  type="email"
                  placeholder={t("currentAuctions.emailPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setExpanded(!isExpanded);
                }}
              >
                {isExpanded ? <UpOutlined /> : <DownOutlined />}
                {isExpanded ? expandedText : "Collapse"}
              </Button>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            className={cx(styles.expanded, { [styles.isExpanded]: isExpanded })}
          >
            <Col span={6}>
              <Item name="phone" label={t("currentAuctions.phoneTitle")}>
                <Input placeholder={t("currentAuctions.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="make" label={t("currentAuctions.makeTitle")}>
                <Select
                  mode="multiple"
                  placeholder={t("currentAuctions.makePlaceholder")}
                >
                  {vehiclesMakeData?.map((make, index) => (
                    <Option key={index} value={make.id}>
                      {make.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>

            <Col span={6}>
              <Item name="auctionVehicleTypeIds" label="Vehicle Type">
                <Select mode="multiple" placeholder="e.g. Cars">
                  {vehicleTypesList?.map((type, index) => (
                    <Option key={index} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row
            className={cx(styles.expanded, { [styles.isExpanded]: isExpanded })}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={8}>
              <Item name="startToAndFrom" label={t("Auction Started")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="endToAndFrom" label={t("currentAuctions.endAtTitle")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="featured" label="Featured">
                <Checkbox
                  value={featuredChecked ? true : false}
                  onChange={handleCheckboxChange}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <div className={styles.auctionRequestOverview}>
          <h1>{t("newAuctions.buyNowRequestTitle")}</h1>
        </div>
        <div className={styles.auctionRequestTable}>
          <Table
            dataSource={data}
            loading={loading}
            rowKey="id"
            onChange={pagination => handleTableChanges(pagination)}
            pagination={pagination}
          >
            <Column
              key="lot"
              title={t("newAuctions.lotTitle")}
              dataIndex={["auctionVehicle", "lotId"]}
              render={(text, record, index) => text}
            />
            <Column
              key="createdAt"
              title={t("currentAuctions.createdAtTitle")}
              dataIndex="createdAt"
              render={date => moment(date).format(dateFormat)}
            />
            <Column
              key="itemtype"
              title="Item Type"
              render={data => capitalize(data.saleType)}
            />
            <Column
              key="auctiontype"
              title="Category"
              render={data =>
                getAuctionVehicleTypeName(
                  data.auctionVehicle?.auctionVehicleTypeId
                )
              }
            />
            <Column
              key="seller"
              title={t("newAuctions.buyerTitle")}
              render={text => text.buyer?.name}
            />
            <Column
              key="email"
              title={t("newAuctions.emailTitle")}
              render={text => text.buyer?.email}
            />
            <Column
              key="phone"
              title={t("newAuctions.phoneTitle")}
              render={text => text.buyer?.mobile}
            />
            <Column
              key="sellerPayment"
              title={t("newAuctions.startingPriceTitle")}
              render={text =>
                text.auctionVehicle?.startingPrice?.currency?.code +
                " " +
                text.auctionVehicle?.startingPrice?.amount
              }
            />
            <Column
              key="buyerPayment"
              title={t("newAuctions.buyNowPriceTitle")}
              render={text =>
                text.auctionVehicle?.BuyNowPrice?.currency?.code +
                " " +
                text.auctionVehicle?.BuyNowPrice?.amount
              }
            />
            <Column
              key="buyerDeposit"
              title={t("newAuctions.buyNowColumnTitle")}
              render={text =>
                text.deposit?.currency?.code + " " + text.deposit?.amount
              }
            />
            <Column
              key="buyerTotalPrice"
              title={t("newAuctions.buyNowTotalPriceTitle")}
              render={text =>
                text.totalPrice?.currency?.code + " " + text.totalPrice?.amount
              }
            />
            <Column
              key="auctionState"
              title="State"
              render={text => text.state}
            />
            <Column
              key="auctionState"
              title="Item State"
              render={text => text.auctionVehicle?.state}
            />
            <Column
              key="action"
              title={t("newAuctions.actionsTitle")}
              render={(text, record, index) => {
                return (
                  <div>
                    {text.actions.map(action => {
                      let key, title, Icon, buttonText, onConfirm, value;

                      switch (action) {
                        case "confirmBuyerAdminFee":
                          key = "confirm_buyer_admin_fee";
                          title =
                            "Are you sure you want to confirm buyer admin fee?";
                          Icon = null;
                          buttonText = "Confirm Buyer Admin Fee";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "confirmBuyerAdminFee");
                          break;

                        case "confirmSellerAdminFee":
                          key = "confirm_seller_admin_fee";
                          title =
                            "Are you sure you want to confirm seller admin fee?";
                          Icon = null;
                          buttonText = "Confirm Seller Admin Fee";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "confirmSellerAdminFee");
                          break;

                        case "transferOfOwnershipComplete":
                          key = "transfer_of_ownership_complete";
                          title =
                            "Are you sure you want to complete transfer ownership?";
                          Icon = null;
                          buttonText = "Transfer Ownership";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(
                              record,
                              "transferOfOwnershipComplete"
                            );
                          break;

                        case "buyerPaymentComplete":
                          key = "complete_buyer_purchase";
                          title =
                            "Are you sure you want to complete buyer purchase?";
                          Icon = null;
                          buttonText = "Complete Buyer Purchase";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "buyerPaymentComplete");
                          break;

                        case "delivered":
                          key = "delivered";
                          title =
                            "Are you sure you want to delivered the request?";
                          Icon = null;
                          buttonText = "Deliver";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "delivered");
                          break;

                        case "reject": {
                          key = "reject";
                          title =
                            "Are you sure you want to reject the request?";
                          Icon = (
                            <DeleteOutlined
                              style={{
                                color: "red"
                              }}
                            />
                          );
                          buttonText = "Reject";
                          value = true;
                          onConfirm = () => handleAcceptReject(record);
                          break;
                        }

                        default:
                          return null;
                      }
                      return (
                        <Popconfirm
                          key={key}
                          onConfirm={onConfirm}
                          icon={Icon}
                          title={title}
                        >
                          <div className={styles.actionButton}>
                            <Button danger={value} key={key} type="link">
                              {buttonText}
                            </Button>
                          </div>
                        </Popconfirm>
                      );
                    })}
                  </div>
                );
              }}
            />
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default memo(BuyNowRequestsScreen);
