import React, { memo, useEffect, useState } from "react";
import { Card, Table } from "antd";
import Column from "antd/lib/table/Column";

import // getEndedInspectionReportRequests
// updateInspectionReportstatus,
// updateAuctionVehicle,
// sendInspectionReportNotification,
"app/api/auctionVehiclesApi";

// import InspectionReportModal from '../InspectionReports/components/InspectionReportsModal';
import { useLocation, useHistory } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import styles from "../InspectionReports/InspectionReports.module.scss";
import { inspectionReportRequestType } from "app/types/inspectionReportRequestType";
import { formatNumber } from "app/helpers/numberFormatHelper";
import { getInspectionReportRequests } from "app/api/auctionVehiclesApi";
// import { useTranslation } from 'react-i18next';

const EndedInspectionReports = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<inspectionReportRequestType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  // const [selectedInspectionReport, setSelectedInspectionReport] = useState<
  //   inspectionReportRequestType
  // >();

  // const [
  //   // isEditInspectionReportModalVisible,
  //   // setEditInspectionReportModalVisible,
  // ] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    try {
      const payload = await getInspectionReportRequests(
        qs.parse(location.search),
        "confirmed"
      );
      setData(payload.data);
      setPagination({
        ...pagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  /**
   * Edit inspection report  modal
   */

  // const showEditInspectionModal = (index: number) => {
  //   setSelectedInspectionReport(data[index]);
  //   setEditInspectionReportModalVisible(true);
  // };

  // const hideEditInspectionReportModal = () => {
  //   setEditInspectionReportModalVisible(false);
  // };

  // const handleInspectionReportSubmit = (values) => {
  //   const body = new FormData();
  //   const listingId = selectedInspectionReport?.listing.id;
  //   if (values.advancedInspectionReport) {
  //     body.append(
  //       'mediaInspectionReports[advanced]',
  //       values.advancedInspectionReport?.fileList[0]?.originFileObj
  //     );
  //   }
  //   // Create auction from form data
  //   const isFormData = true;
  //   if (listingId) {
  //     updateAuctionVehicle(listingId, body, isFormData)
  //       .then((resp) => {
  //         if (resp.status === 200) {
  //           hideEditInspectionReportModal();
  //           sendNotification(listingId);
  //           message.success('Report Uploaded');
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         message.error('Something went wrong. Try again!');
  //       });
  //   }
  // };

  // const handleBuyerPaymentComplete = (index: number) => {
  //   const currentReportRequest = data[index];
  //   if (currentReportRequest) {
  //     updateInspectionReportstatus(currentReportRequest.inspectionReport?.id)
  //       .then(() => getData())
  //       .catch((error) => console.log(error));
  //   }
  // };

  // const sendNotification = (index: number) => {
  //   const currentReportRequest = data[index];
  //   if (currentReportRequest) {
  //     sendInspectionReportNotification(
  //       currentReportRequest.inspectionReport?.id
  //     )
  //       .then(() => getData())
  //       .catch((error) => console.log(error));
  //   }
  // };

  // const { t } = useTranslation();

  // const EditInspectionReportModal = () => {
  //   return (
  //     <InspectionReportModal
  //       isEditable
  //       isVisible={isEditInspectionReportModalVisible}
  //       onClose={hideEditInspectionReportModal}
  //       handleInspectionReportSubmit={handleInspectionReportSubmit}
  //       selectedInspectionReport={selectedInspectionReport}
  //     />
  //   );
  // };

  return (
    <div className={styles.tableContainer}>
      {/* {!!selectedInspectionReport && isEditInspectionReportModalVisible && (
        <EditInspectionReportModal />
      )} */}
      <Card>
        <Table
          dataSource={data}
          loading={isLoading}
          pagination={pagination}
          rowKey="id"
          onChange={pagination => handleTableChanges(pagination)}
        >
          {/* <Column
            key="lotNumber"
            title={"Lot Number"}
            render={(text: inspectionReportRequestType, record, index) =>
              text.listing.lot
            }
          /> */}
          <Column
            key="listingId"
            title={"Order Number"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.inspectionReport?.listingId
            }
          />
          <Column
            key="fullName"
            title={"Full Name"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.buyer?.name
            }
          />
          <Column
            key="email"
            title={"Email"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.buyer?.email
            }
          />
          <Column
            key="phone-number"
            title={"Phone Number"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.buyer?.mobile
            }
          />
          <Column
            key="price"
            title={"Price"}
            render={(text: inspectionReportRequestType, record, index) => {
              return (
                text.inspectionReport?.originalAmount?.currency?.code +
                " " +
                formatNumber(text.inspectionReport?.originalAmount?.amount)
              );
            }}
          />
          <Column
            key="total-price"
            title={"Total Price"}
            render={(text: inspectionReportRequestType, record, index) => {
              return (
                text.inspectionReport?.totalAmount?.currency?.code +
                " " +
                formatNumber(text.inspectionReport?.totalAmount?.amount)
              );
            }}
          />
          <Column
            key="cardNumber"
            title={"Card Number"}
            render={(text: inspectionReportRequestType, record, index) => (
              <>
                {text.paymentCard ? (
                  <>{text?.paymentCard.cardNumber}</>
                ) : (
                  <>N/A</>
                )}
              </>
            )}
          />
          <Column
            key="cardScheme"
            title={"Card Scheme"}
            render={(text: inspectionReportRequestType, record, index) => (
              <>
                {text.paymentCard ? <>{text?.paymentCard.scheme}</> : <>N/A</>}
              </>
            )}
          />
          <Column
            key="requestTime"
            title={"Request Time"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.inspectionReport?.createdAt
            }
          />

          <Column
            key="state"
            title={"State"}
            render={(text: inspectionReportRequestType, record, index) =>
              text?.inspectionReport?.userType === "buyer" ? "Sent" : "Uploaded"
            }
          />
          {/* <Column
          
            key='action'
            title={t('newAuctions.actionsTitle')}
            render={(text, record, index) => {
              switch (text?.inspectionReport?.state) {
                case 'Pending':
                  return (
                    <Popconfirm
                      key='paymentComplete'
                      onConfirm={() => handleBuyerPaymentComplete(index)}
                      title='Are you sure you want to confirm payment?'
                    >
                      <div className={styles.actionButton} key='confirmPayment'>
                        Confirm payment
                      </div>
                    </Popconfirm>
                  );
                case 'Confirmed':
                  return (
                    <div
                      onClick={() => showEditInspectionModal(index)}
                      className={styles.editText}
                    >
                      Upload Report
                    </div>
                  );
              }
            }}
          /> */}
        </Table>
      </Card>
    </div>
  );
};

export default memo(EndedInspectionReports);
