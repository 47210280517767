import React, { useEffect, useState } from "react";
import { Button, Table, Modal, TablePaginationConfig, message } from "antd";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import styles from "./SubcategoriesTable.module.scss";
import { PaginationConfig } from "antd/lib/pagination";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const SubcategoriesTable = ({ selectedCategory, onCreate, visible }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  useEffect(() => {
    loadSubCategoryListings(1);
  }, []);

  const loadSubCategoryListings = async page => {
    try {
      setLoading(true);
      const payload = await dynamicCategoriesApi.getSubListings(
        selectedCategory.id,
        page
      );
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadSubCategoryListings(newPagination.current);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button className={styles.btnLink} type="link">
          {text}
        </Button>
      )
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "40%",
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => visible(record)}>
            Edit
          </Button>
          <Button danger type="link" onClick={() => handleSubCategory(record)}>
            Delete
          </Button>
        </>
      )
    }
  ];

  const handleSubCategory = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this subcategory?",
      content: `You’re about to delete ${record.name}`,
      okText: "Yes, delete subcategory",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteListing(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("subcategory Deleted");
              loadSubCategoryListings(1);
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const Header = () => (
    <div className={styles.categoryOverview}>
      <h1>Subcategories ({data?.length})</h1>
      <Button type="primary" onClick={onCreate}>
        Add Subcategories
      </Button>
    </div>
  );

  return (
    <Table
      title={Header}
      showHeader={false}
      size="small"
      dataSource={data}
      columns={columns}
      pagination={pagination}
      loading={loading}
      onChange={pagination => handleTableChanges(pagination)}
    />
  );
};

export default SubcategoriesTable;
