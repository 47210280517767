import React, { useEffect, useState } from "react";
import styles from "./ChildOptionsTable.module.scss";
import {
  Button,
  Card,
  Form,
  Input,
  List,
  Modal,
  Table,
  TablePaginationConfig,
  message
} from "antd";
import {
  DownOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UpOutlined
} from "@ant-design/icons";
import {
  filterChildOptions,
  getChildOptions
} from "app/api/dynamicCategoryApi";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import OptionsCreateModal from "../OptionsCreateModal/OptionsCreateModal";
import { PaginationConfig } from "antd/lib/pagination";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const { Item } = Form;

const ChildOptionsTable = ({ selectedField }) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState();
  const [isCreateOptions, setIsCreateOptions] = useState<boolean>(false);
  const [fieldId, setFieldId] = useState("");
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  useEffect(() => {
    loadChildOptions(1);
  }, []);

  const loadChildOptions = async page => {
    try {
      setLoading(true);
      const payload = await getChildOptions(selectedField.id, page);
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchOptions = async e => {
    const searchText = e.target.value;
    setSearchText(searchText);
    try {
      setLoading(true);
      const data = await filterChildOptions(selectedField.id, searchText);
      setSearchResults(data.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteField = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this field?",
      content: `You’re about to delete  ${record.name}`,
      okText: "Yes, delete field",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteOption(selectedField.id, record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Option Deleted");
              loadChildOptions(1);
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const handleToggleOption = (record, visible, fieldId) => {
    setSelectedOption(record);
    setIsCreateOptions(visible);
    setFieldId(fieldId);
  };

  const columns = [{ title: "Name", dataIndex: "name", key: "name" }];

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadChildOptions(newPagination.current);
  };

  return (
    <>
      {isCreateOptions && (
        <OptionsCreateModal
          isVisible={isCreateOptions}
          onClose={() => setIsCreateOptions(false)}
          selectedOption={selectedOption}
          selectedField={selectedField}
          childOption
          fieldId={fieldId}
          onUpdate={() => loadChildOptions(1)}
        />
      )}

      <Card className={styles.optionsCard}>
        <h1>Child Options</h1>
        <Form
          layout="vertical"
          name="search_vehicles"
          onFinish={handleSearchOptions}
        >
          <Item name="name" label={"Search:"}>
            <Input
              prefix={<SearchOutlined />}
              allowClear
              className={styles.searchInput}
              onChange={handleSearchOptions}
            />
          </Item>
        </Form>
        <Table
          pagination={pagination}
          dataSource={searchText ? searchResults : data}
          loading={loading}
          rowKey={record => record.id}
          showHeader={false}
          columns={columns}
          onChange={pagination => handleTableChanges(pagination)}
          expandable={{
            expandedRowRender: record => (
              <>
                <Button
                  onClick={() => handleToggleOption(null, true, record.id)}
                  icon={<PlusCircleOutlined />}
                  block
                >
                  Add Options
                </Button>
                <List
                  loading={loading}
                  dataSource={record.children}
                  renderItem={(record: any, item) => (
                    <List.Item
                      actions={[
                        <Button
                          onClick={() =>
                            handleToggleOption(record, true, record.id)
                          }
                          type="link"
                          key="edit"
                        >
                          Edit
                        </Button>,
                        <Button
                          onClick={() => handleDeleteField(record)}
                          danger
                          type="link"
                          key="edit"
                        >
                          Delete
                        </Button>
                      ]}
                    >
                      {record.name}
                    </List.Item>
                  )}
                />
              </>
            ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <span onClick={e => onExpand(record, e)}>
                {expanded ? <DownOutlined /> : <UpOutlined />}
              </span>
            ),
            childrenColumnName: "child"
          }}
        />
      </Card>
    </>
  );
};

export default ChildOptionsTable;
