import { NotificationOutlined } from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
import ManageAdverts from "app/screens/ManageAdverts/ManageAdverts";
import { IMenuItemType } from "app/types/routerType";

export const MANAGE_ADVERTS_SCREEN: IMenuItemType = {
  id: "manage-adverts",
  path: "/manage-adverts",
  title: "Manage Adverts",
  icon: NotificationOutlined,
  component: ManageAdverts,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};
