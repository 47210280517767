import { Button, Card, Modal, PageHeader, Table, message } from "antd";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./ManageFees.module.scss";
import { getFees } from "app/api/feesApi";
import * as feesApi from "app/api/feesApi";
import ManageFeesModal from "./Components/ManageFeesModal";

interface CommissionType {
  commissionPercent: number;
  currencyCode: string;
  id: number;
  maxPrice: number;
  minPrice: number;
}

const ManageFees = () => {
  const location = useLocation();
  const [data, setData] = useState<CommissionType[]>([]);
  const [selectedFee, setSelectedFee] = useState<CommissionType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreateFee, setIsCreateFee] = useState<boolean>(false);

  useEffect(() => {
    loadFees();
  }, [location.search]);

  const loadFees = async () => {
    try {
      setLoading(true);
      const payload = await getFees(qs.parse(location.search));
      setData(payload);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Minimum Price",
      dataIndex: "minPrice",
      key: "minPrice"
    },
    {
      title: "Maximum Price",
      dataIndex: "maxPrice",
      key: "maxPrice"
    },
    {
      title: "Category",
      dataIndex: "listingType",
      key: "listingType",
      render: category => category?.name
    },
    {
      title: "Commission Percentage",
      dataIndex: "commissionPercent",
      key: "commissionPercent",
      render: text => <a>{`${Math.round(text * 100)} %`}</a>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <Button
            key="edit"
            type="link"
            onClick={() => toggleFeeDetails(record)}
          >
            Edit
          </Button>
          <Button
            key="delete"
            danger
            type="link"
            onClick={() => handleDeleteFee(record)}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  const handleDeleteFee = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this Fee?",
      content: `You’re about to delete fee.`,
      okText: "Yes, delete fee.",
      okType: "danger",
      onOk() {
        return feesApi
          .deleteAdvert(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Fee Deleted");
              loadFees();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const toggleFeeDetails = record => {
    setSelectedFee(record);
    setIsCreateFee(true);
  };

  const Header = () => (
    <PageHeader
      title="Manage Fees"
      className={styles.pageHeader}
      extra={[
        <Button type="primary" onClick={() => toggleFeeDetails(null)}>
          Create Fee
        </Button>
      ]}
    />
  );

  return (
    <div className={styles.tableContainer}>
      {isCreateFee && (
        <ManageFeesModal
          isVisible={isCreateFee}
          handleClose={() => setIsCreateFee(false)}
          selectedFee={selectedFee}
          onLoad={() => loadFees()}
          data={data}
        />
      )}
      <Card>
        <Table
          title={Header}
          size="small"
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </Card>
    </div>
  );
};

export default ManageFees;
