import React, { memo, useState, useContext } from "react";
import { Layout, Avatar, Menu } from "antd";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { IMenuItemType } from "app/types/routerType";
import styles from "./NavigationContainer.module.scss";
import Navigation from "./components/Navigation/Navigation";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  clearAccessToken,
  clearUserFromLocalStorage,
} from "app/helpers/authenticationHelper";
import { UserContext } from "app/store/contexts/userContext";
import logo from "../../../assets/logo-small.png";
import logoIcon from "../../../assets/logo-icon.png";
import Loading from "app/components/Loading/Loading";

const { Sider, Header } = Layout;

interface IProps extends RouteComponentProps {
  children: React.ReactNode;
  navigation: IMenuItemType[];
}

const NavigationContainer = ({ children, navigation, ...rest }: IProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const user = useContext(UserContext);

  const handleLogout = () => {
    setLoading(true);
    setTimeout(() => {
      clearAccessToken();
      clearUserFromLocalStorage();
      user.setUser({});
    }, 1000);
  };

  if (loading) {
    return <Loading isFullscreen />;
  }

  const UserSubMenu = (props: any) => (
    <Menu.Item {...props} key="logout">
      <div onClick={handleLogout}>Logout</div>
    </Menu.Item>
  );

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className={styles.container}>
      <Sider
        className={styles.sider}
        collapsible
        collapsed={collapsed}
        collapsedWidth="95"
        width="280"
        theme={"light"}
      >
        {collapsed ? (
          <img src={logoIcon} alt="logo-icon" className={styles.logoIcon} />
        ) : (
          <img src={logo} alt="mazadak-logo" className={styles.logo} />
        )}
        <Navigation location={location} navigation={navigation} {...rest} />
      </Sider>
      <Layout>
        <Header className={styles.header} style={{ padding: 0 }}>
          <div className={styles.navbar}>
            <div className={styles.collapsibleItem} onClick={toggle}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>

            <Menu mode="horizontal" className={styles.menu}>
              <SubMenu
                key="user"
                title={
                  <div>
                    <Avatar icon={<UserOutlined />} size={32} />
                    <span className={styles.userName}>{user.user.name}</span>
                  </div>
                }
              >
                <UserSubMenu />
              </SubMenu>
            </Menu>
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );
};

export default withRouter(memo(NavigationContainer));
