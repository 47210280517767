import React, { memo } from "react";
import { Col, Row } from "antd";
import styles from "./AuthenticationLayout.module.scss";

const AuthenticationLayout = (props) => {
  return (
    <Row align="middle" justify="center" className={styles.wrapper}>
      <Col xs={24} className={styles.container}>
        {props.children}
      </Col>
    </Row>
  );
};

export default memo(AuthenticationLayout);
