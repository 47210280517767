import { Button, Checkbox, Form, Input, Modal, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useTranslation } from "react-i18next";
import { DragAndDropIcon } from "app/components/Icons/Icons";
import { getBase64, beforeUpload } from "app/helpers/imageUploadHelper";
import { vehicleMakeType } from "app/types/vehicleMakeType";
import React, { memo, useEffect, useState } from "react";
import styles from "../VehicleSearch/VehicleSearch.module.scss";

interface MakeModalProps {
  isMakeModalVisible?: boolean;
  isEditable?: boolean;
  hideMakeModal?: () => void;
  handleMakeAdd?: (values: any) => void;
  handleMakeEdit?: (values: any) => void;
  handleMakeDelete?: () => void;
  selectedMake?: vehicleMakeType;
}

const { Item } = Form;

const MakeModal = ({
  isMakeModalVisible,
  isEditable,
  hideMakeModal,
  handleMakeAdd,
  handleMakeEdit,
  handleMakeDelete,
  selectedMake
}: MakeModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>();
  const [hasNamefield, setHasNameField] = useState<boolean>(false);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewDarkModeVisible, setPreviewDarkModeVisible] = useState<boolean>(
    false
  );
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewDarkModeImage, setPreviewDarkModeImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewDarkModeTitle, setPreviewDarkModeTitle] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [darkModeFileList, setDarkModeFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (isEditable && isMakeModalVisible && selectedMake !== null) {
      form.setFieldsValue({ enabled: selectedMake?.enabled });
      // set checkbox to checked if the selected make is deactivated
      setCheckboxChecked(!selectedMake?.enabled);

      if (selectedMake?.logo && fileList.length === 0) {
        setFileList([
          {
            uid: selectedMake.id,
            size: 1,
            name: selectedMake.name as string,
            type: "",
            url: selectedMake.logo
          }
        ]);
      }
      if (selectedMake?.logo && darkModeFileList.length === 0) {
        setDarkModeFileList([
          {
            uid: selectedMake.id,
            size: 1,
            name: selectedMake.name as string,
            type: "",
            url: selectedMake.logoDarkMode
          }
        ]);
      }
    }
    // Set the default value of addModel to false
    if (!isEditable) {
      form.setFieldsValue({ addModel: false });
    }
  }, [
    form,
    isEditable,
    isMakeModalVisible,
    selectedMake,
    fileList,
    darkModeFileList.length
  ]);

  useEffect(() => {
    form.setFieldsValue({
      makeLogo: fileList,
      logoPathDarkMode: darkModeFileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, darkModeFileList]);

  const handleCheckboxChange = event => {
    setCheckboxChecked(event.target.checked);
    form.setFieldsValue({ enabled: !event.target.checked });
  };

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handlePreviewDarkMode = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewDarkModeImage(file.url || file.preview);
    setPreviewDarkModeVisible(true);
    setPreviewDarkModeTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList }) => {
    setFileList(fileList.filter(file => !!file.status));
  };
  const handleDarkModeChange = ({ fileList }) => {
    setDarkModeFileList(fileList.filter(file => !!file.status));
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewDarkModeVisible(false);
  };

  /**
   * When creating a new make only
   *
   */
  const handleMakeSave = () => {
    form.setFieldsValue({ addModel: false });
    form.submit();
  };

  /**
   * When creating a new make and adding a model straight afterwards
   *
   */
  const handleMakeModelSaveAdd = () => {
    form.setFieldsValue({ addModel: true });
    form.submit();
  };

  /**
   * Check if Make name field is completed before allowing saving
   *
   * @returns {boolean}
   */
  const validateNoName = (): void => {
    const fieldValue: string | undefined = form.getFieldValue("name");
    if (fieldValue) {
      setHasNameField(true);
    } else {
      setHasNameField(false);
    }
  };

  return (
    <Modal
      title={isEditable ? "Edit make" : "Add make"}
      visible={isMakeModalVisible}
      onCancel={hideMakeModal}
      footer={
        isEditable ? (
          <div className={styles.editModalFooter}>
            <Button onClick={handleMakeDelete} danger>
              Delete
            </Button>
            <div>
              <Button type="default" onClick={hideMakeModal}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" form="editMake">
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Button type="default" onClick={hideMakeModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!hasNamefield}
              onClick={handleMakeSave}
            >
              Add
            </Button>
            <Button
              type="primary"
              disabled={!hasNamefield}
              onClick={handleMakeModelSaveAdd}
            >
              Save + Add Model
            </Button>
          </div>
        )
      }
    >
      {isEditable ? (
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          name="editMake"
          form={form}
          onFinish={handleMakeEdit}
        >
          <Item
            name="makeLogo"
            label="Make logo"
            valuePropName="fileList"
            extra={t("newAuctions.imageUploadDisclaimer")}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              defaultFileList={[]}
              maxCount={1}
              beforeUpload={beforeUpload}
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: false
              }}
              customRequest={customUpload}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              <div>
                <DragAndDropIcon />
                <div className="ant-upload-text">
                  {t("newAuctions.changeButtonTitle")}
                </div>
              </div>
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Item>
          <Item
            name="logoPathDarkMode"
            label="Make logo (Dark mode)"
            valuePropName="fileListDarkMode"
            extra={t("newAuctions.imageUploadDisclaimer")}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              defaultFileList={[]}
              maxCount={1}
              beforeUpload={beforeUpload}
              fileList={darkModeFileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: false
              }}
              customRequest={customUpload}
              onPreview={handlePreviewDarkMode}
              onChange={handleDarkModeChange}
            >
              <div>
                <DragAndDropIcon />
                <div className="ant-upload-text">
                  {t("newAuctions.changeButtonTitle")}
                </div>
              </div>
            </Upload>
            <Modal
              visible={previewDarkModeVisible}
              title={previewDarkModeTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewDarkModeImage}
              />
            </Modal>
          </Item>
          <Item
            name="name"
            initialValue={selectedMake?.name}
            label="Make name"
            required={true}
          >
            <Input placeholder="Make name" />
          </Item>
          <Item name="enabled" wrapperCol={{ offset: 8 }}>
            <Checkbox checked={checkboxChecked} onChange={handleCheckboxChange}>
              Deactivate (Disable future auctions for this make.)
            </Checkbox>
          </Item>
        </Form>
      ) : (
        <Form
          labelCol={{ span: 8 }}
          form={form}
          name="addMake"
          onFinish={handleMakeAdd}
        >
          <Item
            name="makeLogo"
            label="Make logo"
            valuePropName="fileList"
            extra={t("newAuctions.imageUploadDisclaimer")}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              defaultFileList={[]}
              maxCount={1}
              beforeUpload={beforeUpload}
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true
              }}
              customRequest={customUpload}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length === 0 ? (
                <div>
                  <DragAndDropIcon />
                  <div className="ant-upload-text">
                    {t("newAuctions.uploadButtonTitle")}
                  </div>
                </div>
              ) : null}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Item>
          <Item
            name="logoPathDarkMode"
            label="Make logo (Dark mode)"
            valuePropName="fileListDarkMode"
            extra={t("newAuctions.imageUploadDisclaimer")}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              defaultFileList={[]}
              maxCount={1}
              beforeUpload={beforeUpload}
              fileList={darkModeFileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: false
              }}
              customRequest={customUpload}
              onPreview={handlePreviewDarkMode}
              onChange={handleDarkModeChange}
            >
              <div>
                <DragAndDropIcon />
                <div className="ant-upload-text">
                  {t("newAuctions.changeButtonTitle")}
                </div>
              </div>
            </Upload>
            <Modal
              visible={previewDarkModeVisible}
              title={previewDarkModeTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewDarkModeImage}
              />
            </Modal>
          </Item>
          <Item name="name" label="Make name" required={true}>
            <Input placeholder="Make name" onChange={validateNoName} />
          </Item>
          <Item name="addModel" hidden={true}>
            <Checkbox checked={false} />
          </Item>
        </Form>
      )}
    </Modal>
  );
};

export default memo(MakeModal);
