import React from "react";
import { Modal, Button, message, Input, Form, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { assignDeposit } from "app/api/usersApi";

import { userType } from "app/types/userType";

import styles from "./AssignDepositModal.module.scss";

const { Item } = Form;

interface AssignDepositModalProps {
  isVisible: boolean;
  selectedUser: userType;
  onClose: () => void;
}

const AssignDepositModal = ({
  isVisible,
  selectedUser,
  onClose,
}: AssignDepositModalProps) => {
  const { t } = useTranslation();
  let [form] = Form.useForm();

  const { confirm } = Modal;

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleModalCancel = () => {
    confirm({
      title: "Are you sure you don't want to deposit an amount to this user?",
      icon: <ExclamationCircleOutlined />,
      content: t("newAuctions.modalDescription"),
      okText: t("default.ok"),
      okType: "danger",
      cancelText: t("default.no"),
      onOk() {
        handleClose();
      },
    });
  };

  const onFinish = (values) => {
    const { deposit } = values;
    assignDeposit(selectedUser.id, {
      deposit,
    })
      .then((resp) => {
        if (resp.status === 200) {
          handleClose();
          return message.success("Deposit Created");
        }
      })
      .catch((error) => {
        console.log(error);
        return message.error("Something went wrong. Try again!");
      });
  };

  const checkConfirmation = (rule, value) => {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject("Please confirm the deposit amount");
  };
  return (
    <Modal
      title={t("users.assignDepositTitle")}
      visible={isVisible}
      onCancel={handleModalCancel}
      width={500}
      destroyOnClose
      footer={
        <div className={styles.modalFooter}>
          <Button form="assignDepositForm" htmlType="submit" type="primary">
            {t("users.assignDeposit")}
          </Button>
        </div>
      }
    >
      <div className={styles.modalContainer}>
        <Form
          name="assignDepositForm"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <div className={styles.formItem}>
            <Item
              name="deposit"
              rules={[
                {
                  required: true,
                  message: "Please enter an deposit amount",
                },
              ]}
              label={t("users.depositAmountLabel")}
            >
              <Input type="text" prefix="SAR" />
            </Item>
            <Item
              name="confirmation"
              valuePropName="checked"
              rules={[
                {
                  validator: checkConfirmation,
                },
              ]}
            >
              <div className={styles.checkboxContainer}>
                <Checkbox>
                  <span className={styles.checkboxLabel}>
                    {t("users.assignDepositConfirmation")}
                  </span>
                </Checkbox>
              </div>
            </Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AssignDepositModal;
