import { message } from "antd";

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const beforeUpload = file => {
  const isSizeOk = file.size / 1024 / 1024 <= 5;
  if (!isSizeOk) {
    message.error("Media must be smaller than 5MB");
    return false;
  }

  return true;
};

/**
 *  Helper to convert base64 urls to files
 * @param dataurl
 * @param filename
 */

export const dataURLtoFile = (dataurl, filename) => {
  if (dataurl) {
    var arr = dataurl.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } else {
    console.error("Invalid dataurl:", dataurl);
  }
};
