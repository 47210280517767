import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Card,
  TablePaginationConfig,
  message,
  Modal
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./BulkItems.module.scss";
import CsvUploadModal from "./components/CsvUploadModal/CsvUploadModal";
import EntriesListTable from "./components/EntriesListTable/EntriesListTable";
import RawEntriesListTable from "./components/RawEntriesListTable/RawEntriesListTable";
import { getSheetsList } from "app/api/bulkUploadsApi";
import moment from "moment";
import { PaginationConfig } from "antd/lib/pagination";
import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
import * as bulkUploadsApi from "app/api/bulkUploadsApi";
import { useHistory, useLocation } from "react-router-dom";
import { DataBulkType } from "app/types/bulkUploadsType";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const BulkItems = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<DataBulkType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<DataBulkType>();
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [entriesListVisible, setEntriesListVisible] = useState<boolean>(false);
  const [rawEntriesListVisible, setRawEntriesListVisible] = useState<boolean>(
    false
  );

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Total Entries",
      dataIndex: "itemCount",
      key: "itemCount"
    },
    {
      title: "State",
      key: "processed",
      render: record => (record.processed ? "processed" : "unprocessed")
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: date =>
        date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "N/A",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix()
    },
    {
      title: "Actions",
      key: "action",
      width: "25%",
      render: (text, record, index) => (
        <div className={styles.actionsWrapper}>
          {record.processed ? (
            <Button type="link" onClick={() => toggleEntriesList(index)}>
              View
            </Button>
          ) : (
            <>
              <Button type="text" onClick={() => toggleRawEntriesList(index)}>
                Process
              </Button>
            </>
          )}
          <Button danger type="link" onClick={() => handleDeleteSheet(record)}>
            Delete
          </Button>
        </div>
      )
    }
  ];

  useEffect(() => {
    loadSheetsList();
  }, [location.search]);

  const loadSheetsList = async () => {
    try {
      setLoading(true);
      const payload = await getSheetsList(qs.parse(location.search));
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleEntriesList = (index: number) => {
    setSelectedFile(data[index]);
    setEntriesListVisible(true);
  };

  const toggleRawEntriesList = (index: number) => {
    setSelectedFile(data[index]);
    setRawEntriesListVisible(true);
  };

  const handleModalCancel = () => {
    setUploadModalVisible(false);
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handleDeleteSheet = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this sheet?",
      content: `You’re about to delete  ${record.name}`,
      okText: "Yes, delete sheet",
      okType: "danger",
      onOk() {
        return bulkUploadsApi
          .deleteSheet(record.id)
          .then(p => {
            if (p.status === 200) {
              message.success("Sheet Deleted");
              loadSheetsList();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  return (
    <>
      <CsvUploadModal
        onCancel={handleModalCancel}
        isVisible={uploadModalVisible}
        onUpload={() => loadSheetsList()}
      />
      {entriesListVisible && (
        <EntriesListTable
          data={selectedFile}
          loadSheetList={loadSheetsList}
          visible={() => setEntriesListVisible(false)}
        />
      )}
      {rawEntriesListVisible && (
        <RawEntriesListTable
          data={selectedFile}
          loadSheetList={loadSheetsList}
          visible={() => setRawEntriesListVisible(false)}
        />
      )}
      {!entriesListVisible && !rawEntriesListVisible && (
        <div className={styles.tableContainer}>
          <Card>
            <div className={styles.auctionsOverview}>
              <h1>Upload Items</h1>
              <Button
                icon={<UploadOutlined />}
                onClick={() => setUploadModalVisible(true)}
              >
                Upload File
              </Button>
            </div>
            <Table
              columns={columns}
              loading={loading}
              dataSource={data}
              pagination={pagination}
              onChange={pagination => handleTableChanges(pagination)}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default BulkItems;
