import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get Adverts
 * @param search
 * @returns
 * @export
 */

export const getFees = (search: any) => {
  const endpoint = ENDPOINTS.FEES;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * creates Advert
 * @param search
 */
export const createFee = (body: any) => {
  return api.post(ENDPOINTS.FEES, body);
};

/**
 * updates a user object
 * @param search
 */
export const updateFee = (id: string, body: any) => {
  const endpoint = ENDPOINTS.FEES + `/${id}`;

  return api.post(endpoint, body);
};

/**
 * delete Advert
 * @param search
 */
export const deleteAdvert = (id: number) => {
  const endpoint = ENDPOINTS.FEES + `/${id}`;
  return api.delete(endpoint);
};

// {{baseUrl}}/admin/commission-fees/:id
