import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  TreeSelect,
  Upload,
  message
} from "antd";
import { CategoriesType } from "app/types/pageLayoutType";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ListingCollapse.module.scss";
import {
  getChildFieldOptions,
  getInputFields,
  getListings,
  getParentOptions
} from "app/api/dynamicCategoryApi";
import TextArea from "antd/lib/input/TextArea";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { deleteVehicleImage } from "app/api/auctionVehiclesApi";
import { useTranslation } from "react-i18next";
import { formatNumber } from "app/helpers/numberFormatHelper";
import {
  getBase64,
  beforeUpload,
  dataURLtoFile
} from "app/helpers/imageUploadHelper";
import moment from "moment";
import { getEventDetails, getTimedAuctions } from "app/api/auctionShowsApi";
import { getLocation } from "app/api/listingsApi";

const { Panel } = Collapse;

const ListingCollapse = ({
  allFields,
  form,
  selectedListing,
  action,
  path,
  loadListing
}) => {
  const { Item } = Form;
  const { Option } = Select;
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<number>();
  const [categories, setCategories] = useState<CategoriesType[]>([]);
  const [fields, setFields] = useState<any>([]);
  const [timedAuctions, setTimedAuctions] = useState<any>([]);
  const imageToBase64 = useRef<HTMLCanvasElement>(null);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [advancedReportsFileList, setAdvancedReportsFileList] = useState<any[]>(
    []
  );
  const [initialReportsFileList, setInitialReportsFileList] = useState<any[]>(
    []
  );
  const [listingLocationEnabled, setListingLocationEnabled] = useState<boolean>(
    selectedListing?.addressObjectSameAsDelivery
  );
  const [featuredEnabled, setFeaturedEnabled] = useState<boolean>(
    selectedListing?.featured
  );
  const [buyNowEnabled, setBuyNowEnabled] = useState<boolean>(
    selectedListing?.listingAvailableForSale
  );
  const [videoFiles, setVideoFiles] = useState<any[]>();

  const [activePanels, setActivePanels] = useState<string[] | string>([]);

  const auctionField = form.getFieldValue("auctionAssigned");

  const categoryField = form.getFieldValue("listingTypeId");

  const [searchText, setSearchText] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const handleTreeSearch = (inputValue, treeNode) => {
    return treeNode.props.title
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  const [parentOptions, setParentOptions] = useState({});
  const [childOptions, setChildOptions] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const handleInspectionReportChange = (list, file, type) => {
    setAdvancedReportsFileList(list.filter(file => !!file.status));
    if (!file.status) return false;

    const reports = [{ uid: file.uid, file: file }];
    form.setFieldsValue({
      advancedInspectionReport: reports
    });
  };

  const checkReportFileSize = file => {
    const sizeIsOk = file.size / 1024 / 1024 <= 20;
    if (!sizeIsOk) {
      message.error("Report File size must be less than 20 MB");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchOptions = async (id, isChild, selectedOption) => {
      try {
        const response = isChild
          ? await getChildFieldOptions(id, selectedOption)
          : await getParentOptions(id);
        const data = response.data;

        if (isChild) {
          setChildOptions(prevOptions => ({ ...prevOptions, [id]: data }));
        } else {
          setParentOptions(prevOptions => ({ ...prevOptions, [id]: data }));
        }
      } catch (error) {
        console.error(`Error fetching options for ID ${id}:`, error);
      }
    };

    const fetchOptionsForField = async (field, selectedOption) => {
      const { id, children } = field;
      await fetchOptions(id, false, selectedOption);
      if (children && children.length > 0) {
        await Promise.all(
          children.map(async child => {
            await fetchOptions(child.id, true, selectedOption);
          })
        );
      }
    };

    const initializeOptions = async () => {
      if (fields) {
        await Promise.all(
          fields.map(async field => {
            await fetchOptionsForField(field, selectedOption);
          })
        );
      }
    };

    initializeOptions();

    if (!selectedOption && selectedListing) {
      const parentField = selectedListing.customFields.find(
        field => field.inputTypeId === 4
      );
      if (parentField) {
        setSelectedOption(parentField.valueId);
      }
    }
  }, [fields, selectedListing, selectedOption]);

  useEffect(() => {
    const initial = {};
    selectedListing?.customFields.forEach(field => {
      const { paramName, inputTypeId, value, valueId } = field;
      if (value !== null && value !== undefined) {
        switch (inputTypeId) {
          case 3:
            initial[paramName] = moment(value);
            break;
          case 4:
            initial[paramName] = valueId;
            break;
          case 5:
            initial[paramName] = value !== 1;
            break;
          default:
            initial[paramName] = value;
            break;
        }
      }
    });
    setInitialValues(initial);
  }, [selectedListing]);

  const renderFormField = (field, isChild, initialValue) => {
    const options = isChild ? childOptions[field.id] : parentOptions[field.id];

    switch (field.inputType.id) {
      case 1:
        return (
          <InputNumber
            key={field.id}
            formatter={value => formatNumber(value)}
            data-panel-key="2"
          />
        );
      case 2:
      case 6:
      case 9:
        return (
          <Input
            key={field.id}
            maxLength={50}
            placeholder="Enter Text"
            data-panel-key="2"
          />
        );
      case 3:
        return (
          <DatePicker
            className={styles.inputDate}
            key={field.id}
            picker="year"
            data-panel-key="2"
          />
        );
      case 4:
        return (
          <Select
            key={field.id}
            data-panel-key="2"
            placeholder={`Select ${field.name}`}
            defaultValue={undefined}
            onChange={!isChild ? value => setSelectedOption(value) : undefined}
          >
            {options &&
              options.map(option => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
          </Select>
        );
      case 5:
        return (
          <div className={styles.requireField}>
            <Switch
              data-panel-key="2"
              defaultChecked={initialValue}
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={value => toggleSwitch(field.paramName, value)}
            />
          </div>
        );
      case 7:
        return (
          <TextArea showCount maxLength={250} rows={2} data-panel-key="2" />
        );
      default:
        return null;
    }
  };

  const renderFormFields = (fields, isChild) => {
    return fields?.map(field => {
      return (
        <Form.Item
          key={field.id}
          initialValue={initialValues[field.paramName]}
          name={field.paramName}
          label={`${field.name}:`}
          rules={[{ validator: validateStringLength }]}
          valuePropName={field.inputType.id === 5 ? "checked" : undefined}
        >
          {renderFormField(field, isChild, initialValues[field.paramName])}
        </Form.Item>
      );
    });
  };

  const validateStringLength = (_, value) => {
    if (value && value.length >= 255) {
      return Promise.reject("A String field can only contain 255 characters.");
    }
    return Promise.resolve();
  };

  useEffect(() => {
    getListings()
      .then(payload => {
        setCategories(payload.data);
        setFilteredCategories(payload.data);
      })
      .catch(error => console.log(error));
  }, []);

  const handleSelect = (value, option) => {
    const selectedAddress = option.props.address;
    form.setFieldsValue({
      "addressObject[ar]": selectedAddress.ar,
      "addressObject[cityId]": selectedAddress.cityId
    });
  };

  const handleSearch = async value => {
    if (searchText) {
      return;
    }
    setSearchText(value);

    try {
      const response = await getLocation(value);
      setAddressOptions(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (auctionField) {
      getEventDetails(auctionField)
        .then(payload => {
          form.setFieldsValue({
            startAt: payload.startAt ? moment(payload.startAt) : null,
            endAt: payload.endAt ? moment(payload.endAt) : null
          });
        })
        .catch(error => console.log(error));
    }
  }, [auctionField, form]);

  useEffect(() => {
    form.setFieldsValue({
      media: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    getTimedAuctions()
      .then(payload => {
        setTimedAuctions(payload.data);
      })
      .catch(error => console.log(error));
  }, [selectedListing, form]);

  useEffect(() => {
    if (
      !!imageToBase64 &&
      selectedListing?.mediaPhotos &&
      selectedListing?.mediaPhotos.length > 0
    ) {
      const formattedMediaImages = selectedListing?.mediaPhotos.map(
        (image: any): any => {
          imageToBase64.current?.setAttribute("src", image.url);
          const dataInBase64 = imageToBase64.current?.toDataURL(image.mimeType);
          const imageFile = dataURLtoFile(dataInBase64, image.fileName);

          return {
            uid: image.id.toString(),
            thumbUrl: image.url,
            url: image.url,
            size: image.size,
            name: image.fileName,
            type: image.mimeType,
            originFileObj: imageFile,
            status: "done",
            skip: true
          };
        }
      );
      setFileList(formattedMediaImages);
    }
  }, [selectedListing]);

  useEffect(() => {
    if (!advancedReportsFileList || advancedReportsFileList.length === 0) {
      setAdvancedReportsFileList(defaultAdvancedFileList);
      form.setFieldsValue({
        advancedInspectionReport: defaultAdvancedFileList
      });
    }

    if (!initialReportsFileList || initialReportsFileList.length === 0) {
      setInitialReportsFileList(defaultInitialFileList);
      form.setFieldsValue({
        initialInspectionReport: defaultInitialFileList
      });
    }
  }, []);

  useEffect(() => {
    setActivePanels([]);
  }, []);

  useEffect(() => {
    const namesWithErrors: string[] = allFields
      .filter(item => item.errors.length > 0)
      .map(item => item.name[0]);
    const inputIds: string[] = [];

    if (namesWithErrors.length > 0) {
      namesWithErrors.forEach(name => {
        const element = document.getElementById(`createListingForm_${name}`);

        if (element) {
          const dataValue = element.getAttribute("data-panel-key");
          if (dataValue && !inputIds.includes(dataValue)) {
            inputIds.push(dataValue);
          } else {
            //Check if it is a Select component.
            const classNameToSearch = "ant-select";
            const foundElement = searchElementAbove(element, classNameToSearch);

            if (foundElement) {
              const dataValue = foundElement.getAttribute("data-panel-key");
              if (dataValue && !inputIds.includes(dataValue)) {
                inputIds.push(dataValue);
              }
            }
          }
        }
      });
      setActivePanels(inputIds);
    }
  }, [allFields]);

  const toggleBuyNow = () => {
    const newBuyNowValue = buyNowEnabled ? 0 : 1;

    form.setFieldsValue({
      listingAvailableForSale: newBuyNowValue
    });
    setBuyNowEnabled(!buyNowEnabled);
  };

  const toggleListingLocation = () => {
    const newListingLocationValue = listingLocationEnabled ? 0 : 1;
    form.setFieldsValue({
      addressObjectSameAsDelivery: newListingLocationValue
    });
    setListingLocationEnabled(!listingLocationEnabled);
  };

  const toggleFeatured = () => {
    const newFeaturedValue = featuredEnabled ? 0 : 1;
    form.setFieldsValue({
      featured: newFeaturedValue
    });
    setFeaturedEnabled(!featuredEnabled);
  };

  const toggleSwitch = (fieldName, value: boolean) => {
    form.setFieldsValue({
      [fieldName]: value ? "1" : "0"
    });
  };

  const handleGetInputFields = field => {
    if (field) {
      getInputFields(field)
        .then(payload => {
          setFields(payload);
        })
        .catch(error => {
          console.error("Error fetching input fields:", error);
        });
    }
  };

  useEffect(() => {
    const typeIdToFetch = categoryField;
    handleGetInputFields(typeIdToFetch);
  }, [categoryField]);

  const handleChange = ({ fileList }) => {
    setFileList(fileList.filter(file => !!file.status));
    const maxCount = 20;
    if (fileList.length === maxCount) {
      fileList.splice(maxCount);
      message.warning(
        `Maximum of ${maxCount} images can be uploaded. Please remove excess images.`
      );
    }
  };

  const customUpload = options => {
    const { onSuccess } = options;
    onSuccess("done");
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image</p>
    </div>
  );

  const removeFile = e => {
    deleteVehicleImage(Number(e.uid))
      .then(() => {
        message.warning("Media deleted!");
        loadListing();
      })
      .catch(error => message.error("Something went wrong!"));
  };

  const handleCancel = () => setPreviewVisible(false);

  useEffect(() => {
    form.setFieldsValue({
      advancedInspectionReport: advancedReportsFileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedReportsFileList]);

  const formatFile = file => {
    return {
      uid: file.id.toString(),
      url: file.url,
      size: file.size,
      name: file.fileName,
      type: file.mimeType
    };
  };

  const defaultAdvancedFileList = selectedListing?.mediaInspectionReports
    ?.advanced
    ? [formatFile(selectedListing?.mediaInspectionReports?.advanced)]
    : [];

  const defaultInitialFileList = selectedListing?.mediaInspectionReport
    ? [formatFile(selectedListing?.mediaInspectionReport)]
    : [];

  const saleTypeField = form.getFieldValue("individualListingSaleType");

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleVideoChanged = ({ fileList, file }) => {
    form.setFieldsValue({
      vehicleVideo: fileList
    });
    setVideoFiles(fileList.filter(file => !!file.status));
  };

  const sellerTypes = [{ id: "private", name: "Private" }];
  const sellerTypeField = form.getFieldValue("seller[type]");

  const searchElementAbove = (
    element: HTMLElement | null,
    className: string
  ): HTMLElement | null => {
    if (!element) {
      return null;
    }

    let currentElement = element;

    while (currentElement.parentElement) {
      currentElement = currentElement.parentElement;

      if (currentElement.classList.contains(className)) {
        return currentElement;
      }
    }

    return null;
  };

  const renderTreeNodes = categories => {
    return categories.map(item => {
      if (item.children) {
        return (
          <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id}>
            {renderTreeNodes(item.children)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} />
      );
    });
  };

  const disabledDate = current => {
    // Can not select days before today
    return current < moment().startOf("day");
  };

  const validatePrice = async (value, comparedField, comparisonMessage) => {
    const fieldValue = form.getFieldValue(comparedField);

    if (value && fieldValue !== undefined && value <= fieldValue) {
      return Promise.reject(comparisonMessage);
    }

    return Promise.resolve();
  };

  const validateReservedPrice = async (_, value) => {
    return validatePrice(
      value,
      "startingPrice",
      "Reserved price must be greater than Starting Price."
    );
  };

  const validateListingPrice = async (_, value) => {
    return validatePrice(
      value,
      "reservedPrice",
      "The listing price must be greater than the reserved price."
    );
  };

  const validateLength = (inputFiled, inputLength) => (_, value) => {
    if (value && value.length === inputLength) {
      return Promise.reject(
        `${inputFiled} must have at least ${inputLength} characters.`
      );
    }
    return Promise.resolve();
  };

  const validateTitleLength = validateLength("Title", 50);
  const validateDescriptionLength = validateLength("Description", 250);

  return (
    <>
      <Collapse
        activeKey={activePanels}
        accordion={false}
        expandIconPosition={"right"}
        bordered={false}
        onChange={keys => {
          setActivePanels(keys);
        }}
      >
        {!selectedListing && (
          <Panel
            header="Seller Information"
            key="1"
            className={styles.collapse}
            forceRender
          >
            <Item
              name="shopId"
              label={"Seller:"}
              initialValue={16}
              rules={[
                {
                  required: true,
                  message: "Please select a given (event) seller."
                }
              ]}
            >
              <Select
                size="middle"
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                <Option key={16} value={16}>
                  Mazadak
                </Option>
              </Select>
            </Item>
            <Item
              name="seller[type]"
              rules={[
                { required: true, message: "Please choose seller type!" }
              ]}
              label="Seller Type:"
              initialValue={
                selectedListing && selectedListing?.sellerType
                  ? selectedListing?.sellerType
                  : "private"
              }
            >
              <Select
                size="middle"
                onChange={e => {
                  return form.validateFields();
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {sellerTypes.map((type, index) => (
                  <Option key={index} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Item>
            {sellerTypeField !== "private" && (
              <>
                <Item
                  name="seller[companyVatNo]"
                  label="Vat Number:"
                  rules={[
                    {
                      required: false,
                      message: "Please enter the Company VAT number"
                    }
                  ]}
                  initialValue={
                    selectedListing && selectedListing?.seller?.companyVatNo
                      ? selectedListing?.seller?.companyVatNo
                      : null
                  }
                >
                  <Input
                    size="middle"
                    minLength={15}
                    maxLength={15}
                    type="number"
                    disabled={true}
                  />
                </Item>
                <Item
                  name="seller[commercialRegistration]"
                  label="Commercial Registration:"
                  rules={[
                    {
                      required: false,
                      message: "Please enter the Commercial Registration number"
                    }
                  ]}
                  initialValue={
                    selectedListing &&
                    selectedListing?.seller?.commercialRegistration
                      ? selectedListing?.seller?.commercialRegistration
                      : ""
                  }
                >
                  <Input
                    minLength={10}
                    maxLength={10}
                    disabled={true}
                    type="text"
                  />
                </Item>
              </>
            )}
          </Panel>
        )}

        <Panel
          forceRender
          header="Item Information"
          key="2"
          className={styles.collapse}
        >
          <Item
            name="title"
            label={"Listing Title:"}
            rules={[
              {
                required: true,
                message: "Please enter a given title."
              },
              {
                validator: validateTitleLength
              }
            ]}
            className={styles.inlineInput}
            initialValue={selectedListing?.title}
          >
            <Input.TextArea
              rows={1}
              showCount
              maxLength={50}
              data-panel-key="2"
            />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="titleLocalized[ar]"
            rules={[
              {
                required: true,
                message: "Please enter a given Arabic translation."
              },
              {
                validator: validateTitleLength
              }
            ]}
            label={"Arabic Translation:"}
            initialValue={selectedListing?.titleLocalized?.ar}
          >
            <Input.TextArea
              rows={1}
              showCount
              maxLength={50}
              data-panel-key="2"
            />
          </Item>
          <Item
            className={styles.inlineInput}
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter a given (event) description"
              },
              {
                validator: validateDescriptionLength
              }
            ]}
            label={"Listing Description:"}
            initialValue={selectedListing?.description}
          >
            <TextArea
              rows={2}
              disabled={false}
              showCount
              maxLength={250}
              data-panel-key="2"
            />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="descriptionLocalized[ar]"
            rules={[
              {
                required: true,
                message: "Please enter a given Arabic translation."
              },
              {
                validator: validateDescriptionLength
              }
            ]}
            label={"Arabic Translation:"}
            initialValue={selectedListing?.titleLocalized?.ar}
          >
            <TextArea
              rows={2}
              disabled={false}
              showCount
              maxLength={250}
              data-panel-key="2"
            />
          </Item>
          <Item
            name="listingTypeId"
            rules={[
              {
                required: true,
                message: "Please select a given (listing) category."
              }
            ]}
            label={"Listing Category:"}
            initialValue={selectedListing?.listingTypeId}
          >
            <TreeSelect
              showSearch
              allowClear
              data-panel-key="2"
              value={selectedItem}
              style={{ width: "100%" }}
              placeholder="Select a category"
              onChange={value => setSelectedItem(value)}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              filterTreeNode={handleTreeSearch}
            >
              {filteredCategories && renderTreeNodes(filteredCategories)}
            </TreeSelect>
          </Item>
          <div>
            {fields &&
              fields.map(field => (
                <React.Fragment key={field.id}>
                  {renderFormFields([field], false)}
                  {field.children &&
                    field.children.map(child =>
                      renderFormFields([child], true)
                    )}
                </React.Fragment>
              ))}
          </div>
        </Panel>

        <Panel
          forceRender
          header="Media Upload"
          key="3"
          className={styles.collapse}
        >
          <canvas className={styles.canvas} ref={imageToBase64}></canvas>
          <Form.Item
            noStyle
            name="media"
            hidden={true}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.media !== currentValues.media
            }
          >
            <Input type="text" data-panel-key="3" />
          </Form.Item>
          <Form.Item
            name="media"
            label="Media:"
            rules={[
              {
                required: true,
                message: "Please upload an image."
              }
            ]}
            valuePropName="fileLisImage"
            className={styles.uploadMediaText}
          >
            <Upload
              name="media"
              data-panel-key="3"
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              maxCount={20}
              multiple
              beforeUpload={beforeUpload}
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true
              }}
              customRequest={customUpload}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={removeFile}
              defaultFileList={[]}
            >
              {uploadButton}
            </Upload>
            <span className={styles.uploadMediaText}>
              Only supports jpg and png. Max 1mb file size
            </span>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{
                  width: "100%"
                }}
                src={previewImage}
              />
            </Modal>
          </Form.Item>
          <Item
            name="reportRequested"
            initialValue={selectedListing?.inpsectionReportRequested}
            hidden={!selectedListing || initialReportsFileList.length > 0}
          >
            <Checkbox
              value={selectedListing?.inpsectionReportRequested}
              checked={selectedListing?.inpsectionReportRequested}
            >
              Inspection Report Requested
            </Checkbox>
          </Item>
          {initialReportsFileList.length > 0 && (
            <Form.Item
              name="initialInspectionReport"
              label="Inspection Report:"
              className={styles.uploadMediaText}
              style={{ maxWidth: "400px" }}
            >
              <Upload
                data-panel-key="3"
                defaultFileList={[]}
                onRemove={removeFile}
                onDownload={({ url }) => window.open(url)}
                fileList={initialReportsFileList}
                accept="application/pdf"
                listType="picture"
              ></Upload>
              <Button
                ghost
                type="primary"
                icon={<DownloadOutlined />}
                style={{ marginTop: "10px" }}
                href={selectedListing?.mediaInspectionReport?.url}
                target="_blank"
              >
                {t("Download Report")}
              </Button>
            </Form.Item>
          )}

          <Form.Item
            name="advancedInspectionReport"
            label="Inspection Report:"
            className={styles.uploadMediaText}
            style={{ maxWidth: "400px" }}
            hidden={
              selectedListing && !selectedListing?.inpsectionReportRequested
            }
          >
            <Upload
              accept="application/pdf"
              listType="picture"
              maxCount={1}
              fileList={advancedReportsFileList}
              customRequest={customUpload}
              beforeUpload={checkReportFileSize}
              onChange={({ file, fileList }) =>
                handleInspectionReportChange(fileList, file, "advanced")
              }
              data-panel-key="3"
              onRemove={removeFile}
            >
              {advancedReportsFileList.length <= 0 && (
                <Button icon={<UploadOutlined />}>
                  {t("Inspection Report")}
                </Button>
              )}
            </Upload>
          </Form.Item>

          {selectedListing?.addOns?.videos && (
            <Item
              name="vehicleVideo"
              label={t("newAuctions.videoUploadLabelTitle")}
              getValueFromEvent={normFile}
              style={{
                fontWeight: 500,
                fontSize: "18px",
                paddingTop: "5px"
              }}
            >
              <Upload
                accept="video/mp4,video/ogg,video/quicktime"
                multiple={false}
                maxCount={1}
                customRequest={customUpload}
                defaultFileList={
                  selectedListing?.mediaVideo
                    ? [formatFile(selectedListing?.mediaVideo)]
                    : []
                }
                fileList={videoFiles}
                beforeUpload={file => {
                  const sizeIsOk = file.size / 1024 / 1024 <= 50;
                  if (!sizeIsOk) {
                    message.error(t("newAuctions.videoSizeError"));
                    return false;
                  }
                  return true;
                }}
                onChange={handleVideoChanged}
              >
                <Button>
                  <UploadOutlined />
                  {t("newAuctions.uploadButtonTitle")}
                </Button>
              </Upload>
            </Item>
          )}
        </Panel>

        <Panel
          forceRender
          header="Pricing & positioning"
          key="4"
          className={styles.collapse}
        >
          <Item
            name="individualListingSaleType"
            rules={[
              {
                required: true,
                message: "Please choose listing type!"
              }
            ]}
            label="Listing Type:"
            initialValue={selectedListing?.saleType}
          >
            <Select
              onChange={value => setSelectedItem(value)}
              value={selectedItem}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              options={[{ value: "timed-auction", label: "timed-auction" }]}
            />
          </Item>

          {saleTypeField !== "sale" && (
            <>
              <Item label={"Start Price:"} style={{ marginBottom: 0 }} required>
                <Input.Group compact style={{ display: "flex" }}>
                  <Item name="currencyCode" initialValue="SAR">
                    <Input
                      readOnly={true}
                      disabled={false}
                      style={{ maxWidth: "50px" }}
                    />
                  </Item>
                  <Item
                    style={{ flexGrow: 1 }}
                    name="startingPrice"
                    rules={[
                      {
                        required: true,
                        message: "Start price is required."
                      }
                    ]}
                    initialValue={selectedListing?.startingPrice?.amount}
                  >
                    <InputNumber
                      data-panel-key="4"
                      disabled={false}
                      formatter={value => formatNumber(value)}
                    />
                  </Item>
                </Input.Group>
              </Item>
              <Item
                label={"Reserved Price:"}
                style={{ marginBottom: 0 }}
                required
              >
                <Input.Group compact style={{ display: "flex" }}>
                  <Item name="currencyCode" initialValue="SAR">
                    <Input
                      readOnly={true}
                      disabled={false}
                      style={{ maxWidth: "50px" }}
                    />
                  </Item>
                  <Item
                    style={{ flexGrow: 1 }}
                    name="reservedPrice"
                    rules={[
                      {
                        required: true,
                        message: "Reserve price is required"
                      },
                      {
                        validator: validateReservedPrice
                      }
                    ]}
                    dependencies={["startingPrice"]}
                    initialValue={selectedListing?.reservedPrice?.amount}
                  >
                    <InputNumber
                      data-panel-key="4"
                      disabled={false}
                      formatter={value => formatNumber(value)}
                    />
                  </Item>
                </Input.Group>
              </Item>
              <Item
                label={"Minimum Bid Increment Amount:"}
                style={{ marginBottom: 0 }}
                required
              >
                <Input.Group compact style={{ display: "flex" }}>
                  <Item name="currencyCode" initialValue="SAR">
                    <Input
                      readOnly={true}
                      disabled={false}
                      style={{ maxWidth: "50px" }}
                    />
                  </Item>
                  <Item
                    style={{ flexGrow: 1 }}
                    name="minimumBidAmount"
                    rules={[
                      {
                        required: true,
                        message: "Minimum Bid Increment amount is required"
                      }
                    ]}
                    initialValue={selectedListing?.minimumBidAmount?.amount}
                  >
                    <InputNumber
                      data-panel-key="4"
                      disabled={false}
                      formatter={value => formatNumber(value)}
                    />
                  </Item>
                </Input.Group>
              </Item>
            </>
          )}
          <Item label={"Shipping Cost:"} style={{ marginBottom: 0 }} required>
            <Input.Group compact style={{ display: "flex" }}>
              <Item name="currencyCode" initialValue="SAR">
                <Input
                  readOnly={true}
                  disabled={false}
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="deliveryPrice"
                rules={[
                  {
                    required: true,
                    message: "Shipping Cost is required."
                  }
                ]}
                initialValue={selectedListing?.deliveryPrice?.amount}
              >
                <InputNumber
                  data-panel-key="4"
                  disabled={false}
                  formatter={value => formatNumber(value)}
                />
              </Item>
            </Input.Group>
          </Item>

          <Item name="listingAvailableForSale" initialValue={buyNowEnabled}>
            <Checkbox
              value={buyNowEnabled}
              checked={buyNowEnabled}
              onChange={() => toggleBuyNow()}
            >
              Listing Available For Sale
            </Checkbox>
          </Item>
          {buyNowEnabled && (
            <>
              <Item label="Listing Price:" style={{ marginBottom: 0 }}>
                <Input.Group compact style={{ display: "flex" }}>
                  <Item>
                    <Input
                      data-panel-key="4"
                      readOnly={true}
                      defaultValue={
                        selectedListing?.vehiclePrice?.currency?.code ?? "SAR"
                      }
                      style={{ maxWidth: "50px", marginBottom: 0 }}
                    />
                  </Item>
                  <Item
                    style={{ flexGrow: 1, marginBottom: 0 }}
                    name="buyNowPrice"
                    initialValue={selectedListing?.buyNowPrice?.amount}
                    rules={[
                      {
                        required: buyNowEnabled ? true : false,
                        message: "Listing Price is required."
                      },
                      {
                        validator: validateListingPrice
                      }
                    ]}
                  >
                    <InputNumber
                      data-panel-key="4"
                      formatter={value => formatNumber(value)}
                    />
                  </Item>
                </Input.Group>
              </Item>
            </>
          )}

          <h3>Listing location</h3>

          <Item
            name="addressObjectSameAsDelivery"
            initialValue={listingLocationEnabled}
          >
            <Checkbox
              value={listingLocationEnabled}
              checked={listingLocationEnabled}
              onChange={() => toggleListingLocation()}
            >
              Listing location is the same as my delivery address
            </Checkbox>
          </Item>
          {!listingLocationEnabled && (
            <>
              <Item
                name="addressObject[en]"
                label="Address:"
                rules={[
                  {
                    required: true,
                    message: "listing Address is required!"
                  }
                ]}
                initialValue={
                  selectedListing?.addressObject
                    ? selectedListing?.addressObject?.en
                    : ""
                }
              >
                <Select
                  allowClear
                  showSearch
                  value={searchText}
                  placeholder="Search for an address"
                  style={{ width: "100%" }}
                  onSearch={handleSearch}
                  filterOption={false}
                  onSelect={handleSelect}
                  data-panel-key="4"
                  getPopupContainer={(trigger: any) => trigger.parentElement}
                >
                  {addressOptions.map((address: any, index) => (
                    <Option key={index} value={address.en} address={address}>
                      {address.en}
                    </Option>
                  ))}
                </Select>
              </Item>
              <Item
                hidden
                name="addressObject[ar]"
                label="Address:"
                rules={[
                  {
                    required: true,
                    message: "listing Address is required!"
                  }
                ]}
                initialValue={selectedListing?.addressObject?.ar}
              >
                <Input type="text" data-panel-key="4" />
              </Item>
              <Item
                hidden
                name="addressObject[cityId]"
                label="Address:"
                rules={[
                  {
                    required: true,
                    message: "listing Address is required!"
                  }
                ]}
                initialValue={selectedListing?.addressObject?.cityId}
              >
                <InputNumber
                  data-panel-key="4"
                  formatter={value => formatNumber(value)}
                />
              </Item>
            </>
          )}

          <Item name="featured" initialValue={featuredEnabled}>
            <Checkbox
              value={featuredEnabled}
              checked={featuredEnabled}
              onChange={() => toggleFeatured()}
            >
              Featured
            </Checkbox>
          </Item>
        </Panel>

        {selectedListing &&
          selectedListing?.state !== "ready_for_review" &&
          selectedListing?.saleType !== "sale" &&
          path !== "unsuccessful-orders" &&
          action !== "relist" && (
            <Panel
              forceRender
              header="Auction Information"
              key="5"
              className={styles.collapse}
            >
              <Item
                name="auctionAssigned"
                label={"Assign to Auction:"}
                initialValue={selectedListing?.auction?.id}
                rules={[
                  {
                    required: false,
                    message: "Please select a given Auction."
                  }
                ]}
                hidden={
                  action === "activate" ||
                  selectedListing.saleType !== "timed-auction"
                }
              >
                <Select
                  disabled={selectedListing?.auction?.id}
                  data-panel-key="5"
                  onChange={value => setSelectedItem(value)}
                  value={selectedItem}
                  getPopupContainer={(trigger: any) => trigger.parentElement}
                  options={timedAuctions?.map((timedAuction: any) => ({
                    value: timedAuction.id,
                    label: timedAuction.title
                  }))}
                />
              </Item>

              {auctionField && selectedListing?.auction?.id ? (
                <>
                  <Item
                    name="startAt"
                    label="Start Date & Time:"
                    rules={[
                      {
                        required: false,
                        message: "Lot Start date is required!"
                      }
                    ]}
                  >
                    <DatePicker
                      className={styles.inputDate}
                      showTime={{ format: "HH:mm" }}
                      format={"YYYY-MM-DD HH:mm:ss"}
                      disabled={selectedListing?.auction?.id}
                    />
                  </Item>
                  <Item
                    name="endAt"
                    label="End Date & Time:"
                    rules={[
                      {
                        required: false,
                        message: "Lot End date is required!"
                      }
                    ]}
                  >
                    <DatePicker
                      className={styles.inputDate}
                      showTime={{ format: "HH:mm" }}
                      format={"YYYY-MM-DD HH:mm:ss"}
                      disabled={
                        selectedListing?.auction?.id &&
                        selectedListing?.type !== "auction"
                      }
                    />
                  </Item>
                </>
              ) : (
                <>
                  <>
                    <Item
                      name="startAt"
                      label="Start Date & Time:"
                      rules={[
                        {
                          required: true,
                          message: "Lot Start date is required!"
                        }
                      ]}
                      initialValue={
                        selectedListing?.startAt
                          ? moment(selectedListing?.startAt)
                          : ""
                      }
                      hidden={
                        !selectedListing?.startAt && action !== "activate"
                      }
                    >
                      <DatePicker
                        showTime={{ format: "HH:mm" }}
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD HH:mm:ss"}
                        disabledDate={disabledDate}
                        disabled={selectedListing?.state === "active"}
                        data-panel-key="5"
                      />
                    </Item>
                    <Item
                      name="endAt"
                      label="End Date & Time:"
                      rules={[
                        {
                          required: true,
                          message: "Lot End date is required!"
                        }
                      ]}
                      initialValue={
                        selectedListing?.endAt
                          ? moment(selectedListing?.endAt)
                          : ""
                      }
                      hidden={!selectedListing?.endAt && action !== "activate"}
                    >
                      <DatePicker
                        showTime={{ format: "HH:mm" }}
                        style={{ width: "100%" }}
                        format={"YYYY-MM-DD HH:mm:ss"}
                        disabledDate={disabledDate}
                        disabled={
                          selectedListing?.state === "active" &&
                          selectedListing?.type !== "auction"
                        }
                        data-panel-key="5"
                      />
                    </Item>
                  </>
                </>
              )}
            </Panel>
          )}
        {selectedListing && selectedListing?.buyerData && (
          <Panel
            header="Buyer Information"
            key="6"
            className={styles.collapse}
            forceRender
          >
            <Item
              name="buyerId"
              rules={[
                {
                  required: false,
                  message: "Please choose seller type!"
                }
              ]}
              label="Buyer Id:"
              initialValue={selectedListing?.buyerData?.id}
            >
              <InputNumber readOnly={true} type="number" data-panel-key="6" />
            </Item>
            <Item
              name="buyerName"
              rules={[
                {
                  required: false,
                  message: "Please choose seller type!"
                }
              ]}
              label="Name:"
              initialValue={selectedListing?.buyerData?.buyerName}
            >
              <Input readOnly={true} type="text" data-panel-key="6" />
            </Item>
            <Item
              name="buyerEmail"
              rules={[
                {
                  required: false,
                  message: "Please choose seller type!"
                }
              ]}
              label="Email Address:"
              initialValue={selectedListing?.buyerData?.buyerEmail}
            >
              <Input readOnly={true} type="email" data-panel-key="6" />
            </Item>
            <Item
              name="phoneNumber"
              rules={[
                {
                  required: false,
                  message: "Please choose seller type!"
                }
              ]}
              label="Phone number:"
              initialValue={selectedListing?.buyerData?.buyerMobile}
            >
              <Input readOnly={true} type="text" data-panel-key="6" />
            </Item>
            <Item
              name="deliveryAddress"
              rules={[
                {
                  required: false,
                  message: "Please choose seller type!"
                }
              ]}
              label="Delivery Address:"
              initialValue={
                selectedListing?.buyerData?.deliveryAddress?.address
              }
            >
              <Input readOnly={true} type="text" data-panel-key="6" />
            </Item>
          </Panel>
        )}
      </Collapse>
    </>
  );
};

export default ListingCollapse;
