import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

export const login = ({ email, password }) => {
  const endpoint = ENDPOINTS.LOGIN;

  return api.post(endpoint, { email, password }).then(resp => resp);
};

export const resetPassword = (email: string) => {
  return api.post(ENDPOINTS.ADMIN_RESET_PASSWORD, { email }).then(resp => resp);
};
