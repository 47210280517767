import React, { useEffect, useState } from "react";
import * as auctioneerApi from "app/api/auctioneerApi";
import qs from "query-string";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
  TablePaginationConfig
} from "antd";
import styles from "./AuctioneerPortalUserScreen.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import { useTranslation } from "react-i18next";
import AuctionEditCreate from "./components/AuctionEditCreate/AuctionEditCreate";
import { getAuctioneers } from "app/api/auctioneerApi";
import { PaginationConfig } from "antd/lib/pagination";

const AuctioneerPortalUserScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };
  const [isCreateAuctioneerVisible, setIsCreateAuctioneerVisible] = useState<
    boolean
  >(false);
  const [isEditAuctioneerVisible, setIsEditAuctioneerVisible] = useState<
    boolean
  >(false);
  const [isViewAuctioneerVisible, setIsViewAuctioneerVisible] = useState<
    boolean
  >(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [selectedAuctioneer, seteslectedAuctioneer] = useState();

  const [form] = Form.useForm();
  const { Item } = Form;

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const toggleEditAuctioneerModal = (index: number) => {
    seteslectedAuctioneer(data[index]);
    setIsEditAuctioneerVisible(true);
  };
  const toggleViewAuctioneerModal = (index: number) => {
    seteslectedAuctioneer(data[index]);
    setIsViewAuctioneerVisible(true);
  };

  useEffect(() => {
    loadAuctions();
  }, [location.search, pagination.current]);

  const loadAuctions = () => {
    setLoading(true);
    getAuctioneers(qs.parse(location.search))
      .then(payload => {
        setLoading(false);
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const onDelete = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      content: `You’re about to delete user ${record.firstName}.`,
      okText: "Yes, delete user",
      okType: "danger",
      onOk() {
        return auctioneerApi
          .deleteAuctioneers(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Auctioneer Deleted");
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const onReset = record => {
    Modal.confirm({
      title: `Are you sure you want to reset password?`,
      content: `You’re about to reset ${record.firstName} password.`,
      okText: `Yes, reset ${record.firstName} password`,
      okType: "danger",
      onOk() {
        return auctioneerApi
          .resetPassword(record.id)
          .then(p => {
            if (p.status === 200) {
              message.success("Password has been reset");
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
      render: text => <a>{text}</a>
    },

    {
      title: "Auctioneer Name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Auctions Surname",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile"
    },
    {
      title: "Auctions Assigned",
      dataIndex: "liveAuctions",
      key: "liveAuctions",
      render: record => <>{record.length}</>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => toggleViewAuctioneerModal(index)}>
            View
          </Button>
          <Button type="link" onClick={() => toggleEditAuctioneerModal(index)}>
            Edit
          </Button>
          <Button danger type="link" onClick={() => onDelete(record)}>
            Delete
          </Button>
          <Button type="text" onClick={() => onReset(record)}>
            Reset password
          </Button>
        </>
      )
    }
  ];

  return (
    <>
      {isCreateAuctioneerVisible && (
        <AuctionEditCreate
          isVisible={isCreateAuctioneerVisible}
          onClose={() => setIsCreateAuctioneerVisible(false)}
          onCreate={() => {}}
          selectedAuctioneer={""}
          onUpdate={() => loadAuctions()}
        />
      )}
      {isEditAuctioneerVisible && (
        <AuctionEditCreate
          isEditable
          isVisible={isEditAuctioneerVisible}
          onClose={() => setIsEditAuctioneerVisible(false)}
          onCreate={() => {}}
          selectedAuctioneer={selectedAuctioneer}
          onUpdate={() => loadAuctions()}
        />
      )}
      {isViewAuctioneerVisible && (
        <AuctionEditCreate
          isView
          isVisible={isViewAuctioneerVisible}
          onClose={() => setIsViewAuctioneerVisible(false)}
          onCreate={() => {}}
          selectedAuctioneer={selectedAuctioneer}
          onUpdate={() => loadAuctions()}
        />
      )}

      <div className={styles.tableContainer}>
        <Card className={styles.filterAndSearchContainer}>
          <Form onFinish={(values: any) => handleSearch(values)} form={form}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6}>
                <Item name="userId" label="User ID">
                  <Input type="number" placeholder="User ID" />
                </Item>
              </Col>
              <Col span={6}>
                <Item name="auctioneerName" label="Auctioneer Name">
                  <Input type="text" placeholder="Auctioneer Name" />
                </Item>
              </Col>
              <Col span={6}>
                <Button
                  className={styles.searchButton}
                  type="primary"
                  htmlType="submit"
                >
                  {t("default.searchButton")}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t("default.resetButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className={styles.tableContainer}>
        <Card>
          <div className={styles.auctionsOverview}>
            <h1>Auctioneers</h1>
            <Button
              type="primary"
              onClick={() => setIsCreateAuctioneerVisible(true)}
            >
              Add New Auctioneer
            </Button>
          </div>
          <div className={styles.auctionsTable}>
            <Table
              rowKey="id"
              dataSource={data}
              loading={loading}
              columns={columns}
              onChange={pagination => handleTableChanges(pagination)}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default AuctioneerPortalUserScreen;
