import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "query-string";

import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { Button, Card, Col, Form, Input, Modal, Row, Table } from "antd";
import Column from "antd/lib/table/Column";

import {
  getWithdrawalRequests,
  withdrawalCancel,
  withdrawalCompleted,
  withdrawalProcess
} from "app/api/usersApi";

import { QueryHelper } from "app/helpers/QueryHelper";

import styles from "./WithdrawalRequestsScreen.module.scss";
import { withdrawalRequest, WithdrawalType } from "app/types/userType";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

const WithdrawalRequestsScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<withdrawalRequest[]>([]);

  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [actionState, setActionState] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    console.log({ data });
  }, [data]);

  const getData = () => {
    setLoading(true);
    getWithdrawalRequests(qs.parse(location.search))
      .then(payload => {
        const {
          data: withdrawals,
          meta: { current_page, per_page, total }
        } = payload;
        setData(withdrawals);
        setPagination({
          ...pagination,
          current: current_page,
          pageSize: per_page,
          total
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const cancel = (id: number) => {
    setActionState(true);
    closeModals();
    withdrawalCancel(id)
      .then(() => {
        getData();
      })
      .catch(error => console.log(error))
      .finally(() => setActionState(false));
  };

  const process = (id: number) => {
    setActionState(true);
    withdrawalProcess(id)
      .then(() => {
        getData();
      })
      .catch(error => console.log(error))
      .finally(() => setActionState(false));
  };

  const approve = (id: number) => {
    setActionState(true);
    withdrawalCompleted(id)
      .then(() => {
        getData();
      })
      .catch(error => console.log(error))
      .finally(() => setActionState(false));
  };

  const closeModals = () => {
    Modal.destroyAll();
  };

  const { confirm } = Modal;

  const showCancelConfirmationModal = (id: number) => {
    confirm({
      title: "Are you sure you want to cancel this request?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes, cancel",
      okType: "danger",
      cancelText: "No",
      onOk() {
        return cancel(id);
      }
    });
  };

  const { Item } = Form;

  let [form] = Form.useForm();

  return (
    <div>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Item name="userId" label={t("withdrawals.userId")}>
                <Input placeholder={t("withdrawals.userId")} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="mobile" label={t("withdrawals.phoneNumber")}>
                <Input placeholder={t("withdrawals.phoneNumber")} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="firstName" label={t("First Name")}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="lastName" label={t("Last Name")}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="iban" label={t("withdrawals.userIBAN")}>
                <Input placeholder={t("withdrawals.userIBAN")} />
              </Item>
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className={styles.tableContainer}>
        <Card>
          <div>
            <div className={styles.headerContainer}>
              <h2>{t("withdrawals.title")}</h2>
            </div>
            <Table
              dataSource={data}
              loading={loading}
              rowKey={obj => obj.despositId}
              onChange={pagination => handleTableChanges(pagination)}
              pagination={pagination}
            >
              <Column
                key="id"
                title={t("withdrawals.userId")}
                render={record => record.userId}
              />
              <Column
                key="nameTitle"
                title={t("withdrawals.name")}
                render={record => record.name}
              />
              <Column
                key="phoneNumber"
                title={t("withdrawals.phoneNumber")}
                render={record => record.mobile}
              />
              <Column
                key="userIBAN"
                title={t("withdrawals.userIBAN")}
                render={record => record.iban}
              />
              <Column
                key="requestAmount"
                title={t("withdrawals.requestAmount")}
                render={record =>
                  record?.amount?.currency?.code + " " + record?.amount?.amount
                }
              />
              <Column
                key="status"
                title={t("withdrawals.status")}
                render={record => record.state}
              />
              <Column
                key="action"
                title={t("withdrawals.actions")}
                render={record => {
                  switch (record.state) {
                    case WithdrawalType.Pending:
                      return (
                        <Button
                          onClick={() => process(record.id)}
                          icon={<ClockCircleOutlined />}
                          loading={actionState}
                        >
                          {t("withdrawals.processRequest")}
                        </Button>
                      );
                    case WithdrawalType.Processing:
                      return (
                        <div>
                          <Button
                            onClick={() => approve(record.id)}
                            type="primary"
                            icon={<CheckOutlined />}
                            loading={actionState}
                          >
                            {t("withdrawals.approveRequest")}
                          </Button>
                          <Button
                            style={{ marginLeft: "10px" }}
                            danger
                            onClick={() =>
                              showCancelConfirmationModal(record.id)
                            }
                            icon={<CloseOutlined />}
                            loading={actionState}
                          >
                            {t("withdrawals.cancelRequest")}
                          </Button>
                        </div>
                      );
                    default:
                      return null;
                  }
                }}
              />
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default WithdrawalRequestsScreen;
