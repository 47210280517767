import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../AuctionFormItems.module.scss";
import { auctionType } from "app/types/auctionType";
import PreviewItem from "../components/PreviewItem";

interface UploadDocumentsPreviewProps {
  selectedActiveAuction: auctionType;
}

const UploadDocumentsPreview = ({
  selectedActiveAuction
}: UploadDocumentsPreviewProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.formPreviewItemsContainer}>
      <h3>{t("newAuctions.uploadedDocumentsPreviewLabel")}</h3>
      {selectedActiveAuction.vehicleModel?.categories && (
        <PreviewItem
          label={t("newAuctions.inspectionReportLabelTitle")}
          value={
            selectedActiveAuction.vehicleModel.categories !== null &&
            selectedActiveAuction.vehicleModel.categories[0]?.name
          }
        />
      )}
      {/* @TODO: Read video from selected auction request */}
      <PreviewItem
        label={t("newAuctions.videoUploadLabelTitle")}
        value={
          selectedActiveAuction.mediaVideo !== null &&
          selectedActiveAuction.mediaVideo?.fileName
        }
      />
    </div>
  );
};

export default UploadDocumentsPreview;
