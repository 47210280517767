export enum WithdrawalType {
  Pending = "pending",
  Processing = "processing",
  Completed = "completed",
  Cancelled = "cancelled"
}

export type withdrawalRequest = {
  amount: {
    amount: number;
    currency: {
      code: string;
      fractionDigits: number;
      name: string;
      roundingIncrement: number;
      symbol: string;
    };
  };
  completedAt: string | null;
  createdAt: string;
  despositId: number;
  iban: string;
  id: number;
  mobile: string;
  name: string;
  processingAt: null;
  state: string;
  updatedAt: string | null;
  userId: number;
};

export type userType = {
  id: number;
  email: string;
  profileImage: string;
  firstName: string;
  middleName: string;
  lastName: string;
  areaCode: string;
  mobile: string;
  dateOfBirth: string;
  gender: string;
  address: string;
  role?: {
    id: number;
    name: string;
  };
  city: string;
  state: string;
  country: string;
  checkedTc: boolean;
  deposit: {
    amount: number;
    currency: {
      code: string;
      name: string;
      symbol: string;
      fractionDigits: number;
      roundingIncrement: number;
    };
  };
  favouriteBank: {
    id: number;
    name: string;
    beneficiaryName: string;
    iban: string;
    accountNumber: string;
    createdAt: string;
    updatedAt: string;
  };
  currency: {
    code: string;
    name: string;
    symbol: string;
    fractionDigits: number;
    roundingIncrement: number;
  };
  shop: {
    approved: boolean;
    trusted: boolean;
    createdAt: string;
    description: string;
    name: string;
    followerCount: number;
    id: number;
  };
};
