import React, { memo } from "react";
import { Layout } from "antd";

const { Content } = Layout;

const DefaultLayout = ({ children }) => {
  return (
    <Layout>
      <Content>{children}</Content>
    </Layout>
  );
};

export default memo(DefaultLayout);
