import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Modal,
  Table,
  TablePaginationConfig,
  message
} from "antd";
import styles from "../../BulkItems.module.scss";
import * as bulkUploadsApi from "app/api/bulkUploadsApi";
import { getRawEntriesList, processRawSheet } from "app/api/bulkUploadsApi";
import { RawEntriesType } from "app/types/bulkUploadsType";
import { PaginationConfig } from "antd/lib/pagination";
import { useLocation } from "react-router-dom";
import qs from "query-string";

interface RawEntriesListTableProps {
  data: any;
  visible: () => void;
  loadSheetList: () => void;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const RawEntriesListTable = ({
  data,
  visible,
  loadSheetList
}: RawEntriesListTableProps) => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [rawEntriesData, setRawEntriesData] = useState<RawEntriesType[]>();
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  const columns = [
    {
      title: "Item Type",
      dataIndex: "saleType",
      key: "saleType"
    },
    {
      title: "Category",
      dataIndex: "auctionVehicleType",
      key: "auctionVehicleType"
    },
    {
      title: "Make",
      dataIndex: "vehicleMakeName",
      key: "vehicleMakeName"
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail"
    },
    {
      title: "Reserve Price",
      dataIndex: "reservedPrice",
      key: "reservedPrice",
      render: text => <span>SAR {text}</span>
    },
    {
      title: "Actions",
      key: "upload",
      render: (text, record) => (
        <Button danger type="link" onClick={() => handleDeleteRawEntry(record)}>
          Delete
        </Button>
      )
    }
  ];

  const loadRawEntriesData = async page => {
    try {
      setLoading(true);

      if (data) {
        const payload = await getRawEntriesList(
          data.id,
          qs.parse(location.search),
          page
        );

        setRawEntriesData(payload.data);
        setPagination(() => ({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        }));
      }
    } catch (error) {
      console.error("Something went wrong:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRawEntriesData(1);
  }, []);

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadRawEntriesData(newPagination.current);
  };

  const handleClick = () => {
    visible();
    loadSheetList();
  };

  const handleDeleteRawEntry = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this entry?",
      content: `You’re about to delete ${record.title}`,
      okText: "Yes, delete entry",
      okType: "danger",
      onOk: () => deleteRawEntry(record)
    });
  };

  const deleteRawEntry = async record => {
    try {
      const response = await bulkUploadsApi.deleteRawEntry(record.id);
      if (response.status === 200) {
        message.success("Entry Deleted");
        setLoading(true);
        await loadRawEntriesData(1);
      } else {
        message.error("Something wrong happened, try again.");
      }
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleProcess = async () => {
    try {
      setLoading(true);

      const response = await processRawSheet(data.id);

      if (response.status === 200) {
        visible();
        loadSheetList();
        message.success("Sheet processed successfully");
      } else {
        message.error("Something Wrong happened, try again.");
      }
    } catch (error) {
      message.error("Something went wrong. Please try again!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.tableContainer}>
        <Card>
          <div className={styles.auctionsOverview}>
            <div className={styles.tableTitle}>
              <Button
                className={styles.backButton}
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={handleClick}
              />
              <h1>{data.name} Raw Entries</h1>
            </div>
            <Button onClick={handleProcess} type="primary">
              Process
            </Button>
          </div>
          <Table
            columns={columns}
            loading={loading}
            dataSource={rawEntriesData}
            pagination={pagination}
            onChange={pagination => handleTableChanges(pagination)}
          />
        </Card>
      </div>
    </>
  );
};

export default RawEntriesListTable;
