import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import styles from "../AuctionFormItems.module.scss";

interface PreviewItemProps {
  label: string;
  value: any;
  editable?: boolean;
  onEdit?: any;
}
const PreviewItem = ({
  label,
  value,
  editable = false,
  onEdit = null
}: PreviewItemProps) => {
  return (
    <span>
      <span className={styles.formItem}>{label}</span> <span>{value}</span>
      {editable && (
        <>
          <Button type="text" icon={<EditOutlined />} onClick={onEdit} />
        </>
      )}
    </span>
  );
};

export default PreviewItem;
