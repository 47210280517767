import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./TabsModal.module.scss";
import {
  createTabbedItem,
  getWidgets,
  updateTabbedItem
} from "app/api/pageLayoutApi";
import { CategoriesType, TabType, Widget } from "app/types/pageLayoutType";

interface TabsModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  selectedTab?: TabType;
  categories?: CategoriesType;
  onLoad: () => void;
  path: string;
  widgetId: any;
}

function TabsModal({
  isVisible,
  handleCancel,
  selectedTab,
  categories,
  onLoad,
  path,
  widgetId
}: TabsModalProps) {
  const { Item } = Form;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [selectedItem, setSelectedItem] = useState<number>();
  const [widgets, setWidgets] = useState<Widget[]>([]);

  const handleClose = () => {
    handleCancel();
    onLoad();
    form.resetFields();
  };

  const onFinish = values => {
    const body = new FormData();

    if (values.tabTitle) {
      body.append("title", values.tabTitle);
    }

    if (values.arabicTranslation) {
      body.append("title_localized", values.arabicTranslation);
    }

    if (values.tabListing) {
      body.append("attributes[type]", values.tabListing);
    }

    if (values.tabState) {
      body.append("attributes[state]", values.tabState);
    }

    if (values.tabOrder) {
      body.append("attributes[order]", values.tabOrder);
    }

    if (values.tabCategory) {
      body.append("attributes[category]", values.tabCategory);
    }

    if (values.featured !== undefined) {
      body.append("attributes[featured]", values.featured);
    }

    if (selectedTab?.id !== undefined) {
      updateTabbedItem(path, selectedTab.id, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Widget updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createTabbedItem(path, widgetId, body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 200) {
            handleClose();
            return message.success("Widget created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleSwitchChange = value => {
    form.setFieldsValue({
      featured: value
    });
  };

  useEffect(() => {
    getWidgets()
      .then(payload => {
        setWidgets(payload);
      })
      .catch(error => console.log(error));
  }, []);

  const filteredWidget = widgets?.filter(
    widget => widget?.title === "multitab"
  );

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={handleCancel}
      title={selectedTab ? "Edit Tab" : "Create Tab"}
      footer={
        <Button form="createTabsForm" type="primary" htmlType="submit">
          {selectedTab ? "Edit Tab" : "Create Tab"}
        </Button>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="createTabsForm"
        onFieldsChange={(changedFields, allFields) => {
          return allFields;
        }}
      >
        <div>
          <Item
            className={styles.inlineInput}
            name="tabTitle"
            label={"Tab Title:"}
            initialValue={selectedTab?.title}
            rules={[
              {
                required: true,
                message: "Please enter tab title."
              }
            ]}
          >
            <Input type="text" />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="arabicTranslation"
            label={"Arabic translation:"}
            initialValue={selectedTab?.title_localized}
            rules={[
              {
                required: true,
                message: "Please enter Arabic translation."
              }
            ]}
          >
            <Input type="text" />
          </Item>
          <Item
            name="tabListing"
            label={"Tab Listing:"}
            initialValue={selectedTab?.attributes?.type}
            rules={[
              {
                required: true,
                message: "Please select a given (tab) listing."
              }
            ]}
          >
            <Select
              size="middle"
              onChange={e => {
                return form.validateFields();
              }}
            >
              {widgets &&
                filteredWidget[0]?.attributes?.type?.map(type => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
            </Select>
          </Item>
          <Item
            name="tabCategory"
            rules={[
              {
                required: false,
                message: "Please select a given (tab) category."
              }
            ]}
            label={"Tab Category:"}
            initialValue={
              selectedTab?.attributes?.category
                ? +selectedTab?.attributes?.category
                : ""
            }
          >
            <Select
              showSearch
              placeholder="Select an item"
              onChange={value => setSelectedItem(value)}
              value={selectedItem}
              filterOption={(input, option) =>
                option?.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {categories?.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="tabState"
            rules={[
              {
                required: true,
                message: "Please select a given (widget) state."
              }
            ]}
            label={"Tab State:"}
            initialValue={selectedTab?.attributes?.state}
          >
            <Select
              size="middle"
              onChange={e => {
                return form.validateFields();
              }}
            >
              {widgets &&
                filteredWidget[0]?.attributes?.state?.map(state => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
            </Select>
          </Item>
          <Item
            name="tabOrder"
            rules={[
              {
                required: true,
                message: "Please select a given (tab) order."
              }
            ]}
            label={"Tab Order:"}
            initialValue={selectedTab?.attributes?.order}
          >
            <Select
              size="middle"
              onChange={e => {
                return form.validateFields();
              }}
            >
              {widgets &&
                filteredWidget[0]?.attributes?.order?.map(order => (
                  <Option key={order} value={order}>
                    {order}
                  </Option>
                ))}
            </Select>
          </Item>

          <Item
            name="featured"
            initialValue={
              selectedTab?.attributes?.featured === "true" ? true : false
            }
          >
            <div className={styles.requireField}>
              <div className={styles.requireLabel}>
                Make Tab Featured:
                <p className="ant-form-item-extra">
                  Featured corresponding tab.
                </p>
              </div>
              <Switch
                defaultChecked={
                  selectedTab?.attributes?.featured === "true" ? true : false
                }
                checkedChildren="YES"
                unCheckedChildren="NO"
                onChange={handleSwitchChange}
              />
            </div>
          </Item>
        </div>
      </Form>
    </Modal>
  );
}

export default TabsModal;
