import React, { useState, useEffect } from "react";
import { Modal, Button, message, Select, Input, Form } from "antd";
import { useTranslation } from "react-i18next";

import { getUserRoles } from "app/api/usersApi";
import { createUser } from "app/api/usersApi";

const { Item } = Form;

interface CreateUserModalProps {
  isVisible: boolean;
  onClose: () => void;
  getData: () => void;
}

const CreateUserModal = ({
  isVisible,
  onClose,
  getData
}: CreateUserModalProps) => {
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState<any>([]);

  useEffect(() => {
    getUserRoles().then(resp => setUserRoles(resp.data));
  }, []);

  const { Option } = Select;

  let [form] = Form.useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onFinish = values => {
    const postObject = {
      role: values.role,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName
    };

    createUser(postObject)
      .then((response: any) => {
        const resp = response.response || response;
        if (resp.status === 201) {
          getData();
          handleClose();
          return message.success("User invited");
        }
        const error =
          resp.data.payload?.validation?.[0].errors?.[0].message ||
          resp.data.message;
        message.error(error);
      })
      .catch(error => {
        console.log(error);
        return message.error("Something went wrong. Try again!");
      });
  };

  return (
    <Modal
      title={t("users.inviteNewTitle")}
      visible={isVisible}
      onCancel={handleClose}
      width={400}
      destroyOnClose
      footer={
        <Button form="createUserForm" htmlType="submit" type="primary">
          {t("users.createUserButton")}
        </Button>
      }
    >
      <div>
        <Form
          name="createUserForm"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <div>
            <Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please enter a given (first) name"
                }
              ]}
              label={t("newAuctions.givenNameLabelTitle")}
            >
              <Input type="text" />
            </Item>
            <Item
              name="lastName"
              rules={[
                { required: true, message: "Please enter a sur (last) name" }
              ]}
              label={t("newAuctions.surNameLabelTitle")}
            >
              <Input type="text" />
            </Item>
            <Item
              name="email"
              rules={[{ required: true, message: "Please enter an email" }]}
              label={t("newAuctions.emailLabelTitle")}
            >
              <Input
                type="email"
                placeholder={t("currentAuctions.emailPlaceholder")}
              />
            </Item>
            <Item
              name="role"
              rules={[{ required: true, message: "Please choose a role" }]}
              label={t("users.roleInputLabel")}
              initialValue={userRoles[0]?.id}
            >
              <Select size="middle" placeholder={t("users.rolePlaceholder")}>
                {userRoles.map((role, index) => (
                  <Option key={index} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
