import { IMenuItemType } from "app/types/routerType";
import ActiveAuctionsScreen from "app/screens/AuctionsScreen/ActiveAuctions/ActiveAuctionsScreen";
// import AuctionRequestsScreen from 'app/screens/AuctionsScreen/AuctionRequests/AuctionRequestsScreen';
import EndedAuctionsScreen from "app/screens/AuctionsScreen/EndedAuctions/EndedAuctionsScreen";
import FinalizedAuctionsScreen from "app/screens/AuctionsScreen/FinalizedTrades/FinalizedAuctionsScreen";

import { PermissionEnum } from "app/constants/permissionConst";
import {
  ProfileOutlined,
  UnorderedListOutlined,
  HistoryOutlined
} from "@ant-design/icons";
import BuyNowRequestsScreen from "app/screens/AuctionsScreen/BuyNowRequests/BuyNowRequestsScreen";

export const HOME_SCREEN: IMenuItemType = {
  id: "items",
  path: "/items",
  title: "Items",
  icon: ProfileOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const ACTIVE_AUCTIONS: IMenuItemType = {
  id: "active-auctions",
  path: "/active-auctions",
  component: ActiveAuctionsScreen,
  title: "Active Items",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const BUY_NOW_REQUESTS: IMenuItemType = {
  id: "buy-now-requests",
  path: "/buy-now-requests",
  component: BuyNowRequestsScreen,
  title: "Buy Now",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

// export const AUCTION_REQUESTS: IMenuItemType = {
//   id: "auction-requests",
//   path: "/auction-requests",
//   component: AuctionRequestsScreen,
//   title: "Item Requests",
//   icon: UnorderedListOutlined,
//   permissions: [
//     PermissionEnum.SUPER_ADMIN,
//     PermissionEnum.ADMIN,
//     PermissionEnum.AUCTIONEER,
//     PermissionEnum.BACK_OFFICE_USER
//   ]
// };

export const HISTORY_SCREEN: IMenuItemType = {
  id: "history",
  path: "/history",
  title: "History",
  icon: HistoryOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const ENDED_AUCTIONS: IMenuItemType = {
  id: "ended-auctions",
  path: "/ended-auctions",
  component: EndedAuctionsScreen,
  title: "Ended Items",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const FINALIZED_TRADES: IMenuItemType = {
  id: "finalized-auctions ",
  path: "/finalized-auctions ",
  component: FinalizedAuctionsScreen,
  title: "Finalized Items",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

HOME_SCREEN.subMenuItems = [
  // ACTIVE_AUCTIONS
  // AUCTION_REQUESTS,
  // BUY_NOW_REQUESTS,
];

HISTORY_SCREEN.subMenuItems = [ENDED_AUCTIONS, FINALIZED_TRADES];
