import React from "react";
import { Button, Modal } from "antd";
import WidgetCreateEditForm from "../WidgetsForm/WidgetCreateEditForm";
import { CategoriesType, Widget } from "app/types/pageLayoutType";

interface WidgetCreateModalModalProps {
  path: string;
  onLoad: () => void;
  isVisible: boolean;
  categories?: CategoriesType;
  handleCancel: () => void;
  selectedWidget?: Widget;
}

const WidgetCreateModal = ({
  path,
  onLoad,
  isVisible,
  categories,
  handleCancel,
  selectedWidget
}: WidgetCreateModalModalProps) => {
  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={handleCancel}
      title={selectedWidget ? "Edit Widget" : "Create Widget"}
      footer={
        <Button form="createWidgetForm" type="primary" htmlType="submit">
          {selectedWidget ? "Edit Widget" : "Create Widget"}
        </Button>
      }
    >
      <WidgetCreateEditForm
        path={path}
        onLoad={onLoad}
        onClose={handleCancel}
        categories={categories}
        selectedWidget={selectedWidget}
      />
    </Modal>
  );
};

export default WidgetCreateModal;
