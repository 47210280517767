import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import AuthenticationScreen from "app/screens/AuthenticationScreen/AuthenticationScreen";

/** Screen: Login Page */
export const LOGIN_SCREEN: IMenuItemType = {
  id: "login",
  path: "/login",
  component: AuthenticationScreen,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER,
  ],
};

export const FORGOT_PASSWORD_SCREEN: IMenuItemType = {
  id: "forgotPassword",
  path: "/forgot-password",
  component: AuthenticationScreen,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER,
  ],
};

export const RESET_PASSWORD_SCREEN: IMenuItemType = {
  id: "resetPassword",
  path: "/reset-password",
  component: AuthenticationScreen,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER,
  ],
};
