import React, { memo, useState, useEffect } from "react";
import { Card, Table, Form, Button, Popconfirm, DatePicker } from "antd";
import { Input, Row, Col } from "antd";
import { Select, Checkbox } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./EndedAuctionsScreen.module.scss";
import cx from "classnames";

import { useHistory, useLocation } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import {
  // getAuctionVehicle,
  getEndedAuctions,
  updateAuctionVehicle,
  getVehicleTypes,
  updateAuctionVehicleState
} from "app/api/auctionVehiclesApi";
import { auctionType, vehicleType } from "app/types/auctionType";
import PreviewAuctionModal from "../components/PreviewAuctionModal/PreviewAuctionModal";
import NewVehicleAuctionFormModal from "../components/NewAuctionFormModal/Vehicles/NewVehicleAuctionFormModal";
import { formatNumber } from "app/helpers/numberFormatHelper";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";
import { vehicleConfigType } from "app/types/vehicleConfigType";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { capitalize } from "app/helpers/capitalize";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const EndedAuctionsScreen = () => {
  const [data, setData] = useState<auctionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [selectedPreviewAuction, setSelectedPreviewAuction] = useState<any>(
    undefined
  );
  const [selectedRelistAuction, setSelectedRelistAuction] = useState<any>(
    undefined
  );
  const [isPreviewModalVisible, setPreviewModalVisible] = useState<boolean>(
    false
  );
  const [isRelistModalVisible, setRelistModalVisible] = useState<boolean>(
    false
  );
  const [buyerInformation, setBuyerInformation] = useState<auctionType>();
  const [isExpanded, setExpanded] = useState(false);
  const [expandedText] = useState<string>("Unfold");

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [vehicleTypesList, setVehicleTypesList] = useState<vehicleType[]>([]);
  const [vehiclesMakeData, setVehiclesMakeData] = useState<
    [{ id: string; name: string }]
  >();
  const vehicleConfigs: vehicleConfigType = getVehicleConfigsFromSessionStorage();

  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const { Item } = Form;
  const { Option } = Select;
  const [featuredChecked, setFeaturedChecked] = useState<boolean>();
  const handleCheckboxChange = event => {
    setFeaturedChecked(event.target.checked);
  };
  useEffect(() => {
    getVehicleTypes()
      .then(res => {
        setVehicleTypesList(res);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getData();

    if (vehicleConfigs !== null) {
      setVehiclesMakeData(vehicleConfigs?.filterOptions?.vehicleMakes);
    }
  }, [location.search]);

  const getData = () => {
    setLoading(true);
    getEndedAuctions(qs.parse(location.search))
      .then(payload => {
        setLoading(false);
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(() => {
        setLoading(true);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handlePreviewModalCancel = () => {
    setPreviewModalVisible(false);
    setSelectedPreviewAuction(undefined);
    // setBuyerInformation(undefined);
  };

  const togglePreviewAuctionModal = record => {
    // const LotNum = locations.find(
    //   location => location.id === locationId,
    // )?.slots;
    // getAuctionVehicle(data[index].id)
    //   .then(payload => {
    //     setBuyerInformation(payload);
    //   })
    //   .catch(error => {
    //     console.log("error", error);
    //   });
    setBuyerInformation(record);
    setSelectedPreviewAuction(record);
    setPreviewModalVisible(true);
  };

  const handleRelistModalCancel = () => {
    setRelistModalVisible(false);
    setSelectedRelistAuction(undefined);
  };

  const toggleRelistAuctionModal = record => {
    setSelectedRelistAuction(record);
    setRelistModalVisible(true);
  };

  const handleRecordUpdate = (record, status) => {
    const currentAuctionRequest = record;
    if (currentAuctionRequest) {
      const body = { [status]: true };
      updateAuctionVehicle(currentAuctionRequest.id, body)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };

  const handleAcceptReject = (record, state: string) => {
    const currentAuctionRequest = record;
    if (currentAuctionRequest) {
      updateAuctionVehicleState(currentAuctionRequest.id, state)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };
  const getAuctionVehicleTypeName = id => {
    if (id) {
      const listItem = vehicleTypesList.filter(x => x.id === id);
      if (listItem && listItem[0]?.name) {
        return listItem[0].name;
      } else {
        return "";
      }
    }
    return "";
  };

  return (
    <div className={styles.tableContainer}>
      {!!selectedPreviewAuction && !!isPreviewModalVisible && (
        <PreviewAuctionModal
          form={form}
          isVisible={isPreviewModalVisible}
          onClose={handlePreviewModalCancel}
          selectedActiveAuction={selectedPreviewAuction}
          isEnded
          buyerInformation={buyerInformation}
        />
      )}
      {!!selectedRelistAuction && !!isRelistModalVisible && (
        <NewVehicleAuctionFormModal
          relist
          isVisible={isRelistModalVisible}
          onClose={handleRelistModalCancel}
          selectedAuctionRequest={selectedRelistAuction}
          updateAuctionData={getData}
        />
      )}
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32
            }}
          >
            <Col span={6}>
              <Item name="lot" label={t("currentAuctions.lotTitle")}>
                <Input
                  type="number"
                  placeholder={t("currentAuctions.lotPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerName" label={t("currentAuctions.sellerTitle")}>
                <Input placeholder={t("currentAuctions.sellerPlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerEmail" label={t("currentAuctions.emailTitle")}>
                <Input
                  type="email"
                  placeholder={t("currentAuctions.emailPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setExpanded(!isExpanded);
                }}
              >
                {isExpanded ? <UpOutlined /> : <DownOutlined />}
                {isExpanded ? expandedText : "Collapse"}
              </Button>
            </Col>
          </Row>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32
            }}
            className={cx(styles.expanded, {
              [styles.isExpanded]: isExpanded
            })}
          >
            <Col span={6}>
              <Item name="phone" label={t("currentAuctions.phoneTitle")}>
                <Input placeholder={t("currentAuctions.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="make" label={t("currentAuctions.makeTitle")}>
                <Select
                  mode="multiple"
                  placeholder={t("currentAuctions.makePlaceholder")}
                >
                  {vehiclesMakeData?.map((make, index) => (
                    <Option key={index} value={make.id}>
                      {make.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name="modelName" label={t("currentAuctions.modelTitle")}>
                <Input placeholder={t("currentAuctions.modelPlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="auctionVehicleTypeIds" label="Vehicle Type">
                <Select mode="multiple" placeholder="e.g. Cars">
                  {vehicleTypesList?.map((type, index) => (
                    <Option key={index} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row
            className={cx(styles.expanded, {
              [styles.isExpanded]: isExpanded
            })}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32
            }}
          >
            <Col span={8}>
              <Item name="startToAndFrom" label={t("Auction Started")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="endToAndFrom" label={t("currentAuctions.endAtTitle")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="featured" label="Featured">
                <Checkbox
                  value={featuredChecked ? true : false}
                  onChange={handleCheckboxChange}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <div className={styles.auctionsOverview}>
          <h1>{t("Ended Items")}</h1>
        </div>
        <div className={styles.endedAuctionsTable}>
          <Table
            dataSource={data}
            loading={loading}
            rowKey="id"
            onChange={pagination => handleTableChanges(pagination)}
            pagination={pagination}
          >
            <Column
              key="lot"
              title={t("newAuctions.lotTitle")}
              dataIndex="lot"
              render={(text, record, index) => (
                <div
                  className={styles.actionButton}
                  onClick={() => togglePreviewAuctionModal(record)}
                >
                  {text}
                </div>
              )}
            />
            <Column
              key="itemtype"
              title="Item Type"
              render={data => capitalize(data.saleType)}
            />
            <Column
              key="auctiontype"
              title="Category"
              render={data =>
                getAuctionVehicleTypeName(data.auctionVehicleTypeId)
              }
            />
            <Column
              key="make"
              title={t("newAuctions.makeTitle")}
              render={(text: auctionType, record, index) =>
                text.vehicleModel?.vehicleMake?.name || ""
              }
            />
            <Column
              key="model"
              title={t("newAuctions.modelTitle")}
              render={text =>
                text.vehicleModel?.name ? text.vehicleModel?.name : "N/A"
              }
            />
            <Column
              key="seller"
              title={t("newAuctions.sellerTitle")}
              render={(text, record, index) => text.seller.firstName}
            />
            <Column
              key="email"
              title={t("newAuctions.emailTitle")}
              render={(text, record, index) => text.seller.email}
            />
            <Column
              key="createdAt"
              title={"Created"}
              dataIndex="createdAt"
              sorter={(a: any, b: any) =>
                moment(a.createdAt).unix() - moment(b.createdAt).unix()
              }
              render={date =>
                date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "N/A"
              }
            />
            <Column
              key="endAt"
              title={"Ending"}
              dataIndex="endAt"
              sorter={(a: any, b: any) =>
                moment(a.endAt).unix() - moment(b.endAt).unix()
              }
              render={date => moment(date).format("YYYY-MM-DD HH:mm:ss")}
            />
            <Column
              key="endedAt"
              title={"Ended"}
              dataIndex="endedAt"
              sorter={(a: any, b: any) =>
                moment(a.endedAt).unix() - moment(b.endedAt).unix()
              }
              render={date =>
                date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "N/A"
              }
            />
            <Column
              key="sellerPayment"
              title={t("Payment Total")}
              render={(text, record, index) =>
                text.seller?.prices?.total?.currency?.code +
                " " +
                formatNumber(text.seller?.prices?.total?.amount)
              }
            />
            <Column
              key="reservePrice"
              title={t("Reserve Price")}
              render={text =>
                text.reservedPrice?.currency?.code +
                " " +
                formatNumber(text.reservedPrice?.amount)
              }
            />
            <Column
              key="state"
              title={t("State")}
              render={(text, record, index) => (
                <div>
                  {text.state === "withdrawn" ? "cancelled" : text.state}
                  {text.endAuctionReason && (
                    <p>
                      <b>{t("Reason")}:</b> {text.endAuctionReason}
                    </p>
                  )}
                </div>
              )}
            />
            <Column
              key="inspectionReportBasicPaid"
              title="Admin Fee Paid via ePay"
              render={(text, record, index) =>
                text.adminFeesPaid === null
                  ? "N/A"
                  : text.adminFeesPaid
                  ? "True"
                  : "False"
              }
            />
            <Column
              key="action"
              title={t("newAuctions.actionsTitle")}
              render={(text, record, index) => {
                return (
                  <div>
                    {text.actions.map(action => {
                      let key, title, Icon, buttonText, onConfirm, value;

                      switch (action) {
                        case "relist":
                          key = "relist";
                          title = "Are you sure you want to relist item?";
                          Icon = null;
                          buttonText = "Relist";
                          value = false;
                          onConfirm = () => toggleRelistAuctionModal(record);
                          break;

                        case "confirm_transfer_of_ownership":
                          key = "transfer_ownership";
                          title =
                            "Are you sure you want to transfer ownership?";
                          Icon = null;
                          buttonText = "Transfer ownership";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(
                              record,
                              "transferOfOwnershipComplete"
                            );
                          break;

                        case "buyer_payment_complete":
                          key = "buyerPaymentComplete";
                          title =
                            "Are you sure you want to confirm buyer payment?";
                          Icon = null;
                          buttonText = "Confirm buyer payment";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "buyerPaymentComplete");
                          break;

                        case "seller_payment_complete":
                          key = "sellerPaymentComplete";
                          title =
                            "Are you sure you want to confirm seller payment?";
                          Icon = null;
                          buttonText = "Confirm seller payment";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "sellerPaymentComplete");
                          break;

                        case "confirm_payment":
                          key = "paymentComplete";
                          title = "Are you sure you want to confirm payment?";
                          Icon = null;
                          buttonText = "Confirm payment";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "buyerPaymentComplete");
                          break;

                        case "confirm_handover":
                          key = "confirmHandover";
                          title = "Are you sure you want to confirm handover?";
                          Icon = null;
                          buttonText = "Confirm handover";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "delivered");
                          break;

                        case "buyer_complete_purchase":
                          key = "buyerCompletePurchase";
                          title =
                            "Are you sure you want to complete buyer purchase?";
                          Icon = null;
                          buttonText = "Complete Buyer Purchase";
                          value = false;
                          onConfirm = () =>
                            handleRecordUpdate(record, "buyerCompletePurchase");
                          break;

                        case "accept":
                          key = "requestConfirm";
                          title =
                            "Are you sure you want to accept the request?";
                          Icon = null;
                          buttonText = "Accept";
                          value = false;
                          onConfirm = () =>
                            handleAcceptReject(record, "accept");
                          break;

                        case "deny":
                          key = "requestConfirm";
                          title = "Are you sure you want to deny the request?";
                          Icon = (
                            <DeleteOutlined
                              style={{
                                color: "red"
                              }}
                            />
                          );
                          buttonText = "Reject";
                          value = true;
                          onConfirm = () => handleAcceptReject(record, "deny");
                          break;

                        case "archive":
                        case "reject":
                        case "cancel":
                          key = `${action}`;
                          title = `Are you sure you want to ${action} the request?`;
                          Icon = (
                            <DeleteOutlined
                              style={{
                                color: "red"
                              }}
                            />
                          );
                          buttonText = `${capitalize(action)}`;
                          value = true;
                          onConfirm = () => handleRecordUpdate(record, action);
                          break;
                        default:
                          return null;
                      }
                      return (
                        <Popconfirm
                          key={key}
                          onConfirm={onConfirm}
                          icon={Icon}
                          title={title}
                        >
                          <div className={styles.actionButton}>
                            <Button danger={value} key={key} type="link">
                              {buttonText}
                            </Button>
                          </div>
                        </Popconfirm>
                      );
                    })}
                  </div>
                );
              }}
            />
          </Table>
        </div>
      </Card>
    </div>
  );
};

export default memo(EndedAuctionsScreen);
