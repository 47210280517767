/* eslint-disable react-hooks/exhaustive-deps */
import { Input, InputNumber, Select } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { getVehicleMake } from "app/api/auctionVehiclesApi";
import cx from "classnames";
import styles from "./NewAuctionFormItems.module.scss";
import { vehicleConfigType } from "app/types/vehicleConfigType";
import { auctionType } from "app/types/auctionType";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";
import { formatNumber } from "app/helpers/numberFormatHelper";

interface FormItemProps {
  isActive: boolean;
  relist?: boolean;
  vehicleInformationValue?: auctionType;
  validationError?: boolean;
  disableNext: (disabled: boolean) => void;
  disablePrevious: (disabled: boolean) => void;
  allFields: any;
  isMachinery?: boolean;
}

interface FormItemsWithContextProps extends FormItemProps {
  form: FormInstance;
}

export const VehicleInformationFormItems = ({
  relist,
  vehicleInformationValue,
  isActive,
  form,
  validationError,
  disableNext,
  disablePrevious,
  allFields,
  isMachinery
}: FormItemsWithContextProps) => {
  const { t } = useTranslation();
  const [vehicleMakes, setVehicleMakes] = useState<any>();
  const [vehicleDescription, setVehicleDescription] = useState("");
  const vehicleConfigs: vehicleConfigType = getVehicleConfigsFromSessionStorage();
  const vehicleFields = [
    "make",
    "model",
    "year",
    "mileage",
    "exteriorColor",
    "interiorColor",
    "fuelType",
    "cylinders",
    "transmission",
    "bodyType",
    "vehicleCondition",
    "seller[type]",
    "seller[saudiId]",
    "seller[companyVat]",
    "seller[saudiId]",
    "seller[companyVatNo]",
    "seller[commercialRegistration]"
    // 'horsepower',
    // 'seats',
    // 'doors',
    // 'interiorCondition',
    // 'trim',
  ];

  const fuelTypeField = form.getFieldValue("fuelType");

  const filteredConditions = vehicleConfigs?.filterOptions.conditions.filter(
    condition => condition.id !== "poor" && condition.id !== "bad"
  );

  const sellerTypes = [
    { id: "private", name: "Private" },
    { id: "companyWithVat", name: "Company With VAT" },
    { id: "companyNoVat", name: "Company Without VAT" },
    { id: "governmentOrganization", name: "Government Organization" }
  ];

  /**
   * Form items validation
   */
  useEffect(() => {
    if (isActive) {
      form.validateFields(vehicleFields).catch(error => {
        disableNext(error.errorFields.length > 0);
        disablePrevious(error.errorFields.length > 0);
      });
      if (vehicleInformationValue?.description !== null) {
        setVehicleDescription(vehicleDescription);
      }
    }
  }, [isActive, fuelTypeField]);

  useEffect(() => {
    if (isActive) {
      let hasErrors = false;
      allFields.forEach(field => {
        if (vehicleFields.includes(field.name[0]) && field.errors.length > 0) {
          hasErrors = true;
        }
      });
      if (isInvalidCommercialRegField()) hasErrors = true;
      disableNext(hasErrors);
      disablePrevious(hasErrors);
    }
  }, [allFields, fuelTypeField]);

  /**
   * Get the vehicle models based on the vehicle makes when the vehicle information screen isActive
   */
  const makeField = form.getFieldValue("make");
  useEffect(() => {
    if (isActive && !isMachinery && makeField) {
      getVehicleMake(makeField)
        .then(payload => {
          setVehicleMakes(payload);
        })
        .catch(error => console.log(error));
    }
  }, [isActive, makeField]);

  const { Item } = Form;
  const { Option } = Select;
  const { TextArea } = Input;
  const sellerTypeField = form.getFieldValue("seller[type]");
  const commercialRegField: number = form.getFieldValue(
    "seller[commercialRegistration]"
  );

  /**
   * Check if commercial registration field is more than 10 numbers
   *
   * @returns
   */
  const isInvalidCommercialRegField = () => {
    const isInvald: boolean =
      commercialRegField?.toString().length !== 10 &&
      sellerTypeField !== "private";
    return isInvald;
  };

  return (
    <div className={cx(styles.formItem, { [styles.isActive]: isActive })}>
      <Item
        name="title"
        rules={[{ required: true, message: "Please enter a title!" }]}
        label="Title"
        initialValue={vehicleInformationValue?.title}
      >
        <Input type="text" />
      </Item>
      {!isMachinery && vehicleInformationValue?.auctionVehicleTypeId === 1 && (
        <div>
          <Item
            name="make"
            rules={[{ required: true, message: "Please choose car make!" }]}
            label={t("newAuctions.makeLabelTitle")}
            initialValue={
              vehicleInformationValue?.vehicleModel?.vehicleMake?.id
            }
          >
            <Select size="middle">
              {vehicleConfigs?.filterOptions.vehicleMakes.map((make, index) => (
                <Option key={index} value={make.id}>
                  {make.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="model"
            rules={[{ required: true, message: "Please choose car model!" }]}
            label={t("newAuctions.modelLabelTitle")}
            initialValue={vehicleInformationValue?.vehicleModel?.id}
          >
            <Select size="middle">
              {isActive && vehicleMakes
                ? vehicleMakes.vehicleModels.map((model, index) => (
                    <Option key={index} value={model.id}>
                      {model.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item
            name="mileage"
            rules={[{ required: true, message: "Please choose car mileage!" }]}
            label={t("newAuctions.mileageLabelTitle")}
            initialValue={vehicleInformationValue?.mileage}
          >
            <InputNumber formatter={value => formatNumber(value)} />
          </Item>
          <Item
            name="year"
            rules={[{ required: true, message: "Please choose car year!" }]}
            label={t("newAuctions.yearLabelTitle")}
            initialValue={vehicleInformationValue?.vehicleYear}
          >
            <Select size="middle">
              {vehicleConfigs?.filterOptions.years.map((year, index) => (
                <Option key={index} value={year.name}>
                  {year.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="fuelType"
            rules={[{ required: true, message: "Please choose fuelType!" }]}
            label="Fuel Type"
            initialValue={vehicleInformationValue?.fuelType}
          >
            <Select size="middle">
              {vehicleConfigs?.filterOptions.fuelTypes.map(
                (fuelType, index) => (
                  <Option key={index} value={fuelType.name}>
                    {fuelType.name}
                  </Option>
                )
              )}
            </Select>
          </Item>
          {fuelTypeField !== "Electric" && fuelTypeField !== "electric" && (
            <>
              <Item
                name="transmission"
                rules={[
                  {
                    required: true,
                    message: "Please choose transmission!"
                  }
                ]}
                label={t("newAuctions.transmissionLabelTitle")}
                initialValue={vehicleInformationValue?.vehicleTransmission}
              >
                <Select size="middle">
                  {vehicleConfigs?.filterOptions.transmissions.map(
                    (transmission, index) => (
                      <Option key={index} value={transmission.id}>
                        {transmission.name}
                      </Option>
                    )
                  )}
                </Select>
              </Item>
              <Item
                name="cylinders"
                rules={[
                  {
                    required: true,
                    message: "Please choose cylinders!"
                  }
                ]}
                label="Cylinders"
                initialValue={vehicleInformationValue?.cylinders}
              >
                <Select size="middle">
                  {vehicleConfigs?.filterOptions.cylinders.map(
                    (cylinder, index) => (
                      <Option key={index} value={cylinder.name}>
                        {cylinder.name}
                      </Option>
                    )
                  )}
                </Select>
              </Item>
            </>
          )}

          <Item
            name="interiorColor"
            rules={[
              { required: true, message: "Please choose interior color" }
            ]}
            label="Interior Color"
            initialValue={vehicleInformationValue?.insideColor}
          >
            <Select size="middle">
              {vehicleConfigs?.filterOptions.colors.map((color, index) => (
                <Option key={index} value={color.name}>
                  {color.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item
            name="exteriorColor"
            rules={[
              { required: true, message: "Please choose exterior color" }
            ]}
            label={t("Exterior Color")}
            initialValue={vehicleInformationValue?.vehicleColor}
          >
            <Select size="middle">
              {vehicleConfigs?.filterOptions.colors.map((color, index) => (
                <Option key={index} value={color.name}>
                  {color.name}
                </Option>
              ))}
            </Select>
          </Item>
          {!relist && (
            <Item
              name="vinNumber"
              rules={[{ message: "Please choose vinNumber!" }]}
              label="VIN number"
              initialValue={vehicleInformationValue?.vin}
            >
              <Input type="text" />
            </Item>
          )}
          <Item
            name="vehicleCondition"
            rules={[
              { required: true, message: "Please choose exterior condition!" }
            ]}
            label="Condition"
            initialValue={vehicleInformationValue?.vehicleCondition}
          >
            <Select size="middle">
              {filteredConditions.map((condition, index) => (
                <Option key={index} value={condition.id}>
                  {condition.name}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
      )}
      <Item
        name="seller[type]"
        rules={[{ required: true, message: "Please choose seller type!" }]}
        label="Seller Type"
        initialValue={vehicleInformationValue?.seller.type}
      >
        <Select
          size="middle"
          onChange={e => {
            return form.validateFields();
          }}
        >
          {sellerTypes.map((type, index) => (
            <Option key={index} value={type.id}>
              {type.name}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name="seller[companyVatNo]"
        label="Vat Number"
        rules={[
          {
            required:
              sellerTypeField === "companyWithVat" ||
              sellerTypeField === "governmentOrganization",
            message: "Please enter the Company VAT number"
          }
        ]}
        initialValue={vehicleInformationValue?.seller?.companyVatNo}
      >
        <Input
          size="middle"
          minLength={15}
          maxLength={15}
          disabled={
            sellerTypeField === "private" || sellerTypeField === "companyNoVat"
          }
          type="number"
        />
      </Item>
      <Item
        name="seller[commercialRegistration]"
        label="Commercial Registration"
        rules={[
          {
            required: sellerTypeField !== "private",
            message: "Please enter the Commercial Registration number"
          }
        ]}
        initialValue={vehicleInformationValue?.seller?.commercialRegistration}
      >
        <Input
          minLength={10}
          maxLength={10}
          disabled={sellerTypeField === "private"}
          type="text"
        />
      </Item>
      <Item
        name="description"
        label={t("newAuctions.descriptionLabelTitle")}
        initialValue={vehicleInformationValue?.description}
      >
        <TextArea />
      </Item>
      {/* <Item
            name='trim'
            label='Trim'
            initialValue={vehicleInformationValue?.vehicleTrim?.id}
          >
            <Select size='middle'>
              {isActive && vehicleModels
                ? vehicleModels.vehicleTrims.map((trim, index) => (
                    <Option key={index} value={trim.id}>
                      {trim.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item> */}
      {/* <Item
            name='seats'
            rules={[{ required: true, message: 'Please choose seats' }]}
            label='Seats'
            initialValue={vehicleInformationValue?.seatsType}
            validateStatus={validationError ? 'error' : ''}
          >
            <Select size='middle'>
              {vehicleConfigs?.filterOptions.seats.map((seat, index) => (
                <Option key={index} value={seat.name}>
                  {seat.name}
                </Option>
              ))}
            </Select>
          </Item> */}
      {/* <Item
            name='doors'
            rules={[{ required: true, message: 'Please choose car doors!' }]}
            label={t('newAuctions.doorLabelTitle')}
            validateStatus={validationError ? 'error' : ''}
            initialValue={vehicleInformationValue?.doors}
          >
            <Select size='middle'>
              {vehicleConfigs?.filterOptions.doors.map((door, index) => (
                <Option key={index} value={door.name}>
                  {door.name}
                </Option>
              ))}
            </Select>
          </Item> */}
      {/* <Item
            name='horsepower'
            label='Horsepower'
            initialValue={vehicleInformationValue?.horsepower}
          >
            <Input type='number' />
          </Item> */}

      {/* <Item
            name='bodyType'
            rules={[{ required: true, message: 'Please choose car body!' }]}
            label={t('newAuctions.bodyLabelTitle')}
            initialValue={vehicleInformationValue?.bodyType}
          >
            <Select size='middle'>
              {vehicleConfigs?.filterOptions.bodyTypes.map(
                (bodyType, index) => (
                  <Option key={index} value={bodyType.name}>
                    {bodyType.name}
                  </Option>
                )
              )}
            </Select>
          </Item> */}
      {/* <Item
            name='interiorCondition'
            rules={[
              { required: true, message: 'Please choose interior condition!' },
            ]}
            label='Interior condition'
            initialValue={vehicleInformationValue?.vehicleInteriorCondition}
          >
            <Select size='middle'>
              {vehicleConfigs?.filterOptions.conditions.map(
                (condition, index) => (
                  <Option key={index} value={condition.id}>
                    {condition.name}
                  </Option>
                )
              )}
            </Select>
          </Item> */}
      {/* <Item
        name="seller[saudiId]"
        rules={[
          {
            required: sellerTypeField === "private",
            max: 10,
            min: 10,
            message: "Please enter a valid the Saudi ID ( 10 digits )"
          }
        ]}
        label="Saudi Id"
        initialValue={vehicleInformationValue?.seller?.saudiId}
      >
        <Input
          type="text"
          disabled={
            sellerTypeField === "companyNoVat" ||
            sellerTypeField === "companyWithVat"
          }
          maxLength={10}
        />
      </Item> */}
      {/* <div className={styles.arabic} dir="rtl">
        <br />
        <Item
          name="descriptionLocalized"
          label={t("newAuctions.arabicDescriptionLabelTitle")}
          initialValue={vehicleInformationValue?.descriptionLocalized?.ar}
        >
          <TextArea
            placeholder={t("newAuctions.arabicDescriptionPlaceholder")}
          />
        </Item>
      </div> */}
    </div>
  );
};
