import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get notifications
 * @param search
 * @returns
 * @export
 */

export const getNotifications = (search: any) => {
  const endpoint = ENDPOINTS.NOTIFICATIONS;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * updates a user object
 * @param search
 */
export const updateNotifications = (body: any) => {
  const endpoint = ENDPOINTS.NOTIFICATIONS;

  return api.post(endpoint, body);
};

/**
 * delete Notification
 * @param search
 */
export const deleteNotification = (id: number) => {
  const endpoint = ENDPOINTS.NOTIFICATIONS + `/${id}`;
  return api.delete(endpoint);
};
