import {
  AppstoreOutlined,
  CarOutlined,
  // FormOutlined,
  ProfileOutlined,
  RightSquareOutlined
} from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
// import InspectionReportCategories from 'app/screens/AuctionCategoriesScreen/InspectionReportCategories/InspectionReportCategories';
import InspectionReports from "app/screens/AuctionCategoriesScreen/InspectionReports/InspectionReports";
import EndedInspectionReports from "app/screens/AuctionCategoriesScreen/EndedInspectionReports/EndedInspectionReports";
import { IMenuItemType } from "app/types/routerType";
import VehicleCategoriesScreen from "app/screens/AuctionCategoriesScreen/VehicleCategories/VehicleCategoriesScreen";
import MainCategoriesTable from "app/screens/ManageCategories/MainCategories/MainCategoryTable/MainCategoriesTable";

export const MANAGE_CATEGORIES_SCREEN: IMenuItemType = {
  id: "manage-categories",
  path: "/manage-categories",
  title: "Manage Categories",
  icon: AppstoreOutlined,
  component: MainCategoriesTable,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const AUCTION_CATEGORIES_SCREEN: IMenuItemType = {
  id: "categories",
  path: "/categories",
  title: "Auction Categories",
  icon: RightSquareOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const INSPECTION_CATEGORIES_SCREEN: IMenuItemType = {
  id: "inspection-categories-link",
  path: "/inspection-categories",
  title: "Inspection categories",
  icon: RightSquareOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const AUCTION_CATEGORIES: IMenuItemType = {
  id: "vehicles",
  path: AUCTION_CATEGORIES_SCREEN.path + "/vehicles",
  title: "Vehicles",
  component: VehicleCategoriesScreen,
  icon: CarOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

// export const MANAGE_CATEGORIES: IMenuItemType = {
//   id: 'manage-categories',
//   path: INSPECTION_CATEGORIES_SCREEN.path + '/manage-categories',
//   title: 'Manage Categories',
//   component: InspectionReportCategories,
//   icon: FormOutlined,
//   permissions: [
//     PermissionEnum.SUPER_ADMIN,
//     PermissionEnum.ADMIN,
//     PermissionEnum.AUCTIONEER,
//     PermissionEnum.BACK_OFFICE_USER,
//   ],
// };

export const INSPECTION_VIEW_SCREEN: IMenuItemType = {
  id: "inspection-reports-requests",
  path: "/inspection-reports-requests",
  title: "Inspection Reports Requests",
  component: InspectionReports,
  icon: ProfileOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const ENDED_INSPECTION_VIEW_SCREEN: IMenuItemType = {
  id: "ended-inspection-reports-requests",
  path: "/ended-inspection-reports-requests",
  title: "Ended Inspection Reports Requests",
  component: EndedInspectionReports,
  icon: ProfileOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

AUCTION_CATEGORIES_SCREEN.subMenuItems = [AUCTION_CATEGORIES];
INSPECTION_CATEGORIES_SCREEN.subMenuItems = [
  // MANAGE_CATEGORIES,
  INSPECTION_VIEW_SCREEN,
  ENDED_INSPECTION_VIEW_SCREEN
];
