import { Button, Form, Modal } from "antd";
import React from "react";
import FieldsCreateEditForm from "../../Components/FieldsCreateEditForm/FieldsCreateEditForm";

function CustomFieldsModal({
  onClose,
  fieldId,
  isChild,
  isVisible,
  onUpdate,
  fieldVisible,
  selectedField,
  selectedCategory
}) {
  let [form] = Form.useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
    onUpdate();
  };
  return (
    <Modal
      title={selectedField ? " Edit Field" : "Create Field"}
      visible={isVisible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <Button form="createFieldsForm" type="primary" htmlType="submit">
          {selectedField ? " Edit Field" : "Create Field"}
        </Button>
      }
    >
      <FieldsCreateEditForm
        selectedField={selectedField}
        isChild={isChild}
        fieldId={fieldId}
        selectedCategory={selectedCategory}
        onUpdate={onUpdate}
        onClose={onClose}
        fieldVisible={fieldVisible}
      />
    </Modal>
  );
}

export default CustomFieldsModal;
