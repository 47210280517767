import React, { useState } from "react";
import ListingCollapse from "../ListingCollapse/ListingCollapse";
import { Button, Form, Input, Modal, message } from "antd";
import { createListing, updateListing } from "app/api/listingsApi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { attachListing } from "app/api/auctionShowsApi";
import * as listingApi from "app/api/listingsApi";
import { updateAuctionVehicle } from "app/api/auctionVehiclesApi";

export enum ActionTypes {
  Edit = "edit",
  Review = "review",
  Activate = "activate"
}

const ListingsForm = ({ selectedListing, visible, action, path, onLoad }) => {
  const { confirm } = Modal;
  let [form] = Form.useForm();
  const [allFields, setAllFields] = useState<any>([]);
  const userId = selectedListing?.user_id ? selectedListing?.user_id : 843;
  const saleType = selectedListing?.saleType === "sale" ? true : false;

  const showApprove = () => {
    confirm({
      title: "Do you Want to approve this listing?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          Clicking OK will set the listing status to '<strong>Queued</strong>
          ,' readying it as a live lot in its designated auction.
        </p>
      ),
      onOk() {
        form.submit();
      }
    });
  };

  const showActivate = () => {
    Modal.confirm({
      title: "Are you sure you want to activate auction?",
      content: `You’re about to activate ${selectedListing.title}.`,
      okText: "Activate auction",
      onOk() {
        form.submit();
      }
    });
  };

  const showRelist = () => {
    confirm({
      title: "Do you Want to relist this listing?",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          Clicking OK will set the listing status to '
          <strong>{saleType ? "active" : "activate pending"}</strong>
          ,' moving it to the
          {saleType ? " active " : " request "}listings section.
        </p>
      ),
      onOk() {
        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

        const body = {
          relist: "true",
          startAt: saleType ? currentTime : null
        };
        updateAuctionVehicle(selectedListing.id, body)
          .then((response: any) => {
            const resp = response.response || response;
            if (resp.status === 200) {
              visible();
              return message.success("Listing relisted");
            }
          })
          .catch(error => {
            return message.error("Something went wrong. Try again!");
          });
      }
    });
  };

  const onFinish = values => {
    const body = new FormData();

    allFields.forEach((field: any) => {
      const fieldValue = field.value;
      const fieldName = field.name;

      if (fieldValue !== undefined) {
        if (moment.isMoment(fieldValue)) {
          const formattedDate = fieldValue.format("YYYY");

          body.append(fieldName, formattedDate);
        } else {
          body.append(fieldName, fieldValue);
        }
      }
    });

    body.append("userId", userId);

    body.append("individualListing", "1");

    if (values.listingAvailableForSale !== undefined) {
      body.append(
        "listingAvailableForSale",
        values.listingAvailableForSale ? "1" : "0"
      );
    }

    if (values.startAt !== undefined) {
      body.append(
        "startAt",
        moment(values.startAt).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (values.endAt !== undefined) {
      body.append("endAt", moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"));
    }

    if (values.title) {
      body.append("titleLocalized[en]", values.title);
    }

    if (values.description) {
      body.append("descriptionLocalized[en]", values.description);
    }

    if (
      selectedListing?.state === "ready_for_review" &&
      action === ActionTypes.Review
    ) {
      body.append("state", "queued");
    }
    if (
      selectedListing &&
      selectedListing?.state === "activate_pending" &&
      action === ActionTypes.Activate
    ) {
      body.append("individualListingSaleType", "auction");
    }

    if (values.basicInspectionReport !== undefined) {
      body.append(
        "mediaInspectionReports[basic]",
        values.basicInspectionReport?.fileList[0]?.originFileObj
      );
    }
    if (
      values.advancedInspectionReport &&
      !values.advancedInspectionReport.skip
    ) {
      const file = values.advancedInspectionReport[0]?.originFileObj;
      if (file) {
        body.append("mediaInspectionReports[advanced]", file);
      }
    }
    if (
      values.initialInspectionReport &&
      !values.initialInspectionReport.skip
    ) {
      const file = values.initialInspectionReport[0]?.originFileObj;
      if (file) {
        body.append("mediaInspectionReports[initial]", file);
      }
    }
    // Add video to form data
    if (values.vehicleVideo) {
      body.append("mediaVideo", values.vehicleVideo[0]?.originFileObj);
    }

    // Process and convert each image in array to a blob
    if (values.media) {
      let ins = values.media.length;
      for (let x = 0; x < ins; x++) {
        const image = values.media[x].originFileObj;

        if (!values.media[x].skip) body.append("mediaPhotos[]", image);
      }
    }

    if (selectedListing?.id !== undefined) {
      updateListing(selectedListing.id, body)
        .then(resp => {
          if (resp.status === 200) {
            if (values.auctionAssigned !== undefined) {
              handleAttachAuction(selectedListing?.id, values.auctionAssigned);
            }
            visible();
            message.success("Listing updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createListing(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            visible();
            return message.success("Listing created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleAttachAuction = async (listingId, auctionId) => {
    if (!listingId || !auctionId) {
      console.error("Invalid listing or auction ID");
      return;
    }

    try {
      const updateData = {
        liveAuctionId: auctionId,
        listingId: listingId
      };

      await attachListing(updateData);

      message.success("Listing attached");
    } catch (error) {
      console.error("Error updating listing:", error);
    }
  };

  const submitButtonText = selectedListing ? "Save" : "Submit";

  const handleArchiveListing = () => {
    let endAuctionDescription = "";

    Modal.confirm({
      title: "Archive Active Listing?",
      content: (
        <div>
          <p>You are about to archive the active listing:</p>
          <p>{selectedListing.title}</p>
          <Input.TextArea
            placeholder="Listing Ending reason"
            onChange={e => (endAuctionDescription = e.target.value)}
            required
          />
        </div>
      ),
      okText: "Archive listing",
      okType: "danger",
      onOk() {
        if (selectedListing) {
          const body = {
            cancel: true,
            endAuctionReason: endAuctionDescription || "Cancelled"
          };

          updateAuctionVehicle(selectedListing.id, body, false)
            .then(p => {
              if (p.status === 200) {
                message.success("Listing archived");
                visible();
              } else {
                message.error("Something Wrong happened, try again.");
              }
            })
            .catch(error => console.log(error));
        }
      }
    });
  };

  const handleDeleteListing = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this listing?",
      content: `You’re about to delete ${selectedListing.title}.`,
      okText: "Yes, delete listing.",
      okType: "danger",
      onOk() {
        return listingApi
          .deleteListing(selectedListing.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Listing Deleted");
              visible();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const isPathValid =
    !path || path === "auction-requests" || path === "active-listings";

  const isActionValid = !action || action === ActionTypes.Edit;

  const isButtonDisabled = listing =>
    listing?.state === "active" &&
    listing?.type !== "auction" &&
    listing?.saleType !== "sale";

  return (
    <div>
      <Form
        name="createListingForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={(changedFields, allFields) => {
          setAllFields(allFields);
        }}
      >
        <ListingCollapse
          allFields={allFields}
          form={form}
          selectedListing={selectedListing}
          action={action}
          path={path}
          loadListing={onLoad}
        />
      </Form>
      <div
        style={{
          textAlign: "right",
          marginTop: "20px"
        }}
      >
        {selectedListing ? (
          <Button
            style={{
              marginRight: 8
            }}
            onClick={
              selectedListing?.state !== "active"
                ? handleDeleteListing
                : handleArchiveListing
            }
            danger
          >
            {selectedListing.state !== "active" ? "Delete" : "Archive"}
          </Button>
        ) : (
          <Button
            style={{
              marginRight: 8
            }}
            onClick={visible}
          >
            Cancel
          </Button>
        )}

        <Button
          type="primary"
          htmlType="submit"
          form="createListingForm"
          disabled={isButtonDisabled(selectedListing)}
          hidden={!(isActionValid && isPathValid)}
        >
          {submitButtonText}
        </Button>
        <Button
          type="primary"
          onClick={
            action === ActionTypes.Review
              ? showApprove
              : action === ActionTypes.Activate
              ? showActivate
              : showRelist
          }
          hidden={action === ActionTypes.Edit || action === undefined}
        >
          {action === ActionTypes.Review
            ? "Approve"
            : action === ActionTypes.Activate
            ? "Activate"
            : "Relist"}
        </Button>
      </div>
    </div>
  );
};

export default ListingsForm;
