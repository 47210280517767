import React from "react";
import CategoryCard from "../../Components/CategoryCard/Cards/CategoryCard";
import { PageHeader } from "antd";
import FieldsCreateEditForm from "../../Components/FieldsCreateEditForm/FieldsCreateEditForm";
import styles from "./ChildOptionsDetails.module.scss";
import ChildOptionsTable from "../ChildOptionsTable/ChildOptionsTable";

const ChildOptionsDetails = ({
  selectedField,
  visible,
  selectedCategory,
  onLoad
}) => {
  const routes = [
    {
      path: "manage-categories",
      breadcrumbName: "Manage Categories"
    },
    {
      path: "general",
      breadcrumbName: "General"
    },
    {
      path: "child-options",
      breadcrumbName: "Child Options"
    }
  ];

  const Header = () => (
    <PageHeader
      className={styles.pageHeader}
      onBack={() => visible(null, false)}
      title={selectedField?.name}
      breadcrumb={{ routes, separator: ">" }}
    />
  );
  return (
    <>
      <CategoryCard
        title={<Header />}
        cardContent={
          <FieldsCreateEditForm
            selectedField={selectedField}
            isChild
            fieldId={undefined}
            selectedCategory={selectedCategory}
            onUpdate={onLoad}
            onClose={() => visible(null, false)}
            fieldVisible={visible}
          />
        }
      />
      <ChildOptionsTable selectedField={selectedField} />
    </>
  );
};

export default ChildOptionsDetails;
