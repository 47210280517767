/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useContext } from "react";
import { Button, Select, DatePicker, Table, Spin, Checkbox } from "antd";
import { UpOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import { Form, Input, Row, Col, Card } from "antd";
import Modal from "antd/lib/modal/Modal";
import { TablePaginationConfig } from "antd/lib/table";
import { PaginationConfig } from "antd/lib/pagination";

import PreviewAuctionModal from "../components/PreviewAuctionModal/PreviewAuctionModal";
import {
  getActiveAuctionPolling,
  getActiveAuctionVehicles,
  updateAuctionVehicle,
  getDownPaymentAmount,
  getVehicleTypes
} from "app/api/auctionVehiclesApi";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";
import { vehicleConfigType } from "app/types/vehicleConfigType";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
import moment from "moment";
import cx from "classnames";
import styles from "./ActiveAuctionsScreen.module.scss";
import { auctionType, vehicleType } from "app/types/auctionType";
import { useInterval } from "app/helpers/intervalHookHelper";
import { UserContext } from "app/store/contexts/userContext";
import NewVehicleAuctionFormModal from "../components/NewAuctionFormModal/Vehicles/NewVehicleAuctionFormModal";
import SettingsAuctionModal from "../components/SettingsAuctionModal/SettingsAuctionModal";
import { capitalize } from "app/helpers/capitalize";

const ActiveAuctionsScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };
  const POLL_INTERVAL: number = 5000;

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const user = useContext(UserContext);
  const [data, setData] = useState<auctionType[]>([]);

  const [vehicleTypesList, setVehicleTypesList] = useState<vehicleType[]>([]);
  const [vehiclesMakeData, setVehiclesMakeData] = useState<
    [{ id: string; name: string }]
  >();
  const [selectedActiveAuction, setSelectedActiveAuction] = useState<
    auctionType
  >();
  const [vehicleModalType, setVehicleModalType] = useState<string | null>();
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [loading, setLoading] = useState<boolean>(true);
  const [polling, setPolling] = useState<boolean>(false);
  const [downPaymentAmount, setDownPaymentAmount] = useState<number>();
  const [pollTimestamp, setPollTimestamp] = useState<number>(
    new Date().getTime()
  );
  const [isExpanded, setExpanded] = useState(false);
  const [expandedText] = useState<string>("Unfold");
  const [selectedAuctionRequest, setSelectedAuctionRequest] = useState<
    auctionType
  >();
  const [isVehicleModalVisible, setVehicleModalVisible] = useState<boolean>(
    false
  );
  const [isSettingsModalVisible, setSettingsModalVisible] = useState<boolean>(
    false
  );

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEndAuctionModalVisible, setEndAuctionModalVisible] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();

  const vehicleConfigs: vehicleConfigType = getVehicleConfigsFromSessionStorage();

  // Kick off polling for live auctions
  useInterval(() => {
    // Update UI state to indicate polling active
    setPolling(true);
    // Fetch poll data
    getActiveAuctionPolling(pollTimestamp, qs.parse(location.search))
      .then(payload => {
        setPolling(false);
        setPollTimestamp(payload.data.timeStamp);
        // If array length is not 0 there is new data
        if (payload?.data?.auctionVehicle?.length !== 0) getData();
      })
      .catch(error => {
        setPolling(false);
        console.error(error);
      });
  }, POLL_INTERVAL);

  useEffect(() => {
    getData();

    if (vehicleConfigs !== null) {
      setVehiclesMakeData(vehicleConfigs?.filterOptions?.vehicleMakes);
    }
  }, [location.search]);

  useEffect(() => {
    getDownPaymentAmount()
      .then(payload => {
        setDownPaymentAmount(payload.amount);
      })
      .catch(error => console.log(error));
  }, [downPaymentAmount]);

  const getData = () => {
    setLoading(true);
    getActiveAuctionVehicles(qs.parse(location.search))
      .then(payload => {
        setLoading(false);
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getVehicleTypes()
      .then(res => {
        setVehicleTypesList(res);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleModalCancel = async shouldUpdate => {
    if (shouldUpdate) {
      await getData();
    }
    setIsVisible(false);
    setSelectedActiveAuction(undefined);
  };

  const toggleActiveAuctionModal = record => {
    setSelectedActiveAuction(record);
    setIsVisible(true);
  };

  const toggleNewAuctionModal = (
    record: any | null = null,
    type: string | null = null
  ) => {
    const selectedData: any = record !== null ? record : selectedActiveAuction;
    setSelectedAuctionRequest(selectedData);
    setIsVisible(false);
    setVehicleModalVisible(true);
    setVehicleModalType(type);
  };

  const handleVehicleModalCancel = async shouldUpdate => {
    if (shouldUpdate) {
      await getData();
    }
    setVehicleModalVisible(false);
    setSelectedAuctionRequest(undefined);
  };

  const toggleSettingsModal = () => {
    setSettingsModalVisible(true);
  };

  const handleSettingsModalClose = () => {
    setSettingsModalVisible(false);
    getDownPaymentAmount()
      .then(payload => {
        setDownPaymentAmount(payload.amount);
      })
      .catch(error => console.log(error));
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);
    const startRangeTimeValue = values["startToAndFrom"];
    const endRangeTimeValue = values["endToAndFrom"];

    // We don't need the object twice, since startRangeTimeValue and endRangeTimeValues delivers them
    delete values["startToAndFrom"];
    delete values["endToAndFrom"];

    values = {
      ...currentSearch,
      ...values,
      featured: featuredChecked ? 1 : "",
      startAtFrom: startRangeTimeValue
        ? startRangeTimeValue[0].format(dateFormat)
        : undefined,
      startAtTo: startRangeTimeValue
        ? startRangeTimeValue[1].format(dateFormat)
        : undefined,
      endAtFrom: endRangeTimeValue
        ? endRangeTimeValue[0].format(dateFormat)
        : undefined,
      endAtTo: endRangeTimeValue
        ? endRangeTimeValue[1].format(dateFormat)
        : undefined
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handleEndActiveAuction = (currentAuctionRequest: any, values: any) => {
    if (currentAuctionRequest) {
      const body = {
        cancel: true,
        endAuctionReason: values.endAuctionDescription || "Cancelled"
      };

      updateAuctionVehicle(currentAuctionRequest.id, body, false)
        .then(() => {
          getData();
          setEndAuctionModalVisible(false);
        })
        .catch(error => console.log(error));
    }
  };

  const showEndAuctionModal = (data: any) => {
    setSelectedActiveAuction(data);
    setEndAuctionModalVisible(true);
  };

  const hideEndAuctionModal = () => {
    setEndAuctionModalVisible(false);
    form.resetFields();
  };

  const { Item } = Form;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { TextArea } = Input;
  const isSuperAdmin: boolean | undefined = user.user.isSuperAdmin;
  const [featuredChecked, setFeaturedChecked] = useState<boolean>();
  const handleCheckboxChange = event => {
    setFeaturedChecked(event.target.checked);
  };

  const getAuctionVehicleTypeName = id => {
    if (id) {
      const listItem = vehicleTypesList.filter(x => x.id === id);
      if (listItem && listItem[0]?.name) {
        return listItem[0].name;
      } else {
        return "";
      }
    }
    return "";
  };

  const confirmationModal = () => {
    const record: any = selectedActiveAuction;
    if (!record) return;
    return (
      <Modal
        key="end_auction"
        visible={isEndAuctionModalVisible}
        onCancel={hideEndAuctionModal}
        title={
          t("currentAuctions.endAuctionTitle").replace(
            "auction",
            record.saleType
          ) +
          " - " +
          record.lot
        }
        destroyOnClose={true}
        footer={null}
      >
        <Form
          name="endAuctionForm"
          form={form}
          layout="vertical"
          onFinish={values => handleEndActiveAuction(record, values)}
        >
          <Item
            name="endAuctionDescription"
            label={t("currentAuctions.reasonLabel")}
            rules={[
              {
                required: true,
                message: t("currentAuctions.endAuctionError")
              }
            ]}
          >
            <TextArea
              placeholder={t("currentAuctions.endAuctionPlaceholder").replace(
                "Auction",
                capitalize(record.saleType)
              )}
            />
          </Item>

          <div className={styles.modalFooter}>
            <Button
              className={styles.cancelButton}
              onClick={hideEndAuctionModal}
            >
              {t("default.cancel")}
            </Button>

            <Button type="primary" htmlType="submit">
              {t("default.confirm")}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  };
  return (
    <div>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <Item name="lot" label={t("currentAuctions.lotTitle")}>
                <Input
                  type="number"
                  placeholder={t("currentAuctions.lotPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerName" label={t("currentAuctions.sellerTitle")}>
                <Input placeholder={t("currentAuctions.sellerPlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="sellerEmail" label={t("currentAuctions.emailTitle")}>
                <Input
                  type="email"
                  placeholder={t("currentAuctions.emailPlaceholder")}
                />
              </Item>
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setExpanded(!isExpanded);
                }}
              >
                {isExpanded ? <UpOutlined /> : <DownOutlined />}
                {isExpanded ? expandedText : "Collapse"}
              </Button>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            className={cx(styles.expanded, { [styles.isExpanded]: isExpanded })}
          >
            <Col span={6}>
              <Item name="phone" label={t("currentAuctions.phoneTitle")}>
                <Input placeholder={t("currentAuctions.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="make" label={t("currentAuctions.makeTitle")}>
                <Select
                  mode="multiple"
                  placeholder={t("currentAuctions.makePlaceholder")}
                >
                  {vehiclesMakeData?.map((make, index) => (
                    <Option key={index} value={make.id}>
                      {make.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={6}>
              <Item name="modelName" label={t("currentAuctions.modelTitle")}>
                <Input placeholder={t("currentAuctions.modelPlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="auctionVehicleTypeIds" label="Vehicle Type">
                <Select mode="multiple" placeholder="e.g. Cars">
                  {vehicleTypesList?.map((type, index) => (
                    <Option key={index} value={type.id}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row
            className={cx(styles.expanded, { [styles.isExpanded]: isExpanded })}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={8}>
              <Item name="startToAndFrom" label={t("Auction Started")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="endToAndFrom" label={t("currentAuctions.endAtTitle")}>
                <RangePicker showTime size="middle" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="featured" label="Featured">
                <Checkbox
                  value={featuredChecked ? true : false}
                  onChange={handleCheckboxChange}
                />
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className={styles.tableContainer}>
        <Card>
          <div className={styles.auctionsOverview}>
            <h1>
              {t("currentAuctions.activeAuctionsTitle").replace(
                "Auctions",
                "Items"
              )}
            </h1>{" "}
            {polling ? <Spin /> : null}
            <Button
              onClick={() => toggleSettingsModal()}
              icon={<EditOutlined />}
            >
              Settings {isSettingsModalVisible}
            </Button>
          </div>

          <div className={styles.auctionsTable}>
            {!!selectedActiveAuction && !!isVisible && (
              <PreviewAuctionModal
                isSuperAdmin={isSuperAdmin}
                form={form}
                isVisible={isVisible}
                onEdit={() => toggleNewAuctionModal()}
                onClose={() => handleModalCancel(true)}
                selectedActiveAuction={selectedActiveAuction}
              />
            )}
            {!!selectedAuctionRequest && !!isVehicleModalVisible && (
              <NewVehicleAuctionFormModal
                vehicleModalType={vehicleModalType}
                isSuperAdmin={isSuperAdmin}
                isVisible={isVehicleModalVisible}
                onClose={() => handleVehicleModalCancel(true)}
                selectedAuctionRequest={selectedAuctionRequest}
                updateAuctionData={getData}
              />
            )}
            {!!isSettingsModalVisible && (
              <SettingsAuctionModal
                isSuperAdmin={isSuperAdmin}
                form={form}
                downPaymentAmount={downPaymentAmount}
                isVisible={isSettingsModalVisible}
                onClose={() => handleSettingsModalClose()}
              />
            )}

            <Table
              rowKey="id"
              dataSource={data}
              loading={loading}
              pagination={pagination}
              onChange={pagination => handleTableChanges(pagination)}
            >
              <Column
                key="lot"
                title={t("currentAuctions.lotTitle")}
                dataIndex="lot"
                render={(text, record, index) => (
                  <div
                    className={styles.lot}
                    onClick={() => toggleActiveAuctionModal(record)}
                  >
                    {text}
                  </div>
                )}
              />
              <Column
                key="itemtype"
                title="Item Type"
                render={data => capitalize(data.saleType)}
              />
              <Column
                key="auctiontype"
                title="Category"
                render={data =>
                  getAuctionVehicleTypeName(data.auctionVehicleTypeId)
                }
              />
              <Column
                key="model"
                title={t("currentAuctions.modelTitle")}
                render={data =>
                  data.vehicleModel?.name ? data.vehicleModel?.name : "N/A"
                }
              />
              <Column
                key="seller"
                title={t("currentAuctions.sellerTitle")}
                render={data =>
                  data.seller?.firstName ? data.seller?.firstName : "N/A"
                }
              />
              <Column
                key="email"
                title={t("currentAuctions.emailTitle")}
                render={data =>
                  data.seller?.email ? data.seller?.email : "N/A"
                }
              />
              <Column
                key="phone"
                title={t("currentAuctions.phoneTitle")}
                render={data =>
                  data.seller?.mobile ? data.seller?.mobile : "N/A"
                }
              />
              <Column
                key="endAt"
                title={t("currentAuctions.endAtTitle").replace(
                  "Auction",
                  "Items"
                )}
                dataIndex="endAt"
                sorter={(a: any, b: any) =>
                  moment(a.endAt).unix() - moment(b.endAt).unix()
                }
                render={date => moment(date).format(dateFormat)}
              />
              <Column
                key="createdAt"
                title={t("currentAuctions.createdAtTitle")}
                dataIndex="createdAt"
                sorter={(a: any, b: any) =>
                  moment(a.createdAt).unix() - moment(b.createdAt).unix()
                }
                render={date => moment(date).format(dateFormat)}
              />
              <Column
                key="actions"
                title={t("currentAuctions.actionsTitle")}
                render={(text, record: any, index) => {
                  return (
                    <div>
                      <Button
                        onClick={() => showEndAuctionModal(record)}
                        type="link"
                      >
                        {`Cancel ${capitalize(record.saleType)}`}
                      </Button>
                      {isSuperAdmin ? (
                        <Button
                          onClick={() => toggleNewAuctionModal(record, "Edit")}
                          icon={<EditOutlined />}
                        >
                          {t("default.edit")}
                        </Button>
                      ) : null}
                    </div>
                  );
                }}
              />
            </Table>
            {confirmationModal()}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(ActiveAuctionsScreen);
