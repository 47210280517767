import React, { useEffect, useState } from "react";
import { Table, Button, Card, TablePaginationConfig } from "antd";
import styles from "../../BulkItems.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal";
import { vehicleType } from "app/types/auctionType";
import { getVehicleTypes } from "app/api/auctionVehiclesApi";
import { getEntriesList } from "app/api/bulkUploadsApi";
import { DataBulkType, RawEntriesType } from "app/types/bulkUploadsType";
import { PaginationConfig } from "antd/lib/pagination";
import qs from "query-string";
import { useLocation } from "react-router-dom";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

interface EntriesListTableProps {
  data: DataBulkType | undefined;
  visible: () => void;
  loadSheetList: () => void;
}

const EntriesListTable = ({
  data,
  visible,
  loadSheetList
}: EntriesListTableProps) => {
  const location = useLocation();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState();
  const [entriesData, setEntriesData] = useState<RawEntriesType[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleTypesList, setVehicleTypesList] = useState<vehicleType[]>([]);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  useEffect(() => {
    loadEntriesData(1);
  }, []);

  const loadEntriesData = async page => {
    try {
      setLoading(true);

      if (data) {
        const payload = await getEntriesList(
          data.id,
          qs.parse(location.search),
          page
        );
        setEntriesData(payload.data);
        setPagination(() => ({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        }));
      }
    } catch (error) {
      console.error("Something went wrong:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Lot No.",
      dataIndex: "lot",
      key: "lot",
      render: text => text
    },
    {
      title: "Item Type",
      dataIndex: "saleType",
      key: "saleType"
    },
    {
      title: "Category",
      key: "category",
      render: data => getAuctionVehicleTypeName(data.auctionVehicleTypeId)
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      render: (text, record, index) =>
        record.vehicleModel?.vehicleMake?.name || ""
    },
    {
      title: "Model",
      dataIndex: "make",
      key: "make",
      render: (text, record, index) => record.vehicleModel?.name || ""
    },
    {
      title: "Image listings",
      dataIndex: "mediaPhotos",
      key: "mediaPhotos",
      render: (text, record, index) => record?.mediaPhotos?.length ?? 0
    },
    {
      title: "Email",
      dataIndex: "user",
      key: "user",
      render: (text, record, index) => record?.seller?.email
    },
    {
      title: "Reserve Price",
      dataIndex: "reservedPrice",
      key: "reservedPrice",
      render: record => <span>SAR {record?.amount}</span>
    },
    {
      title: "Actions",
      key: "upload",
      render: (text, record) => (
        <Button type="link" onClick={() => handleUpload(record)}>
          Upload images
        </Button>
      )
    }
  ];

  useEffect(() => {
    getVehicleTypes()
      .then(res => {
        setVehicleTypesList(res);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const getAuctionVehicleTypeName = id => {
    if (id) {
      const listItem = vehicleTypesList.filter(x => x.id === id);
      if (listItem && listItem[0]?.name) {
        return listItem[0].name;
      } else {
        return "";
      }
    }
    return "";
  };

  const handleUpload = record => {
    setSelectedEntry(record);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setSelectedEntry(undefined);
    setModalVisible(false);
  };

  const handleClick = () => {
    visible();
    loadSheetList();
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadEntriesData(newPagination.current);
  };

  return (
    <>
      {!!selectedEntry && (
        <ImageUploadModal
          selectedEntry={selectedEntry}
          visible={modalVisible}
          onCancel={handleModalCancel}
          onLoad={() => loadEntriesData(1)}
        />
      )}
      <div className={styles.tableContainer}>
        <Card>
          <div className={styles.auctionsOverview}>
            <div className={styles.tableTitle}>
              <Button
                className={styles.backButton}
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={handleClick}
              />
              <h1>{data?.name} Entries</h1>
            </div>
          </div>
          <Table
            pagination={pagination}
            loading={loading}
            columns={columns}
            dataSource={entriesData}
            onChange={pagination => handleTableChanges(pagination)}
          />
        </Card>
      </div>
    </>
  );
};

export default EntriesListTable;
