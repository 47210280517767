import React, { memo, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout/DefaultLayout";
import ErrorScreen from "app/screens/ErrorScreen/ErrorScreen";
import { ClientErrors } from "app/constants/errorCodes";
import values from "lodash/values";
import { PRIVATE_ROUTES, NAVIGATION, PUBLIC_ROUTES } from "./Router.config";
import Permission, {
  setPermissions
} from "app/components/Permission/Permission";
import NavigationContainer from "./components/NavigationContainer/NavigationContainer";

import {
  isAuthenticated,
  getUserFromLocalStorage
} from "app/helpers/authenticationHelper";
import { UserContext, UserRoles } from "app/store/contexts/userContext";

const Router = () => {
  const filteredRoutes = values(PRIVATE_ROUTES).filter(item => item.component);

  const user = useContext(UserContext);
  const userFromLocalStorage = getUserFromLocalStorage();

  useEffect(() => {
    user.setUser({
      name: userFromLocalStorage?.firstName,
      isSuperAdmin: userFromLocalStorage?.role.name === UserRoles.SuperAdmin
    });
    setPermissions([userFromLocalStorage?.role?.name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated() && user !== null ? (
    <NavigationContainer navigation={NAVIGATION}>
      <Switch>
        {filteredRoutes.map(item => (
          <Route
            key={item.id}
            exact
            path={item.path}
            render={props => (
              <Permission
                redirect="/login"
                fallback={
                  <DefaultLayout>
                    <ErrorScreen status={ClientErrors.FORBIDDEN} />
                  </DefaultLayout>
                }
                requiredPermissions={item.permissions}
              >
                <DefaultLayout {...props} item={item}>
                  <item.component {...props} />
                </DefaultLayout>
              </Permission>
            )}
          />
        ))}
        <Redirect
          exact
          from={PUBLIC_ROUTES.LOGIN_SCREEN.path}
          to={PRIVATE_ROUTES.ACTIVE_AUCTIONS.path}
        />

        <Route
          path="*"
          render={props => (
            <DefaultLayout {...props}>
              <ErrorScreen status={ClientErrors.NOT_FOUND} />
            </DefaultLayout>
          )}
        />
      </Switch>
    </NavigationContainer>
  ) : (
    <Switch>
      {values(PUBLIC_ROUTES).map(route => (
        <Route
          key={route.id}
          exact
          path={route.path}
          component={route.component}
        />
      ))}

      <Redirect to={PUBLIC_ROUTES.LOGIN_SCREEN.path} />
    </Switch>
  );
};

export default memo(Router);
