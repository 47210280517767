import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get sheet list
 *
 * @param
 * @returns
 * @export
 */

export const getSheetsList = (search: any) => {
  const endpoint = ENDPOINTS.BULK_UPLOADS + `/auctionvehicles/batches`;
  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

/**
 * upload sheet
 * @param search
 */
export const uploadSheet = (body: any) => {
  //return api.post(ENDPOINTS.BULK_UPLOADS + `/auctionvehicles/`, body);
  return api.post(ENDPOINTS.BULK_UPLOADS + `/auctions/`, body);//rajesh
};

/**
 * delete sheet
 * @param search
 */
export const deleteSheet = (id: string) => {
  const endpoint = ENDPOINTS.BULK_UPLOADS + `/deletesheet/${id}`;
  return api.delete(endpoint);
};

/**
 * get entries list
 * @param search
 */
export const getEntriesList = (batchId: string, body: any, page: number) => {
  const endpoint =
    ENDPOINTS.BULK_UPLOADS + `/auctionvehicles/${batchId}?page=${page}`;
  return api.get(endpoint, { ...body }).then(resp => resp.data);
};

/**
 * get raw entries list
 * @param search
 */
export const getRawEntriesList = (batchId: string, body: any, page: number) => {
  const endpoint = ENDPOINTS.BULK_UPLOADS + `/sheetentries/${batchId}`;
  return api.get(endpoint, { ...body }).then(resp => resp.data);
};

/**
 * delete raw entry
 * @param search
 */
export const deleteRawEntry = (entryId: string) => {
  const endpoint = ENDPOINTS.BULK_UPLOADS + `/deleterawentry/${entryId}`;
  return api.delete(endpoint);
};

/**
 * process raw sheet
 * @param search
 */
export const processRawSheet = (batchId: string) => {
  const endpoint =
    ENDPOINTS.BULK_UPLOADS + `/auctionvehicles/${batchId}/process`;
  return api.post(endpoint);
};

/**
 * upload entry images
 * @param search
 */
export const uploadEntryImages = (auctionVehicleId: string, body: any) => {
  return api.post(`${ENDPOINTS.AUCTION_VEHICLES}/${auctionVehicleId}`, body);
};
