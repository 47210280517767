import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get auctioneer data from API.
 *
 * @returns Auctioneer data
 * @export
 */

export const fetchAuctioneers = () => {
  const endpoint = ENDPOINTS.AUCTIONEER;
  return api.get(endpoint).then(({ data }) => data);
};

/**
 * Get auctioneers data from API with optional search parameters.
 *
 * @param search Optional search parameters
 * @returns Auctioneers data
 * @export
 */

export const getAuctioneers = (search: any) => {
  const endpoint = ENDPOINTS.AUCTIONEER;
  return api.get(endpoint, { params: search }).then(({ data }) => data);
};

/**
 * Create Auctioneers
 */
export const createAuctioneer = (body: any) => {
  return api.post(ENDPOINTS.AUCTIONEER, body);
};

/**
 * Update Auctioneers
 */
export const updateAuctioneer = (id: string, body: any) => {
  const endpoint = ENDPOINTS.AUCTIONEER + `/${id}`;
  return api.post(endpoint, body);
};

/**
 * Delete Auctioneers
 */
export const deleteAuctioneers = (id: string) => {
  const endpoint = ENDPOINTS.AUCTIONEER + `/${id}`;
  return api.delete(endpoint);
};

/**
 * Reset Password
 */
export const resetPassword = (id: string) => {
  const endpoint = ENDPOINTS.AUCTIONEER + `/${id}/send-password`;
  return api.post(endpoint);
};

/**
 * Detach Auctioneer
 */
export const detachAuctioneers = (ownerId: string, auctionId: string) => {
  const endpoint =
    ENDPOINTS.AUCTIONEER + `/${ownerId}/live-auctions/${auctionId}/detach`;
  return api.delete(endpoint);
};
/**
 * get live auctions users
 *
 * @returns
 * @export
 */
export const getAuctionsAssigned = (
  liveAuctionOwnerId: string,
  search: any,
  page: number
) => {
  const endpoint =
    ENDPOINTS.LIVE_AUCTIONS +
    `/query?liveAuctionOwnerId=${liveAuctionOwnerId}&page=${page}`;
  return api
    .get(endpoint, {
      params: {
        ...search
      }
    })
    .then(resp => resp.data);
};
