import React, { useState } from "react";
import { Modal, Avatar, Input, Button, message, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Form from "antd/lib/form";
import { UserOutlined } from "@ant-design/icons";

import { userType } from "app/types/userType";

import styles from "./UserDetailsModal.module.scss";
import { editUser, updateShopTrusted } from "app/api/usersApi";

interface UserDetailsModalProps {
  isVisible: boolean;
  selectedUser: userType;
  onClose: () => void;
  onLoad: () => void;
}

const UserDetailsModal = ({
  isVisible,
  selectedUser,
  onClose,
  onLoad
}: UserDetailsModalProps) => {
  const { t } = useTranslation();

  let [form] = Form.useForm();

  const [shopTrustedEnabled, setShopTrustedEnabled] = useState<boolean>(
    selectedUser?.shop?.trusted
  );

  const handleClose = () => {
    onClose();
    form.resetFields();
  };
  const Item = Form.Item;

  const updateUserDetails = async (values: any) => {
    try {
      const userFields = [
        "firstName",
        "lastName",
        "email",
        "mobile"
        // 'shop trusted',
      ];
      let payload: any = {};

      for (let i in userFields) {
        let field = userFields[i];
        if (selectedUser[field] !== values[field]) {
          payload[field] = values[field];
        }
      }

      let res = await editUser(selectedUser.id, payload);
      if (res.status !== 200) {
        throw new Error("Something Wrong happened, try again.");
      }
      message.success("User has been updated");
      return res;
    } catch (error) {
      message.error(message);
    }
  };

  const url = selectedUser.profileImage;

  // Function to check if the URL is an image
  function isImageUrl(url) {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  }

  const toggleShopTrusted = async () => {
    const newShopTrustedValue = !shopTrustedEnabled;

    const body = {
      "shop trusted": newShopTrustedValue
    };

    try {
      await updateShopTrusted(selectedUser.shop.id, body);
      setShopTrustedEnabled(newShopTrustedValue);
      onLoad();
      message[newShopTrustedValue ? "success" : "warning"](
        `Shop has been ${newShopTrustedValue ? "trusted" : "untrusted"}`
      );
      // onClose();
    } catch (error) {
      console.error("Error toggling shop trust:", error);
      message.error("Failed to toggle shop trust");
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleClose}
      width={400}
      destroyOnClose
      footer={null}
    >
      <div className={styles.modalContainer}>
        {url && isImageUrl(url) ? (
          <Avatar size={100} src={url} alt="avatar" />
        ) : (
          <Avatar size={100} icon={<UserOutlined />} />
        )}
      </div>
      {/* <span
          className={styles.name}
        >{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
        <div className={styles.labelContainer}>
          <strong>{t("newAuctions.emailLabelTitle")}</strong>{" "}
          <span>{`${selectedUser.email}`}</span>
        </div>
        <div className={styles.labelContainer}>
          <strong>{t("newAuctions.phoneLabelTitle")}</strong>{" "}
          <span>{`${selectedUser.mobile}`}</span>
        </div> */}
      <div className={cx(styles.formItem)}>
        <Form layout="vertical" name="editAppUser" onFinish={updateUserDetails}>
          <Item
            name="firstName"
            required={true}
            label={t("newAuctions.givenNameLabelTitle")}
            initialValue={selectedUser?.firstName}
          >
            <Input type="text" />
          </Item>
          {/* <Item
            name='middleName'
            label={t('newAuctions.middleNameLabelTitle')}
            initialValue={selectedUser?.middleName}
          >
            <Input type='text' />
          </Item> */}
          <Item
            name="lastName"
            required={true}
            label={t("newAuctions.surNameLabelTitle")}
            initialValue={selectedUser?.lastName}
          >
            <Input type="text" />
          </Item>
          <Item
            name="email"
            required={true}
            label={t("newAuctions.emailLabelTitle")}
            initialValue={selectedUser?.email}
          >
            <Input type="text" />
          </Item>
          <Item
            name="mobile"
            required={true}
            label={t("newAuctions.phoneLabelTitle")}
            initialValue={selectedUser?.mobile}
          >
            <Input type="text" />
          </Item>
          {selectedUser?.shop && (
            <>
              <Item
                name="shopName"
                required={true}
                label="Shop Name:"
                initialValue={selectedUser?.shop?.name}
              >
                <Input readOnly type="text" />
              </Item>
              <Item name="shop trusted" initialValue={shopTrustedEnabled}>
                <Checkbox
                  value={shopTrustedEnabled}
                  checked={shopTrustedEnabled}
                  onChange={toggleShopTrusted}
                >
                  Trusted Seller
                </Checkbox>
              </Item>
            </>
          )}

          <Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 16 }
            }}
          >
            <Button type="primary" htmlType="submit">
              Update Details
            </Button>
          </Item>
        </Form>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
