import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import Router from "app/routers/Router";
import "app/localization/localization";
import { UserProvider } from "app/store/contexts/userContext";
import { LanguageDef } from "@nstack-io/javascript-sdk/dist/types/types";
import { updateLocalization } from "app/helpers/localizationHelpers";
import Loading from "app/components/Loading/Loading";
import { LanguageProvider } from "app/store/contexts/LanguageContext";
import { isAuthenticated } from "app/helpers/authenticationHelper";
import { updateVehicleConfig } from "app/helpers/vehicleConfigsHelper";

const App = () => {
  const [loadingTranslation, setLoadingTranslation] = useState(true);
  const [availableLanguages, setAvailableLanguages] = useState<LanguageDef[]>(
    []
  );

  useEffect(() => {
    const fetchTranslation = async () => {
      // Fetch the translations when app is started
      const response = await updateLocalization();
      setLoadingTranslation(false);

      if (response) {
        setAvailableLanguages(response.availableLanguages);
      }
    };
    fetchTranslation();
    if (isAuthenticated()) {
      updateVehicleConfig();
    }
  }, []);

  if (loadingTranslation) {
    return <Loading isFullscreen />;
  }

  return (
    <Suspense fallback={<Loading isFullscreen />}>
      <LanguageProvider availableLanguages={availableLanguages}>
        <UserProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </UserProvider>
      </LanguageProvider>
    </Suspense>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
