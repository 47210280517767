import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Table,
  Popconfirm,
  message,
  Button,
  Col,
  Form,
  Input,
  Row
} from "antd";
import Column from "antd/lib/table/Column";

import {
  getInspectionReportRequests,
  updateInspectionReportstatus,
  updateAuctionVehicle,
  sendInspectionReportNotification
} from "app/api/auctionVehiclesApi";

import InspectionReportModal from "./components/InspectionReportsModal";
import { useLocation, useHistory } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import styles from "./InspectionReports.module.scss";
import { inspectionReportRequestType } from "app/types/inspectionReportRequestType";
import { formatNumber } from "app/helpers/numberFormatHelper";
import { useTranslation } from "react-i18next";

const InspectionReports = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<inspectionReportRequestType[]>([]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [selectedInspectionReport, setSelectedInspectionReport] = useState<
    inspectionReportRequestType
  >();

  const path = location.pathname.slice(1);

  const [
    isEditInspectionReportModalVisible,
    setEditInspectionReportModalVisible
  ] = useState<boolean>(false);

  const getData = async () => {
    setLoading(true);
    try {
      const payload = await getInspectionReportRequests(
        qs.parse(location.search),
        "pending"
      );
      setData(payload.data);
      setPagination({
        ...pagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  /**
   * Edit inspection report  modal
   */

  const showEditInspectionModal = (index: number) => {
    setSelectedInspectionReport(data[index]);
    setEditInspectionReportModalVisible(true);
  };

  const hideEditInspectionReportModal = () => {
    setEditInspectionReportModalVisible(false);
  };

  const handleInspectionReportSubmit = values => {
    const body = new FormData();
    const listingId = selectedInspectionReport?.listingId;
    const requestID = selectedInspectionReport?.inspectionReport?.id;

    if (values.advancedInspectionReport) {
      body.append(
        "mediaInspectionReports[advanced]",
        values.advancedInspectionReport?.fileList[0]?.originFileObj
      );
    }
    // Create auction from form data
    const isFormData = true;
    if (listingId) {
      updateAuctionVehicle(listingId, body, isFormData)
        .then(resp => {
          if (resp.status === 200) {
            hideEditInspectionReportModal();
            if (requestID) {
              sendNotification(requestID);
            }
            message.success("Report Uploaded");
          }
        })
        .catch(error => {
          setLoading(false);
          message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleBuyerPaymentComplete = (index: number) => {
    const currentReportRequest = data[index];
    if (currentReportRequest) {
      updateInspectionReportstatus(currentReportRequest.inspectionReport?.id)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };

  const sendNotification = (index: number) => {
    if (index) {
      sendInspectionReportNotification(index)
        .then(() => getData())
        .catch(error => console.log(error));
    }
  };

  const { t } = useTranslation();

  const EditInspectionReportModal = () => {
    return (
      <InspectionReportModal
        isEditable
        isVisible={isEditInspectionReportModalVisible}
        onClose={hideEditInspectionReportModal}
        handleInspectionReportSubmit={handleInspectionReportSubmit}
        selectedInspectionReport={selectedInspectionReport}
      />
    );
  };

  const { Item } = Form;

  let [form] = Form.useForm();

  const toggleListing = (record, action) => {
    history.push(`/${path}/${action}/${record}`);
  };

  return (
    <div>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Item name="id" label="Order Number">
                <Input placeholder="e.g 121234" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="name" label={"Full Name"}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            {/* <Col span={8}>
              <Item name='lastName' label={'Last name'}>
                <Input placeholder={'e.g.Smith'} />
              </Item>
            </Col> */}
            <Col span={8}>
              <Item name="email" label={t("users.emailTitle")}>
                <Input type="email" placeholder={t("users.emailPlaceholder")} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="mobile" label={t("users.phoneTitle")}>
                <Input placeholder={t("users.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={8}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className={styles.tableContainer}>
        {!!selectedInspectionReport && isEditInspectionReportModalVisible && (
          <EditInspectionReportModal />
        )}
        <Card>
          <div className={styles.headerContainer}>
            <h2>{t("Inspection Report Requests")}</h2>
          </div>
          <Table
            dataSource={data}
            loading={isLoading}
            pagination={pagination}
            rowKey="id"
            onChange={pagination => handleTableChanges(pagination)}
          >
            {/* <Column
              key="userType"
              title={"User Type"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.inspectionReport?.userType
              }
            /> */}
            <Column
              key="listingId"
              dataIndex={"listingId"}
              title={"Order Number"}
              render={record => (
                <Button
                  className={styles.actionsCta}
                  type="link"
                  onClick={() => toggleListing(record, "edit")}
                >
                  {record}
                </Button>
              )}
            />
            <Column
              key="fullName"
              title={"Full Name"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.buyer?.name
              }
            />
            <Column
              key="email"
              title={"Email"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.buyer?.email
              }
            />
            <Column
              key="mobile"
              title={"Phone Number"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.buyer?.mobile
              }
            />
            <Column
              key="price"
              title={"Price"}
              render={(text: inspectionReportRequestType, record, index) => {
                return (
                  text.inspectionReport?.originalAmount?.currency?.code +
                  " " +
                  formatNumber(text.inspectionReport?.originalAmount?.amount)
                );
              }}
            />
            <Column
              key="total-price"
              title={"Total Price"}
              render={(text: inspectionReportRequestType, record, index) => {
                return (
                  text.inspectionReport?.totalAmount?.currency?.code +
                  " " +
                  formatNumber(text.inspectionReport?.totalAmount?.amount)
                );
              }}
            />
            {/* <Column
            key="card-number"
            title={"Card Number"}
            render={(text: inspectionReportRequestType, record, index) => {
              return (
                text.inspectionReport?.totalAmount?.currency?.code +
                " " +
                formatNumber(text.inspectionReport?.totalAmount?.amount)
              );
            }}
          />
           <Column
            key="total-price"
            title={"Total Price"}
            render={(text: inspectionReportRequestType, record, index) => {
              return (
                text.inspectionReport?.totalAmount?.currency?.code +
                " " +
                formatNumber(text.inspectionReport?.totalAmount?.amount)
              );
            }}
          />
           <Column
            key="total-price"
            title={"Total Price"}
            render={(text: inspectionReportRequestType, record, index) => {
              return (
                text.inspectionReport?.totalAmount?.currency?.code +
                " " +
                formatNumber(text.inspectionReport?.totalAmount?.amount)
              );
            }}
          /> */}

            <Column
              key="cardNumber"
              title={"Card Number"}
              render={(text: inspectionReportRequestType, record, index) => (
                <>
                  {text.paymentCard ? (
                    <>{text?.paymentCard.cardNumber}</>
                  ) : (
                    <>N/A</>
                  )}
                </>
              )}
            />
            <Column
              key="cardScheme"
              title={"Card Scheme"}
              render={(text: inspectionReportRequestType, record, index) => (
                <>
                  {text.paymentCard ? (
                    <>{text?.paymentCard.scheme}</>
                  ) : (
                    <>N/A</>
                  )}
                </>
              )}
            />
            <Column
              key="requestTime"
              title={"Request Time"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.inspectionReport?.createdAt
              }
            />

            <Column
              key="action-state"
              title={"State"}
              render={(text: inspectionReportRequestType, record, index) =>
                text?.inspectionReport?.state
              }
            />
            <Column
              key="action"
              title={t("newAuctions.actionsTitle")}
              render={(text, record, index) => {
                if (text?.inspectionReport?.advancedReport)
                  return (
                    <Popconfirm
                      key="paymentComplete"
                      onConfirm={() => handleBuyerPaymentComplete(index)}
                      title="Are you sure you want to confirm the sent report?"
                    >
                      <div className={styles.actionButton} key="confirmPayment">
                        Report Sent
                      </div>
                    </Popconfirm>
                  );
                else {
                  return (
                    <div
                      onClick={() => showEditInspectionModal(index)}
                      className={styles.editText}
                    >
                      Upload Report
                    </div>
                  );
                }
              }}
            />
          </Table>
        </Card>
      </div>
    </div>
  );
};

export default memo(InspectionReports);
