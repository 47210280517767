import Form, { FormInstance } from "antd/lib/form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getBase64,
  beforeUpload,
  dataURLtoFile
} from "app/helpers/imageUploadHelper";
import { DragAndDropIcon } from "app/components/Icons/Icons";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload, Button, Checkbox, message, Modal } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
// import Modal from 'antd/lib/modal/Modal';
import cx from "classnames";
import styles from "./NewAuctionFormItems.module.scss";
import { auctionType } from "app/types/auctionType";
import { deleteVehicleImage } from "app/api/auctionVehiclesApi";
import * as vehicleApi from "app/api/auctionVehiclesApi";

interface FormItemProps {
  isActive: boolean;
  uploadInformationValue?: auctionType;
}

interface FormItemsWithContextProps extends FormItemProps {
  form: FormInstance;
}

export const UploadImagesAndDocumentFormItems = ({
  form,
  isActive,
  uploadInformationValue
}: FormItemsWithContextProps) => {
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [videoChecked, setVideoChecked] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [initialReportsFileList, setInitialReportsFileList] = useState<any[]>(
    []
  );
  const [advancedReportsFileList, setAdvancedReportsFileList] = useState<any[]>(
    []
  );

  const [videoFiles, setVideoFiles] = useState<any[]>();
  const [hasCategories, setCategories] = useState<boolean>(false);
  const imageToBase64 = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    form.setFieldsValue({
      vehicleImages: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (
      !!imageToBase64 &&
      uploadInformationValue?.mediaPhotos &&
      uploadInformationValue?.mediaPhotos.length > 0
    ) {
      const formattedMediaImages = uploadInformationValue?.mediaPhotos.map(
        (image: any): any => {
          imageToBase64.current?.setAttribute("src", image.url);
          const dataInBase64 = imageToBase64.current?.toDataURL(image.mimeType);
          const imageFile = dataURLtoFile(dataInBase64, image.fileName);

          return {
            uid: image.id.toString(),
            thumbUrl: image.url,
            url: image.url,
            size: image.size,
            name: image.fileName,
            type: image.mimeType,
            originFileObj: imageFile,
            status: "done",
            skip: true
          };
        }
      );
      setFileList(formattedMediaImages);
    }
  }, [uploadInformationValue]);

  useEffect(() => {
    if (isActive) {
      if (
        uploadInformationValue?.vehicleModel?.categories &&
        uploadInformationValue?.vehicleModel?.categories[0] &&
        uploadInformationValue?.vehicleModel?.categories[0]?.name
      ) {
        setCategories(true);
      }
    }
  }, [isActive, uploadInformationValue]);

  const { t } = useTranslation();
  const { Item } = Form;

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleChange = ({ fileList }) => {
    setFileList(fileList.filter(file => !!file.status));
  };

  const handleInitialReportChange = (list, file, type) => {
    setInitialReportsFileList(list.filter(file => !!file.status));
    if (!file.status) return false;

    const reports = [{ uid: file.uid, file: file }];
    form.setFieldsValue({
      initialInspectionReports: reports
    });
  };

  const handleAdvancedReportChange = (list, file, type) => {
    setAdvancedReportsFileList(list.filter(file => !!file.status));
    if (!file.status) return false;

    const reports = [{ uid: file.uid, file: file }];
    form.setFieldsValue({
      advancedInspectionReports: reports
    });
  };

  const handleVideoChanged = ({ fileList, file }) => {
    form.setFieldsValue({
      vehicleVideo: fileList
    });
    setVideoFiles(fileList.filter(file => !!file.status));
  };

  const uploadButton = (
    <div>
      <DragAndDropIcon />
      <div className="ant-upload-text">
        {t("newAuctions.uploadButtonTitle")}
      </div>
    </div>
  );

  const handleVideoCheckbox = () => {
    setVideoChecked(!videoChecked);
  };

  const checkReportFileSize = file => {
    const sizeIsOk = file.size / 1024 / 1024 <= 20;
    if (!sizeIsOk) {
      message.error("Report File size must be less than 20 MB");
      return false;
    }
    return true;
  };

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const formatFile = file => {
    return {
      uid: file.id.toString(),
      url: file.url,
      size: file.size,
      name: file.fileName,
      type: file.mimeType
    };
  };

  const removeFile = e => {
    deleteVehicleImage(Number(e.uid))
      .then(() => {
        setTimeout(message.success("File deleted!"), 5000);
      })
      .catch(error => message.error("Something went wrong!"));
  };

  useEffect(() => {
    form.setFieldsValue({
      advancedInspectionReport: advancedReportsFileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedReportsFileList]);

  const handleDeleteReport = (
    reportType,
    setFileList,
    originalFileList
  ) => e => {
    const onOk = () => {
      vehicleApi
        .deleteVehicleImage(Number(e.uid))
        .then(() => {
          setFileList([]);
          message.success(`${reportType} Report Deleted!`);
        })
        .catch(error => {
          message.error("Something went wrong!");
        });
    };

    const onCancel = () => {
      setFileList(originalFileList);
    };

    Modal.confirm({
      title: `Are you sure you want to delete this ${reportType} Report?`,
      content: `You’re about to delete ${reportType} Report.`,
      okText: `Yes, delete ${reportType} Report`,
      okType: "danger",
      onOk,
      onCancel
    });
  };

  const handleDeleteAdvanced = handleDeleteReport(
    "Advanced",
    setAdvancedReportsFileList,
    advancedReportsFileList
  );

  const handleDeleteInitial = handleDeleteReport(
    "Initial",
    setInitialReportsFileList,
    initialReportsFileList
  );

  const defaultAdvancedFileList = uploadInformationValue?.mediaInspectionReports
    ?.advanced
    ? [formatFile(uploadInformationValue?.mediaInspectionReports?.advanced)]
    : [];

  const defaultInitialFileList = uploadInformationValue?.mediaInspectionReports
    ?.initial
    ? [formatFile(uploadInformationValue?.mediaInspectionReports?.initial)]
    : [];

  useEffect(() => {
    if (!advancedReportsFileList || advancedReportsFileList.length === 0) {
      setAdvancedReportsFileList(defaultAdvancedFileList);
      form.setFieldsValue({
        advancedInspectionReport: defaultAdvancedFileList
      });
    }

    if (!initialReportsFileList || initialReportsFileList.length === 0) {
      setInitialReportsFileList(defaultInitialFileList);
      form.setFieldsValue({
        initialInspectionReport: defaultInitialFileList
      });
    }
  }, []);

  const handleDownload = file => {
    const downloadFile = file => {
      const downloadUrl = file.url;
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.target = "_blank";
      link.download = `${file.name}.pdf`;
      link.click();
    };

    downloadFile(file);
  };

  return (
    <div
      className={cx(styles.uploadFormItems, {
        [styles.isActive]: isActive
      })}
    >
      <canvas className={styles.canvas} ref={imageToBase64}></canvas>
      <Item
        name="vehicleImages"
        label={t("newAuctions.vehicleImageLabelTitle")}
        required={true}
        valuePropName="fileList"
        className={styles.uploadMediaText}
        style={{ fontWeight: 500, fontSize: "18px" }}
      >
        <Upload
          accept="image/jpg,image/jpeg,image/png"
          listType="picture-card"
          multiple
          beforeUpload={beforeUpload}
          fileList={fileList}
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: true
          }}
          customRequest={customUpload}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={removeFile}
          onDownload={({ url }) => window.open(url)}
        >
          {uploadButton}
        </Upload>
        <span className={styles.uploadMediaText}>
          {t("newAuctions.imageUploadDisclaimer")}
        </span>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="vehicleImage"
            style={{ width: "100%" }}
            src={previewImage}
          />
        </Modal>
      </Item>

      <div className={styles.border}>
        <Checkbox
          className={styles.uploadCheckbox}
          checked={hasCategories}
          disabled={hasCategories}
        >
          {/*
           * @TODO: Add translations from NStack
           */}
          The seller has requested a{" "}
          <b>
            {uploadInformationValue?.vehicleModel?.categories[0]?.name} -{" "}
            {
              uploadInformationValue?.addOns?.inspectionReport
                ?.categoryPriceType
            }
          </b>{" "}
          inspection report
        </Checkbox>

        <Item
          name="advancedInspectionReport"
          required={false}
          label={t("Advanced Inspection Report")}
          style={{
            fontWeight: 500,
            fontSize: "18px",
            paddingTop: "5px"
          }}
        >
          <Upload
            defaultFileList={[]}
            onDownload={({ url }) => window.open(url)}
            onRemove={handleDeleteAdvanced}
            accept="application/pdf"
            listType="picture"
            maxCount={1}
            customRequest={customUpload}
            fileList={advancedReportsFileList}
            beforeUpload={checkReportFileSize}
            onChange={({ file, fileList }) =>
              handleAdvancedReportChange(fileList, file, "advanced")
            }
          >
            {advancedReportsFileList?.length === 0 && (
              <Button icon={<UploadOutlined />}>
                {t("newAuctions.advancedReportTitle")}
              </Button>
            )}
          </Upload>
          {advancedReportsFileList?.length !== 0 && (
            <Button
              onClick={() => handleDownload(advancedReportsFileList[0])}
              type="primary"
              icon={<DownloadOutlined />}
              style={{ marginTop: "10px" }}
            >
              {t("Download Report")}
            </Button>
          )}
        </Item>
        <Item
          name="initialInspectionReport"
          hidden={
            !uploadInformationValue?.addOns?.inspectionReports?.initial ||
            initialReportsFileList?.length === 0
          }
          required={false}
          label={t("Initial Inspection Report")}
          style={{
            fontWeight: 500,
            fontSize: "18px",
            paddingTop: "5px"
          }}
        >
          <Upload
            defaultFileList={[]}
            onDownload={({ url }) => window.open(url)}
            onRemove={handleDeleteInitial}
            fileList={initialReportsFileList}
            accept="application/pdf"
            listType="picture"
            beforeUpload={checkReportFileSize}
            maxCount={1}
            customRequest={customUpload}
            onChange={({ file, fileList }) =>
              handleInitialReportChange(fileList, file, "initial")
            }
          ></Upload>
          {initialReportsFileList?.length !== 0 && (
            <Button
              onClick={() => handleDownload(initialReportsFileList[0])}
              type="primary"
              icon={<DownloadOutlined />}
              style={{ marginTop: "10px" }}
            >
              {t("Download Report")}
            </Button>
          )}
        </Item>

        <span className={styles.uploadMediaText}>
          {t("newAuctions.inspectionReportDisclaimer")}
        </span>
      </div>

      <div className={styles.border}>
        <Checkbox
          checked={uploadInformationValue?.addOns?.videos}
          disabled
          onChange={handleVideoCheckbox}
          className={styles.uploadCheckbox}
        >
          {/*
           * @TODO: Add translations from NStack
           */}
          The seller has requested a video
        </Checkbox>
        {uploadInformationValue?.addOns?.videos && (
          <Item
            name="vehicleVideo"
            label={t("newAuctions.videoUploadLabelTitle")}
            getValueFromEvent={normFile}
            style={{
              fontWeight: 500,
              fontSize: "18px",
              paddingTop: "5px"
            }}
          >
            <Upload
              accept="video/mp4,video/ogg,video/quicktime"
              multiple={false}
              maxCount={1}
              customRequest={customUpload}
              defaultFileList={
                uploadInformationValue?.mediaVideo
                  ? [formatFile(uploadInformationValue?.mediaVideo)]
                  : []
              }
              fileList={videoFiles}
              beforeUpload={file => {
                const sizeIsOk = file.size / 1024 / 1024 <= 50;
                if (!sizeIsOk) {
                  message.error(t("newAuctions.videoSizeError"));
                  return false;
                }
                return true;
              }}
              onChange={handleVideoChanged}
            >
              <Button>
                <UploadOutlined />
                {t("newAuctions.uploadButtonTitle")}
              </Button>
            </Upload>
          </Item>
        )}
      </div>
    </div>
  );
};
