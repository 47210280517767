import { Button, Card, PageHeader, Table, TablePaginationConfig } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./TranslationsScreen.module.scss";
import TranslationsModal from "./Components/TranslationsModal";
import { getNotifications } from "app/api/notificationsApi";
import { PaginationConfig } from "antd/lib/pagination";
import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
import { useLocation, useHistory } from "react-router-dom";

interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const TranslationsScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTranslation, setSelectedTranslation] = useState<any>();
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION);
  const [isUpdateTranslation, setIsUpdateTranslation] = useState<boolean>(
    false
  );

  useEffect(() => {
    loadNotifications();
  }, [location.search]);

  const loadNotifications = async () => {
    try {
      setLoading(true);
      const payload = await getNotifications(qs.parse(location.search));
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleTranslationsModal = record => {
    setSelectedTranslation(record);
    setIsUpdateTranslation(true);
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const columns = [
    {
      title: "Language",
      dataIndex: "language",
      key: "language"
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key"
    },
    {
      title: "Translation",
      dataIndex: "value",
      key: "value"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button
          key="edit"
          type="link"
          onClick={() => toggleTranslationsModal(record)}
        >
          Update
        </Button>
      )
    }
  ];

  const Header = () => (
    <PageHeader className={styles.pageHeader} title="Manage Translations" />
  );
  return (
    <div className={styles.tableContainer}>
      {isUpdateTranslation && (
        <TranslationsModal
          isVisible={isUpdateTranslation}
          handleClose={() => setIsUpdateTranslation(false)}
          selectedNotification={selectedTranslation}
          onLoad={() => loadNotifications()}
        />
      )}
      <Card>
        <Table
          size="small"
          title={Header}
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={pagination}
          onChange={pagination => handleTableChanges(pagination)}
        />
      </Card>
    </div>
  );
};

export default TranslationsScreen;
