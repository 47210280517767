import {
  UnorderedListOutlined,
  VideoCameraAddOutlined
} from "@ant-design/icons";
import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import AuctionShowsTable from "app/screens/AuctionShows/AuctionShowsTable";
import AuctionShowsTabs from "app/screens/AuctionShows/Components/AuctionShowsTabs/AuctionShowsTabs";

const createShowsDetailsScreen = path => ({
  id: "details",
  path,
  component: AuctionShowsTabs,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
});

export const AUCTION_SHOWS_SCREEN: IMenuItemType = {
  id: "auction-shows",
  path: "/auction-shows",
  title: "Auctions & Shows",
  icon: VideoCameraAddOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const PROGRESS_SCREEN: IMenuItemType = {
  id: "in-progress",
  path: "/in-progress",
  component: AuctionShowsTable,
  title: "In Progress",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const UPCOMING_SCREEN: IMenuItemType = {
  id: "upcoming",
  path: "/upcoming",
  component: AuctionShowsTable,
  title: "Upcoming",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const REQUESTS_SCREEN: IMenuItemType = {
  id: "new-events",
  path: `/new-events`,
  component: AuctionShowsTable,
  title: "New Events",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const EVENTS_HISTORY_SCREEN: IMenuItemType = {
  id: "history",
  path: "/history",
  component: AuctionShowsTable,
  title: "History",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const SHOWS_DETAILS_SCREEN_REQUESTS = createShowsDetailsScreen(
  "/new-events/:id"
);
export const SHOWS_DETAILS_SCREEN_UPCOMING = createShowsDetailsScreen(
  "/upcoming/:id"
);
export const SHOWS_DETAILS_SCREEN_IN_PROGRESS = createShowsDetailsScreen(
  "/in-progress/:id"
);
export const SHOWS_DETAILS_SCREEN_HISTORY = createShowsDetailsScreen(
  "/history/:id"
);

AUCTION_SHOWS_SCREEN.subMenuItems = [
  PROGRESS_SCREEN,
  UPCOMING_SCREEN,
  REQUESTS_SCREEN,
  EVENTS_HISTORY_SCREEN
];
