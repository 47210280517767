import React, { useState } from "react";
import { Modal, Upload, Button, Form, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadSheet } from "app/api/bulkUploadsApi";
const { Item } = Form;

interface CsvUploadModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onUpload: () => void;
}

const CsvUploadModal = ({
  isVisible,
  onCancel,
  onUpload
}: CsvUploadModalProps) => {
  const [fileList, setFileList] = useState<any[]>();
  let [form] = Form.useForm();

  const handleBulkUploads = values => {
    const formData = new FormData();
    const { fileUpload } = values;

    if (fileUpload) {
      formData.append("file", fileUpload.fileList[0].originFileObj);
    }

    uploadSheet(formData)
      .then(({ status, data }) => {
        if (status === 200) {
          onUpload();
          handleCancel();
          message.success("Data uploaded successfully");
        } else {
          displayErrorMessage(data);
        }
      })
      .catch(() => {
        message.error("Something went wrong. Please try again!");
      });
  };

  const displayErrorMessage = data => {
    if (data && data.errors && data.errors.length > 0) {
      const errorMessage = data.errors[0];
      message.error(errorMessage);
    } else {
      message.error("Invalid data. Please check your input and try again.");
    }
  };

  const handleUploadRemove = () => {
    setFileList([]);
  };

  const handleUploadChange = file => {
    if (!file.status) return false;

    setFileList([file]);

    form.setFieldsValue({
      csvFileUpload: { file }
    });
  };

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <Modal
        title="Upload Excel"
        visible={isVisible}
        onCancel={handleCancel}
        footer={
          <>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" form="uploadBulkItems">
              Upload
            </Button>
          </>
        }
      >
        <p>Upload a CSV file for bulk data entry.</p>
        <p>Click the button below to select a file.</p>
        <Form
          layout="horizontal"
          form={form}
          name="uploadBulkItems"
          onFinish={handleBulkUploads}
        >
          <Item
            name="fileUpload"
            required={true}
            rules={[
              {
                required: true,
                message: "Please upload a file"
              }
            ]}
          >
            <Upload
              defaultFileList={[]}
              fileList={fileList}
              //accept=".csv"
              accept=".xlsx"//rajesh
              beforeUpload={() => false}
              onChange={handleUploadChange}
              onRemove={handleUploadRemove}
              maxCount={1}
              listType="picture"
              customRequest={customUpload}
            >
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CsvUploadModal;
