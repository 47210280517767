import React, { useEffect, useState } from "react";
import styles from "./WidgetCreateEditForm.module.scss";
import { Form, Input, Select, Switch, TreeSelect, message } from "antd";
import {
  createWidget,
  getWidgets,
  updateWidget,
  updateWidgetTitle
} from "app/api/pageLayoutApi";
import { getAdverts } from "app/api/advertsApi";
import { CategoriesType, Widget } from "app/types/pageLayoutType";
import { getListings } from "app/api/dynamicCategoryApi";

interface WidgetCreateEditFormProps {
  selectedWidget: any;
  categories?: CategoriesType;
  onLoad: () => void;
  onClose: () => void;
  path: string;
}

interface Advert {
  createdAt: string;
  id: number;
  link: string;
  media: {
    url: string;
    type: string;
  };
  title: string;
  title_localized: string;
  updatedAt: string;
}

const WidgetCreateEditForm = ({
  path,
  onLoad,
  onClose,
  categories,
  selectedWidget
}: WidgetCreateEditFormProps) => {
  const { Item } = Form;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [allFields, setAllFields] = useState<any[]>([]);
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [adverts, setAdverts] = useState<Advert[]>([]);
  const [integers, setIntegers] = useState<number[]>([]);

  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    getListings()
      .then(payload => {
        setFilteredCategories(payload.data);
      })
      .catch(error => console.log(error));
  }, []);

  const handleTreeSearch = (inputValue, treeNode) => {
    return treeNode.props.title
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  const widgetId =
    selectedWidget?.widget_type !== "multitab"
      ? selectedWidget?.items[0]?.id
      : selectedWidget?.id;

  useEffect(() => {
    getAdverts(null)
      .then(payload => {
        setAdverts(payload.data);
      })
      .catch(error => console.log(error));
  }, [allFields]);

  useEffect(() => {
    getWidgets()
      .then(payload => {
        setWidgets(payload);
      })
      .catch(error => console.log(error));
  }, []);

  const handleClose = () => {
    onClose();
    onLoad();
    form.resetFields();
  };

  const onFinish = values => {
    const body = new FormData();

    if (values.categoriesMultiple) {
      for (const intValue of values.categoriesMultiple) {
        body.append("attributes[category][]", intValue);
      }
    }

    if (values.widgetTitle) {
      body.append("title", values.widgetTitle);
    }

    if (values.arabicTranslation) {
      body.append("title_localized", values.arabicTranslation);
    }

    if (values.widgetListing) {
      body.append("attributes[type]", values.widgetListing);
    }

    if (values.widgetCategory) {
      body.append("attributes[category]", values.widgetCategory);
    }

    if (values.widgetState) {
      body.append("attributes[state]", values.widgetState);
    }

    if (values.widgetOrder) {
      body.append("attributes[order]", values.widgetOrder);
    }

    if (values.itemCount) {
      body.append("attributes[count]", values.itemCount);
    }
    if (values.adsListing) {
      body.append("attributes[advert]", values.adsListing);
    }

    if (values.featured !== undefined) {
      body.append("attributes[featured]", values.featured);
    }

    if (widgetId !== undefined) {
      const updateFunction =
        selectedWidget?.widget_type === "multitab"
          ? updateWidgetTitle
          : updateWidget;

      updateFunction(path, widgetId, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Widget " + (widgetId ? "updated" : "created"));
          }
        })
        .catch(() => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createWidget(path, listTypeField, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Widget created");
          }
        })
        .catch(() => {
          message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleSwitchChange = value => {
    form.setFieldsValue({
      featured: value
    });
  };

  const listTypeField = form.getFieldValue("widgetType");
  const isCarousel = [
    "carousel",
    "shop",
    "lot",
    "show",
    "live-auction",
    "timed-auction"
  ].includes(listTypeField);

  useEffect(() => {
    if (selectedWidget?.widget_type === "category") {
      const stringArray = selectedWidget?.items[0]?.attributes?.category;

      if (Array.isArray(stringArray)) {
        const arrayOfNumbers = stringArray.map(Number);
        setIntegers(arrayOfNumbers);
      } else {
        setIntegers([]);
      }
    }
  }, [selectedWidget]);

  const filteredWidget = widgets?.filter(
    widget => widget.title === listTypeField
  );

  const renderTreeNodes = categories => {
    return categories.map(item => {
      if (item.children) {
        return (
          <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id}>
            {renderTreeNodes(item.children)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} />
      );
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name="createWidgetForm"
      onFieldsChange={(changedFields, allFields) => {
        setAllFields(allFields);
      }}
    >
      <div>
        <Item
          className={styles.inlineInput}
          name="widgetTitle"
          label={"Widget Title:"}
          initialValue={selectedWidget?.title}
          rules={[
            {
              required: true,
              message: "Please enter widget title."
            }
          ]}
        >
          <Input type="text" />
        </Item>
        <Item
          className={`${styles.inlineInput} ${styles.margin}`}
          name="arabicTranslation"
          label={"Arabic translation:"}
          initialValue={selectedWidget?.title_localized}
          rules={[
            {
              required: true,
              message: "Please enter Arabic translation."
            }
          ]}
        >
          <Input type="text" />
        </Item>
        <Item
          name="widgetType"
          label={"Widget Type:"}
          initialValue={selectedWidget?.widget_type}
          rules={[
            {
              required: true,
              message: "Please select a given (widget) type."
            }
          ]}
        >
          <Select
            disabled={selectedWidget ? true : false}
            size="middle"
            onChange={() => {
              return form.validateFields();
            }}
            getPopupContainer={(trigger: any) => trigger.parentElement}
          >
            {widgets?.map(widget =>
              path === "discover" && widget?.title !== "watchlist" ? (
                <Option key={widget.title} value={widget.title}>
                  {widget.title}
                </Option>
              ) : (
                path !== "discover" &&
                widget?.title !== "multitab" && (
                  <Option key={widget.title} value={widget.title}>
                    {widget.title}
                  </Option>
                )
              )
            )}
          </Select>
        </Item>

        {isCarousel && (
          <>
            <Item
              name="widgetListing"
              label={"Widget Listing:"}
              initialValue={selectedWidget?.items[0]?.attributes?.type}
              rules={[
                {
                  required: true,
                  message: "Please select a given (widget) listing."
                }
              ]}
            >
              <Select
                size="middle"
                onChange={() => {
                  return form.validateFields();
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {widgets &&
                  filteredWidget[0]?.attributes?.type?.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item
              name="widgetCategory"
              rules={[
                {
                  required: false,
                  message: "Please select a given (widget) category."
                }
              ]}
              label={"Widget Category:"}
              initialValue={
                selectedWidget?.items[0]?.attributes?.category
                  ? +selectedWidget?.items[0]?.attributes?.category
                  : null
              }
            >
              <TreeSelect
                showSearch
                allowClear
                value={selectedItem}
                style={{ width: "100%" }}
                placeholder="Select a category"
                onChange={value => setSelectedItem(value)}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
                filterTreeNode={handleTreeSearch}
              >
                {renderTreeNodes(filteredCategories)}
              </TreeSelect>
            </Item>
            <Item
              name="widgetState"
              rules={[
                {
                  required: true,
                  message: "Please select a given (widget) state."
                }
              ]}
              label={"Widget State:"}
              initialValue={selectedWidget?.items[0]?.attributes?.state}
            >
              <Select
                size="middle"
                onChange={() => {
                  return form.validateFields();
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {widgets &&
                  filteredWidget[0]?.attributes?.state?.map(state => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item
              name="itemCount"
              rules={[
                {
                  required: true,
                  message: "Please select a given (widget) count."
                }
              ]}
              label={"Item Count:"}
              initialValue={selectedWidget?.items[0]?.attributes?.count}
            >
              <Select
                size="middle"
                onChange={() => {
                  return form.validateFields();
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {widgets &&
                  filteredWidget[0]?.attributes?.count?.map(count => (
                    <Option key={count} value={count}>
                      {count}
                    </Option>
                  ))}
              </Select>
            </Item>
          </>
        )}
        <Item
          name="widgetOrder"
          rules={[
            {
              required: listTypeField === "seller" || isCarousel,
              message: "Please select a given (widget) order."
            }
          ]}
          label={"Widget Order:"}
          initialValue={selectedWidget?.items[0]?.attributes?.order}
          hidden={listTypeField !== "seller" && !isCarousel}
        >
          <Select
            size="middle"
            onChange={() => {
              return form.validateFields();
            }}
            getPopupContainer={(trigger: any) => trigger.parentElement}
          >
            {widgets &&
              filteredWidget[0]?.attributes?.order?.map(order => (
                <Option key={order} value={order}>
                  {order}
                </Option>
              ))}
          </Select>
        </Item>

        {isCarousel && (
          <Item
            name="featured"
            initialValue={
              selectedWidget?.items[0]?.attributes?.featured
                ? selectedWidget?.items[0]?.attributes?.featured
                : false
            }
          >
            <div className={styles.requireField}>
              <div className={styles.requireLabel}>
                Display Featured Items:
                <p className="ant-form-item-extra">
                  Toggle to Show Prominent Featured Items.
                </p>
              </div>
              <Switch
                defaultChecked={
                  selectedWidget?.items[0]?.attributes?.featured === "true"
                    ? true
                    : false
                }
                checkedChildren="YES"
                unCheckedChildren="NO"
                onChange={handleSwitchChange}
              />
            </div>
          </Item>
        )}

        {(listTypeField === "advertisement" &&
          listTypeField === "advertisment") ||
          (listTypeField && (
            <Item
              name="adsListing"
              label={"Select Advert:"}
              initialValue={
                selectedWidget?.items[0]?.attributes?.advert
                  ? +selectedWidget?.items[0]?.attributes?.advert
                  : ""
              }
              rules={[
                {
                  required: false,
                  message: "Please select a given (ad) listing."
                }
              ]}
            >
              <Select
                onChange={value => setSelectedItem(value)}
                value={selectedItem}
                size="middle"
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {adverts?.map(ad => (
                  <Option key={ad?.id} value={ad?.id}>
                    {ad.title}
                  </Option>
                ))}
              </Select>
            </Item>
          ))}

        {listTypeField === "watchlist" && (
          <>
            <Item
              name="widgetListing"
              label={"Widget Listing:"}
              initialValue={selectedWidget?.items[0]?.attributes?.type}
              rules={[
                {
                  required: true,
                  message: "Please select a given (widget) listing."
                }
              ]}
            >
              <Select
                size="middle"
                onChange={() => {
                  return form.validateFields();
                }}
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {widgets &&
                  filteredWidget[0]?.attributes?.type?.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
              </Select>
            </Item>
            <Item
              name="widgetCategory"
              rules={[
                {
                  required: true,
                  message: "Please select a given (widget) category."
                }
              ]}
              label={"Widget Category:"}
              initialValue={
                selectedWidget?.items[0]?.attributes?.category
                  ? +selectedWidget?.items[0]?.attributes?.category
                  : null
              }
            >
              <Select
                showSearch
                placeholder="Select an item"
                onChange={value => setSelectedItem(value)}
                value={selectedItem}
                filterOption={(input, option) =>
                  option?.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                {categories?.map(category => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </>
        )}

        {listTypeField === "category" && (
          <Form.Item
            name="categoriesMultiple"
            label="Categories Multiple"
            initialValue={integers}
            rules={[
              {
                required: true,
                message: "Please select categories!",
                type: "array"
              }
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Please select categories"
              getPopupContainer={(trigger: any) => trigger.parentElement}
            >
              {categories?.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default WidgetCreateEditForm;
