import React, { memo } from "react";
import { Result } from "antd";
import { ClientErrors } from "app/constants/errorCodes";
import { withTranslation, WithTranslation } from "react-i18next";
import { LockOutlined } from "@ant-design/icons";
import logoIcon from "../../assets/logo.png";

interface IProps extends WithTranslation {
  status: ClientErrors;
}

/** 403 Not found screen */
const ErrorScreen = ({ t, status }: IProps) => {
  if (status === ClientErrors.FORBIDDEN) {
    return (
      <Result
        icon={<LockOutlined />}
        title="Restricted Access"
        subTitle="You do not have access to this page"
      />
    );
  } else {
    return <Result icon={<img width="35%" src={logoIcon} alt="Mazadak" />} />;
  }
};

export default withTranslation()(memo(ErrorScreen));
