import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

// Get Countries
export const getCountries = () => {
  const endpoint = ENDPOINTS.LOCATIONS + `/countries`;
  return api.get(endpoint).then(resp => resp.data);
};

// Get Region
export const getRegion = (id: number) => {
  const endpoint = ENDPOINTS.LOCATIONS + `/regions?countryId=${id}`;
  return api.get(endpoint).then(resp => resp.data);
};

// Get City
export const getCity = (id: number) => {
  const endpoint = ENDPOINTS.LOCATIONS + `/cities?regionId=${id}`;
  return api.get(endpoint).then(resp => resp.data);
};
