import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styles from "../AuctionFormItems.module.scss";
import PreviewItem from "../components/PreviewItem";
import { auctionType } from "app/types/auctionType";
import Column from "antd/lib/table/Column";
import { Checkbox, Form, Input, Table } from "antd";
import { getVehicleBids, getAuctionVehicle } from "app/api/auctionVehiclesApi";
import AuctionEndDateModal from "../../../components/EditAuctionEndDateModal/EditEndDare";
import { formatNumber } from "../../../../../helpers/numberFormatHelper";

interface AuctionInformationPreviewProps {
  selectedActiveAuction: auctionType;
  buyerInformation?: auctionType;
  onUpdate?: any;
}

const AuctionInformationPreview = ({
  selectedActiveAuction,
  buyerInformation,
  onUpdate
}: AuctionInformationPreviewProps) => {
  const { t } = useTranslation();
  const { Item } = Form;
  const [bids, setBids] = useState([]);
  const [editAuctionDateModal, setEditAuctionDateModal] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState(selectedActiveAuction);

  useEffect(() => {
    getVehicleBids(selectedAuction.id)
      .then(payload => {
        setBids(payload.data);
      })
      .catch(error => console.log(error));
  }, [selectedAuction.id, selectedAuction]);

  /**
   * Update auction state
   *
   * @param {*} id
   * @returns
   */
  const getAuction = async (id: any) => {
    const res = await getAuctionVehicle(id);
    setSelectedAuction(res);
    return res;
  };

  return (
    <div className={styles.formPreviewItemsContainer}>
      <h3>{t("newAuctions.auctionInformationPreviewLabel")}</h3>
      <PreviewItem
        label={t("newAuctions.startPriceLabelTitle")}
        value={
          formatNumber(selectedAuction.startingPrice?.amount) +
            " " +
            selectedAuction.startingPrice?.currency?.code ?? "SAR"
        }
      />

      <PreviewItem
        label={t("newAuctions.buyNowLabelTitle")}
        value={
          formatNumber(selectedAuction.vehiclePrice?.amount) +
            " " +
            selectedAuction.vehiclePrice?.currency?.code ?? "SAR"
        }
      />

      <PreviewItem
        label={t("newAuctions.startDateLabelTitle")}
        value={moment(selectedAuction.startAt).format("YYYY-MM-DD HH:mm:ss")}
      />

      <PreviewItem
        label={t("currentAuctions.endAtTitle")}
        value={moment(selectedAuction.endAt).format("YYYY-MM-DD HH:mm:ss")}
        editable={true}
        onEdit={() => {
          setEditAuctionDateModal(!editAuctionDateModal);
        }}
      />

      <AuctionEndDateModal
        onSave={() => {
          setEditAuctionDateModal(false);
          getAuction(selectedAuction.id);
          onUpdate(true);
        }}
        auction={selectedActiveAuction}
        onCancel={() => setEditAuctionDateModal(false)}
        visible={editAuctionDateModal}
      ></AuctionEndDateModal>

      <div className={styles.latLngForm}>
        <Checkbox
          checked={
            selectedActiveAuction?.addOns?.delivery?.coordinates !== null
          }
        >
          {t("currentAuctions.buyerPickupText")}
        </Checkbox>

        <Form layout="vertical">
          <Item
            name="lat"
            label={t("newAuctions.latitudeLabelTitle")}
            required={true}
            initialValue={
              selectedActiveAuction?.addOns?.delivery?.coordinates !== null
                ? selectedActiveAuction?.addOns?.delivery?.coordinates.latitude
                : "N/A"
            }
          >
            <Input disabled={true} value={1} />
          </Item>
          <Item
            name="long"
            label={t("newAuctions.longitudeLabelTitle")}
            required={true}
            initialValue={
              selectedActiveAuction?.addOns?.delivery?.coordinates !== null
                ? selectedActiveAuction?.addOns?.delivery?.coordinates.longitude
                : "N/A"
            }
          >
            <Input disabled={true} />
          </Item>
        </Form>
        <div className={styles.bidTable}>
          <Table rowKey="id" dataSource={bids}>
            <Column
              key="user"
              title="User"
              dataIndex="userId"
              render={(text, record, index) => text}
            />
            <Column
              key="bidTime"
              title={t("currentAuctions.bidTime")}
              render={(text, record, index) =>
                moment(text.updatedAt).format("YYYY-MM-DD HH:mm:ss")
              }
            />
            <Column
              key="bids"
              title={t("currentAuctions.bids")}
              render={(text, record, index) =>
                formatNumber(text.auctionedPrice.amount) +
                " " +
                text.auctionedPrice.currency.code
              }
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AuctionInformationPreview;
