import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import {
  ProfileOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import ListingsTable from "app/screens/ListingsScreen/ListingsTable";
import ListingDetailsScreen from "app/screens/ListingsScreen/components/ListingDetailsScreen/ListingDetailsScreen";

export const ListingsDetailsScreen = path => ({
  id: "listings-details",
  path,
  component: ListingDetailsScreen,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
});

export const LISTINGS_SCREEN: IMenuItemType = {
  id: "listings",
  path: "/listings",
  title: "Listings",
  icon: ProfileOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const ACTIVE_LISTINGS: IMenuItemType = {
  id: "active-listings",
  path: "/active-listings",
  component: ListingsTable,
  title: "Active Listings",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const LISTING_REQUESTS: IMenuItemType = {
  id: "auction-requests",
  path: "/auction-requests",
  component: ListingsTable,
  title: "Auction Requests",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const PAST_LISTINGS: IMenuItemType = {
  id: "orders",
  path: "/orders",
  title: "Orders",
  icon: ShoppingCartOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const SUCCESSFUL_ORDERS: IMenuItemType = {
  id: "successful-orders",
  path: "/successful-orders",
  component: ListingsTable,
  title: "Successful Orders",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const UNSUCCESSFUL_ORDERS: IMenuItemType = {
  id: "unsuccessful-orders",
  path: "/unsuccessful-orders",
  component: ListingsTable,
  title: "Unsuccessful Orders",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const FINALIZED_TRADES: IMenuItemType = {
  id: "finalized-orders",
  path: "/finalized-orders",
  component: ListingsTable,
  title: "Finalized Orders",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const LISTINGS_DETAILS = ListingsDetailsScreen("/:path/:action/:id");

PAST_LISTINGS.subMenuItems = [
  SUCCESSFUL_ORDERS,
  UNSUCCESSFUL_ORDERS,
  FINALIZED_TRADES
];
LISTINGS_SCREEN.subMenuItems = [ACTIVE_LISTINGS, LISTING_REQUESTS];
