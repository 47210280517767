import React, { useState, useEffect } from "react";
import { Modal, Button, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import Form from "antd/lib/form";

import { userType } from "app/types/userType";
import Loading from "app/components/Loading/Loading";
import { editUser, getUserRoles } from "app/api/usersApi";

const { Item } = Form;

interface EditUserFormModalProps {
  isVisible: boolean;
  selectedUser: userType;
  onClose: () => void;
  getData: () => void;
}

const EditUserFormModal = ({
  isVisible,
  selectedUser,
  onClose,
  getData,
}: EditUserFormModalProps) => {
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState<any>([]);

  useEffect(() => {
    getUserRoles().then((resp) => setUserRoles(resp.data));
  }, []);

  const { Option } = Select;

  let [form] = Form.useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onFinish = (values) => {
    const postObject = {
      role: values.role,
    };

    editUser(selectedUser.id, postObject)
      .then((resp) => {
        if (resp.status === 200) {
          getData();
          handleClose();
          return message.success("User updated");
        }
      })
      .catch((error) => {
        console.log(error);
        return message.error("Something went wrong. Try again!");
      });
  };

  return (
    <Modal
      title={t("users.editRoleTitle")}
      visible={isVisible}
      onCancel={handleClose}
      width={400}
      destroyOnClose
      footer={
        <div>
          <Button form="editUserFormModal" onClick={handleClose}>
            {t("default.cancel")}
          </Button>
          <Button form="editUserFormModal" htmlType="submit" type="primary">
            {t("default.confirm")}
          </Button>
        </div>
      }
    >
      <div>
        {userRoles?.length === 0 ? (
          <Loading />
        ) : (
          <Form
            name="editUserFormModal"
            layout="vertical"
            form={form}
            onFinish={onFinish}
          >
            <div>
              <Item
                name="role"
                label={t("users.roleInputLabel")}
                initialValue={
                  selectedUser && selectedUser.role
                    ? selectedUser.role.id
                    : null
                }
              >
                <Select size="middle">
                  {userRoles?.map((role, index) => (
                    <Option key={index} value={role?.id}>
                      {role?.name}
                    </Option>
                  ))}
                </Select>
              </Item>
            </div>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default EditUserFormModal;
