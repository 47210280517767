export class QueryHelper {
  /**
   * Remove all undefined and empty values from object
   */
  static removeUndefined(values: Object) {
    Object.keys(values).forEach(
      (key) =>
        (values[key] == null || values[key].length === 0) && delete values[key]
    );
  }
}
