import React, { useState } from "react";
import styles from "../NewAuctionFormModal.module.scss";
import { Modal, Form, Steps, Button, message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { updateAuctionVehicle } from "app/api/auctionVehiclesApi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import cx from "classnames";
import SellerInformationFormItems from "app/screens/AuctionsScreen/components/NewAuctionFormModal/NewAuctionFormItems/SellerInformationItems";
import { VehicleInformationFormItems } from "app/screens/AuctionsScreen/components/NewAuctionFormModal/NewAuctionFormItems/VehicleInformationItems";
import { UploadImagesAndDocumentFormItems } from "app/screens/AuctionsScreen/components/NewAuctionFormModal/NewAuctionFormItems/UploadImageAndDocumentItems";
import { AuctionInformationFormItems } from "app/screens/AuctionsScreen/components/NewAuctionFormModal/NewAuctionFormItems/AuctionInformationItems";
import { ReviewAuctionItems } from "app/screens/AuctionsScreen/components/NewAuctionFormModal/NewAuctionFormItems/ReviewAuctionItems";
import { auctionType } from "app/types/auctionType";
import { editUser } from "app/api/usersApi";
import { capitalize } from "app/helpers/capitalize";

interface NewVehicleAuctionModalProps {
  relist?: boolean;
  isSuperAdmin?: boolean;
  isVisible: boolean;
  selectedAuctionRequest: auctionType;
  onClose: () => void;
  updateAuctionData: () => void;
  vehicleModalType?: string | null;
}

const NewVehicleAuctionFormModal = ({
  relist,
  isSuperAdmin,
  isVisible,
  selectedAuctionRequest,
  onClose,
  updateAuctionData,
  vehicleModalType
}: NewVehicleAuctionModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [allFields, setAllFields] = useState<any>([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  let [form] = Form.useForm();

  const { Step } = Steps;
  const { confirm } = Modal;

  const handleClose = () => {
    setLoading(false);
    onClose();
    setCurrentStep(0);
    form.resetFields();
  };

  const handleModalCancel = () => {
    confirm({
      title: t("newAuctions.modalWarningTitle"),
      icon: <ExclamationCircleOutlined />,
      content: t("newAuctions.modalDescription"),
      okText: t("default.ok"),
      okType: "danger",
      cancelText: t("default.no"),
      onOk() {
        handleClose();
      }
    });
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async values => {
    const body = new FormData();
    body.append("title", values.title);
    if (values.year) {
      body.append("vehicleYear", values.year);
    }
    if (values.model) {
      body.append("vehicleModelId", values.model);
    }
    if (values.transmission) {
      body.append("vehicleTransmission", values.transmission);
    }
    if (values.exteriorColor) {
      body.append("vehicleColor", values.exteriorColor);
    }
    if (values.mileage) {
      body.append("mileage", values.mileage);
    }
    if (values.vehicleCondition) {
      body.append("vehicleCondition", values.vehicleCondition);
    }
    // if (values.interiorCondition) {
    //   body.append("vehicleInteriorCondition", values.interiorCondition);
    // }
    if (values.areaCode) {
      body.append("zipCode", values.areaCode);
    }

    // if (values.description) {
    body.append("description", values.description);
    // }

    /** If the auction is being relisted */
    if (relist) {
      body.append("relist", JSON.stringify(relist));
    }

    if (values.descriptionLocalized) {
      body.append("descriptionLocalized[ar]", values.descriptionLocalized);
      body.append("descriptionLocalized[en]", values.description);
    }
    if (
      values.startPrice !== false &&
      values.startPrice !== undefined &&
      values.saleType !== "sale"
    ) {
      body.append("startingPrice", values.startPrice);
    }
    if (values.saleType) {
      body.append("saleType", values.saleType);
    }
    if (
      values.reservedPrice !== false &&
      values.reservedPrice !== undefined &&
      values.saleType !== "sale"
    ) {
      body.append("reservedPrice", values.reservedPrice);
    }

    if (values["vehiclePrice"] && values["vehicleAvailableForSale"]) {
      body.append("vehiclePrice", values.vehiclePrice);
    }
    if (!values["vehicleAvailableForSale"]) {
      body.append("vehiclePrice", "");
    }
    body.append(
      "vehicleAvailableForSale",
      values["vehicleAvailableForSale"] ? "1" : "0"
    );

    body.append(
      "startAt",
      moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
    );

    body.append("endAt", moment(values.endDate).format("YYYY-MM-DD HH:mm:ss"));

    if (values.upcomingDate) {
      body.append(
        "comingSoonStart",
        moment(values.upcomingDate).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (values.completionDate) {
      body.append(
        "comingSoonEnd",
        moment(values.completionDate).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (values.comingSoon !== undefined) {
      body.append("comingSoon", values.comingSoon ? "1" : "0");
    }

    if (values.downPayment) {
      body.append("downPayment", values.downPayment);
    } else {
      body.append("downPayment", "null");
    }

    if (values.minimumBidAmount !== undefined && values.saleType !== "sale") {
      body.append("minimumBidAmount", values.minimumBidAmount);
    }

    if (values.bodyType) {
      body.append("bodyType", values.bodyType);
    }
    if (values.vinNumber) {
      body.append("vin", values.vinNumber);
    }

    body.append("featured", values.featured ? "1" : "0");

    if (values.cylinders) {
      body.append("cylinders", values.cylinders);
    }
    if (values.interiorColorist) {
      body.append("insideColor", values.interiorColor);
    }
    if (values.fuelType) {
      body.append("fuelType", values.fuelType);
    }
    if (values.canAcceptOffers) {
      body.append("canAcceptOffers", values.canAcceptOffers ? "1" : "0");
    }
    if (values["seller[type]"]) {
      body.append("seller[type]", values["seller[type]"]);
    }

    // if (values.doors) {
    //   body.append('doors', values.doors);
    // }

    // if (values.seats) {
    //   body.append('seatsType', values.seats);
    // }

    // if (values.trim) {
    //   body.append('vehicleTrimId', values.trim);
    // }

    // if (values.horsepower) {
    //   body.append('horsepower', values.horsepower);
    // }

    if (values["seller[saudiId]"]) {
      body.append("seller[saudiId]", values["seller[saudiId]"]);
    }

    if (values["seller[companyVatNo]"]) {
      body.append("seller[companyVatNo]", values["seller[companyVatNo]"]);
    }

    if (values["seller[commercialRegistration]"]) {
      body.append(
        "seller[commercialRegistration]",
        values["seller[commercialRegistration]"]
      );
    }

    // Process and convert each image in array to a blob
    if (values.vehicleImages) {
      let ins = values.vehicleImages.length;
      for (let x = 0; x < ins; x++) {
        const image = values.vehicleImages[x].originFileObj;

        if (!values.vehicleImages[x].skip) body.append("mediaPhotos[]", image);
      }
    }
    if (values.basicInspectionReport !== undefined) {
      body.append(
        "mediaInspectionReports[basic]",
        values.basicInspectionReport?.fileList[0]?.originFileObj
      );
    }
    if (
      values.advancedInspectionReport &&
      !values.advancedInspectionReport.skip
    ) {
      const file = values.advancedInspectionReport[0]?.originFileObj;
      if (file) {
        body.append("mediaInspectionReports[advanced]", file);
      }
    }

    if (
      values.initialInspectionReport &&
      !values.initialInspectionReport.skip
    ) {
      const file = values.initialInspectionReport[0]?.originFileObj;
      if (file) {
        body.append("mediaInspectionReports[initial]", file);
      }
    }

    // Add video to form data
    if (values.vehicleVideo) {
      body.append("mediaVideo", values.vehicleVideo[0]?.originFileObj);
    }

    if (selectedAuctionRequest.state === "ready_for_review") {
      body.append("state", "queued");
    }
    if (values["addOns[delivery][coordinates][latitude]"]) {
      body.append(
        "addOns[delivery][coordinates][latitude]",
        values["addOns[delivery][coordinates][latitude]"]
      );
    }
    if (values["addOns[delivery][coordinates][latitude]"]) {
      body.append(
        "addOns[delivery][capacity]",
        values["addOns[delivery][capacity]"]
      );
    }
    if (values["addOns[delivery][coordinates][latitude]"]) {
      body.append(
        "addOns[delivery][coordinates][longitude]",
        values["addOns[delivery][coordinates][longitude]"]
      );
    }

    if (values.geoLocation) {
      const geoData = JSON.parse(values.geoLocation);
      for (var i = 0; i < geoData.length; i++) {
        var myItemInArr = geoData[i];
        for (var prop in myItemInArr) {
          body.append(`geoLocation[${i}][${prop}]`, myItemInArr[prop]);
        }
      }
    }
    // Create auction from form data
    setLoading(true);
    // if user details changed, update API called
    if (
      selectedAuctionRequest.seller.lastName !== values.surName ||
      selectedAuctionRequest.seller.firstName !== values.givenName
    ) {
      await editUser(selectedAuctionRequest.user_id, {
        firstName: values.givenName,
        lastName: values.surName
      });
    }

    updateAuctionVehicle(selectedAuctionRequest.id, body, true)
      .then(resp => {
        if (resp.status === 200) {
          handleClose();
          updateAuctionData();
          message.success("Auction created");
        }
      })
      .catch(error => {
        setLoading(false);
        message.error("Something went wrong. Try again!");
      })
      .finally(() => {
        // Remove loading anyways t("newAuctions.modalTitle")
        setLoading(false);
      });
  };

  return (
    <Modal
      title={
        vehicleModalType
          ? `${vehicleModalType} ${capitalize(
              selectedAuctionRequest.saleType
            )} - ${selectedAuctionRequest.lot}`
          : `Relist Item - ${selectedAuctionRequest.lot}`
      }
      visible={isVisible}
      onCancel={handleModalCancel}
      width={700}
      destroyOnClose
      footer={
        <div className={styles.modalFooter}>
          <div>
            <Button
              type="primary"
              disabled={currentStep === 0 || disablePrevious}
              onClick={handlePrevious}
            >
              {t("default.previousButton")}
            </Button>
            <Button
              className={cx(styles.steps, {
                [styles.isHidden]: currentStep === 4
              })}
              type="primary"
              onClick={handleNext}
              disabled={currentStep === 3 || disableNext}
            >
              {t("default.nextButton")}
            </Button>
          </div>
          {currentStep < 4 && (
            <Button
              disabled={currentStep < 3 || disableNext}
              type="primary"
              onClick={handleNext}
            >
              {`Review Item`}
            </Button>
          )}
          {currentStep === 4 && (
            <Button
              form="createAuctionForm"
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={disableNext}
            >
              {t("newAuctions.approveRequest")}
            </Button>
          )}
        </div>
      }
    >
      <div className={styles.modalContainer}>
        <div className={styles.formContainer}>
          <Form
            name="createAuctionForm"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFieldsChange={(changedFields, allFields) => {
              setAllFields(allFields);
            }}
          >
            <SellerInformationFormItems
              userInformationValue={selectedAuctionRequest}
              isActive={currentStep === 0}
              disableNext={setDisableNext}
              form={form}
            />
            <VehicleInformationFormItems
              relist={relist}
              vehicleInformationValue={selectedAuctionRequest}
              isActive={currentStep === 1}
              form={form}
              disableNext={setDisableNext}
              disablePrevious={setDisablePrevious}
              allFields={allFields}
            />
            <UploadImagesAndDocumentFormItems
              uploadInformationValue={selectedAuctionRequest}
              isActive={currentStep === 2}
              form={form}
            />
            <AuctionInformationFormItems
              form={form}
              isActive={currentStep === 3}
              isSuperAdmin={isSuperAdmin}
              auctionInformationValue={selectedAuctionRequest}
              disableNext={setDisableNext}
              disablePrevious={setDisablePrevious}
              allFields={allFields}
              relist={relist}
            />
            <ReviewAuctionItems
              isActive={currentStep === 4}
              auctionInformationValue={selectedAuctionRequest}
              form={form}
              disableNext={setDisableNext}
            />
          </Form>
        </div>
        <div className={styles.stepContainer}>
          <Steps
            direction="vertical"
            current={currentStep}
            className={cx(styles.steps, {
              [styles.isHidden]: currentStep === 4
            })}
          >
            <Step
              title={t("newAuctions.sellerStepTitle")}
              description={t("newAuctions.sellerStepDescription")}
            />
            <Step
              title={t("newAuctions.vehicleStepTitle")}
              description={t("newAuctions.sellerStepDescription")}
            />
            <Step
              title={t("newAuctions.uploadStepTitle")}
              description={t("newAuctions.uploadStepDescription")}
            />
            <Step title={`Item information`} description={`Item start time`} />
          </Steps>
        </div>
      </div>
    </Modal>
  );
};

export default NewVehicleAuctionFormModal;
