import { valueType } from "antd/lib/statistic/utils";

/**
 * Gets the Users browser locale so number are formatted correctly
 *
 * @returns {string}
 */
const getLocale = (): string => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

/**
 * Convert number into readable format
 *
 * @param {(number | valueType | undefined)} number
 * @returns {string}
 */
export const formatNumber = (
  number: number | valueType | undefined
): string => {
  if (number || number === 0) {
    return new Intl.NumberFormat(getLocale()).format(number as number);
  } else {
    return "";
  }
};
