import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { IMenuItemType } from "app/types/routerType";
import { getNavigationTitle } from "app/helpers/routerHelper";
import Permission, {
  hasPermission
} from "app/components/Permission/Permission";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";

const { Item } = Menu;

interface IProps {
  /** List of navigation items */
  navigation: IMenuItemType[];
  location: any;
}

const PARENT_PATH_REGEX = /[/]\w*/;
const SUB_PATH_REGEX = /^\/.[^/]+\w/g;

const Navigation = ({ location, navigation }: IProps) => {
  const { t } = useTranslation();
  const [openSubMenuKey, setOpenSubMenuKey] = useState<string>("");

  const getSubMenuOpenKey = (path: string) => {
    // get submenu key from path
    const parentPath = path.match(PARENT_PATH_REGEX);
    return (parentPath && parentPath[0]) || "";
  };

  const getMenuOpenKey = (path: string) => {
    const parentPath = path.match(PARENT_PATH_REGEX);
    const subPaths = path.match(SUB_PATH_REGEX);
    return (subPaths && subPaths[0]) || (parentPath && parentPath[0]) || "";
  };

  useEffect(() => {
    const currentPagePath = "/active-listings";
    setOpenSubMenuKey(getSubMenuOpenKey(currentPagePath));
  }, []);

  const onOpenChange = newOpenKeys => {
    if (newOpenKeys.length > 0) {
      setOpenSubMenuKey(newOpenKeys[newOpenKeys.length - 1]);
    } else {
      setOpenSubMenuKey("");
    }
  };

  const MenuItem = ({ item, ...rest }: { item: IMenuItemType }) => {
    return (
      <Permission requiredPermissions={item.permissions}>
        <Item {...rest}>
          <Link to={{ pathname: item.path, search: item.params }}>
            <Icon component={item.icon} />
            <span>{t(getNavigationTitle(item))}</span>
          </Link>
        </Item>
      </Permission>
    );
  };

  return (
    <Menu mode="inline" openKeys={[openSubMenuKey]} onOpenChange={onOpenChange}>
      {navigation.map(item =>
        item.subMenuItems ? (
          hasPermission(item.permissions) ? (
            <SubMenu
              key={item.path}
              title={
                <span>
                  <Icon component={item.icon} />
                  <span>{t(getNavigationTitle(item))}</span>
                </span>
              }
            >
              {item.subMenuItems.map(subItem => (
                <MenuItem key={getMenuOpenKey(subItem.path)} item={subItem} />
              ))}
            </SubMenu>
          ) : (
            undefined
          )
        ) : (
          <MenuItem key={getMenuOpenKey(item.path)} item={item} />
        )
      )}
    </Menu>
  );
};

export default memo(Navigation);
