import { TranslationOutlined } from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
import TranslationsScreen from "app/screens/TranslationsScreen/TranslationsScreen";
import { IMenuItemType } from "app/types/routerType";

export const TRANSLATIONS_SCREEN: IMenuItemType = {
  id: "translations",
  path: "/translations",
  title: "Translations",
  icon: TranslationOutlined,
  component: TranslationsScreen,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};
