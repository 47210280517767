import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  // InputNumber,
  Modal,
  Select,
  Switch,
  TreeSelect,
  Upload,
  UploadProps,
  message
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  UploadOutlined
} from "@ant-design/icons";
import moment from "moment";
import { RcFile } from "antd/lib/upload";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import styles from "./AuctionShowsForm.module.scss";
import * as eventsApi from "app/api/auctionShowsApi";
import { UploadFile } from "antd/lib/upload/interface";
import { getListings } from "app/api/dynamicCategoryApi";
import { fetchAuctioneers } from "app/api/auctioneerApi";
import { CategoriesType } from "app/types/pageLayoutType";
// import { formatNumber } from 'app/helpers/numberFormatHelper';
import { createEvent, updateEvent } from "app/api/auctionShowsApi";
import { beforeUpload, getBase64 } from "app/helpers/imageUploadHelper";
import { getCity, getCountries, getRegion } from "app/api/locationApi";
import EmergencyDeleteModal from "app/screens/LiveAuctionsScreen/components/EmergencyDeleteModal/EmergencyDeleteModal";
import { AuctionData } from "app/types/auctionShowsType";

interface AuctionShowsFormProps {
  path: string;
  selectedEvent?: AuctionData;
  handleClose: () => void;
  onLoadDetails: () => void;
}

interface CityData {
  data: {
    countryCode: string;
    id: number;
    latitude: number;
    longitude: number;
    name: string;
    regionId: number;
  }[];
}

interface RegionData {
  data: {
    code: string;
    countryId: number;
    id: number;
    name: string;
  }[];
}

interface CountryData {
  data: {
    countryCode: string;
    countryCode2: string;
    currencyCode: string;
    flagImagesUrl: string;
    id: number;
    name: string;
    phonecode: string;
  }[];
}

interface AuctioneerData {
  active: boolean;
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  liveAuctionId: number | null;
  liveAuctions: {}[];
  middleName: string;
  mobile: string;
  updatedAt: string;
}

interface FileListItem {
  name: string;
  size: number;
  type: string;
  uid: string;
  url: string;
}

const { Item } = Form;

const AuctionShowsForm = ({
  path,
  handleClose,
  selectedEvent,
  onLoadDetails
}: AuctionShowsFormProps) => {
  let [form] = Form.useForm();
  const { Option } = Select;
  const [city, setCity] = useState<CityData>();
  const [region, setRegion] = useState<RegionData>();
  const [countries, setCountries] = useState<CountryData>();
  const [auctioneer, setAuctioneer] = useState<AuctioneerData[]>([]);
  const [fileList, setFileList] = useState<FileListItem[] | any>([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [allFields, setAllFields] = useState<any[]>([]);
  const [previewVisible, setPreviewOpen] = useState(false);
  const [emergencyDelete, setEmergencyDelete] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoriesType[]>([]);
  const [shouldShowClear, setShouldShowClear] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    selectedEvent?.auctionType
  );

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const handleTreeSearch = (inputValue, treeNode) => {
    return treeNode.props.title
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    fetchAuctioneers()
      .then(payload => {
        setAuctioneer(payload.data);
      })
      .catch(error => console.log(error));
  }, [allFields]);

  const countryField = form.getFieldValue("country");
  useEffect(() => {
    getCountries()
      .then(payload => {
        setCountries(payload);
      })
      .catch(error => console.log(error));
  }, []);

  const regionField = form.getFieldValue("region");
  useEffect(() => {
    if (regionField) {
      getCity(regionField)
        .then(payload => {
          setCity(payload);
        })
        .catch(error => console.log(error));
    }
  }, [regionField]);

  useEffect(() => {
    if (countryField) {
      getRegion(countryField)
        .then(payload => {
          setRegion(payload);
        })
        .catch(error => console.log(error));
    }
  }, [countryField]);

  const disabledDate = current => {
    return current < moment().startOf("day");
  };

  useEffect(() => {
    getListings()
      .then(payload => {
        setCategories(payload.data);
        setFilteredCategories(payload.data);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedEvent && selectedEvent.path) {
      setFileList([
        {
          uid: selectedEvent.path,
          size: 1,
          name: selectedEvent.path as string,
          type: "",
          url: selectedEvent.path
        }
      ]);
    }
  }, [selectedEvent]);

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const onFinish = values => {
    const body = new FormData();

    body.append("currencyCode", "SAR");

    if (values.title) {
      body.append("title", values.title);
    }

    if (values.titleAr) {
      body.append("titleLocalized", values.titleAr);
    }

    if (values.description) {
      body.append("description", values.description);
    }

    if (values.descriptionLocalized) {
      body.append("descriptionLocalized", values.descriptionLocalized);
    }

    if (values.auctionType) {
      body.append("auctionType", values.auctionType);
    }

    if (values.liveLink) {
      body.append("url", values.liveLink);
    }

    if (values.streamKey) {
      body.append("key", values.streamKey);
    }

    if (values.startDate) {
      body.append(
        "startAt",
        moment(values.startDate).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (values.endDate) {
      body.append(
        "endAt",
        moment(values.endDate).format("YYYY-MM-DD HH:mm:ss")
      );
    }

    if (values.region) {
      body.append("regionCode", values.region);
    }

    if (values.city) {
      body.append("cityId", values.city);
    }

    if (values.street) {
      body.append("streetName", values.street);
    }

    if (values.country !== undefined && values.registrationFee) {
      body.append("registrationFee", values.registrationFee);
    }

    if (values.auctioneer) {
      body.append("liveAuctionOwnerId", values.auctioneer);
    }

    if (values.eventCategory) {
      body.append("listingTypeId", values.eventCategory);
    }

    if (values.eventSeller) {
      body.append("seller", values.eventSeller);
    }

    if (values.featured !== undefined) {
      body.append("featured", values.featured ? "1" : "0");
    }

    // Process and convert each image in array to a blob
    if (values.media) {
      let ins = values.media.length;
      for (let x = 0; x < ins; x++) {
        const image = values.media[x].originFileObj;

        body.append("imagePath", image);
      }
    }

    if (selectedEvent?.id !== undefined) {
      updateEvent(selectedEvent.id, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Live auction updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createEvent(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            handleClose();
            return message.success("Live auction created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image or video</p>
      <p className={styles.uploadHint}>
        (Images or Videos must be less than 5MB, format png, jpg, jpeg or mp4
        and a ratio of 2:1 )
      </p>
    </div>
  );

  const handleSwitchChange = value => {
    form.setFieldsValue({
      featured: value
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      media: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleClear = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this Live Stream?",
      okText: "Yes, delete live stream",
      okType: "danger",
      onOk() {
        return eventsApi
          .deleteLiveAuctionStream(selectedEvent?.id)
          .then(p => {
            if (p.status === 200) {
              message.success("Live Stream Deleted");
              onLoadDetails();
              form.setFieldsValue({
                liveLink: "",
                streamKey: ""
              });
              setShouldShowClear(false);
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const auctionFields = form.getFieldValue("auctionType");

  const handleGenerateLink = async () => {
    if (!selectedEvent?.id) {
      return;
    }
    try {
      const response = await eventsApi.createLiveStream(selectedEvent?.id);
      if (response.status === 201) {
        const { key, endpoint } = response.data;
        form.setFieldsValue({
          liveLink: endpoint,
          streamKey: key
        });
        onLoadDetails();
        setShouldShowClear(true);
        message.success("Live stream created successfully");
      } else {
        message.error("Something Wrong happened, try again.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderTreeNodes = categories => {
    return categories.map(item => {
      if (item.children) {
        return (
          <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id}>
            {renderTreeNodes(item.children)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} />
      );
    });
  };

  const validateLength = (inputFiled, inputLength) => (_, value) => {
    if (value && value.length >= inputLength) {
      return Promise.reject(
        `${inputFiled} must have at least ${inputLength} characters.`
      );
    }
    return Promise.resolve();
  };

  const validateTitleLength = validateLength("Title", 50);
  const validateDescriptionLength = validateLength("Description", 250);

  return (
    <div className={styles.formWrapper}>
      {emergencyDelete && (
        <EmergencyDeleteModal
          isVisible={emergencyDelete}
          onClose={() => setEmergencyDelete(false)}
          isAuction
          selectedLiveAuction={selectedEvent}
          onCreate={handleClose}
        />
      )}
      <Form
        name="createEventForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={(changedFields, allFields) => {
          setAllFields(allFields);
        }}
      >
        <div>
          <Item
            name="title"
            label={"Title:"}
            rules={[
              {
                required: true,
                message: "Please enter a given title."
              },
              {
                validator: validateTitleLength
              }
            ]}
            className={styles.inlineInput}
            initialValue={selectedEvent?.title}
          >
            <Input.TextArea rows={1} showCount maxLength={50} />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="titleAr"
            rules={[
              {
                required: false,
                message: "Please enter a given Arabic translation."
              },
              {
                validator: validateTitleLength
              }
            ]}
            label={"Arabic Translation:"}
            initialValue={selectedEvent?.titleLocalized}
          >
            <Input.TextArea rows={1} showCount maxLength={50} />
          </Item>
          <Form.Item
            className={styles.inlineInput}
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter a given (event) description"
              },
              {
                validator: validateDescriptionLength
              }
            ]}
            label={"Event Description:"}
            initialValue={selectedEvent?.description}
          >
            <TextArea rows={2} disabled={false} showCount maxLength={250} />
          </Form.Item>
          <Form.Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="descriptionLocalized"
            rules={[
              {
                required: false,
                message: "Please enter a given Arabic translation."
              },
              {
                validator: validateDescriptionLength
              }
            ]}
            label={"Arabic Translation:"}
            initialValue={selectedEvent?.descriptionLocalized}
          >
            <TextArea rows={2} disabled={false} showCount maxLength={250} />
          </Form.Item>

          <Item
            name="auctionType"
            label={"Event Type:"}
            initialValue={
              selectedEvent?.auctionType
                ? selectedEvent?.auctionType
                : "timed-auction"
            }
            rules={[
              {
                required: true,
                message: "Please select a given (event) type."
              }
            ]}
          >
            <Select
              onChange={value => setSelectedItem(value)}
              value={selectedItem}
              disabled={false}
              size="middle"
              options={[
                { value: "timed-auction", label: "Timed Auction" },
                { value: "live-auction", label: "Live Auction" }
              ]}
              getPopupContainer={(trigger: any) => trigger.parentElement}
            />
          </Item>
          <Item
            className={styles.inlineInput}
            name="startDate"
            label="Start Date & Time:"
            rules={[{ required: true, message: "Start date is required!" }]}
            initialValue={
              selectedEvent?.startAt ? moment(selectedEvent?.startAt) : null
            }
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              style={{ width: "100%" }}
              format={"YYYY-MM-DD HH:mm:ss"}
              disabledDate={disabledDate}
              disabled={false}
              getPopupContainer={(trigger: any) => trigger.parentElement}
            />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="endDate"
            label="End Date & Time:"
            rules={[
              {
                required: auctionFields === "timed-auction",
                message: "Start date is required!"
              }
            ]}
            initialValue={
              selectedEvent?.endAt ? moment(selectedEvent?.endAt) : null
            }
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              style={{ width: "100%" }}
              format={"YYYY-MM-DD HH:mm:ss"}
              disabledDate={disabledDate}
              disabled={auctionFields !== "timed-auction"}
              getPopupContainer={(trigger: any) => trigger.parentElement}
            />
          </Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.auctionType === currentValues.auctionType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("auctionType") === "live-auction" ? (
                <>
                  <Form.Item
                    label="Auction Address:"
                    style={{ marginBottom: 0 }}
                    required
                  >
                    <Form.Item
                      name="country"
                      rules={[
                        { required: true, message: "Please select a country" }
                      ]}
                      initialValue={selectedEvent?.country?.id}
                    >
                      <Select
                        placeholder="Country"
                        disabled={false}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option: any) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        getPopupContainer={(trigger: any) =>
                          trigger.parentElement
                        }
                      >
                        {countries?.data
                          ? countries?.data.map((country, index) => (
                              <Option
                                key={index}
                                value={country.id}
                                label={country.name}
                              >
                                {country.name}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="region"
                      rules={[
                        { required: true, message: "Please select a region" }
                      ]}
                      initialValue={selectedEvent?.region?.id}
                    >
                      <Select
                        placeholder="Region"
                        disabled={false}
                        getPopupContainer={(trigger: any) =>
                          trigger.parentElement
                        }
                      >
                        {region?.data
                          ? region?.data.map((region, index) => (
                              <Option key={index} value={region.id}>
                                {region.name}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="city"
                      rules={[
                        { required: true, message: "Please select a city" }
                      ]}
                      initialValue={selectedEvent?.city?.id}
                    >
                      <Select
                        placeholder="City"
                        disabled={false}
                        getPopupContainer={(trigger: any) =>
                          trigger.parentElement
                        }
                      >
                        {city?.data
                          ? city?.data.map((city, index) => (
                              <Option key={index} value={city.id}>
                                {city.name}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="street"
                      rules={[
                        { required: true, message: "Please select a street" }
                      ]}
                      initialValue={selectedEvent?.streetName}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        disabled={false}
                      ></Input>
                    </Form.Item>
                  </Form.Item>
                  {selectedEvent && (
                    <>
                      <Form.Item
                        label={"Live Stream Endpoint"}
                        style={{ marginBottom: 0 }}
                        required
                      >
                        <Input.Group>
                          <Item
                            style={{
                              width: "calc(100% - 137px)",
                              display: "inline-block",
                              marginRight: "10px"
                            }}
                            name="liveLink"
                            rules={[
                              {
                                required: true,
                                message: "Please create a channel"
                              }
                            ]}
                            initialValue={
                              selectedEvent?.liveAuctionLiveStream?.endpoint
                            }
                          >
                            <Input
                              readOnly
                              type="link"
                              disabled={false}
                              suffix={
                                <CopyOutlined
                                  style={{ color: "#C4C4C4" }}
                                  onClick={() => {}}
                                />
                              }
                            />
                          </Item>
                          <Item
                            style={{
                              display: "inline-block"
                            }}
                          >
                            <Button
                              type="primary"
                              onClick={
                                selectedEvent?.liveAuctionLiveStream !== null
                                  ? handleClear
                                  : handleGenerateLink
                              }
                            >
                              {selectedEvent?.liveAuctionLiveStream !== null ||
                              shouldShowClear
                                ? "Clear Channel"
                                : "Create Channel"}
                            </Button>
                          </Item>
                        </Input.Group>
                      </Form.Item>
                      <Item
                        name="streamKey"
                        rules={[
                          {
                            required: true,
                            message: "Please create a channel"
                          }
                        ]}
                        label={"Stream Key"}
                        initialValue={selectedEvent?.liveAuctionLiveStream?.key}
                      >
                        <Input
                          type="text"
                          disabled={false}
                          readOnly
                          suffix={
                            <CopyOutlined
                              style={{ color: "#C4C4C4" }}
                              onClick={() => {}}
                            />
                          }
                        />
                      </Item>
                    </>
                  )}

                  {/* <Item
                    label={'Registration fee:'}
                    style={{ marginBottom: 0 }}
                    hidden={true}
                  >
                    <Input.Group compact style={{ display: 'flex' }}>
                      <Item name='currencyCode' initialValue='SAR'>
                        <Input
                          readOnly={true}
                          disabled={false}
                          style={{ maxWidth: '50px' }}
                        />
                      </Item>
                      <Item
                        style={{ flexGrow: 1 }}
                        name='registrationFee'
                        rules={[
                          {
                            required: false,
                            message: 'Registration fee is required',
                          },
                        ]}
                        initialValue={selectedEvent?.registrationFee?.amount}
                      >
                        <InputNumber
                          disabled={false}
                          formatter={(value) => formatNumber(value)}
                        />
                      </Item>
                    </Input.Group>
                  </Item> */}

                  <Item
                    name="auctioneer"
                    rules={[
                      {
                        required: true,
                        message: "Please select a auctioneer"
                      }
                    ]}
                    label={"Auctioneer:"}
                    initialValue={selectedEvent?.auctioneers[0]?.id}
                  >
                    <Select
                      size="middle"
                      disabled={false}
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                    >
                      {auctioneer?.map(auctioneers => (
                        <Option key={auctioneers.id} value={auctioneers.id}>
                          {auctioneers.firstName} {auctioneers.lastName}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </>
              ) : (
                <>
                  <Item
                    name="eventCategory"
                    rules={[
                      {
                        required: false,
                        message: "Please select a given (event) category."
                      }
                    ]}
                    label={"Event Category:"}
                    initialValue={selectedEvent?.listingType?.id}
                  >
                    <TreeSelect
                      showSearch
                      allowClear
                      value={selectedItem}
                      style={{ width: "100%" }}
                      placeholder="Select a category"
                      onChange={value => setSelectedItem(value)}
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                      filterTreeNode={handleTreeSearch}
                    >
                      {renderTreeNodes(filteredCategories)}
                    </TreeSelect>
                  </Item>

                  <Item
                    name="eventSeller"
                    label={"Seller:"}
                    initialValue={"mazadak"}
                    rules={[
                      {
                        required: true,
                        message: "Please select a given (event) seller."
                      }
                    ]}
                  >
                    <Select
                      disabled={true}
                      size="middle"
                      getPopupContainer={(trigger: any) =>
                        trigger.parentElement
                      }
                    >
                      <Option key="mazadak" value="mazadak">
                        Mazadak
                      </Option>
                    </Select>
                  </Item>
                </>
              )
            }
          </Form.Item>

          <Item
            name="media"
            label="Cover Image:"
            valuePropName="fileList"
            extra={""}
          >
            <canvas className={styles.canvas}></canvas>
            <div className={styles.upload}>
              <Upload
                accept="image/png,image/jpeg,image/jpg,video/mp4"
                listType="picture-card"
                defaultFileList={[]}
                maxCount={1}
                beforeUpload={beforeUpload}
                fileList={fileList}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true
                }}
                customRequest={customUpload}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="EventImage"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Item>
          <Item
            name="featured"
            initialValue={
              selectedEvent?.featured ? selectedEvent?.featured : false
            }
          >
            <div className={styles.requireField}>
              <div className={styles.requireLabel}>
                Make Event Featured:
                <p className="ant-form-item-extra">
                  Featured corresponding event.
                </p>
              </div>
              <Switch
                defaultChecked={
                  selectedEvent?.featured ? selectedEvent?.featured : false
                }
                checkedChildren="YES"
                unCheckedChildren="NO"
                onChange={handleSwitchChange}
              />
            </div>
          </Item>
          <Divider />
          <Item>
            <Button
              danger
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => {
                setEmergencyDelete(true);
              }}
              hidden={
                path !== "in-progress" ||
                selectedEvent?.auctionType !== "live-auction"
              }
            >
              Emergency Delete
            </Button>
            <Button
              form="createEventForm"
              type="primary"
              htmlType="submit"
              hidden={path === "in-progress" || path === "history"}
            >
              {selectedEvent ? "Save " : "Create "} event
            </Button>
          </Item>
        </div>
      </Form>
    </div>
  );
};

export default AuctionShowsForm;
