import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { NstackInstance } from "@nstack-io/javascript-sdk";
import { Env } from "app/configs/config";

const INITIAL_LANG = "en-EN";
export const DEFAULT_NS = "translation";

export const nstackClient = new NstackInstance({
  appId: Env.NSTACK_APP_ID,
  apiKey: Env.NSTACK_API_KEY,
  version: Env.VERSION,
  initialLanguage: INITIAL_LANG,
  meta: `web;${Env.NODE_ENV}`,
});

i18next.use(initReactI18next).init({
  fallbackLng: INITIAL_LANG,
  lng: INITIAL_LANG,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: DEFAULT_NS,
  /**
   * Add initial translation keys/values below to show here
   * while the rest of the translation
   * is downloaded
   */
  resources: {
    "en-US": {
      translation: {
        clientErrors: {
          forbiddenTitle: "403",
          forbidden: "You do not have permission to view this content.",
          notFoundTitle: "404",
          notFound: "The content you were looking for was not found.",
        },
      },
    },
  },
});

export default i18next;
