import axios, { AxiosRequestConfig } from "axios";
import { Env } from "app/configs/config";
import { clearAccessToken, getAccessToken } from "./authenticationHelper";
import { notification } from "antd";

export const api = axios.create({
  baseURL: Env.BASE_URL,
  headers: {
    "N-Meta": "web;development"
  }
});

const authInterceptor = async (request: AxiosRequestConfig) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
};

const reqInterceptor = async (response: any) => {
  if (response.response.status === 401) {
    clearAccessToken();
    window.location.href = "/login";
    Promise.reject(response.response.message);
  }
  if (response.response.status === 412) {
    const validationResponse: any[] = response.response.data.payload.validation;
    validationResponse.forEach(validation => {
      validation.errors.forEach(error => {
        notification.open({
          message: response.response.data.message,
          description: error.message
        });
      });
    });
    Promise.reject(response.response.message);
  }
  return response;
};

api.interceptors.request.use(authInterceptor, function(error) {
  return Promise.reject(error);
});

api.interceptors.response.use(function(response) {
  return response;
}, reqInterceptor);
