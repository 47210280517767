import { IMenuItemType } from "app/types/routerType";
import { PermissionEnum } from "app/constants/permissionConst";
import { UserOutlined, UnorderedListOutlined } from "@ant-design/icons";

import BackOfficeUsersScreen from "app/screens/BackOfficeUsersScreen/BackOfficeUsersScreen";
import AppUsersScreen from "app/screens/AppUsersScreen/AppUsersScreen";
import WithdrawalRequestsScreen from "app/screens/WithdrawalRequestsScreen/WithdrawalRequestsScreen";

import AuctioneerPortalUserScreen from "app/screens/AuctioneerPortalUserScreen/AuctioneerPortalUserScreen";
// import BusinessesScreen from 'app/screens/Businesses/BusinessScreen';

export const USERS_SCREEN: IMenuItemType = {
  id: "users",
  path: "/users",
  title: "Accounts",
  icon: UserOutlined,
  permissions: [PermissionEnum.SUPER_ADMIN, PermissionEnum.ADMIN]
};

export const APP_USERS: IMenuItemType = {
  id: "app-users",
  path: "/app-users",
  component: AppUsersScreen,
  title: "App Users",
  icon: UnorderedListOutlined,
  permissions: [PermissionEnum.SUPER_ADMIN, PermissionEnum.ADMIN]
};

export const BACK_OFFICE_USERS: IMenuItemType = {
  id: "back-office-users",
  path: "/back-office-users",
  component: BackOfficeUsersScreen,
  title: "Back-office users",
  icon: UnorderedListOutlined,
  permissions: [PermissionEnum.SUPER_ADMIN, PermissionEnum.ADMIN]
};

// export const BUSINESS_SCREEN: IMenuItemType = {
//   id: 'businesses',
//   path: '/businesses',
//   component: BusinessesScreen,
//   title: 'Businesses',
//   icon: UnorderedListOutlined,
//   permissions: [
//     PermissionEnum.SUPER_ADMIN,
//     PermissionEnum.ADMIN,
//     PermissionEnum.AUCTIONEER,
//     PermissionEnum.BACK_OFFICE_USER,
//   ],
// };

export const AUCTIONEER_PORTAL_SCREEN: IMenuItemType = {
  id: "auctioneer-portal-users",
  path: "/auctioneer-portal-users",
  component: AuctioneerPortalUserScreen,
  title: "Users Auctioneer Portal",
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const WITHDRAWAL_REQUESTS: IMenuItemType = {
  id: "withdrawal-requests",
  path: "/withdrawal-requests",
  component: WithdrawalRequestsScreen,
  title: "Withdrawal Requests",
  icon: UnorderedListOutlined,
  permissions: [PermissionEnum.SUPER_ADMIN, PermissionEnum.ADMIN]
};

USERS_SCREEN.subMenuItems = [
  BACK_OFFICE_USERS,
  APP_USERS,
  WITHDRAWAL_REQUESTS,
  AUCTIONEER_PORTAL_SCREEN
  // BUSINESS_SCREEN,
];
