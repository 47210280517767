/* eslint-disable react-hooks/exhaustive-deps */
import { FormInstance } from "antd/lib/form/Form";
import {
  Input,
  Form,
  DatePicker,
  Checkbox,
  InputNumber,
  Button,
  Select
} from "antd";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "./NewAuctionFormItems.module.scss";
import moment from "moment";

import { auctionType } from "app/types/auctionType";
import { formatNumber } from "app/helpers/numberFormatHelper";
import { getPriceEditStatus } from "app/api/auctionVehiclesApi";
import axios from "axios";
import { capitalize } from "app/helpers/capitalize";

interface FormItemProps {
  isActive: boolean;
  relist?: boolean;
  isSuperAdmin?: boolean;
  disableNext: (boolean) => void;
  disablePrevious: (boolean) => void;
  allFields: any;
  auctionInformationValue?: auctionType;
  relisted?: boolean;
}
interface GeoLocation {
  lang: string;
  text: string;
}
interface FormItemsWithContextProps extends FormItemProps {
  form: FormInstance;
}

export const AuctionInformationFormItems = ({
  isActive,
  isSuperAdmin,
  form,
  disableNext,
  disablePrevious,
  allFields,
  relist,
  relisted,
  auctionInformationValue
}: FormItemsWithContextProps) => {
  const [buyNowEnabled, setBuyNowEnabled] = useState<boolean>(
    auctionInformationValue?.buyNowOption || false
  );
  const [canOfferEnabled, setCanOfferEnabled] = useState<boolean>(
    auctionInformationValue?.saleOffer?.canAcceptOffers || false
  );
  const [featuredEnabled, setFeaturedEnabled] = useState<boolean>(
    auctionInformationValue?.featured || false
  );
  const [downPaymentEnable, setDownPaymentEnable] = useState<boolean>(
    auctionInformationValue?.customDownPayment || false
  );
  const [upcomingEnabled, setUpcomingEnabled] = useState<boolean>(
    auctionInformationValue?.comingSoon || false
  );
  const [hasLatLong, setLatLong] = useState<boolean>(false);
  const [latCord, setLatCord] = useState<number>(0);
  const [longCord, setLongCord] = useState<number>(0);
  const [newLatCord, setNewLatCord] = useState<number>(0);
  const [newLongCord, setNewLongCord] = useState<number>(0);
  const [hasBids, setHasBids] = useState<boolean>(false);
  const [updateGeoLocation, setUpdateGeoLocation] = useState<boolean>(false);
  const [geoLocations, setGeoLocations] = useState<GeoLocation[]>(
    auctionInformationValue?.geoLocation
      ? auctionInformationValue?.geoLocation
      : []
  );

  const { Item } = Form;
  const { Option } = Select;
  const { t } = useTranslation();
  const auctionInformationFields = [
    "saleType",
    "startPrice",
    "reservedPrice",
    "downPayment",
    "minimumBidAmount",
    "vehiclePrice",
    "startDate",
    "endDate",
    "upcomingDate",
    "completionDate",
    "comingSoon",
    "addOns[delivery][coordinates][latitude]",
    "addOns[delivery][coordinates][longitude]"
  ];

  function validateCompletionDate(_, value) {
    const upcomingDate = moment.utc(form.getFieldValue("upcomingDate"));
    const completionDate = moment.utc(value);
    if (completionDate <= upcomingDate) {
      return Promise.reject(
        "Completion date must be greater than the upcoming date"
      );
    }
    return Promise.resolve();
  }

  function validateStartDate(_, value) {
    const completionDate = moment.utc(form.getFieldValue("completionDate"));
    const startDate = moment.utc(value);
    if (startDate <= completionDate && upcomingEnabled) {
      return Promise.reject(
        "Start date must be greater than the completion date"
      );
    }
    return Promise.resolve();
  }

  function validateEndDate(_, value) {
    const startDate = moment.utc(form.getFieldValue("startDate"));
    const endDate = moment.utc(value);
    if (endDate <= startDate) {
      return Promise.reject("End date must be greater than the start date");
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (isActive) {
      form.validateFields(auctionInformationFields).catch(error => {
        disableNext(error.errorFields.length > 0);
        disablePrevious(error.errorFields.length > 0);
      });
      /** Super Admin users can not update the price when the active auction has bids */
      if (isSuperAdmin && auctionInformationValue) {
        getPriceEditStatus(auctionInformationValue.id).then(response => {
          if (response.status === 200) {
            setHasBids(response.auctionBids.auctionHasBids as boolean);
          }
        });
      }
    }
  }, [isActive]);

  useEffect(() => {
    const latCordValue: number = (latCord as unknown) as number;
    const longCordValue: number = (longCord as unknown) as number;

    if (isActive) {
      let hasErrors = false;
      setUpdateGeoLocation(false);
      setNewLatCord(0);
      setNewLongCord(0);

      allFields.forEach(field => {
        if (
          (auctionInformationFields.includes(field.name[0]) &&
            field.errors.length > 0) ||
          (field.name[0] === "addOns[delivery][coordinates][latitude]" &&
            Number(field.value) !== latCordValue) ||
          (field.name[0] === "addOns[delivery][coordinates][longitude]" &&
            Number(field.value) !== longCordValue)
        ) {
          hasErrors = true;
        }
        if (
          (field.name[0] === "addOns[delivery][coordinates][latitude]" &&
            Number(field.value) !== latCordValue) ||
          (field.name[0] === "addOns[delivery][coordinates][longitude]" &&
            Number(field.value) !== longCordValue)
        ) {
          setUpdateGeoLocation(true);
          if (field.name[0] === "addOns[delivery][coordinates][latitude]") {
            setNewLatCord(Number(field.value));
          }
          if (field.name[0] === "addOns[delivery][coordinates][longitude]") {
            setNewLongCord(Number(field.value));
          }
        }
      });

      if (buyNowEnabled && !form.getFieldValue("vehiclePrice"))
        hasErrors = true;
      disableNext(hasErrors);
      disablePrevious(hasErrors);
    }
  }, [allFields, latCord, longCord]);

  useEffect(() => {
    if (isActive) {
      if (
        auctionInformationValue?.addOns.delivery !== null &&
        auctionInformationValue?.addOns?.delivery?.coordinates?.latitude !==
          null &&
        auctionInformationValue?.addOns?.delivery?.coordinates?.longitude !==
          null
      ) {
        setLatLong(true);
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (
      auctionInformationValue?.addOns.delivery !== null &&
      auctionInformationValue?.addOns?.delivery?.coordinates?.latitude !==
        null &&
      auctionInformationValue?.addOns?.delivery?.coordinates?.longitude !== null
    ) {
      setLatCord(
        (auctionInformationValue?.addOns?.delivery?.coordinates
          ?.latitude as unknown) as number
      );
      setLongCord(
        (auctionInformationValue?.addOns?.delivery?.coordinates
          ?.longitude as unknown) as number
      );
    }

    // getGeoLocation('24.8256678056', '46.8208999');
  }, []);

  useEffect(() => {
    // form.setFieldsValue({ "geoLocation[0][text]": "testsetset" })
    geoLocations.forEach((location, index) => {
      const fieldKey = "geoLocation[" + index + "][text]";
      form.setFieldsValue({ [fieldKey]: location.text });
    });
    form.setFieldsValue({ geoLocation: JSON.stringify(geoLocations) });
  }, [geoLocations]);

  const GeoLocationOnChange = (index, newvalue, currentObj) => {
    currentObj[index]["text"] = newvalue;
    form.setFieldsValue({ geoLocation: JSON.stringify(currentObj) });
  };
  const getGeoLocation = (
    latitude: number | string,
    longitude: number | string
  ) => {
    const listOfGeoLangs: string[] = ["ar", "en"];
    setGeoLocations([]);
    const geoLocationsTemp = geoLocations;
    listOfGeoLangs.map(language => {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBx9EjP7uZeJMfvunVHIYY8x0yVL09QiZ4&language=${language}`
        )
        .then(function(response) {
          let city, state, country;
          if (response.data.status !== "ZERO_RESULTS") {
            for (
              let i = 0;
              i < response.data.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.data.results[0].address_components[i].types.length;
                j++
              ) {
                switch (
                  response.data.results[0].address_components[i].types[j]
                ) {
                  case "locality":
                    city =
                      response.data.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state =
                      response.data.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country =
                      response.data.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            setGeoLocations(location => [
              ...location,
              {
                lang: language,
                text: `${country}, ${state}, ${city}`
              }
            ]);
          } else {
            setGeoLocations(geoLocationsTemp);
            console.log("No location information found");
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    });
  };

  const toggleBuyNow = () => {
    const priceValue: number | undefined =
      !buyNowEnabled && auctionInformationValue?.vehiclePrice?.amount
        ? auctionInformationValue?.vehiclePrice?.amount
        : undefined;
    form.setFieldsValue({
      vehiclePrice: priceValue,
      vehicleAvailableForSale: !buyNowEnabled
    });
    setBuyNowEnabled(!buyNowEnabled);
  };

  const toggleCanOffer = () => {
    form.setFieldsValue({
      canAcceptOffers: !canOfferEnabled
    });
    setCanOfferEnabled(!canOfferEnabled);
  };
  const togglefeatured = () => {
    form.setFieldsValue({
      featured: !featuredEnabled
    });
    setFeaturedEnabled(!featuredEnabled);
  };

  const toggleDownPayment = () => {
    form.setFieldsValue({
      downPaymentEnable: !downPaymentEnable
    });
    if (downPaymentEnable !== false) {
      form.setFieldsValue({
        downPayment: null
      });
    } else {
      form.setFieldsValue({
        downPayment: auctionInformationValue?.depositAmount?.amount
      });
    }

    setDownPaymentEnable(!downPaymentEnable);
  };

  const toggleUpcoming = () => {
    form.setFieldsValue({
      comingSoon: !upcomingEnabled
    });
    form.validateFields();
    setUpcomingEnabled(!upcomingEnabled);
  };

  const disabledDate = current => {
    // Can not select days before today
    return current < moment().startOf("day");
  };

  const handleGeoCall = () => {
    const lat = newLatCord ? newLatCord : latCord;
    const long = newLongCord ? newLongCord : longCord;
    getGeoLocation(lat, long);
    setLatCord(lat);
    setLongCord(long);
  };

  const saleType = ["sale", "auction"];
  const saleTypeField = form.getFieldValue("saleType");

  return (
    <div className={cx(styles.formItem, { [styles.isActive]: isActive })}>
      <Item
        name="saleType"
        rules={[{ required: true, message: "Please choose item type!" }]}
        label="Item Type"
        initialValue={auctionInformationValue?.saleType}
      >
        <Select
          size="middle"
          onChange={e => {
            return form.validateFields();
          }}
        >
          {saleType.map((type, index) => (
            <Option key={index} value={saleType[index]}>
              {capitalize(saleType[index])}
            </Option>
          ))}
        </Select>
      </Item>
      {saleTypeField !== "sale" && (
        <>
          <Item
            label={t("newAuctions.startPriceLabelTitle")}
            style={{ marginBottom: 0 }}
          >
            <Input.Group compact style={{ display: "flex" }}>
              <Item>
                <Input
                  readOnly={true}
                  disabled={hasBids}
                  defaultValue={
                    auctionInformationValue?.startingPrice?.currency?.code ??
                    "SAR"
                  }
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="startPrice"
                rules={[{ required: true, message: "Start price is required" }]}
                initialValue={auctionInformationValue?.startingPrice?.amount}
              >
                <InputNumber
                  disabled={hasBids || saleTypeField === "sale"}
                  formatter={value => formatNumber(value)}
                />
              </Item>
            </Input.Group>
          </Item>

          <Item
            label={t("currentAuctions.reservePriceLabel")}
            style={{ marginBottom: 0 }}
          >
            <Input.Group compact style={{ display: "flex" }}>
              <Item>
                <Input
                  readOnly={true}
                  disabled={hasBids}
                  defaultValue={
                    auctionInformationValue?.reservedPrice?.currency?.code ??
                    "SAR"
                  }
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="reservedPrice"
                rules={[
                  {
                    required: true,
                    message: "Reserve price is required"
                  }
                ]}
                initialValue={auctionInformationValue?.reservedPrice?.amount}
              >
                <InputNumber
                  formatter={value => formatNumber(value)}
                  disabled={saleTypeField === "sale"}
                />
              </Item>
            </Input.Group>
          </Item>
          <Item
            label="Minimum Bid Increment Amount:"
            style={{ marginBottom: 0 }}
          >
            <Input.Group compact style={{ display: "flex" }}>
              <Item>
                <Input
                  readOnly={true}
                  defaultValue={
                    auctionInformationValue?.minimumBidAmount?.currency?.code ??
                    "SAR"
                  }
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="minimumBidAmount"
                rules={[
                  {
                    required: true,
                    message: "Minimum Bid Increment amount is required"
                  }
                ]}
                initialValue={auctionInformationValue?.minimumBidAmount?.amount}
              >
                <InputNumber formatter={value => formatNumber(value)} />
              </Item>
            </Input.Group>
          </Item>
        </>
      )}

      <Item
        label={t("newAuctions.buyNowLabelTitle")}
        style={{ marginBottom: 0 }}
      >
        <Input.Group compact style={{ display: "flex" }}>
          <Item>
            <Input
              readOnly={true}
              disabled={
                (!auctionInformationValue?.vehiclePrice?.amount &&
                  !buyNowEnabled) ||
                !buyNowEnabled
              }
              defaultValue={
                auctionInformationValue?.vehiclePrice?.currency?.code ?? "SAR"
              }
              style={{ maxWidth: "50px", marginBottom: 0 }}
            />
          </Item>
          <Item
            style={{ flexGrow: 1, marginBottom: 0 }}
            name="vehiclePrice"
            initialValue={auctionInformationValue?.vehiclePrice?.amount}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const startPrice: number = getFieldValue("startPrice");
                  const tenPercent: number = startPrice / 10;
                  const minPrice: number = startPrice + tenPercent;
                  if (saleTypeField === "sale") {
                    return Promise.resolve();
                  }
                  if (!value || value > minPrice) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("newAuctions.buyNowValidation"))
                  );
                }
              })
            ]}
          >
            <InputNumber
              disabled={
                (!auctionInformationValue?.vehiclePrice?.amount &&
                  !buyNowEnabled) ||
                !buyNowEnabled
              }
              formatter={value => formatNumber(value)}
            />
          </Item>
        </Input.Group>
      </Item>
      {downPaymentEnable && (
        <Item label="Down Payment Amount:" style={{ marginBottom: 0 }}>
          <Input.Group compact style={{ display: "flex" }}>
            <Item>
              <Input
                readOnly={true}
                defaultValue={"SAR"}
                style={{ maxWidth: "50px" }}
              />
            </Item>
            <Item
              style={{ flexGrow: 1 }}
              name="downPayment"
              rules={[
                {
                  required: downPaymentEnable === true,
                  message: "Down payment amount is required"
                }
              ]}
              initialValue={auctionInformationValue?.depositAmount?.amount}
            >
              <InputNumber formatter={value => formatNumber(value)} />
            </Item>
          </Input.Group>
        </Item>
      )}
      {saleTypeField === "sale" && (
        <Item name="canAcceptOffers" initialValue={canOfferEnabled}>
          <Checkbox
            value={canOfferEnabled}
            checked={canOfferEnabled}
            onChange={() => toggleCanOffer()}
          >
            Accept Offers
          </Checkbox>
        </Item>
      )}
      <Item name="vehicleAvailableForSale" initialValue={buyNowEnabled}>
        <Checkbox
          value={buyNowEnabled}
          checked={buyNowEnabled}
          onChange={() => toggleBuyNow()}
          disabled={saleTypeField === "sale"}
        >
          {t("newAuctions.disableBuyNowLabel")}
        </Checkbox>
      </Item>
      <Item name="downPaymentEnable" initialValue={downPaymentEnable}>
        <Checkbox
          value={downPaymentEnable}
          checked={downPaymentEnable}
          onChange={() => toggleDownPayment()}
        >
          Enable Down Payment Price
        </Checkbox>
      </Item>
      <Item name="featured" initialValue={featuredEnabled}>
        <Checkbox
          value={featuredEnabled}
          checked={featuredEnabled}
          onChange={() => togglefeatured()}
        >
          Featured
        </Checkbox>
      </Item>
      <Item name="comingSoon" initialValue={upcomingEnabled}>
        <Checkbox
          value={upcomingEnabled}
          checked={upcomingEnabled}
          onChange={() => toggleUpcoming()}
        >
          Coming Soon Carousel
        </Checkbox>
      </Item>
      {upcomingEnabled && (
        <>
          <Item
            name="upcomingDate"
            label="Upcoming Date:"
            rules={[
              {
                required: upcomingEnabled === true,
                message: "Upcoming date is required!"
              }
            ]}
            initialValue={
              auctionInformationValue?.comingSoon &&
              auctionInformationValue?.comingSoonStart
                ? moment(auctionInformationValue?.comingSoonStart)
                : ""
            }
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              style={{ width: 300 }}
              format={"YYYY-MM-DD HH:mm:ss"}
              disabledDate={disabledDate}
            />
          </Item>
          <Item
            name="completionDate"
            label="Completion Date:"
            rules={[
              {
                required: upcomingEnabled === true,
                message: "Completion date is required!"
              },
              {
                validator: validateCompletionDate
              }
            ]}
            initialValue={
              auctionInformationValue?.comingSoon &&
              auctionInformationValue?.comingSoonEnd
                ? moment(auctionInformationValue?.comingSoonEnd)
                : ""
            }
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              style={{ width: 300 }}
              format={"YYYY-MM-DD HH:mm:ss"}
              disabledDate={disabledDate}
            />
          </Item>
        </>
      )}
      <Item
        name="startDate"
        label="Lot Start Date:"
        rules={[
          {
            required: true,
            message: !relisted
              ? "Lot Start date is required!"
              : "Lot Start date must be a future date."
          },
          {
            validator: validateStartDate
          }
        ]}
        initialValue={
          auctionInformationValue?.startAt !== null &&
          moment.utc(auctionInformationValue?.startAt)
            ? moment.utc(auctionInformationValue?.startAt)
            : ""
        }
      >
        <DatePicker
          showTime={{ format: "HH:mm" }}
          style={{ width: 300 }}
          format={"YYYY-MM-DD HH:mm:ss"}
          disabledDate={disabledDate}
        />
      </Item>
      <Item
        name="endDate"
        label="Lot End Date:"
        rules={[
          {
            required: true,
            message: !relisted
              ? "Lot End date is required!"
              : "Lot End date must be a future date."
          },
          {
            validator: validateEndDate
          }
        ]}
        initialValue={
          auctionInformationValue?.endAt !== null &&
          moment.utc(auctionInformationValue?.endAt)
            ? moment.utc(auctionInformationValue?.endAt)
            : ""
        }
      >
        <DatePicker
          // disabled={isSuperAdmin && isActive}
          showTime={{ format: "HH:mm" }}
          style={{ width: 300 }}
          format={"YYYY-MM-DD HH:mm:ss"}
          disabledDate={disabledDate}
        />
      </Item>
      <Checkbox checked={hasLatLong} disabled={hasLatLong}>
        {t("newAuctions.sellerPickupText")}
      </Checkbox>
      {hasLatLong && (
        <div>
          <Item
            name="addOns[delivery][coordinates][latitude]"
            label={t("newAuctions.latitudeLabelTitle")}
            initialValue={latCord}
          >
            <Input disabled={false} />
          </Item>
          <Item
            name="addOns[delivery][coordinates][longitude]"
            label={t("newAuctions.longitudeLabelTitle")}
            initialValue={longCord}
          >
            <Input disabled={false} />
          </Item>
          {updateGeoLocation && (
            <Item>
              <Button onClick={handleGeoCall} type="primary">
                Update geo Location
              </Button>
            </Item>
          )}
          <Item label={"Geo Location:"} style={{ marginBottom: 0 }}>
            {typeof geoLocations != "undefined" &&
              geoLocations.map((item, index) => (
                <Input.Group compact style={{ display: "flex" }}>
                  <Item>
                    <Input
                      readOnly={true}
                      disabled={true}
                      defaultValue={item.lang}
                      style={{ maxWidth: "50px" }}
                    />
                  </Item>
                  <Item
                    style={{ flexGrow: 1 }}
                    name={`geoLocation[${index}][text]`}
                    initialValue={item.text}
                  >
                    <Input
                      defaultValue={item.text}
                      onChange={e => {
                        GeoLocationOnChange(
                          index,
                          e.target.value,
                          geoLocations
                        );
                      }}
                    />
                  </Item>
                </Input.Group>
              ))}
            {geoLocations && (
              <Item
                name="geoLocation"
                initialValue={JSON.stringify(geoLocations)}
                noStyle={true}
              >
                <Input hidden={true} />
              </Item>
            )}
          </Item>
        </div>
      )}
      <Item
        name="addOns[delivery][capacity]"
        style={{ marginTop: "20px" }}
        label={t("newAuctions.capacityTitle")}
        initialValue={auctionInformationValue?.addOns?.delivery?.capacity}
      >
        <Input disabled={true} />
      </Item>
      <Item
        name="seller[favouriteBankId]"
        style={{ marginTop: "20px" }}
        label={t("newAuctions.sellerBankLabelTitle")}
        initialValue={auctionInformationValue?.seller?.favouriteBank?.name}
      >
        <Input disabled={true} />
      </Item>
    </div>
  );
};
