import React, { memo, useEffect, useState } from "react";
import { Button, Checkbox, Input, Modal, Select } from "antd";
import Form from "antd/lib/form";

import styles from "../VehicleSearch/VehicleSearch.module.scss";
import { getVehicleMake } from "app/api/auctionVehiclesApi";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";

interface TrimModalProps {
  isTrimModalVisible?: boolean;
  isEditable?: boolean;
  hideTrimModal?: () => void;
  handleTrimAdd?: (values: any) => void;
  handleTrimEdit?: (values: any) => void;
  handleTrimDelete?: () => void;
  selectedTrim?: any;
  selectedModelId?: string | null;
}

const { Item } = Form;
const { Option } = Select;

const TrimModal = ({
  isTrimModalVisible,
  isEditable,
  hideTrimModal,
  handleTrimAdd,
  handleTrimEdit,
  handleTrimDelete,
  selectedTrim,
  selectedModelId
}: TrimModalProps) => {
  let [form] = Form.useForm();

  const [checkboxChecked, setCheckboxChecked] = useState<boolean>();
  const [loadingModels, setLoadingModels] = useState<boolean>(true);
  const vehicleMakes: [
    { id: string; name: string }
  ] = getVehicleConfigsFromSessionStorage()?.filterOptions?.vehicleMakes;

  useEffect(() => {
    if (isEditable && isTrimModalVisible && selectedTrim !== null) {
      form.setFieldsValue({ enabled: selectedTrim?.enabled });
      // set checkbox to checked if the selected trim is deactivated
      setCheckboxChecked(!selectedTrim?.enabled);
    }
  }, [form, isEditable, isTrimModalVisible, selectedTrim]);

  const handleCheckboxChange = event => {
    setCheckboxChecked(event.target.checked);
    form.setFieldsValue({ enabled: !event.target.checked });
  };

  const [vehicleModels, setVehicleModels] = useState([]);

  useEffect(() => {
    if (
      !isEditable &&
      isTrimModalVisible &&
      vehicleMakes &&
      vehicleMakes.length > 0
    ) {
      form?.setFieldsValue({ make: vehicleMakes[0].id });
      getModelsForMake(Number(vehicleMakes[0].id));
      if (selectedModelId) {
        form?.setFieldsValue({
          vehicleModelId: selectedModelId
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isEditable, isTrimModalVisible]);

  useEffect(() => {
    if (isEditable && isTrimModalVisible) {
      getModelsForMake(selectedTrim.vehicleModel.vehicleMake.id);
      form?.setFieldsValue({
        make: selectedTrim.vehicleModel.vehicleMake.id,
        name: selectedTrim.name
      });
      if (selectedModelId) {
        form?.setFieldsValue({
          vehicleModelId: selectedModelId
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isEditable, isTrimModalVisible]);

  const handleMakeChange = value => {
    form.setFieldsValue({ vehicleModelId: null });
    // return the models for the make as well
    return getModelsForMake(value);
  };

  const getModelsForMake = (id: number) => {
    setLoadingModels(true);
    getVehicleMake(id)
      .then(payload => {
        setLoadingModels(false);
        setVehicleModels(payload.vehicleModels);
        if (selectedTrim)
          form.setFieldsValue({ vehicleModelId: selectedTrim.vehicleModel.id });
      })
      .catch(error => console.log("Something went wrong", error))
      .finally(() => setLoadingModels(false));
  };

  return (
    <Modal
      title={isEditable ? "Edit trim" : "Add trim"}
      visible={isTrimModalVisible}
      onCancel={hideTrimModal}
      footer={
        isEditable ? (
          <div className={styles.editModalFooter}>
            <Button danger onClick={handleTrimDelete}>
              Delete
            </Button>
            <div>
              <Button type="default" onClick={hideTrimModal}>
                Cancel
              </Button>
              <Button type="primary" form="editTrim" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Button type="default" onClick={hideTrimModal}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary" form="addTrim">
              Add
            </Button>
          </div>
        )
      }
    >
      {isEditable ? (
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          name="editTrim"
          onFinish={handleTrimEdit}
          form={form}
        >
          <Item name="make" label="Make" required={true}>
            <Select placeholder={"Choose make"} onChange={handleMakeChange}>
              {vehicleMakes && vehicleMakes.length > 0
                ? vehicleMakes.map(make => (
                    <Option key={make.id} value={make.id}>
                      {make.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="vehicleModelId" label="Model" required={true}>
            <Select
              placeholder={loadingModels ? "Loading..." : "Choose model"}
              loading={loadingModels}
            >
              {vehicleModels && vehicleModels.length > 0
                ? vehicleModels.map((model: any) => (
                    <Option key={model.id} value={model.id}>
                      {model.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="name" label="Trim name" required={true}>
            <Input placeholder="Trim name" />
          </Item>
          <Item name="enabled" wrapperCol={{ offset: 8 }}>
            <Checkbox checked={checkboxChecked} onChange={handleCheckboxChange}>
              Deactivate (Disable future auctions for this trim.)
            </Checkbox>
          </Item>
        </Form>
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          name="addTrim"
          onFinish={handleTrimAdd}
          form={form}
        >
          <Item name="make" label="Make" required={true}>
            <Select placeholder={"Choose make"} onChange={handleMakeChange}>
              {vehicleMakes && vehicleMakes.length > 0
                ? vehicleMakes.map(make => (
                    <Option key={make.id} value={make.id}>
                      {make.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="vehicleModelId" label="Model" required={true}>
            <Select placeholder={"Choose model"}>
              {vehicleModels && vehicleModels.length > 0
                ? vehicleModels.map((model: any) => (
                    <Option key={model.id} value={model.id}>
                      {model.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="name" label="Trim name" required={true}>
            <Input placeholder="Trim name" />
          </Item>
        </Form>
      )}
    </Modal>
  );
};

export default memo(TrimModal);
