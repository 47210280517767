import React from "react";
import { Button, Divider, Modal, message } from "antd";
import styles from "./PriceBreakdownModal.module.scss";
import { formatNumber } from "app/helpers/numberFormatHelper";
import { updateListing } from "app/api/listingsApi";

const PriceBreakdownModal = ({
  isVisible,
  handleClose,
  selectedListing,
  loadListings
}) => {
  // PriceItem component
  const PriceItem = ({ name, currencyCode, amount, keyName, isBold }) => {
    const containerClasses = `${styles.priceContainer} ${
      keyName === "commission" ? styles.mb : ""
    } ${["delivery", "vatOnCommissionFee"].includes(keyName) ? styles.bg : ""}`;
    const itemNameClasses = `${styles.itemName} ${
      isBold ? styles.boldText : ""
    } ${keyName === "vat" ? styles.isUppercase : ""}`;
    const asterisk = keyName === "commission" && "*";
    const dash = ["delivery", "vatOnCommissionFee"].includes(keyName) && "-  ";

    return (
      <div className={containerClasses}>
        <div className={itemNameClasses}>
          {asterisk}
          {dash}
          {keyName === "total_price" ? "Total Payment to Seller" : name}
          {keyName === "vatOnCommissionFee" && "*"}
        </div>
        <div className={styles.amountContainer}>
          <span className={`${styles.code} ${isBold ? styles.boldText : ""}`}>
            {currencyCode}
          </span>
          <span className={`${styles.amount} ${isBold ? styles.boldText : ""}`}>
            {formatNumber(amount)}
          </span>
        </div>
      </div>
    );
  };

  const renderPriceItems = items =>
    items?.map((item, index) => (
      <PriceItem
        key={index}
        name={item.name}
        currencyCode={item.value.currency.code}
        amount={item.value.amount}
        isBold={item.keyName === "total_price"}
        keyName={item.keyName}
      />
    ));

  const filteredItems = selectedListing?.buyerData?.priceBreakdown.filter(
    item => item.userType !== "buyer" && item.keyName !== "commission"
  );

  const commissionItems = selectedListing?.buyerData?.priceBreakdown.filter(
    item => item.keyName === "commission"
  );

  // BankDetails component
  const BankDetails = ({ label, value }) => (
    <div className={styles.bankContainer}>
      <span className={styles.itemName}>{label}</span>
      <span>{value}</span>
    </div>
  );

  const handleUpdateListing = async () => {
    try {
      const body = { paymentToSeller: true };
      await updateListing(selectedListing?.id, body);
      message.success(`Seller successfully Paid.`);
      handleClose();
      loadListings();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title="Price Breakdown"
      visible={isVisible}
      onCancel={handleClose}
      width={390}
      destroyOnClose
      footer={[
        <Button key="close" type="text" onClick={handleClose}>
          Cancel
        </Button>,
        <Button type="primary" onClick={handleUpdateListing}>
          Seller Paid
        </Button>
      ]}
      className={styles.priceModal}
    >
      <div>
        {renderPriceItems(filteredItems)}
        <Divider className={styles.antDivider} />
        {renderPriceItems(commissionItems)}

        <div className={styles.detailsWrapper}>
          <h3 className={styles.detailsTitle}>Bank Details</h3>
          <BankDetails
            label="Bank Name"
            value={selectedListing?.shopBankingDetails?.bankName}
          />
          <BankDetails
            label="Account holder name"
            value={selectedListing?.shopBankingDetails?.accountHolderName}
          />
          <BankDetails
            label="Account Number"
            value={selectedListing?.shopBankingDetails?.accountNumber}
          />
          <BankDetails
            label="iBan"
            value={selectedListing?.shopBankingDetails?.iban}
          />
          <BankDetails
            label="Swift Bic"
            value={selectedListing?.shopBankingDetails?.swiftBic}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PriceBreakdownModal;
