import React, { useState } from "react";
import CategoryCard from "../../Components/CategoryCard/Cards/CategoryCard";
import { PageHeader, Tabs } from "antd";
import styles from "./MainCategoryDetails.module.scss";
import CategoryCreateEditForm from "../../Components/CategoryCreateEditForm/CategoryCreateEditForm";
import SubcategoriesTable from "../../SubCategories/SubcategoriesTable/SubcategoriesTable";
import SubcategoryDetails from "../../SubCategories/SubcategoryDetails/SubcategoryDetails";
import CustomFieldsTable from "../../CustomFields/CustomFieldsTable";
import CustomFieldsDetails from "../../CustomFields/CustomFieldsDetails/CustomFieldsDetails";
import ChildOptionsDetails from "../../ListOptions/ChildOptionsDetails/ChildOptionsDetails";

const MainCategoryDetails = ({ selectedCategory, visible, onLoad }) => {
  const [activeTab, setActiveTab] = useState("general");
  const [isCreateSubCategory, setIsCreateSubCategory] = useState<boolean>(
    false
  );
  const [subCategoryDetails, setSubCategoryDetails] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [isFieldDetails, setIsFieldDetails] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [childFormVisible, setChildFormVisible] = useState(false);

  const baseRoutes = [
    {
      path: "manage-categories",
      breadcrumbName: "Manage Categories"
    }
  ];

  const subcategoriesRoute =
    activeTab === "subcategories"
      ? {
          path: "subcategories",
          breadcrumbName: "Subcategories"
        }
      : {
          path: "general",
          breadcrumbName: "General"
        };

  const routes = subcategoriesRoute
    ? [...baseRoutes, subcategoriesRoute]
    : baseRoutes;

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const toggleSubcategory = record => {
    setSubCategoryDetails(true);
    setSelectedSubCategory(record);
  };

  const toggleFieldDetails = record => {
    setIsFieldDetails(true);
    setSelectedField(record);
  };

  const toggleCreateSubCategory = record => {
    setIsCreateSubCategory(true);
    setSelectedSubCategory(undefined);
  };

  const handleToggleChildForm = (record, isEditing) => {
    setSelectedField(record);
    setChildFormVisible(isEditing);
  };

  const Header = () => (
    <PageHeader
      onBack={visible}
      title={selectedCategory?.name}
      className={styles.pageHeader}
      breadcrumb={{ routes, separator: ">" }}
    />
  );

  return (
    <>
      {!isCreateSubCategory &&
        !subCategoryDetails &&
        !isFieldDetails &&
        !childFormVisible && (
          <CategoryCard
            title={<Header />}
            cardContent={
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <Tabs.TabPane tab="General" key="general">
                  <CategoryCreateEditForm
                    selectedCategory={selectedCategory}
                    onLoad={onLoad}
                    visible={visible}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Subcategories" key="subcategories">
                  <SubcategoriesTable
                    onCreate={toggleCreateSubCategory}
                    selectedCategory={selectedCategory}
                    visible={toggleSubcategory}
                  />
                </Tabs.TabPane>
              </Tabs>
            }
          />
        )}

      {subCategoryDetails && (
        <SubcategoryDetails
          visible={() => setSubCategoryDetails(false)}
          selectedSubCategory={selectedSubCategory}
          selectedCategory={selectedSubCategory}
          onLoad={onLoad}
        />
      )}

      {isFieldDetails && (
        <CustomFieldsDetails
          visible={() => setIsFieldDetails(false)}
          selectedField={selectedField}
          selectedCategory={selectedCategory}
          onLoad={onLoad}
        />
      )}

      {childFormVisible && (
        <ChildOptionsDetails
          selectedField={selectedField}
          visible={handleToggleChildForm}
          selectedCategory={selectedCategory}
          onLoad={onLoad}
        />
      )}

      {activeTab === "general" && !isFieldDetails && !childFormVisible && (
        <CustomFieldsTable
          childVisible={handleToggleChildForm}
          fieldVisible={toggleFieldDetails}
          selectedCategory={selectedCategory}
        />
      )}

      {activeTab === "subcategories" && isCreateSubCategory && (
        <CategoryCard
          visible={() => setIsCreateSubCategory(false)}
          title={"Create new subcategory"}
          extra={true}
          cardContent={
            <CategoryCreateEditForm
              visible={() => setIsCreateSubCategory(false)}
              onLoad={onLoad}
              subcategory
              parentId={selectedCategory.id}
              selectedCategory={selectedSubCategory}
            />
          }
        />
      )}
    </>
  );
};

export default MainCategoryDetails;
