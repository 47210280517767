import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import moment from "moment";
import { Modal, Table, Form } from "antd";
import Column from "antd/lib/table/Column";
import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { userType } from "app/types/userType";
import { listUserDeposits } from "app/api/usersApi";
import styles from "./BalanceHistoryModal.module.scss";

const stateToRoute = {
  successful: "successful-orders",
  unsuccessful: "unsuccessful-orders",
  finalized: "finalized-orders",
  new: "auction-requests"
};

const dateFormat = "YYYY-MM-DD HH:mm:ss";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

interface BalanceHistoryModalProps {
  isVisible: boolean;
  selectedUser: userType;
  onClose: () => void;
}

const BalanceHistoryModal = ({
  isVisible,
  selectedUser,
  onClose
}: BalanceHistoryModalProps) => {
  let [form] = Form.useForm();

  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  useEffect(() => {
    handleListUserDeposits(1);
  }, []);

  const handleListUserDeposits = page => {
    listUserDeposits(selectedUser.id, qs.parse(location.search), page)
      .then(payload => {
        const {
          data: deposits,
          meta: { current_page, per_page, total }
        } = payload;
        setData(deposits);
        setPagination({
          ...pagination,
          current: current_page,
          pageSize: per_page,
          total
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    handleListUserDeposits(newPagination.current);
  };

  return (
    <Modal
      title={t("users.balanceHistoryTitle")}
      visible={isVisible}
      onCancel={handleClose}
      width={650}
      destroyOnClose
      footer={null}
    >
      <div className={styles.modalContainer}>
        <Table
          dataSource={data}
          loading={loading}
          rowKey={obj => obj.id.toString()}
          onChange={pagination => handleTableChanges(pagination)}
          pagination={pagination}
        >
          <Column
            key="auctionVehicle"
            title={t("Order No.")}
            render={text =>
              text.listing?.id ? (
                <a
                  target="_blank"
                  href={`${stateToRoute[text.listing?.type] ??
                    "auction-requests"}/edit/${text.listing?.id}`}
                >
                  {text.listing?.id}
                </a>
              ) : (
                <span>N/A</span>
              )
            }
          />
          <Column
            key="type"
            title={t("users.balanceTypeLabel")}
            render={record => record.type}
          />
          <Column
            key="movementTitle"
            title={t("users.movementLabel")}
            render={record =>
              Math.sign(record.deposit.amount) >= 0
                ? `+${Math.abs(record.deposit.amount)}`
                : `-${Math.abs(record.deposit.amount)}`
            }
          />
          <Column
            key="balanceTitle"
            title={t("users.balanceLabel")}
            render={record => record.balance?.amount}
          />
          <Column
            key="createdAt"
            title={t("users.timeLabel")}
            dataIndex="createdAt"
            render={date => moment(date).format(dateFormat)}
          />
          <Column
            key="assignedBy"
            title={t("users.assignedByLabel")}
            render={record => record.assignedBy}
          />
        </Table>
      </div>
    </Modal>
  );
};

export default BalanceHistoryModal;
