import React, { useEffect, useState } from "react";
import CategoryCard from "../../Components/CategoryCard/Cards/CategoryCard";
import styles from "./SubcategoryDetails.module.scss";

import { Button, Card, Modal, PageHeader, Table, Tag, message } from "antd";
import CategoryCreateEditForm from "../../Components/CategoryCreateEditForm/CategoryCreateEditForm";
import { getInputFields, reorderFields } from "app/api/dynamicCategoryApi";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { MenuOutlined } from "@ant-design/icons";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import CustomFieldsModal from "../../CustomFields/CustomFieldsModal/CustomFieldsModal";
import CustomFieldsDetails from "../../CustomFields/CustomFieldsDetails/CustomFieldsDetails";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const SubcategoryDetails = ({
  visible,
  selectedSubCategory,
  selectedCategory,
  onLoad
}) => {
  const [selectedField, setSelectedField] = useState();
  const [isCreateFieldsVisible, setIsCreateFieldsVisible] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [fields, setFields] = useState<any>([]);
  const [listFieldVisible, setListFieldVisible] = useState(false);

  useEffect(() => {
    loadCustomFields();
  }, []);

  const loadCustomFields = async () => {
    try {
      setLoading(true);
      const payload = await getInputFields(
        selectedSubCategory.id,
        qs.parse(location.search)
      );
      setFields(payload);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const routes = [
    {
      path: "manage-categories",
      breadcrumbName: "Manage Categories"
    },
    {
      path: "subcategories",
      breadcrumbName: "Subcategories"
    },
    {
      path: "subcategory-details",
      breadcrumbName: "Subcategory Details"
    }
  ];

  const DragHandle = () => <MenuOutlined className={styles.dragVisible} />;
  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      render: () => <DragHandle />
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Required",
      dataIndex: "isOptional",
      key: "isOptional",
      render: record => (
        <Tag
          color={record ? "red" : "blue"}
          key={record ? "required" : "optional"}
        >
          {record ? "required" : "optional"}
        </Tag>
      )
    },
    {
      title: "inputType",
      dataIndex: "inputType",
      key: "inputType",
      render: item => <>{item.name}</>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          {record.origin === "parent" ? (
            <>
              <Button
                onClick={() => handleEnableListing(record)}
                type="link"
                danger={record.enabled}
              >
                {record.enabled ? "Disable" : "Enable"}
              </Button>
            </>
          ) : (
            <>
              <Button
                type="link"
                onClick={
                  record?.inputType?.name === "List"
                    ? () => handleToggleField(record, true)
                    : () => toggleField(record)
                }
              >
                Edit
              </Button>
              <Button
                danger
                type="link"
                onClick={() => handleDeleteField(record)}
              >
                Delete
              </Button>
            </>
          )}
        </>
      )
    }
  ];

  const Header = () => (
    <PageHeader
      className={styles.pageHeader}
      onBack={visible}
      title={selectedSubCategory?.name}
      breadcrumb={{ routes, separator: ">" }}
    />
  );

  const SubHeader = () => (
    <div className={styles.categoryOverview}>
      <h1>Subcategory Fields</h1>
      <Button type="primary" onClick={() => toggleField(null)}>
        Add Field
      </Button>
    </div>
  );

  const handleDeleteField = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this field?",
      content: `You’re about to delete  ${record.name}`,
      okText: "Yes, delete field",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteField(selectedCategory.id, record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Subcategory field deleted");
              loadCustomFields();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const toggleField = record => {
    setSelectedField(record);
    setIsCreateFieldsVisible(true);
  };

  const handleToggleField = (record, isEditing) => {
    setSelectedField(record);
    setListFieldVisible(isEditing);
  };

  const handleEnableListing = async record => {
    if (!record) {
      return;
    }

    const body = {
      enabled: record.enabled ? 0 : 1
    };

    try {
      const response = await dynamicCategoriesApi.enableParentField(
        selectedSubCategory.id,
        body,
        record.id
      );

      if (response.status === 200) {
        message.success("Field updated");
        loadCustomFields();
      } else {
        message.error("Something went wrong, please try again.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    try {
      const items = [...fields];

      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setFields(items);

      const newData = items.map((item: any) => item.id);

      if (!newData.length) return;

      const body = { customFieldsOrder: newData };

      const response = await reorderFields(body, selectedCategory.id);

      if (response.status === 200) {
        loadCustomFields();
        message.success("Fields Reordered");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DraggableContainer = (props: any) => (
    <Droppable droppableId="fieldsData" {...props}>
      {provided => (
        <tbody
          className={styles.draggableContainer}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.children}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { "data-row-key": dataRowKey } = restProps;
    const index = fields.findIndex(x => x.id === Number(dataRowKey));
    return (
      <Draggable
        key={`${dataRowKey}`}
        draggableId={`${dataRowKey}`}
        index={index !== -1 ? index : 0}
      >
        {provided => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...restProps}
            className={fields?.length > 0 ? styles.draggableRow : ""}
          />
        )}
      </Draggable>
    );
  };

  return (
    <>
      {listFieldVisible && (
        <CustomFieldsDetails
          visible={handleToggleField}
          selectedField={selectedField}
          selectedCategory={selectedCategory}
          onLoad={() => loadCustomFields()}
        />
      )}

      {isCreateFieldsVisible && (
        <CustomFieldsModal
          selectedCategory={selectedCategory}
          selectedField={selectedField}
          onUpdate={() => loadCustomFields()}
          isVisible={isCreateFieldsVisible}
          onClose={() => setIsCreateFieldsVisible(false)}
          fieldId={undefined}
          isChild={false}
          fieldVisible={undefined}
        />
      )}

      {!listFieldVisible && (
        <>
          <CategoryCard
            title={<Header />}
            cardContent={
              <CategoryCreateEditForm
                selectedCategory={selectedSubCategory}
                onLoad={onLoad}
                visible={visible}
                subcategory
              />
            }
          />
          <Card className={styles.subCatWrapper}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Table
                rowKey={record => record.id}
                title={SubHeader}
                showHeader={false}
                dataSource={fields}
                columns={columns}
                loading={loading}
                pagination={false}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow
                  }
                }}
              />
            </DragDropContext>
          </Card>
        </>
      )}
    </>
  );
};

export default SubcategoryDetails;
