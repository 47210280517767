import React, { memo, useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import styles from "../VehicleSearch/VehicleSearch.module.scss";
import { vehicleModelType } from "app/types/vehicleModelType";
import { getVehicleCategories } from "app/api/auctionVehiclesApi";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";

interface ModelModalProps {
  isModelModalVisible?: boolean;
  isEditable?: boolean;
  hideModelModal?: () => void;
  handleModelAdd?: (values: any) => void;
  handleModelEdit?: (values: any) => void;
  handleModelDelete?: () => void;
  inspectionReportCategories?: [];
  selectedModel?: vehicleModelType;
  selectedMakeId?: string | null;
}

const { Item } = Form;
const { Option } = Select;

const ModelModal = ({
  isModelModalVisible,
  isEditable,
  hideModelModal,
  handleModelAdd,
  handleModelDelete,
  handleModelEdit,
  inspectionReportCategories,
  selectedModel,
  selectedMakeId
}: ModelModalProps) => {
  let [form] = Form.useForm();

  const [checkboxChecked, setCheckboxChecked] = useState<boolean>();
  const [categories, setCategories] = useState(inspectionReportCategories);
  const vehicleMakes: [
    { id: string; name: string }
  ] = getVehicleConfigsFromSessionStorage()?.filterOptions?.vehicleMakes;

  useEffect(() => {
    if (isEditable && isModelModalVisible && selectedModel !== null) {
      form.setFieldsValue({ enabled: selectedModel?.enabled });
      // set checkbox to checked if the selected model is deactivated
      setCheckboxChecked(!selectedModel?.enabled);
    }
    // Set the default value of addTrim to false
    if (!isEditable) {
      form.setFieldsValue({ addAnotherModel: false });
    }
  }, [form, isEditable, isModelModalVisible, selectedModel, vehicleMakes]);

  const handleCheckboxChange = event => {
    setCheckboxChecked(event.target.checked);
    form.setFieldsValue({ enabled: !event.target.checked });
  };

  const getInspectionReportCategories = name => {
    if (name.length < 3) {
      setCategories(inspectionReportCategories);
      return false;
    }
    getVehicleCategories({
      name
    })
      .then(payload => {
        setCategories(payload.data);
      })
      .catch(error => console.log("Something went wrong", error));
  };

  /**
   * When creating a new model only
   *
   */
  const handleModelSave = () => {
    form.setFieldsValue({ addAnotherModel: false });
    form.submit();
  };

  /**
   * When creating a new model and adding a trim straight afterwards
   *
   */
  const handleModelTrimSaveAdd = () => {
    form.setFieldsValue({ addAnotherModel: true });
    form.submit();
  };

  return (
    <Modal
      title={isEditable ? "Edit model" : "Add model"}
      visible={isModelModalVisible}
      onCancel={hideModelModal}
      footer={
        isEditable ? (
          <div className={styles.editModalFooter}>
            <Button danger onClick={handleModelDelete}>
              Delete
            </Button>
            <div>
              <Button type="default" onClick={hideModelModal}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" form="editModel">
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Button type="default" onClick={hideModelModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleModelSave}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              Add
            </Button>
            <Button
              type="primary"
              onClick={handleModelTrimSaveAdd}
              disabled={
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length
              }
            >
              + Add Another Model
            </Button>
          </div>
        )
      }
    >
      {isEditable ? (
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          name="editModel"
          form={form}
          onFinish={handleModelEdit}
        >
          <Item
            name="vehicleMakeId"
            initialValue={selectedModel?.vehicleMake?.id}
            label="Make"
            required={true}
          >
            <Select placeholder={"Choose make"}>
              {vehicleMakes
                ? vehicleMakes.map((make, index) => (
                    <Option key={index} value={make.id}>
                      {make.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item
            name="name"
            initialValue={selectedModel?.name}
            label="Model name"
            required={true}
            rules={[
              {
                required: true,
                message: "Model name is required"
              }
            ]}
          >
            <Input placeholder="Model name" />
          </Item>
          <Item
            name="categories"
            label="Inspection report type"
            initialValue={selectedModel?.categories?.map(category => {
              return {
                label: category.name,
                value: category.id
              };
            })}
            rules={[
              {
                type: "array"
              },
              {
                required: true,
                message: "Report type is required."
              }
            ]}
          >
            <Select
              labelInValue
              mode="multiple"
              placeholder={"Inspection report type"}
              options={categories?.map((c: any) => {
                return { label: c.name, value: c.id };
              })}
              filterOption={true}
              optionFilterProp="label"
              notFoundContent="Start typing to search ( 3 letters min)"
              showSearch
              onSearch={getInspectionReportCategories}
            >
              {categories
                ? categories.map((inspectionReportCategory: any, index) => (
                    <Option key={index} value={inspectionReportCategory.id}>
                      {inspectionReportCategory.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="enabled" wrapperCol={{ offset: 8 }}>
            <Checkbox checked={checkboxChecked} onChange={handleCheckboxChange}>
              Deactivate (Disable future auctions for this model.)
            </Checkbox>
          </Item>
        </Form>
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="horizontal"
          name="addModel"
          form={form}
          onFinish={handleModelAdd}
        >
          <Item
            name="vehicleMakeId"
            label="Make"
            initialValue={selectedMakeId}
            rules={[{ required: true }]}
          >
            <Select placeholder={"Choose make"}>
              {vehicleMakes
                ? vehicleMakes.map((make, index) => (
                    <Option key={index} value={make.id}>
                      {make.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="name" label="Model name" rules={[{ required: true }]}>
            <Input placeholder="Model name" />
          </Item>
          <Item
            name="categories"
            label="Inspection report type"
            rules={[
              {
                type: "array"
              },
              {
                required: true
              }
            ]}
          >
            <Select
              mode="multiple"
              placeholder={"Inspection report type"}
              options={categories?.map((c: any) => {
                return { label: c.name, value: c.id };
              })}
              filterOption={true}
              optionFilterProp="label"
              notFoundContent="Start typing to search ( 3 letters min)"
              showSearch
              onSearch={getInspectionReportCategories}
            >
              {categories
                ? categories.map((inspectionReportCategory: any, index) => (
                    <Option key={index} value={inspectionReportCategory.id}>
                      {inspectionReportCategory.name}
                    </Option>
                  ))
                : null}
            </Select>
          </Item>
          <Item name="addAnotherModel" hidden={true}>
            <Checkbox checked={false} />
          </Item>
        </Form>
      )}
    </Modal>
  );
};

export default memo(ModelModal);
