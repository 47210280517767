import React, { useState } from "react";
import { Modal, Upload, Button } from "antd";
import { FormInstance } from "antd/lib/form";
import SellerInformationPreview from "../AuctionPreviewFormItems/SellerInformation/SellerInformationPreview";
import VehicleInformationPreview from "../AuctionPreviewFormItems/VehicleInformation/VehicleInformationPreview";
import UploadDocumentsPreview from "../AuctionPreviewFormItems/UploadDocumentsInformation/UploadDocumentsPreview";
import AuctionInformationPreview from "../AuctionPreviewFormItems/AuctionInformation/AuctionInformationPreview";
import styles from "./PreviewAuctionModal.module.scss";
import { auctionType } from "app/types/auctionType";
import { Card } from "antd";
import PreviewItem from "../AuctionPreviewFormItems/components/PreviewItem";
import { useTranslation } from "react-i18next";
import moment from "moment";
import BuyerInformationPreview from "../AuctionPreviewFormItems/BuyerInformation/BuyerInformationPreview";
import { deleteVehicleImage } from "app/api/auctionVehiclesApi";
import { message } from "antd";

interface PreviewAuctionModalProps {
  isVisible: boolean;
  isSuperAdmin?: boolean;
  selectedActiveAuction: auctionType;
  onEdit?: () => void;
  onClose: (shouldUpdate: boolean) => void;
  isEnded?: boolean;
  buyerInformation?: auctionType;
}

interface FormItemsWithContextProps extends PreviewAuctionModalProps {
  form: FormInstance;
}

const PreviewAuctionModal = ({
  isVisible,
  isSuperAdmin,
  selectedActiveAuction,
  onEdit,
  onClose,
  isEnded,
  buyerInformation
}: FormItemsWithContextProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(shouldUpdateAuctions);
  };

  const [shouldUpdateAuctions, setShouldUpdateAuctions] = useState(false);
  const removeFile = e => {
    deleteVehicleImage(Number(e.id))
      .then(() => {
        setTimeout(message.success("Image deleted!"), 5000);
      })
      .catch(error => message.error("Something went wrong!"));
  };

  return (
    <Modal
      title={`Preview Auction - ${selectedActiveAuction.lot}`}
      visible={isVisible}
      destroyOnClose
      width={700}
      onCancel={handleClose}
      footer={
        <div className={styles.modalFooter}>
          <span className={styles.lastEdited}>
            Last edited by {selectedActiveAuction.updatedBy} at{" "}
            {moment(selectedActiveAuction.updatedAt).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </span>
          {isSuperAdmin ? (
            <Button onClick={onEdit}>{t("default.edit")}</Button>
          ) : null}
          <Button onClick={handleClose} type="primary">
            {t("default.ok")}
          </Button>
        </div>
      }
    >
      <div className={styles.previewModalContainer}>
        <div className={styles.leftContainer}>
          <h3>Images</h3>
          <div className={styles.previewImagesContainer}>
            {selectedActiveAuction.mediaPhotos &&
              selectedActiveAuction.mediaPhotos.length > 0 &&
              selectedActiveAuction.mediaPhotos.map((image, index) => {
                const fileList: any = [
                  { uid: index, status: "done", url: image.url, id: image.id }
                ];
                return (
                  <div key={index}>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onRemove={removeFile}
                    />
                  </div>
                );
              })}
          </div>
          <AuctionInformationPreview
            buyerInformation={buyerInformation}
            selectedActiveAuction={selectedActiveAuction}
            onUpdate={() => setShouldUpdateAuctions(true)}
          />
          {isEnded && (
            <BuyerInformationPreview buyerInformation={buyerInformation} />
          )}
        </div>
        <div className={styles.rightContainer}>
          <Card className={styles.sellerBuyerInfo}>
            <div className={styles.sellerBuyerItems}>
              <PreviewItem
                label={t("newAuctions.sellerBankLabelTitle")}
                value={
                  selectedActiveAuction.seller?.favouriteBank
                    ? selectedActiveAuction.seller?.favouriteBank?.name
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("currentAuctions.sellerIban")}
                value={
                  selectedActiveAuction.seller.iban !== null
                    ? selectedActiveAuction.seller.iban
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("currentAuctions.sellerPayText")}
                value={
                  selectedActiveAuction.sellerPrices
                    ? selectedActiveAuction.sellerPrices.total.amount +
                      " " +
                      selectedActiveAuction.sellerPrices.total.currency.code
                    : "N/A"
                }
              />
            </div>
          </Card>

          <SellerInformationPreview
            selectedActiveAuction={selectedActiveAuction}
          />
          <VehicleInformationPreview
            selectedActiveAuction={selectedActiveAuction}
          />
          <UploadDocumentsPreview
            selectedActiveAuction={selectedActiveAuction}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewAuctionModal;
