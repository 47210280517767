import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../AuctionFormItems.module.scss";
import PreviewItem from "../components/PreviewItem";
import { auctionType } from "app/types/auctionType";

interface SellerInformationPreviewProps {
  selectedActiveAuction: auctionType;
}

const SellerInformationPreview = ({
  selectedActiveAuction
}: SellerInformationPreviewProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.formPreviewItemsContainer}>
      <h3>{t("newAuctions.sellerInformationPreviewLabel")}</h3>

      <PreviewItem
        label={t("newAuctions.givenNameLabelTitle")}
        value={selectedActiveAuction.seller?.firstName}
      />

      <PreviewItem
        label={t("newAuctions.surNameLabelTitle")}
        value={selectedActiveAuction.seller?.lastName}
      />
      <PreviewItem
        label={t("newAuctions.addressLabelTitle")}
        value={selectedActiveAuction.seller?.address}
      />
      <PreviewItem
        label={t("newAuctions.cityLabelTitle")}
        value={selectedActiveAuction.seller?.city}
      />

      <PreviewItem
        label={t("newAuctions.regionLabelTitle")}
        value={selectedActiveAuction.seller?.state}
      />

      <PreviewItem
        label={t("newAuctions.emailLabelTitle")}
        value={selectedActiveAuction.seller?.email}
      />

      <PreviewItem
        label={t("newAuctions.areaCodeLabelTitle")}
        value={selectedActiveAuction.seller?.areaCode}
      />

      <PreviewItem
        label={t("newAuctions.phoneLabelTitle")}
        value={selectedActiveAuction.seller?.mobile}
      />
    </div>
  );
};

export default SellerInformationPreview;
