import React, { useEffect, useState } from "react";
import styles from "./AuctionEditCreate.module.scss";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
  TablePaginationConfig
} from "antd";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { QueryHelper } from "app/helpers/QueryHelper";
import { useTranslation } from "react-i18next";
import {
  createAuctioneer,
  getAuctionsAssigned,
  updateAuctioneer
} from "app/api/auctioneerApi";
import * as auctioneerApi from "app/api/auctioneerApi";
import { PaginationConfig } from "antd/lib/pagination";

interface AuctionEditCreateProps {
  isEditable?: boolean;
  isView?: boolean;
  isVisible: boolean;
  displayPlace?: string;
  adsEmpty?: boolean;
  onClose: () => void;
  onCreate: () => void;
  onUpdate: () => void;
  selectedAuctioneer: any;
}

const AuctionEditCreate = ({
  onClose,
  isVisible,
  isEditable,
  isView,
  selectedAuctioneer,
  onUpdate
}: AuctionEditCreateProps) => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<[]>();
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  let [form] = Form.useForm();
  let [searchForm] = Form.useForm();
  const { Item } = Form;

  useEffect(() => {
    loadAssignedAuctions(1);
  }, [location.search]);

  const loadAssignedAuctions = async page => {
    try {
      setLoading(true);
      const payload = await getAuctionsAssigned(
        selectedAuctioneer.id,
        qs.parse(location.search),
        page
      );
      setData(payload.data);
      setPagination(() => ({
        ...pagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    loadAssignedAuctions(newPagination.current);
  };

  const onFinish = values => {
    const body = new FormData();

    body.append("active", selectedAuctioneer.active ? "0" : "1");

    if (values.auctioneerName) {
      body.append("firstName", values.auctioneerName);
    }

    if (values.lastName) {
      body.append("lastName", values.lastName);
    }

    if (values.auctioneerEmail) {
      body.append("email", values.auctioneerEmail);
    }

    if (values.mobile) {
      body.append("mobile", values.mobile);
    }

    if (selectedAuctioneer?.id !== undefined) {
      updateAuctioneer(selectedAuctioneer?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            onUpdate();
            handleClose();
            message.success("Auctioneer updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createAuctioneer(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            onUpdate();
            handleClose();
            return message.success("Auctioneer created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const onDetach = (selectedAuctioneer, record) => {
    Modal.confirm({
      title: "Are you sure you want to detach this auction?",
      content: `You’re about to detach auction ${record.title}.`,
      okText: "Yes, detach auction",
      okType: "danger",
      onOk() {
        return auctioneerApi
          .detachAuctioneers(selectedAuctioneer.id, record.id)
          .then(p => {
            if (p.status === 200) {
              handleClose();
              onUpdate();
              message.success("Auction detached successfully");
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
            setLoading(false);
          });
      }
    });
  };

  const columns = [
    {
      title: "Auction ID",
      dataIndex: "id",
      key: "id",
      render: text => <a>{text}</a>
    },
    {
      title: "Auction Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <Button
          danger
          type="link"
          onClick={() => onDetach(selectedAuctioneer, record)}
        >
          Remove
        </Button>
      )
    }
  ];

  const updatedColumns = isEditable
    ? columns
    : columns.filter(col => col.key !== "action");

  return (
    <Modal
      title={
        isView
          ? "Auctioneer Profile"
          : isEditable
          ? "Edit Auctioneer"
          : "Add New Auctioneer"
      }
      visible={isVisible}
      onCancel={handleClose}
      width={580}
      footer={
        isView ? (
          <Button type="primary" onClick={handleClose}>
            Close
          </Button>
        ) : (
          <Button form="createAuctioneerForm" type="primary" htmlType="submit">
            {isEditable ? "Update" : "Create"} Auctioneer Profile
          </Button>
        )
      }
      destroyOnClose
    >
      <Form
        name="createAuctioneerForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Item
          className={styles.inlineInput}
          name="auctioneerName"
          rules={[
            {
              required: true,
              message: "Please enter Auctioneer Name"
            }
          ]}
          label={"Auctioneer Name:"}
          initialValue={selectedAuctioneer?.firstName}
        >
          <Input type="text" />
        </Item>
        <Item
          className={`${styles.inlineInput} ${styles.margin}`}
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter Auctioneer Surname"
            }
          ]}
          label={"Auctioneer Surname:"}
          initialValue={selectedAuctioneer?.lastName}
        >
          <Input type="text" />
        </Item>
        <Item
          name="auctioneerEmail"
          rules={[
            {
              required: true,
              message: "Please input your E-mail!"
            },
            {
              type: "email",
              message: "This is not a valid e-mail address"
            }
          ]}
          label={"Auctioneer Email:"}
          initialValue={selectedAuctioneer?.email}
        >
          <Input />
        </Item>
        <Item
          name="mobile"
          rules={[
            {
              required: true,
              message: "Please enter Auctioneer Mobile"
            }
          ]}
          label={"Auctioneer mobile:"}
          initialValue={selectedAuctioneer?.mobile}
        >
          <Input type="text" />
        </Item>
      </Form>
      {isView || isEditable ? (
        <div>
          <h1 className="ant-modal-title">Live Auctions</h1>
          <Divider />
          <Form
            name="auctionSearch"
            onFinish={(values: any) => handleSearch(values)}
            form={searchForm}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col>
                <Item name="auctionName" label="Auction Name:">
                  <Input type="text" placeholder="Auctioneer Name" />
                </Item>
              </Col>
              <Col>
                <Button
                  className={styles.searchButton}
                  type="primary"
                  htmlType="submit"
                  form="auctionSearch"
                >
                  {t("default.searchButton")}
                </Button>
                <Button
                  onClick={() => {
                    searchForm.resetFields();
                  }}
                >
                  {t("default.resetButton")}
                </Button>
              </Col>
            </Row>
          </Form>

          <Table
            size="small"
            dataSource={data}
            columns={updatedColumns}
            loading={loading}
            pagination={pagination}
            onChange={pagination => handleTableChanges(pagination)}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default AuctionEditCreate;
