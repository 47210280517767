import React, { memo, useState, useEffect } from "react";
import styles from "./FinalizedAuctionsScreen.module.scss";
import { Input, Row, Col, Button, DatePicker } from "antd";
import { Select, Checkbox } from "antd";
import Column from "antd/lib/table/Column";
import { Card, Form, Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import cx from "classnames";
import {
  getAuctionVehicle,
  getFinalizedTrades,
  getVehicleTypes
} from "app/api/auctionVehiclesApi";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { PaginationConfig } from "antd/lib/pagination";
import { QueryHelper } from "app/helpers/QueryHelper";
import { TablePaginationConfig } from "antd/lib/table";
import PreviewAuctionModal from "../components/PreviewAuctionModal/PreviewAuctionModal";
import { auctionType, vehicleType } from "app/types/auctionType";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { getVehicleConfigsFromSessionStorage } from "app/helpers/vehicleConfigsHelper";
import { vehicleConfigType } from "app/types/vehicleConfigType";

const FinalizedAuctionsScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const [data, setData] = useState<auctionType[]>([]);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedActiveAuction, setSelectedActiveAuction] = useState<
    auctionType
  >();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [buyerInformation, setBuyerInformation] = useState<auctionType>();
  const [isExpanded, setExpanded] = useState(false);
  const [expandedText] = useState<string>("Unfold");

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { RangePicker } = DatePicker;

  const [vehiclesMakeData, setVehiclesMakeData] = useState<
    [{ id: string; name: string }]
  >();
  const [vehicleTypesList, setVehicleTypesList] = useState<vehicleType[]>([]);
  const [form] = Form.useForm();
  const { Item } = Form;
  const { Option } = Select;
  const [featuredChecked, setFeaturedChecked] = useState<boolean>();
  const handleCheckboxChange = event => {
    setFeaturedChecked(event.target.checked);
  };

  const vehicleConfigs: vehicleConfigType = getVehicleConfigsFromSessionStorage();

  useEffect(() => {
    getVehicleTypes()
      .then(res => {
        setVehicleTypesList(res);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getFinalizedTrades(qs.parse(location.search))
      .then(payload => {
        setLoading(false);
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }, [location.search, pagination.current]);

  useEffect(() => {
    // getData();

    if (vehicleConfigs !== null) {
      setVehiclesMakeData(vehicleConfigs?.filterOptions?.vehicleMakes);
    }
  }, [location.search]);

  const handleModalCancel = () => {
    setIsVisible(false);
    setSelectedActiveAuction(undefined);
  };

  const togglePreviewAuctionModal = record => {
    getAuctionVehicle(record.id)
      .then(payload => {
        setBuyerInformation(payload);
      })
      .catch(error => {
        console.log(error);
      });
    setSelectedActiveAuction(record);
    setIsVisible(true);
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };
  const getAuctionVehicleTypeName = id => {
    if (id) {
      const listItem = vehicleTypesList.filter(x => x.id === id);
      if (listItem && listItem[0]?.name) {
        return listItem[0].name;
      } else {
        return "";
      }
    }
    return "";
  };
  return (
    <div>
      <div className={styles.tableContainer}>
        <Card className={styles.filterAndSearchContainer}>
          <Form onFinish={(values: any) => handleSearch(values)} form={form}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6}>
                <Item name="lot" label={t("currentAuctions.lotTitle")}>
                  <Input
                    type="number"
                    placeholder={t("currentAuctions.lotPlaceholder")}
                  />
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="sellerName"
                  label={t("currentAuctions.sellerTitle")}
                >
                  <Input placeholder={t("currentAuctions.sellerPlaceholder")} />
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="sellerEmail"
                  label={t("currentAuctions.emailTitle")}
                >
                  <Input
                    type="email"
                    placeholder={t("currentAuctions.emailPlaceholder")}
                  />
                </Item>
              </Col>
              <Col span={6}>
                <Button
                  className={styles.searchButton}
                  type="primary"
                  htmlType="submit"
                >
                  {t("default.searchButton")}
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                  }}
                >
                  {t("default.resetButton")}
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    setExpanded(!isExpanded);
                  }}
                >
                  {isExpanded ? <UpOutlined /> : <DownOutlined />}
                  {isExpanded ? expandedText : "Collapse"}
                </Button>
              </Col>
            </Row>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className={cx(styles.expanded, {
                [styles.isExpanded]: isExpanded
              })}
            >
              <Col span={6}>
                <Item name="phone" label={t("currentAuctions.phoneTitle")}>
                  <Input placeholder={t("currentAuctions.phonePlaceholder")} />
                </Item>
              </Col>
              <Col span={6}>
                <Item name="make" label={t("currentAuctions.makeTitle")}>
                  <Select
                    mode="multiple"
                    placeholder={t("currentAuctions.makePlaceholder")}
                  >
                    {vehiclesMakeData?.map((make, index) => (
                      <Option key={index} value={make.id}>
                        {make.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item name="modelName" label={t("currentAuctions.modelTitle")}>
                  <Input placeholder={t("currentAuctions.modelPlaceholder")} />
                </Item>
              </Col>
              <Col span={6}>
                <Item name="auctionVehicleTypeIds" label="Vehicle Type">
                  <Select mode="multiple" placeholder="e.g. Cars">
                    {vehicleTypesList?.map((type, index) => (
                      <Option key={index} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              {/* <Col span={6}>
                <Item name='featured' label='Featured'>
                  <Checkbox
                    value={featuredChecked ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </Item>
              </Col> */}
            </Row>
            <Row
              className={cx(styles.expanded, {
                [styles.isExpanded]: isExpanded
              })}
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col span={8}>
                <Item name="startToAndFrom" label={t("Auction Started")}>
                  <RangePicker showTime size="middle" />
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  name="endToAndFrom"
                  label={t("currentAuctions.endAtTitle")}
                >
                  <RangePicker showTime size="middle" />
                </Item>
              </Col>
              <Col span={8}>
                <Item name="featured" label="Featured">
                  <Checkbox
                    value={featuredChecked ? true : false}
                    onChange={handleCheckboxChange}
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <div className={styles.auctionsOverview}>
            <h1>{t("Finalized Items")}</h1>
          </div>

          <div className={styles.auctionsTable}>
            {!!selectedActiveAuction && !!isVisible && (
              <PreviewAuctionModal
                isVisible={isVisible}
                onClose={handleModalCancel}
                selectedActiveAuction={selectedActiveAuction}
                form={form}
                isEnded
                buyerInformation={buyerInformation}
              />
            )}
            <Table
              rowKey="id"
              dataSource={data}
              loading={loading}
              pagination={pagination}
              onChange={pagination => handleTableChanges(pagination)}
            >
              <Column
                key="lot"
                title={t("currentAuctions.lotTitle")}
                dataIndex="lot"
                render={(text, record, index) => (
                  <div
                    className={styles.lot}
                    onClick={() => togglePreviewAuctionModal(record)}
                  >
                    {text}
                  </div>
                )}
              />
              <Column
                key="auctiontype"
                title="Item Type"
                render={data =>
                  getAuctionVehicleTypeName(data.auctionVehicleTypeId)
                }
              />
              <Column
                key="make"
                title={t("currentAuctions.makeTitle")}
                render={data => data.vehicleModel?.vehicleMake.name}
              />
              <Column
                key="model"
                title={t("currentAuctions.modelTitle")}
                render={data => data.vehicleModel?.name}
              />
              <Column
                key="seller"
                title={t("currentAuctions.sellerTitle")}
                render={data => data.seller.firstName}
              />
              <Column
                key="email"
                title={t("finalizedTrades.emailTitle")}
                render={data => data.seller.email}
              />
              <Column
                key="handoverMethod"
                title={t("Finalized Auction Reason")}
                render={(data: auctionType) =>
                  data?.endAuctionReason !== null
                    ? data.endAuctionReason
                    : "No reason given"
                }
              />
              <Column
                key={"state"}
                title={t("State")}
                filters={[
                  {
                    text: "Successful",
                    value: "successful"
                  },
                  {
                    text: "Cancelled",
                    value: "cancelled"
                  }
                ]}
                onFilter={(value, record: any) => {
                  if (value === "successful") {
                    return record.state === "archived";
                  } else if (value === "cancelled") {
                    return record.state === "unsuccessful_archived";
                  } else {
                    return true;
                  }
                }}
                render={text => (
                  <div>
                    {text.state === "archived"
                      ? "successful"
                      : text.state === "unsuccessful_archived"
                      ? "Cancelled"
                      : null}
                  </div>
                )}
              />
              <Column
                key="startAt"
                title={"Created"}
                dataIndex="startAt"
                sorter={(a: any, b: any) =>
                  moment(a.startAt).unix() - moment(b.startAt).unix()
                }
                render={date => moment(date).format(dateFormat)}
              />
              <Column
                key="endAt"
                title={"Ended"}
                dataIndex="endAt"
                sorter={(a: any, b: any) =>
                  moment(a.endAt).unix() - moment(b.endAt).unix()
                }
                render={date => moment(date).format(dateFormat)}
              />
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(FinalizedAuctionsScreen);
