import React, { useState } from "react";
import { Modal, Button, Upload, Form, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadIbanDoc, ConfirmIbanDoc } from "app/api/usersApi";

import styles from "./UploadIBANsModal.module.scss";

const { Item } = Form;

interface AssignDepositModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const UploadIBANsModal = ({ isVisible, onClose }: AssignDepositModalProps) => {
  let [form] = Form.useForm();

  const [isDocumentUploaded, setIsDocumentUploaded] = useState<boolean>(false);

  const [errorsList, setErrorsList] = useState<any>([]);
  const [buttonText, setButtonText] = useState<string>("Upload");

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleModalCancel = () => {
    handleClose();
  };

  const uploadDoc = values => {
    setButtonText("Uploading...");
    const body = new FormData();

    if (values.ibanDoc) {
      body.append("file", values.ibanDoc?.fileList[0]?.originFileObj);
    }

    const isFormData = true;
    setErrorsList([]);
    uploadIbanDoc(body, isFormData)
      .then(resp => {
        console.log(resp);
        if (typeof resp.data.message != "undefined") {
          if (typeof resp.data.message == "string") {
            message.error(resp.data.message);
          }
          let errorsListArray: string[] = [];
          if (typeof resp.data.message == "object") {
            resp.data.message.forEach(errorObj => {
              errorObj.error.forEach(err => {
                const errMsg = `Row ${errorObj.row}: ${err}`;
                errorsListArray.push(errMsg);
              });
            });
          }
          setErrorsList(errorsListArray);
        } else {
          if (resp.data.recordsToImport === 0) {
            message.error("0 new records to import");
          } else {
            message.success(
              resp.data.recordsToImport + " Records found. Confirm Import."
            );
            setIsDocumentUploaded(true);
          }
        }
        setButtonText("Upload");
      })
      .catch(error => {
        setButtonText("Upload");
        message.error("Something went wrong. Try again!");
      });
  };
  const confirmDoc = values => {
    const body = new FormData();

    if (values.ibanDoc) {
      body.append("file", values.ibanDoc?.fileList[0]?.originFileObj);
    }
    const isFormData = true;
    ConfirmIbanDoc(body, isFormData)
      .then(resp => {
        if (resp.status === 200) {
          message.success("IBAN Document Uploaded");
          handleClose();
        }
      })
      .catch(error => {
        message.error("Something went wrong. Try again!");
      });
  };

  return (
    <Modal
      // TODO: Update translation
      title="Upload IBAN Document"
      visible={isVisible}
      onCancel={handleModalCancel}
      width={500}
      destroyOnClose
      footer={
        <div className={styles.modalFooter}>
          <Button form="assignDepositForm" htmlType="submit" type="primary">
            {/* TODO: Update translation */}
            {!isDocumentUploaded ? buttonText : "Confirm"}
          </Button>
        </div>
      }
    >
      <div className={styles.modalContainer}>
        <Form
          name="assignDepositForm"
          layout="vertical"
          form={form}
          onFinish={!isDocumentUploaded ? uploadDoc : confirmDoc}
        >
          <div className={styles.formItem}>
            <Item name="ibanDoc" required={true} className={styles.uploadField}>
              <Upload
                accept="application/xls"
                listType="picture"
                maxCount={1}
                onChange={({ file, fileList }) => setErrorsList([])}
              >
                <Button icon={<UploadOutlined />}>
                  {/* TODO: Update translation */}
                  IBAN Document
                </Button>
              </Upload>
            </Item>
            {errorsList.length > 0 && (
              <div className={styles.errorsFound}>
                {errorsList.length} Errors Found
              </div>
            )}
            <ul className={styles.errorList}>
              {errorsList.map(errorMsg => (
                <li>{errorMsg}</li>
              ))}
            </ul>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UploadIBANsModal;
