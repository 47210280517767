import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../AuctionFormItems.module.scss";
import PreviewItem from "../components/PreviewItem";
import { auctionType } from "app/types/auctionType";
import { formatNumber } from "app/helpers/numberFormatHelper";

interface VehicleInformationPreviewProps {
  selectedActiveAuction: auctionType;
}

const VehicleInformationPreview = ({
  selectedActiveAuction
}: VehicleInformationPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.formPreviewItemsContainer}>
      <h3>{t("newAuctions.vehicleInformationPreviewLabel")}</h3>
      <PreviewItem
        label="Featured"
        value={selectedActiveAuction?.featured ? "True" : "False"}
      />
      <PreviewItem
        label={t("newAuctions.makeLabelTitle")}
        value={selectedActiveAuction.vehicleModel?.vehicleMake?.name}
      />
      <PreviewItem
        label={t("newAuctions.modelLabelTitle")}
        value={selectedActiveAuction.vehicleModel?.name}
      />
      {/* <PreviewItem
        label={"Trim:"}
        value={selectedActiveAuction.vehicleTrim?.name}
      /> */}

      <PreviewItem
        label={t("newAuctions.yearLabelTitle")}
        value={selectedActiveAuction.vehicleYear}
      />

      <PreviewItem
        label={t("newAuctions.mileageLabelTitle")}
        value={formatNumber(selectedActiveAuction.mileage)}
      />

      <PreviewItem
        label={"Exterior Color:"}
        value={selectedActiveAuction.vehicleColor}
      />

      <PreviewItem
        label={"Interior Color:"}
        value={selectedActiveAuction.insideColor}
      />

      {/* <PreviewItem label={"Seats:"} value={selectedActiveAuction.seatsType} /> */}

      {/* <PreviewItem
        label={t("newAuctions.doorLabelTitle")}
        value={selectedActiveAuction.doors}
      /> */}

      {/* <PreviewItem
        label={'Horsepower:'}
        value={selectedActiveAuction.horsepower}
      /> */}

      <PreviewItem
        label={"Cylinders:"}
        value={selectedActiveAuction.cylinders}
      />

      <PreviewItem
        label={t("newAuctions.transmissionLabelTitle")}
        value={selectedActiveAuction.vehicleTransmission}
      />

      <PreviewItem
        label={"Fuel Type:"}
        value={selectedActiveAuction.fuelType}
      />

      <PreviewItem
        label={"Condition:"}
        value={selectedActiveAuction.vehicleCondition}
      />

      <PreviewItem
        label={"Seller Type:"}
        value={selectedActiveAuction.seller.type}
      />

      {/* <PreviewItem
        label={'Body Type:'}
        value={selectedActiveAuction.bodyType}
      /> */}

      <PreviewItem label={"VIN No:"} value={selectedActiveAuction.vin} />

      {/* <PreviewItem
        label={'Interior Condition:'}
        value={selectedActiveAuction.vehicleInteriorCondition}
      /> */}

      <PreviewItem
        label={"Saudi ID:"}
        value={selectedActiveAuction.seller.saudiId}
      />

      <PreviewItem
        label={"Corporate VAT No:"}
        value={selectedActiveAuction.seller.companyVatNo}
      />

      <PreviewItem
        label={"Commercial Registration:"}
        value={selectedActiveAuction.seller.commercialRegistration}
      />

      <PreviewItem
        label={t("newAuctions.descriptionLabelTitle")}
        value={selectedActiveAuction.description}
      />

      <PreviewItem
        label={t("newAuctions.arabicStepTitle")}
        value={selectedActiveAuction.descriptionLocalized?.ar}
      />
    </div>
  );
};

export default VehicleInformationPreview;
