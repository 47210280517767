import { Button, Form, Input, Select, Switch, message } from "antd";
import {
  createField,
  fetchFieldTypes,
  updateField
} from "app/api/dynamicCategoryApi";
import React, { useEffect, useState } from "react";
import styles from "./FieldsCreateEditForm.module.scss";

const { Item } = Form;

const FieldsCreateEditForm = ({
  selectedField,
  isChild,
  fieldId,
  selectedCategory,
  onUpdate,
  onClose,
  fieldVisible
}) => {
  let [form] = Form.useForm();
  const { Option } = Select;
  const [allFields, setAllFields] = useState<any>([]);
  const [fieldType, setFieldType] = useState<any>([]);

  const handleClose = () => {
    onClose();
    onUpdate();
    form.resetFields();
  };

  useEffect(() => {
    fetchFieldTypes()
      .then(payload => {
        setFieldType(payload);
      })
      .catch(error => console.log(error));
  }, [allFields]);

  const onFinish = values => {
    const body = new FormData();

    if (isChild && fieldId !== undefined) {
      body.append("parentId", fieldId);
    }

    if (isChild && values.filterByParent !== undefined) {
      body.append("filterByParent", values.filterByParent ? "1" : "0");
    }

    if (values.fieldName) {
      body.append("name", values.fieldName);
    }

    if (values.arabicTranslation) {
      body.append("nameAr", values.arabicTranslation);
    }

    if (values.fieldType) {
      body.append("listingTypeCustomFieldInputTypeId", values.fieldType);
    }

    if (values.requireField !== undefined) {
      body.append("isOptional", values.requireField ? "1" : "0");
    }

    if (selectedField?.id !== undefined) {
      updateField(selectedCategory?.id, body, selectedField?.id)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Field updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createField(selectedCategory?.id, body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            handleClose();
            return message.success("Field created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleFieldChange = (fieldName, value) => {
    form.setFieldsValue({
      [fieldName]: value
    });
  };

  const fieldTypeField = form.getFieldValue("fieldType");

  return (
    <Form
      name="createFieldsForm"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(changedFields, allFields) => {
        setAllFields(allFields);
      }}
    >
      <div>
        <Item
          className={styles.inlineInput}
          name="fieldName"
          label={"Field name:"}
          initialValue={selectedField?.nameLocalized?.en}
          rules={[
            {
              required: true,
              message: "Please enter field name."
            }
          ]}
        >
          <Input type="text" />
        </Item>
        <Item
          className={`${styles.inlineInput} ${styles.margin}`}
          name="arabicTranslation"
          label={"Arabic translation:"}
          initialValue={selectedField?.nameLocalized?.ar}
          rules={[
            {
              required: true,
              message: "Please enter Arabic translation."
            }
          ]}
        >
          <Input type="text" />
        </Item>
        <Item
          extra={
            fieldTypeField === 2
              ? "A String field can only contain 255 characters."
              : ""
          }
          name="fieldType"
          rules={[
            {
              required: true,
              message: "Please select a given (field) type."
            }
          ]}
          label={"Select field type:"}
          initialValue={selectedField?.inputType?.id}
        >
          <Select
            size="middle"
            onChange={e => {
              return form.validateFields();
            }}
          >
            {fieldType
              ?.filter(field => field?.id !== 9)
              .map(
                field =>
                  (!fieldId || field?.id === 4) && (
                    <Option key={field.id} value={field.id}>
                      {field.name}
                    </Option>
                  )
              )}
          </Select>
        </Item>
        <Item
          hidden={!isChild}
          name="filterByParent"
          initialValue={
            selectedField?.filterByParent === true
              ? selectedField?.filterByParent
              : false
          }
        >
          <div className={styles.requireField}>
            <div className={styles.requireLabel}>
              Filter By Parent:
              <p className="ant-form-item-extra">
                Filter By Parent refines results based on a specified parent
                field.
              </p>
            </div>
            <Switch
              defaultChecked={selectedField?.filterByParent}
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={value => handleFieldChange("filterByParent", value)}
            />
          </div>
        </Item>
        <Item
          hidden={fieldTypeField === 6 || fieldTypeField === 7}
          name="requireField"
          initialValue={
            selectedField?.isOptional === true
              ? selectedField?.isOptional
              : false
          }
        >
          <div className={styles.requireField}>
            <div className={styles.requireLabel}>
              Make this a required field:
              <p className="ant-form-item-extra">
                If a field is not required, it will be optional for the user to
                fill.
              </p>
            </div>
            <Switch
              defaultChecked={selectedField?.isOptional}
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={value => handleFieldChange("requireField", value)}
            />
          </div>
        </Item>
        {fieldVisible && (
          <Item>
            <Button form="createFieldsForm" type="primary" htmlType="submit">
              Edit Field
            </Button>
          </Item>
        )}
      </div>
    </Form>
  );
};

export default FieldsCreateEditForm;
