import React, { memo, useContext, useState } from "react";
import Form from "antd/lib/form";
import { Input, Button, message, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "app/api/authenticationApi";
import {
  setAccessToken,
  setUserInLocalStorage
} from "app/helpers/authenticationHelper";
import { UserContext, UserRoles } from "app/store/contexts/userContext";
import styles from "./Login.module.scss";
import { useTranslation } from "react-i18next";
import { PUBLIC_ROUTES } from "app/routers/Router.config";
import { setPermissions } from "app/components/Permission/Permission";
import { getVehicleConfigs } from "app/api/auctionVehiclesApi";
import { setVehicleConfigsInSessionStorage } from "app/helpers/vehicleConfigsHelper";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const fetchAndSetConfig = () => {
    getVehicleConfigs()
      .then(payload => {
        setVehicleConfigsInSessionStorage(payload);
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  const onFinish = values => {
    setLoading(true);
    login(values)
      .then(resp => {
        if (resp.status === 200) {
          fetchAndSetConfig();
          const user = resp.data.user;
          setPermissions([user.role.name]);
          setAccessToken(resp.data.token.accessToken);
          setLoading(false);
          setUserInLocalStorage(user);
          setUser({
            id: user.id,
            name: user.firstName,
            isSuperAdmin: user.role.name === UserRoles.SuperAdmin
          });
        }
      })
      .catch(() => {
        message.error("Your email or password is wrong. Please try again!");
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Spin />;
  }

  const { Item } = Form;

  return (
    <div>
      <Form name="login" layout="vertical" onFinish={onFinish}>
        <div className={styles.signInText}>{t("login.signInHeading")}</div>
        <Item
          name="email"
          label="Email"
          rules={[{ required: true, message: t("login.emailValidation") }]}
        >
          <Input prefix={<UserOutlined />} type="email" placeholder="Email" />
        </Item>
        <Item
          name="password"
          label="Password"
          rules={[{ required: true, message: t("login.passwordValidation") }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Item>
        <Item>
          <Button htmlType="submit" type="primary">
            {t("login.signIn")}
          </Button>
        </Item>
      </Form>
      <a href={PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path}>
        {t("login.forgotPassword")}
      </a>
    </div>
  );
};

export default memo(Login);
