// Auth helper for storing and getting token data from local storage
import jwt_decode from "jwt-decode";

const ACCESS_TOKEN = "access-token";
const REFRESH_TOKEN = "refresh-token";
const USER = "user";

export const setAccessToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN, token);
};

export const isAuthenticated = () => {
  const token = getAccessToken();
  if (!token) {
    return false;
  }
  const decoded: unknown | any = jwt_decode(token);
  return decoded.exp > Date.now() / 1000;
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setUserInLocalStorage = (user: any) => {
  return localStorage.setItem(USER, JSON.stringify(user));
};

export const getUserFromLocalStorage = () => {
  const user: any = localStorage.getItem(USER);
  return JSON.parse(user) || undefined;
};

export const clearUserFromLocalStorage = () => {
  return localStorage.removeItem(USER);
};

/**
 *
 * @param token refreshToken
 */
export const setRefreshToken = (token: string) => {
  return localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return refreshToken;
};

/**
 * Clear access-token from local storage
 */
export const clearAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN);
};
