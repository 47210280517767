import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get Page Widgets
 *
 * @param
 * @returns
 * @export
 */

export const getPageWidgets = (page: any) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}`;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * Get widgets
 *
 * @returns widgets data
 * @export
 */

export const getWidgets = () => {
  const endpoint = ENDPOINTS.WIDGETS;
  return api.get(endpoint).then(({ data }) => data);
};

/**
 * sort widgets
 */
export const sortWidgets = (body: any, page: any) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/sort`;

  return api.post(endpoint, body);
};

/**
 * create widgets
 * @param search
 */
export const createWidget = (page: string, widget: string, body: any) => {
  return api.post(ENDPOINTS.PAGE_LAYOUT + `/${page}/create/${widget}`, body);
};

/**
 * updates widgets
 * @param search
 */
export const updateWidget = (page: string, widgetId: any, body: any) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/update/${widgetId}`;

  return api.post(endpoint, body);
};

/**
 * updates widgets title
 * @param search
 */
export const updateWidgetTitle = (page: string, widgetId: any, body: any) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/update-title/${widgetId}`;

  return api.post(endpoint, body);
};

/**
 * delete widget
 * @param search
 */
export const deleteWidget = (page: string, id: string) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/delete/${id}`;
  return api.post(endpoint);
};

/**
 * create tabbed widgets
 * @param search
 */
export const createTabbedItem = (page: string, widgetId: number, body: any) => {
  return api.post(
    ENDPOINTS.PAGE_LAYOUT + `/${page}/add-item/${widgetId}`,
    body
  );
};

/**
 * updates tabbed widgets
 * @param search
 */
export const updateTabbedItem = (page: string, itemId: any, body: any) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/update-item/${itemId}`;

  return api.post(endpoint, body);
};

/**
 * delete tabbed widget
 * @param search
 */
export const deleteTabbedWidget = (page: string, id: string) => {
  const endpoint = ENDPOINTS.PAGE_LAYOUT + `/${page}/delete-item/${id}`;
  return api.post(endpoint);
};
