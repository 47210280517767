import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * get live auctions
 *
 * @returns
 * @export
 */

export const getLiveAuctions = (search: any, state: string) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/query?state=${state}`;
  return api
    .get(endpoint, {
      params: {
        ...search
      }
    })
    .then(resp => resp.data);
};
/**
 * create live auctions
 */
export const createLiveAuction = (body: any) => {
  return api.post(ENDPOINTS.LIVE_AUCTIONS + `/create`, body);
};
/**
 * update live auctions
 */
export const updateLiveAuction = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/update`;

  return api.post(endpoint, body);
};
/**
 * delete live auctions
 * @param search
 */
export const deleteLiveAuction = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}`;
  return api.delete(endpoint, body);
};
/**
 * approve live auctions
 */
export const approveLiveAuction = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/update`;
  return api.post(endpoint, body);
};

/**
 * create live auctions lots
 */
export const createLiveAuctionLot = (body: any) => {
  return api.post(ENDPOINTS.LIVE_AUCTIONS + `/lot/store`, body);
};
/**
 * update live auctions
 */
export const updateLiveAuctionLot = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/${id}/update`;

  return api.post(endpoint, body);
};
/**
 * get live auctions users
 *
 * @returns
 * @export
 */
export const getLiveAuctionsUsers = (id: string, search: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/users`;
  return api
    .get(endpoint, {
      params: {
        ...search
      }
    })
    .then(resp => resp.data);
};
/**
 * delete live auctions
 * @param search
 */
export const deleteLiveAuctionLot = (id: string, body?: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/${id}`;
  return api.delete(endpoint, body);
};
/**
 * update live auctions
 */
export const updateReorderedLot = (body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/order`;

  return api.post(endpoint, body);
};
/**
 * get live auctions users
 *
 * @returns
 * @export
 */
export const getLiveAuctionsDetails = (liveAuctionId: string) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${liveAuctionId}/show`;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * create live stream
 * @param search
 */
export const createLiveStream = (liveAuctionId: number) => {
  const endpoint =
    ENDPOINTS.LIVE_AUCTIONS + `/${liveAuctionId}/create-livestream`;
  return api.post(endpoint);
};
/**

/**
 * get auction details
 *
 * @returns
 * @export
 */

export const getAuctionDetails = (liveAuctionId: number) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${liveAuctionId}/show`;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * delete live streaming
 * @param search
 */
export const deleteLiveAuctionStream = (id: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/delete-livestream`;
  return api.delete(endpoint);
};
