import { MessageOutlined } from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
import ManageOTP from "app/screens/ManageOTP/ManageOTP";
import { IMenuItemType } from "app/types/routerType";

export const MANAGE_OTP_SCREEN: IMenuItemType = {
  id: "otp-requests",
  path: "/otp-requests",
  title: "OTP Requests",
  icon: MessageOutlined,
  component: ManageOTP,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};
