import {
  Button,
  Card,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Table,
  TablePaginationConfig
} from "antd";
import styles from "./ManageOTP.module.scss";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOTP } from "app/api/listingsApi";
import { useLocation, useHistory } from "react-router-dom";
import { PaginationConfig } from "antd/lib/pagination";
import { QueryHelper } from "app/helpers/QueryHelper";

interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
}

interface VerificationRecord {
  id: number;
  user_id: number;
  user: string;
  mobile: string;
  code: string;
  attempts: number;
  verified: boolean;
  created_at: string;
  updated_at: string;
  expires_at: string;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const ManageOTP = () => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<VerificationRecord[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION);

  useEffect(() => {
    loadOtp();
  }, [location.search]);

  const loadOtp = async () => {
    try {
      setLoading(true);
      const payload = await getOTP(qs.parse(location.search));
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
      render: text => <a>{text}</a>
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user"
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      key: "mobile"
    },
    {
      title: "OTP",
      dataIndex: "code",
      key: "code",
      render: text => <a>{text}</a>
    }
  ];

  const Header = () => (
    <PageHeader className={styles.pageHeader} title="OTP Requests" />
  );

  return (
    <div className={styles.tableContainer}>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <Item name="mobile" label={t("users.phoneTitle")}>
                <Input placeholder={t("users.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card>
        <Table
          title={Header}
          size="small"
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={pagination}
          onChange={pagination => handleTableChanges(pagination)}
        />
      </Card>
    </div>
  );
};

export default ManageOTP;
