import React from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import Form from "antd/lib/form";
import { UserOutlined } from "@ant-design/icons";

import { Avatar } from "antd";

import { userType } from "app/types/userType";

import styles from "./UserDetailsModal.module.scss";

interface UserDetailsModalProps {
  isVisible: boolean;
  selectedUser: userType;
  onClose: () => void;
}

const UserDetailsModal = ({
  isVisible,
  selectedUser,
  onClose,
}: UserDetailsModalProps) => {
  const { t } = useTranslation();

  let [form] = Form.useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleClose}
      width={400}
      destroyOnClose
      footer={null}
    >
      <div className={styles.modalContainer}>
        <Avatar size={100} icon={<UserOutlined />} />
        <span className={styles.name}>{`${
          selectedUser.firstName
        } ${selectedUser.lastName || ""}`}</span>
        <div className={styles.labelContainer}>
          <strong>{t("newAuctions.emailLabelTitle")}</strong>{" "}
          <span>{`${selectedUser.email}`}</span>
        </div>
        <div className={styles.labelContainer}>
          <strong>{t("newAuctions.phoneLabelTitle")}</strong>{" "}
          <span>{`${selectedUser.mobile}`}</span>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
