import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get Adverts
 * @param search
 * @returns
 * @export
 */

export const getAdverts = (search: any) => {
  const endpoint = ENDPOINTS.ADVERTISEMENTS;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * creates Advert
 * @param search
 */
export const createAdvert = (body: any) => {
  return api.post(ENDPOINTS.ADVERTISEMENTS, body);
};

/**
 * updates a user object
 * @param search
 */
export const updateAdvert = (id: string, body: any) => {
  const endpoint = ENDPOINTS.ADVERTISEMENTS + `/${id}`;

  return api.post(endpoint, body);
};

/**
 * delete Advert
 * @param search
 */
export const deleteAdvert = (id: string) => {
  const endpoint = ENDPOINTS.ADVERTISEMENTS + `/${id}`;
  return api.delete(endpoint);
};
