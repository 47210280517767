import { Button, Form, Input, Modal, Upload, message } from "antd";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import styles from "./CategoryCreateEditForm.module.scss";
import React, { useEffect, useState } from "react";
import { beforeUpload, getBase64 } from "app/helpers/imageUploadHelper";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";

const { Item } = Form;

interface CategoryCreateEditFormProps {
  selectedCategory?: any;
  parentId?: any;
  subcategory?: boolean;
  visible: () => void;
  onLoad: () => void;
}

const CategoryCreateEditForm = ({
  onLoad,
  visible,
  selectedCategory,
  subcategory,
  parentId
}: CategoryCreateEditFormProps) => {
  let [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinish = values => {
    const body = new FormData();

    if (parentId && subcategory) {
      body.append("parentId", parentId);
    }

    if (values.name) {
      body.append("name", values.name);
    }

    if (values.nameAr) {
      body.append("nameAr", values.nameAr);
    }

    if (values.description) {
      body.append("description", values.description);
    }

    if (values.descriptionAr) {
      body.append("descriptionAr", values.descriptionAr);
    }

    // Process and convert each image in array to a blob
    if (values.media) {
      let ins = values.media.length;
      for (let x = 0; x < ins; x++) {
        const image = values.media[x].originFileObj;
        if (image) {
          body.append("imagePath", image);
        }
      }
    }

    if (selectedCategory?.id !== undefined) {
      dynamicCategoriesApi
        .updateListing(selectedCategory?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Category updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      dynamicCategoriesApi
        .createListing(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            handleClose();
            return message.success("Category created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const handleClose = () => {
    onLoad();
    visible();
  };

  useEffect(() => {
    form.setFieldsValue({
      media: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.imagePath) {
      setFileList([
        {
          uid: selectedCategory.imagePath,
          size: 1,
          name: selectedCategory.imagePath as string,
          type: "",
          url: selectedCategory.imagePath
        }
      ]);
    }
  }, [selectedCategory]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image or video</p>
      <p className={styles.uploadHint}>
        (Images or Videos must be less than 5MB, format png, jpg, jpeg or mp4
        and a ratio of 2:1 )
      </p>
    </div>
  );

  return (
    <Form
      name="createCategoryForm"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <div>
        <Item
          className={styles.inlineInput}
          name="name"
          label={subcategory ? "Subcategory Name:" : "Category Name:"}
          initialValue={selectedCategory?.nameLocalized?.en}
          rules={[
            {
              required: true,
              message: "Please enter a given name."
            }
          ]}
        >
          <Input maxLength={50} type="text" />
        </Item>
        <Item
          className={`${styles.inlineInput} ${styles.margin}`}
          name="nameAr"
          rules={[
            {
              required: true,
              message: "Please enter a given Arabic translation."
            }
          ]}
          label={"Name Arabic Translation:"}
          initialValue={selectedCategory?.nameLocalized?.ar}
        >
          <Input maxLength={50} type="text" />
        </Item>
        <Item
          className={styles.inlineInput}
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter a given description."
            }
          ]}
          label={
            subcategory ? "Subcategory Description:" : "Category Description:"
          }
          initialValue={selectedCategory?.descriptionLocalized?.en}
        >
          <Input maxLength={250} type="text" />
        </Item>
        <Item
          className={`${styles.inlineInput} ${styles.margin}`}
          name="descriptionAr"
          rules={[
            {
              required: true,
              message: "Please enter a given Arabic translation."
            }
          ]}
          label={"Description Arabic Translation:"}
          initialValue={selectedCategory?.descriptionLocalized?.ar}
        >
          <Input maxLength={250} type="text" />
        </Item>
        {!subcategory && (
          <Item
            name="media"
            label="Cover Image:"
            valuePropName="fileList"
            extra={""}
            rules={[
              {
                required: true,
                message: "Please upload image."
              }
            ]}
          >
            <canvas className={styles.canvas}></canvas>
            <div className={styles.upload}>
              <Upload
                accept="image/png,image/jpeg,image/jpg,video/mp4"
                listType="picture-card"
                defaultFileList={[]}
                maxCount={1}
                beforeUpload={beforeUpload}
                fileList={fileList}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true
                }}
                customRequest={customUpload}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="AdsImage"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Item>
        )}

        <Item>
          <Button form="createCategoryForm" type="primary" htmlType="submit">
            {selectedCategory ? "Save " : "Create "}
            {subcategory ? "Subcategory" : "Category"}
          </Button>
        </Item>
      </div>
    </Form>
  );
};

export default CategoryCreateEditForm;
