import React, { useReducer } from "react";

export enum UserRoles {
  SuperAdmin = "super-admin"
}

type UserContextDef = {
  id?: number;
  name?: string;
  isSuperAdmin?: boolean;
};

type UserContextState = {
  user: UserContextDef;
  setUser: React.Dispatch<Partial<UserContextDef>>;
};

const reducer = (user: UserContextDef, setUser: Partial<UserContextDef>) => {
  return { ...user, ...setUser };
};

const UserContext = React.createContext({} as UserContextState);

function UserProvider({ children }) {
  const [user, setUser] = useReducer(reducer, {});

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
