import { getVehicleConfigs } from "app/api/auctionVehiclesApi";
import { vehicleConfigType } from "app/types/vehicleConfigType";

const VEHICLE_CONFIGS = "vehicleConfigs";

export const setVehicleConfigsInSessionStorage = (
  vehicleConfigs: vehicleConfigType
) => {
  return sessionStorage.setItem(
    VEHICLE_CONFIGS,
    JSON.stringify(vehicleConfigs)
  );
};

export const getVehicleConfigsFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem(VEHICLE_CONFIGS) || "{}");
};

export const updateVehicleConfig = async (callback: Function | null = null) => {
  await getVehicleConfigs()
    .then((payload: vehicleConfigType) => {
      setVehicleConfigsInSessionStorage(payload);
      if (callback) callback();
    })
    .catch(error => {
      console.log("error", error);
    });
};
