import React, { useEffect, useRef, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  TreeSelect,
  Typography,
  Upload
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import styles from "./CreateLotModal.module.scss";
import { formatNumber } from "app/helpers/numberFormatHelper";
import {
  createLiveAuctionLot,
  updateLiveAuctionLot
} from "app/api/liveAuctionsApi";
import EmergencyDeleteModal from "../EmergencyDeleteModal/EmergencyDeleteModal";
import { deleteVehicleImage } from "app/api/auctionVehiclesApi";
import {
  beforeUpload,
  dataURLtoFile,
  getBase64
} from "app/helpers/imageUploadHelper";
import { UploadFile } from "antd/lib/upload/interface";
import { LiveAuctionsDataType } from "app/types/LiveAuctionsType";
import { getListings } from "app/api/dynamicCategoryApi";

interface CreateLotModalProps {
  isVisible: boolean;
  isEditable?: boolean;
  isView?: boolean;
  isLive?: boolean;
  onClose: () => void;
  onCreate: () => void;
  selectedLot?: any;
  path?: any;
  selectedLiveAuction: LiveAuctionsDataType;
}

const CreateLotModal = ({
  isVisible,
  onClose,
  selectedLot,
  onCreate,
  selectedLiveAuction,
  path
}: CreateLotModalProps) => {
  const [emergencyDelete, setEmergencyDelete] = useState(false);
  let [form] = Form.useForm();
  const { Item } = Form;
  const [allFields, setAllFields] = useState<any>([]);
  const imageToBase64 = useRef<HTMLCanvasElement>(null);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [categories, setCategories] = useState<any>();

  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    selectedLot?.auctionType
  );

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const handleTreeSearch = (inputValue, treeNode) => {
    return treeNode.props.title
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    getListings()
      .then(payload => {
        setCategories(payload.data);
        setFilteredCategories(payload.data);
      })
      .catch(error => console.log(error));
  }, [allFields]);

  useEffect(() => {
    form.setFieldsValue({
      imagePath: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (
      !!imageToBase64 &&
      selectedLot?.listing?.mediaPhotos &&
      selectedLot?.listing?.mediaPhotos.length > 0
    ) {
      const formattedMediaImages = selectedLot?.listing?.mediaPhotos.map(
        (image: any): any => {
          imageToBase64.current?.setAttribute("src", image.url);
          const dataInBase64 = imageToBase64.current?.toDataURL(image.mimeType);
          const imageFile = dataURLtoFile(dataInBase64, image.fileName);

          return {
            uid: image.id.toString(),
            thumbUrl: image.url,
            url: image.url,
            size: image.size,
            name: image.fileName,
            type: image.mimeType,
            originFileObj: imageFile,
            status: "done",
            skip: true
          };
        }
      );
      setFileList(formattedMediaImages);
    }
  }, [selectedLot]);

  const customUpload = options => {
    const { onSuccess } = options;
    onSuccess("done");
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList.filter(file => !!file.status));
    const maxCount = 20;
    if (fileList.length === maxCount) {
      fileList.splice(maxCount);
      message.warning(
        `Maximum of ${maxCount} images can be uploaded. Please remove excess images.`
      );
    }
  };

  const removeFile = e => {
    deleteVehicleImage(Number(e.uid))
      .then(() => {
        setTimeout(message.success("Image deleted!"), 5000);
      })
      .catch(error => message.error("Something went wrong!"));
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onFinish = values => {
    const body = new FormData();

    body.append("listing[shopId]", "1");

    body.append("liveAuctionId", `${selectedLiveAuction?.id}`);

    if (values.lotCategory) {
      body.append("listing[listingTypeId]", values.lotCategory);
    }

    if (values.lotTitle) {
      body.append("listing[title]", values.lotTitle);
    }

    if (values.individualListingSaleType) {
      body.append(
        "listing[individualListingSaleType]",
        values.individualListingSaleType
      );
    }

    if (values.lotDescription) {
      body.append("listing[description]", values.lotDescription);
    }

    if (values.reservedPrice !== undefined) {
      body.append("listing[reservedPrice]", values.reservedPrice);
    }

    if (values.startingPrice !== undefined) {
      body.append("listing[startingPrice]", values.startingPrice);
    }

    if (values.firstInc !== undefined) {
      body.append("bidIncrementOne", values.firstInc);
    }

    if (values.secondInc !== undefined) {
      body.append("bidIncrementTwo", values.secondInc);
    }

    if (values.thirdInc !== undefined) {
      body.append("bidIncrementThree", values.thirdInc);
    }

    // Process and convert each image in array to a blob
    if (values.imagePath) {
      let ins = values.imagePath.length;
      for (let x = 0; x < ins; x++) {
        const image = values.imagePath[x].originFileObj;

        if (!values.imagePath[x].skip) body.append("mediaPhotos[]", image);
      }
    }

    if (selectedLot?.id !== undefined) {
      updateLiveAuctionLot(selectedLot?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            onCreate();
            message.success("Live Auction Lot Updated");
            onClose();
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createLiveAuctionLot(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            onCreate();
            message.success("Live auction lot created");
            onClose();
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  const validateReservedPrice = async (_, value) => {
    const fieldA = form.getFieldValue("startingPrice");

    if (value && fieldA !== undefined && value <= fieldA) {
      return Promise.reject(
        "Reserved price must be greater than Starting Price."
      );
    }

    return Promise.resolve();
  };

  const bidLabel = (
    <>
      Enter Custom Bid Increments &nbsp;
      <Typography.Text style={{ fontSize: "12px" }} type="secondary">
        (Default SAR 500; SAR 1000; SAR 2000)
      </Typography.Text>
    </>
  );

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image</p>
    </div>
  );

  const renderTreeNodes = categories => {
    return categories?.map(item => {
      if (item.children) {
        return (
          <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id}>
            {renderTreeNodes(item.children)}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id} />
      );
    });
  };

  return (
    <Modal
      title={
        path === "live" ? "View Lot" : selectedLot ? "Edit Lot" : "Add Lot"
      }
      visible={isVisible}
      onCancel={handleClose}
      width={500}
      destroyOnClose
      footer={[
        path !== "live" ? (
          <>
            <Button key="cancel" type="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              key="addLot"
              form="createLotForm"
              type="primary"
              htmlType="submit"
            >
              {selectedLot ? "Edit Lot" : "Add Lot"}
            </Button>
          </>
        ) : null
      ]}
    >
      {emergencyDelete && (
        <EmergencyDeleteModal
          isVisible={emergencyDelete}
          onClose={() => setEmergencyDelete(false)}
          selectedLot={selectedLot}
          selectedLiveAuction={selectedLiveAuction}
          onCreate={onCreate}
        />
      )}
      <Form
        name="createLotForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={(changedFields, allFields) => {
          setAllFields(allFields);
        }}
      >
        <div>
          <Item
            name="lotCategory"
            rules={[
              {
                required: true,
                message: "Please select a given (lot) category."
              }
            ]}
            label={"Lot Category:"}
            initialValue={selectedLot?.listing?.listingType?.id}
          >
            <TreeSelect
              showSearch
              allowClear
              value={selectedItem}
              style={{ width: "100%" }}
              placeholder="Select a category"
              onChange={value => setSelectedItem(value)}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              filterTreeNode={handleTreeSearch}
            >
              {renderTreeNodes(filteredCategories)}
            </TreeSelect>
          </Item>
          <Item
            name="lotTitle"
            rules={[
              {
                required: true,
                message: "Please enter a title"
              }
            ]}
            label={"Title:"}
            initialValue={selectedLot?.listing?.title}
          >
            <Input type="text" />
          </Item>
          <Item
            name="lotDescription"
            rules={[
              {
                required: true,
                message: "Please enter a given (auction) description"
              }
            ]}
            label={"Description:"}
            initialValue={selectedLot?.listing?.description}
          >
            <TextArea rows={2} showCount maxLength={200} />
          </Item>
          <Item
            name="individualListingSaleType"
            rules={[
              {
                required: true,
                message: "Please choose listing type!"
              }
            ]}
            label="Type:"
            initialValue={selectedLot?.listing?.saleType}
          >
            <Select
              onChange={value => setSelectedItem(value)}
              value={selectedItem}
              getPopupContainer={(trigger: any) => trigger.parentElement}
              options={[{ value: "live-auction", label: "Live auction" }]}
            />
          </Item>
          <canvas className={styles.canvas} ref={imageToBase64}></canvas>
          <Item
            name="imagePath"
            label="Media"
            required={true}
            valuePropName="fileLisImage"
            className={styles.uploadMediaText}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              maxCount={20}
              multiple
              beforeUpload={beforeUpload}
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true
              }}
              customRequest={customUpload}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={removeFile}
              defaultFileList={[]}
            >
              {uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Item>
          <Item label={"Start Price:"} style={{ marginBottom: 0 }} required>
            <Input.Group compact style={{ display: "flex" }}>
              <Item name="currencyCode" initialValue="SAR">
                <Input
                  readOnly={true}
                  disabled={false}
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="startingPrice"
                rules={[
                  {
                    required: true,
                    message: "Start price is required."
                  }
                ]}
                initialValue={selectedLot?.listing?.startingPrice?.amount}
              >
                <InputNumber
                  disabled={false}
                  formatter={value => formatNumber(value)}
                />
              </Item>
            </Input.Group>
          </Item>
          <Item label={"Reserved Price:"} style={{ marginBottom: 0 }} required>
            <Input.Group compact style={{ display: "flex" }}>
              <Item name="currencyCode" initialValue="SAR">
                <Input
                  readOnly={true}
                  disabled={false}
                  style={{ maxWidth: "50px" }}
                />
              </Item>
              <Item
                style={{ flexGrow: 1 }}
                name="reservedPrice"
                rules={[
                  {
                    required: true,
                    message: "Reserve price is required"
                  },
                  {
                    validator: validateReservedPrice
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const minimumBid =
                        selectedLot?.listing?.minimumBidAmount?.amount;
                      if (value === minimumBid) {
                        return Promise.reject(
                          `Reserve Price cannot be equal to Minimum Bid (${minimumBid})`
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                dependencies={["startingPrice"]}
                initialValue={selectedLot?.listing?.reservedPrice?.amount}
              >
                <InputNumber
                  disabled={false}
                  formatter={value => formatNumber(value)}
                />
              </Item>
            </Input.Group>
          </Item>
          <Form.Item label={bidLabel} style={{ marginBottom: 0 }} required>
            <Input.Group>
              <Item
                className={`${styles.inlineField}`}
                name="firstInc"
                rules={[
                  {
                    required: false,
                    message: "Please enter 1st Increment"
                  }
                ]}
                initialValue={
                  selectedLot?.bidIncrementOne
                    ? selectedLot.bidIncrementOne
                    : "500"
                }
              >
                <Input min={0} type="number" placeholder="1st Increment" />
              </Item>
              <Item
                className={`${styles.inlineField} ${styles.margin}`}
                name="secondInc"
                rules={[
                  {
                    required: false,
                    message: "Please enter 2nd Increment"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const firstInc = getFieldValue("firstInc");
                      if (
                        !value ||
                        parseFloat(value) < parseFloat(firstInc) ||
                        parseFloat(value) < 1
                      ) {
                        return Promise.reject(
                          new Error(
                            "2nd Increment must be greater than 1st Increment"
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                initialValue={
                  selectedLot?.bidIncrementTwo
                    ? selectedLot?.bidIncrementTwo
                    : "1000"
                }
              >
                <Input min={0} type="number" placeholder="2nd Increment" />
              </Item>
              <Item
                className={`${styles.inlineField} ${styles.margin}`}
                name="thirdInc"
                rules={[
                  {
                    required: false,
                    message: "Please enter 2nd Increment"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const secondInc = getFieldValue("secondInc");
                      if (
                        !value ||
                        parseFloat(value) < parseFloat(secondInc) ||
                        parseFloat(value) < 1
                      ) {
                        return Promise.reject(
                          new Error(
                            "3rd Increment must be greater than 2nd Increment"
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
                initialValue={
                  selectedLot?.bidIncrementThree
                    ? selectedLot?.bidIncrementThree
                    : "2000"
                }
              >
                <Input min={0} type="number" placeholder="3rd Increment" />
              </Item>
            </Input.Group>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateLotModal;
