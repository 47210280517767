import { Upload, Button, Form, Modal, message } from "antd";
import { inspectionReportRequestType } from "app/types/inspectionReportRequestType";
import React, { memo, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "../../InspectionReports/InspectionReports.module.scss";
// import { formatNumber } from "app/helpers/numberFormatHelper";

interface InspectionReportModalProps {
  isEditable?: boolean;
  isVisible: boolean;
  onClose: () => void;
  handleInspectionReportSubmit?: (values: any) => void;
  selectedInspectionReport?: inspectionReportRequestType;
}
const { Item } = Form;

const InspectionReportCategoryModal = ({
  isEditable,
  isVisible,
  onClose,
  handleInspectionReportSubmit,
  selectedInspectionReport
}: InspectionReportModalProps) => {
  const [reportsFileList, setReportsFileList] = useState<any[]>();

  let [form] = Form.useForm();

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const checkReportFileSize = file => {
    const sizeIsOk = file.size / 1024 / 1024 <= 20;
    if (!sizeIsOk) {
      message.error("Report File size must be less than 20 MB");
      return false;
    }
    return true;
  };

  const handleInspectionReportChange = (list, file, type) => {
    setReportsFileList(list.filter(file => !!file.status));
    if (!file.status) return false;

    const reports = [{ uid: file.uid, file: file }];
    form.setFieldsValue({
      inspectionReports: reports
    });
  };
  const { t } = useTranslation();
  // const handleTest = values => {
  //   console.log("Upload doc2", values);
  // };

  return (
    <Modal
      title={"Upload Inspection Report"}
      visible={isVisible}
      onCancel={onClose}
      footer={
        // Edit inspection report category footer
        <div className={styles.editModalFooter}>
          <div className={styles.editModalFooterDiv}>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              form="uploadInspectionReport"
            >
              Upload
            </Button>
          </div>
        </div>
      }
    >
      <Form
        labelCol={{ span: 8 }}
        layout="horizontal"
        form={form}
        name="uploadInspectionReport"
        onFinish={handleInspectionReportSubmit}
      >
        <Item
          name="advancedInspectionReport"
          required={true}
          className={styles.uploadField}
        >
          <Upload
            accept="application/pdf"
            listType="picture"
            maxCount={1}
            fileList={reportsFileList}
            customRequest={customUpload}
            beforeUpload={checkReportFileSize}
            onChange={({ file, fileList }) =>
              handleInspectionReportChange(fileList, file, "advanced")
            }
          >
            <Button icon={<UploadOutlined />}>{t("Inspection Report")}</Button>
          </Upload>
        </Item>
      </Form>
    </Modal>
  );
};

export default memo(InspectionReportCategoryModal);
