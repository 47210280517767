const AUCTION_VEHICLES_TYPES = "/auction-vehicle-types";
const AUCTION_VEHICLES_URL = "/admin/listings";
const AUCTION_VEHICLE_BIDS_URI = "/price-update-status";
const AUCTION_VEHICLES_POLLING_URL = "/admin/listings/long-polling";
const AUCTION_VEHICLES_BUY_NOW = "/admin/listings/buy-now";
const AUCTION_VEHICLES_UPDATE_STATE =
  "/admin/listings/reserved-not-met/update-state";
const AUCTION_VEHICLES_BUY_NOW_OPTION = "/admin/listings/switch-buy-now-option";
const VEHICLE_MAKES_URL = "/admin/vehicle-makes";
const VEHICLE_MAKES_LOGO_URI = "/with-media";
const VEHICLE_MODELS_URL = "/admin/vehicle-models";
const VEHICLE_TRIMS_URL = "/admin/vehicle-trims";
const VEHICLE_SEARCH = "/admin/vehicle-makes-models-trims";
const LOGIN_URL = "/admin/login";
const VEHICLE_CONFIGS_URL = "/admin/configs";
const ADMIN_USERS_URL = "/admin/users";
const ADMIN_USER_WITHDRAWAL_URL = "/admin/user/wallet-withdraw";
const ROLES_URL = "/admin/roles";
const DEPOSITS_URL = "/deposits";
const VEHICLE_CATEGORIES_URL = "/admin/vehicle-categories";
const ADMIN_RESET_PASSWORD = "/admin/reset-password";
const INSPECTION_REPORTS_REQUESTS = "/admin/inspection-report";
const AUCTION_SETTINGS = "/admin/auction-settings";
const UPLOAD_IBAN_DOC = "admin/virtual-iban/upload-sheet";
const BUSINESSES_URL = "/admin/businesses";
const ADVERTISEMENTS_URL = "/admin/advertisements";
const LIVE_AUCTIONS_URL = "/admin/live-auctions";
const LOCATIONS_URL = "/location";
const AUCTIONS_URL = "/admin/auctioneers";
const BULK_UPLOADS_URL = "/admin/import/bulkuploads";
const DYNAMIC_CATEGORIES_URL = "/admin/listing-types";
const DYNAMIC_FIELDS_URL = "/admin/custom-fields";
const PAGE_LAYOUT_URL = "/admin/page";
const WIDGETS_URL = "/admin/widgets";
const FEES_URL = "/admin/commission-fees";
const OTP_URL = "/admin/otps";
const SHOPS_URL = "/admin/shops";
const NOTIFICATIONS_URL = "/admin/notifications/translations";

export const ENDPOINTS = Object.freeze({
  AUCTION_VEHICLE_TYPES: AUCTION_VEHICLES_TYPES,
  AUCTION_VEHICLES: AUCTION_VEHICLES_URL,
  AUCTION_VEHICLE_BIDS_URI: AUCTION_VEHICLE_BIDS_URI,
  AUCTION_VEHICLES_POLLING_URL: AUCTION_VEHICLES_POLLING_URL,
  AUCTION_VEHICLES_BUY_NOW: AUCTION_VEHICLES_BUY_NOW,
  AUCTION_VEHICLES_BUY_NOW_OPTION: AUCTION_VEHICLES_BUY_NOW_OPTION,
  VEHICLE_MAKES: VEHICLE_MAKES_URL,
  VEHICLE_MAKES_LOGO_URI: VEHICLE_MAKES_LOGO_URI,
  VEHICLE_SEARCH: VEHICLE_SEARCH,
  LOGIN: LOGIN_URL,
  VEHICLE_CONFIGS: VEHICLE_CONFIGS_URL,
  VEHICLE_MODELS: VEHICLE_MODELS_URL,
  VEHICLE_TRIMS: VEHICLE_TRIMS_URL,
  ADMIN_USERS: ADMIN_USERS_URL,
  ADMIN_USER_WITHDRAWAL: ADMIN_USER_WITHDRAWAL_URL,
  ROLES: ROLES_URL,
  DEPOSITS: DEPOSITS_URL,
  VEHICLE_CATEGORIES: VEHICLE_CATEGORIES_URL,
  ADMIN_RESET_PASSWORD,
  INSPECTION_REPORTS_REQUESTS: INSPECTION_REPORTS_REQUESTS,
  AUCTION_VEHICLES_UPDATE_STATE: AUCTION_VEHICLES_UPDATE_STATE,
  AUCTION_SETTINGS: AUCTION_SETTINGS,
  UPLOAD_IBAN_DOC: UPLOAD_IBAN_DOC,
  BUSINESSES_URL: BUSINESSES_URL,
  ADVERTISEMENTS: ADVERTISEMENTS_URL,
  BULK_UPLOADS: BULK_UPLOADS_URL,
  LIVE_AUCTIONS: LIVE_AUCTIONS_URL,
  LOCATIONS: LOCATIONS_URL,
  AUCTIONEER: AUCTIONS_URL,
  DYNAMIC_CATEGORIES: DYNAMIC_CATEGORIES_URL,
  CUSTOM_FIELDS: DYNAMIC_FIELDS_URL,
  PAGE_LAYOUT: PAGE_LAYOUT_URL,
  WIDGETS: WIDGETS_URL,
  FEES: FEES_URL,
  OTP: OTP_URL,
  SHOPS: SHOPS_URL,
  NOTIFICATIONS: NOTIFICATIONS_URL
});
