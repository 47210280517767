import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get events
 *
 * @param
 * @returns
 * @export
 */

export const getEvents = (search: any, state: string) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/query?state=${state}`;
  return api
    .get(endpoint, {
      params: {
        sortBy: "createdAt",
        sortOrder: "DESC",
        ...search
      }
    })
    .then(resp => resp.data);
};

/**
/**
 * Get event details
 *
 * @param
 * @returns
 * @export
 */

export const getEventDetails = async (eventId: string) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${eventId}/show`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
 * create event
 */
export const createEvent = (body: any) => {
  return api.post(ENDPOINTS.LIVE_AUCTIONS + `/create`, body);
};

/**
 * update event
 */
export const updateEvent = (id: any, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/update`;

  return api.post(endpoint, body);
};

/**
 * delete live streaming
 * @param search
 */
export const deleteLiveAuctionStream = (id: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/delete-livestream`;
  return api.delete(endpoint);
};

/**
 * create live stream
 * @param search
 */
export const createLiveStream = (liveAuctionId: number) => {
  const endpoint =
    ENDPOINTS.LIVE_AUCTIONS + `/${liveAuctionId}/create-livestream`;
  return api.post(endpoint);
};

/**
 * delete event
 * @param search
 */
export const deleteEvent = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}`;
  return api.delete(endpoint, body);
};

/**
 * approve live auctions
 */
export const approveEvent = (id: string, body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/${id}/update`;
  return api.post(endpoint, body);
};

/**
 * get auction details
 *
 * @returns
 * @export
 */

export const getUnassignedListings = () => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/queued/timed-auctions`;
  return api.get(endpoint).then(resp => resp.data);
};

/**
 * attach listing
 */
export const attachListing = (body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/attach`;
  return api.post(endpoint, body);
};

/**
 * unattach listing
 */
export const unattachListing = (body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/unattach`;
  return api.post(endpoint, body);
};

/**
 * reorder listings
 */
export const reorderedListings = (body: any) => {
  const endpoint = ENDPOINTS.LIVE_AUCTIONS + `/lot/order`;

  return api.post(endpoint, body);
};

/**
 * Get timed-auctions
 *
 * @param
 * @returns
 * @export
 */

export const getTimedAuctions = () => {
  const endpoint =
    ENDPOINTS.LIVE_AUCTIONS + `/query?state=requests&auctionType=timed-auction`;
  return api.get(endpoint).then(resp => resp.data);
};
