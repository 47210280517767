import { Button, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { getLiveAuctionsUsers } from "app/api/liveAuctionsApi";
import { PaginationConfig } from "antd/lib/pagination";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
interface RegisteredUsersDataType {
  key: React.Key;
  name: string;
  userId: string;
  Onsite: string;
}

const columns: ColumnsType<RegisteredUsersDataType> = [
  {
    title: "Tag",
    dataIndex: "bidderNumber",
    key: "bidderNumber",
    render: text => (
      <Button shape="circle" size="middle" type="primary">
        {text}
      </Button>
    )
  },
  {
    title: "Name",
    dataIndex: "user",
    key: "user",
    render: record => (
      <p>
        {record?.firstName} {record?.middleName} {record?.lastName}
      </p>
    )
  },
  {
    title: "Email",
    dataIndex: "user",
    key: "user",
    render: record => <a>{record.email}</a>
  },
  {
    title: "User Id",
    dataIndex: "userId",
    key: "userId",
    render: text => <a>{text}</a>
  },
  {
    title: "Site",
    dataIndex: "onSite",
    filters: [
      {
        text: "Online",
        value: "online"
      },
      {
        text: "Onsite",
        value: "onsite"
      }
    ],
    onFilter: (value: any, record: any) => {
      return value === "online" ? !record.onSite : record.onSite;
    },
    render: record => <p>{record ? "onsite" : "online"}</p>
  }
];

const RegisteredUsersList = ({ selectedLiveAuction }) => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<RegisteredUsersDataType[]>();
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    LoadLiveAuctionUsers();
  }, [location.search]);

  const LoadLiveAuctionUsers = () => {
    setLoading(true);
    getLiveAuctionsUsers(selectedLiveAuction?.id, qs.parse(location.search))
      .then(payload => {
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(error => {
        error("Something went wrong", error);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={pagination}
      onChange={pagination => handleTableChanges(pagination)}
    />
  );
};

export default RegisteredUsersList;
