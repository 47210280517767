import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "query-string";

import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import { Card, Col, Form, Input, Row, Table } from "antd";
import Column from "antd/lib/table/Column";
import Button from "antd/lib/button";
import { PlusOutlined } from "@ant-design/icons";

import { getBackOfficeUsers } from "app/api/usersApi";
import EditUserFormModal from "./components/EditUserFormModal/EditUserFormModal";
import CreateUserModal from "./components/CreateUserModal/CreateUserModal";

import UserDetailsModal from "./components/UserDetailsModal/UserDetailsModal";

import { QueryHelper } from "app/helpers/QueryHelper";

import styles from "./BackOfficeUsersScreen.module.scss";
import { userType } from "app/types/userType";

const BackOfficeUserScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<userType[]>([]);
  const [isEditUserVisible, setIsEditUserVisible] = useState<boolean>(false);
  const [isCreateUserVisible, setIsCreateUserVisible] = useState<boolean>(
    false
  );
  const [isUserDetailsVisible, setIsUserDetailsVisible] = useState<boolean>(
    false
  );

  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const getData = () => {
    getBackOfficeUsers(qs.parse(location.search))
      .then(payload => {
        const {
          data: users,
          meta: { current_page, per_page, total }
        } = payload;
        setData(users);

        setPagination({
          ...pagination,
          current: current_page,
          pageSize: per_page,
          total
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  // const handlePagination = (values: any) => {
  //   // Get current vars from location
  //   const currentSearch = qs.parse(location.search);

  //   // Get values from formFields and add them to the values object
  //   values = {
  //     ...currentSearch,
  //     ...values,
  //   };

  //   QueryHelper.removeUndefined(values);

  //   history.push({
  //     pathname: location.pathname,
  //     search: qs.stringify(values, { arrayFormat: 'comma' }),
  //   });
  // };

  // const handleTableChanges = (newPagination: TablePaginationConfig) =>
  //   handlePagination({
  //     page: newPagination.current,
  //   });

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const { Item } = Form;
  let [form] = Form.useForm();

  return (
    <div>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <Item name="userId" label="User ID">
                <Input placeholder="User ID" />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="mobile" label={t("users.phoneTitle")}>
                <Input placeholder={t("users.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="firstName" label={"First name"}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="lastName" label={"Last name"}>
                <Input placeholder={"e.g.Smith"} />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="email" label={t("users.emailTitle")}>
                <Input type="email" placeholder={t("users.emailPlaceholder")} />
              </Item>
            </Col>
            <Col span={8}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
              >
                {t("default.resetButton")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className={styles.tableContainer}>
        <Card>
          <div>
            {!!selectedUser && !!isEditUserVisible && (
              <EditUserFormModal
                isVisible={isEditUserVisible}
                onClose={() => {
                  setIsEditUserVisible(false);
                  setSelectedUser(undefined);
                }}
                selectedUser={selectedUser}
                getData={getData}
              />
            )}
            {!!isCreateUserVisible && (
              <CreateUserModal
                isVisible={isCreateUserVisible}
                onClose={() => setIsCreateUserVisible(false)}
                getData={getData}
              />
            )}
            {!!selectedUser && !!isUserDetailsVisible && (
              <UserDetailsModal
                isVisible={isUserDetailsVisible}
                onClose={() => {
                  setIsUserDetailsVisible(false);
                  setSelectedUser(undefined);
                }}
                selectedUser={selectedUser}
              />
            )}
            <div className={styles.headerContainer}>
              <h2>{t("users.backOfficeUsersTitle")}</h2>
              <Button
                className={styles.inviteUserButton}
                onClick={() => setIsCreateUserVisible(true)}
              >
                <PlusOutlined /> {t("users.inviteNew")}
              </Button>
            </div>
            <Table
              dataSource={data}
              loading={loading}
              rowKey={obj => obj.id}
              onChange={pagination => handleTableChanges(pagination)}
              pagination={pagination}
            >
              <Column
                key="id"
                title={t("users.userIdTitle")}
                dataIndex="id"
                render={(text, record: any, index) => (
                  <div
                    className={styles.editUserButton}
                    onClick={() => {
                      setSelectedUser(data[index]);
                      setIsUserDetailsVisible(true);
                    }}
                  >
                    {record.id}
                  </div>
                )}
              />
              <Column
                key="nameTitle"
                title={t("users.nameTitle")}
                render={record =>
                  `${record.firstName} ${record.lastName || ""}`
                }
              />
              <Column
                key="email"
                title={t("users.emailTitle")}
                render={record => record.email}
              />
              <Column
                key="phone"
                title={t("users.phoneTitle")}
                render={record => record.mobile}
              />
              <Column
                key="action"
                title={t("users.actionsTitle")}
                render={(text, record, index) => (
                  <div
                    className={styles.editUserButton}
                    onClick={() => {
                      setSelectedUser(data[index]);
                      setIsEditUserVisible(true);
                    }}
                  >
                    {t("default.edit")}
                  </div>
                )}
              />
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BackOfficeUserScreen;
