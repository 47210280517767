import React, { useEffect, useState } from "react";
import { message } from "antd";
import { Button, Input, Form, TablePaginationConfig, Table } from "antd";
import Column from "antd/lib/table/Column";
import { LeftOutlined } from "@ant-design/icons";
import { PaginationConfig } from "antd/lib/pagination";

import { QueryHelper } from "app/helpers/QueryHelper";
import qs from "query-string";

import { useLocation, useHistory } from "react-router-dom";

import {
  createVehicleMake,
  deleteVehicleMake,
  getVehicleMakes,
  searchVehicleMakeModelTrim,
  updateVehicleMake,
  createVehicleModel,
  deleteVehicleModel,
  getVehicleCategories,
  updateVehicleModel,
  createVehicleTrim,
  updateVehicleTrim,
  deleteVehicleTrim,
  getVehicleModelsByMake,
  getVehicleTrimsByModel
} from "app/api/auctionVehiclesApi";
import styles from "./VehicleSearch.module.scss";
import {
  VehicleSearchContentTypes,
  vehicleSearchType
} from "app/types/vehicleSearchTypes";
import { vehicleMakeType } from "app/types/vehicleMakeType";
import { vehicleModelType } from "app/types/vehicleModelType";
import { trimType } from "app/types/trimType";
import { useTranslation } from "react-i18next";
import MakeModal from "../MakeModal/MakeModal";
import { updateVehicleConfig } from "app/helpers/vehicleConfigsHelper";
import ModelModal from "../ModelModal/ModelModal";
import TrimModal from "../TrimModal/TrimModal";
import Title from "antd/lib/typography/Title";

interface VehicleSearchQueryProps {
  searchType: VehicleSearchContentTypes;
  searchString?: string | string[] | undefined | null;
  page?: number | null;
}

interface ActiveItemProps {
  name: string;
  id: number;
  make?: string;
  previousId?: string;
  type: VehicleSearchContentTypes;
  logo?: string;
}

interface AddModalProps {
  label?: string;
  onclick?: () => void;
}

const VehicleSearch = () => {
  const initialPagination = (
    current: number = 0,
    pageSize: number = 0,
    total: number = 0
  ): PaginationConfig => {
    return {
      current: current,
      pageSize: pageSize,
      total: total,
      showSizeChanger: false
    };
  };

  const { Item } = Form;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState<
    (vehicleMakeType | vehicleModelType | trimType)[]
  >();
  const [activeItem, setActiveItem] = useState<ActiveItemProps>();
  const [searchQuery, setSearchQuery] = useState<VehicleSearchQueryProps>({
    searchType: qs.parse(location.search).name
      ? VehicleSearchContentTypes.Search
      : VehicleSearchContentTypes.Make,
    searchString: qs.parse(location.search).name
      ? qs.parse(location.search).name
      : "",
    page: null
  });
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<any>(initialPagination());
  const [isAddMakeModalVisible, setAddMakeModalVisible] = useState<boolean>(
    false
  );
  const [isEditMakeModalVisible, setEditMakeModalVisible] = useState<boolean>(
    false
  );
  const [selectedMake, setSelectedMake] = useState<vehicleMakeType>();
  const [addModal, setAddModal] = useState<AddModalProps>();

  const [inspectionReportCategories, setInspectionReportCategories] = useState<
    []
  >([]);

  const [isAddModelModalVisible, setAddModelModalVisible] = useState<boolean>(
    false
  );
  const [isEditModelModalVisible, setEditModelModalVisible] = useState<boolean>(
    false
  );
  const [selectedModel, setSelectedModel] = useState<vehicleModelType>();
  const [selectedMakeId, setSelectedMakeId] = useState<string | null>(null);
  const [selectedModelId, setSelectedModelId] = useState<string | null>(null);

  const [isAddTrimModalVisible, setAddTrimModalVisible] = useState<boolean>(
    false
  );
  const [isEditTrimModalVisible, setEditTrimModalVisible] = useState<boolean>(
    false
  );
  const [selectedTrim, setSelectedTrim] = useState<trimType>();

  useEffect(() => {
    if (!activeItem) {
      getData();
    }
    updateModalBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, activeItem]);

  const parseSearchData = (
    data: vehicleSearchType
  ): vehicleMakeType[] | vehicleModelType[] | trimType[] => {
    const makeResults: vehicleMakeType[] = data.makes.result.map(
      item => (item = { ...item, type: VehicleSearchContentTypes.Make })
    );
    const modelResults: vehicleModelType[] = data.models.result.map(
      item => (item = { ...item, type: VehicleSearchContentTypes.Model })
    );
    const trimResults: trimType[] = data.trims.result.map(
      item => (item = { ...item, type: VehicleSearchContentTypes.Trim })
    );
    const combinedResults = [...makeResults, ...modelResults, ...trimResults];
    return combinedResults;
  };

  const getData = () => {
    setLoading(true);
    if (searchQuery.searchType === VehicleSearchContentTypes.Search) {
      doSearch();
    }
    if (searchQuery.searchType === VehicleSearchContentTypes.Make) {
      getMakes();
    }
  };

  const addMakeBtn = () => {
    setAddModal({
      label: "+ Add Make",
      onclick: handleAddModal
    });
  };

  const addModelBtn = () => {
    setAddModal({
      label: "+ Add Model",
      onclick: handleAddModal
    });
  };

  const addMTrimBtn = () => {
    setAddModal({
      label: "+ Add Trim",
      onclick: handleAddModal
    });
  };

  const handleAddModal = () => {
    switch (searchQuery.searchType) {
      case VehicleSearchContentTypes.Make:
        setAddMakeModalVisible(true);
        break;
      case VehicleSearchContentTypes.Model:
        setAddModelModalVisible(true);
        break;
      case VehicleSearchContentTypes.Trim:
        setAddTrimModalVisible(true);
        break;
    }
  };

  const updateModalBtn = () => {
    switch (searchQuery.searchType) {
      case VehicleSearchContentTypes.Model:
        addModelBtn();
        break;
      case VehicleSearchContentTypes.Trim:
        addMTrimBtn();
        break;
      default:
        addMakeBtn();
        break;
    }
  };

  const doSearch = () => {
    if (searchQuery.searchString && searchQuery.searchString.length >= 2) {
      searchVehicleMakeModelTrim({
        name: searchQuery.searchString
      })
        .then(payload => {
          setData(parseSearchData(payload));
          setPagination({
            ...pagination,
            current: payload.makes.current_page,
            pageSize: payload.makes.per_page,
            total: payload.makes.total
          });
        })
        .catch(error => {
          setLoading(true);
          console.error("Something went wrong", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSearch = (values: any, paging: boolean = false) => {
    // Get current search from location
    let queryParams = { page: searchQuery.page };
    // Remove any paging data if doing a new search
    if (!paging && queryParams.page) delete queryParams.page;

    const currentSearch = queryParams;

    let queryState: VehicleSearchQueryProps = {
      searchType: VehicleSearchContentTypes.Make
    };

    if (values.name && values.name.length >= 2) {
      queryState = {
        searchType: VehicleSearchContentTypes.Search,
        searchString: values.name
      };
    }

    if (values.page) {
      queryState.page = values.page;
    }

    setSearchQuery(queryState);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    if ((values.name && values.name.length >= 2) || values.page) {
      history.push({
        pathname: location.pathname,
        search: qs.stringify(values, { arrayFormat: "comma" })
      });
    }
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values, true);
  };

  const handleSearchClear = event => {
    if (event.currentTarget.value.length === 0 && activeItem === null) {
      setSearchQuery({
        searchType: VehicleSearchContentTypes.Make
      });
      history.push({
        pathname: location.pathname
      });
    }
  };

  const resetData = () => {
    setData([]);
    history.push({
      pathname: location.pathname
    });
  };

  const goBack = () => {
    resetData();
    if (activeItem) {
      switch (activeItem.type) {
        case VehicleSearchContentTypes.Model:
          if (activeItem.previousId)
            handleMakeView(parseInt(activeItem.previousId));
          break;
        case VehicleSearchContentTypes.Trim:
          if (activeItem.previousId)
            handleModelView(parseInt(activeItem.previousId));
          break;
        default:
          setSearchQuery({
            searchType: VehicleSearchContentTypes.Make
          });
          setActiveItem(undefined);
          break;
      }
    }
  };

  const handleSearchView = (result: any) => {
    const type: VehicleSearchContentTypes = result.type as VehicleSearchContentTypes;
    switch (type) {
      case VehicleSearchContentTypes.Make:
        handleMakeView(parseInt(result.id));
        break;
      case VehicleSearchContentTypes.Model:
        handleModelView(parseInt(result.id));
        break;
    }
  };

  const handleEditResult = (index: number) => {
    if (data !== undefined) {
      const type: VehicleSearchContentTypes = data[index]
        .type as VehicleSearchContentTypes;
      switch (type) {
        case VehicleSearchContentTypes.Make:
          setSelectedMake(data[index] as vehicleMakeType);
          setEditMakeModalVisible(true);
          break;
        case VehicleSearchContentTypes.Model:
          setSelectedModel(data[index] as vehicleModelType);
          setEditModelModalVisible(true);
          break;
        case VehicleSearchContentTypes.Trim:
          setSelectedTrim(data[index] as trimType);
          setEditTrimModalVisible(true);
          break;
      }
    }
  };

  /**
   * ---------------------------------------------------------------
   *  Make Handlers
   * ---------------------------------------------------------------
   */

  const getMakes = () => {
    getVehicleMakes({ page: searchQuery.page })
      .then(payload => {
        setData(payload.data);
        setPagination({
          ...pagination,
          current: payload.meta.current_page,
          pageSize: payload.meta.per_page,
          total: payload.meta.total
        });
      })
      .catch(error => {
        setLoading(true);
        console.log("Something went wrong", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showEditMakeModal = (index: number) => {
    if (data !== undefined) {
      setSelectedMake(data[index] as vehicleMakeType);
      setEditMakeModalVisible(true);
    }
  };

  const AddMakeModal = () => {
    return (
      <MakeModal
        isMakeModalVisible={isAddMakeModalVisible}
        hideMakeModal={hideAddMakeModal}
        handleMakeAdd={handleMakeAdd}
      />
    );
  };

  const EditMakeModal = () => {
    return (
      <MakeModal
        isEditable
        isMakeModalVisible={isEditMakeModalVisible}
        hideMakeModal={hideEditMakeModal}
        handleMakeEdit={handleMakeEdit}
        handleMakeDelete={handleMakeDelete}
        selectedMake={selectedMake}
      />
    );
  };

  const hideEditMakeModal = () => {
    setEditMakeModalVisible(false);
  };

  const hideAddMakeModal = () => {
    setAddMakeModalVisible(false);
  };

  const handleMakeEdit = values => {
    const withLogo: boolean = values.makeLogo.length > 0 ? true : false;
    const withDarkLogo: boolean =
      values.logoPathDarkMode.length > 0 ? true : false;

    let postBody = values;
    const body = new FormData();
    if (withLogo && typeof values.makeLogo[0].originFileObj != "undefined") {
      body.append("logo_path", values.makeLogo[0].originFileObj);
      body.append("name", values.name);
    }
    if (
      withDarkLogo &&
      typeof values.logoPathDarkMode[0].originFileObj != "undefined"
    ) {
      body.append(
        "logo_path_dark_mode",
        values.logoPathDarkMode[0].originFileObj
      );
    }

    body.append("name", values.name);
    postBody = body;

    updateVehicleMake(Number(selectedMake?.id), postBody, withLogo)
      .then(() => {
        setTimeout(message.success("Make updated"), 5000);
        setEditMakeModalVisible(false);
        getData();
        updateVehicleConfig();
      })
      .catch(error => message.error("Something went wrong!"));
  };

  const handleMakeDelete = () => {
    deleteVehicleMake(Number(selectedMake?.id))
      .then(() => {
        setTimeout(message.success("Make deleted!"), 5000);
        setEditMakeModalVisible(false);
        getData();
        updateVehicleConfig();
      })
      .catch(error => message.error("Something went wrong!"));
  };

  const handleMakeAdd = values => {
    const addModel: boolean = values.addModel;
    const withLogo: boolean = values.makeLogo.length > 0 ? true : false;
    let postBody = values;

    if (withLogo) {
      const body = new FormData();
      body.append("logo_path", values.makeLogo[0].originFileObj);
      body.append("name", values.name);
      postBody = body;
    }

    createVehicleMake(postBody, withLogo)
      .then(resp => {
        if (resp.status === 201) {
          setTimeout(message.success("Make created"), 2000);
          setAddMakeModalVisible(false);
          if (addModel) {
            const makeId: string = resp.data.id;
            setSelectedMakeId(makeId);
            handleMakeView(parseInt(makeId));
            // Open modal if there is an ID it should open to
            if (makeId) {
              updateVehicleConfig(() => showAddModelModal());
            } else {
              updateVehicleConfig();
            }
          } else {
            getData();
            updateVehicleConfig();
          }
        }
      })
      .catch(error => {
        message.error("Something went wrong. " + error, 2000);
      });
  };

  const handleMakeView = (id: number) => {
    getInspectionReportCategories();
    resetData();
    setLoading(true);
    setSelectedMakeId(id.toString());
    getVehicleModelsByMake(id)
      .then(resp => {
        setSearchQuery({
          searchType: VehicleSearchContentTypes.Model
        });
        setData(resp.vehicleModels);
        setPagination({
          ...pagination,
          ...initialPagination(
            1,
            resp.vehicleModels.length,
            resp.vehicleModels.length
          )
        });
        setActiveItem({
          name: resp.name,
          id: id,
          type: VehicleSearchContentTypes.Make,
          logo: resp.logo
        });
      })
      .catch(error => {
        message.error("Something went wrong. " + error, 2000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * ---------------------------------------------------------------
   *  Model Handlers
   * ---------------------------------------------------------------
   */

  const getInspectionReportCategories = () => {
    getVehicleCategories(location.search)
      .then(payload => {
        setInspectionReportCategories(payload.data);
      })
      .catch(error => console.log("Something went wrong", error));
  };

  const showAddModelModal = () => {
    setAddModelModalVisible(true);
  };

  const hideAddModelModal = () => {
    setAddModelModalVisible(false);
  };

  const handleModelAdd = values => {
    const addAnotherModel: boolean = values.addAnotherModel;
    createVehicleModel(values)
      .then(resp => {
        if (resp.status === 201) {
          setTimeout(message.success("Model created"), 2000);
          setAddModelModalVisible(false);
          if (addAnotherModel) {
            // Allow the previous model to close before opening a new one
            setTimeout(() => showAddModelModal(), 200);
          }
          handleMakeView(parseInt(selectedMakeId as string));
        }
      })
      .catch(error => {
        message.error("Something went wrong", error);
      });
  };

  const AddModelModal = () => {
    return (
      <ModelModal
        isModelModalVisible={isAddModelModalVisible}
        hideModelModal={hideAddModelModal}
        handleModelAdd={handleModelAdd}
        inspectionReportCategories={inspectionReportCategories}
        selectedMakeId={selectedMakeId}
      />
    );
  };

  const showEditModelModal = (index: number) => {
    if (data !== undefined) {
      setSelectedModel(data[index] as vehicleModelType);
    }
    setEditModelModalVisible(true);
  };

  const hideEditModelModal = () => {
    setEditModelModalVisible(false);
  };

  const handleModelEdit = values => {
    updateVehicleModel(Number(selectedModel?.id), {
      ...values,
      categories: values.categories.map(c => c.value)
    })
      .then(() => {
        setTimeout(message.success("Model updated"), 2000);
        setEditModelModalVisible(false);
        handleMakeView(parseInt(selectedMakeId as string));
      })
      .catch(error => message.error("Something went wrong"));
  };

  const handleModelDelete = () => {
    deleteVehicleModel(Number(selectedModel?.id))
      .then(() => {
        setTimeout(message.success("Model deleted"), 2000);
        setEditModelModalVisible(false);
        handleMakeView(parseInt(selectedMakeId as string));
      })
      .catch(error => message.error("Something went wrong"));
  };

  const EditModelModal = () => {
    return (
      <ModelModal
        isEditable
        isModelModalVisible={isEditModelModalVisible}
        hideModelModal={hideEditModelModal}
        handleModelEdit={handleModelEdit}
        handleModelDelete={handleModelDelete}
        inspectionReportCategories={inspectionReportCategories}
        selectedModel={selectedModel}
      />
    );
  };

  const handleModelView = (id: number) => {
    getInspectionReportCategories();
    resetData();
    setLoading(true);
    setSelectedModelId(id.toString());
    getVehicleTrimsByModel(id)
      .then(resp => {
        setSearchQuery({
          searchType: VehicleSearchContentTypes.Trim
        });
        setData(resp.vehicleTrims);
        setPagination({
          ...pagination,
          ...initialPagination(
            1,
            resp.vehicleTrims.length,
            resp.vehicleTrims.length
          )
        });
        setActiveItem({
          ...activeItem,
          name: resp.name,
          id: id,
          make: resp.vehicleMake.name,
          previousId: resp.vehicleMake.id,
          type: VehicleSearchContentTypes.Model
        });
      })
      .catch(error => {
        message.error("Something went wrong. " + error, 2000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * ---------------------------------------------------------------
   *  Trims Handlers
   * ---------------------------------------------------------------
   */

  const hideAddTrimModal = () => {
    setAddTrimModalVisible(false);
  };

  const handleTrimAdd = values => {
    const postObject = {
      name: values.name,
      vehicleModelId: values.vehicleModelId,
      enabled: true
    };

    createVehicleTrim(postObject)
      .then(resp => {
        if (resp.status === 201) {
          setTimeout(message.success("Trim created"), 5000);
          setAddTrimModalVisible(false);
          handleModelView(parseInt(values.vehicleModelId));
        }
      })
      .catch(error => {
        message.error("Something went wrong", error);
      });
  };

  const AddTrimModal = () => {
    return (
      <TrimModal
        isTrimModalVisible={isAddTrimModalVisible}
        hideTrimModal={hideAddTrimModal}
        handleTrimAdd={handleTrimAdd}
        selectedModelId={selectedModelId}
      />
    );
  };

  /**
   * Edit trim modal
   */

  const showEditTrimModal = (index: number) => {
    if (data !== undefined) {
      setSelectedTrim(data[index] as trimType);
    }
    setEditTrimModalVisible(true);
  };

  const hideEditTrimModal = () => {
    setEditTrimModalVisible(false);
  };

  const handleTrimEdit = values => {
    const postObject = {
      name: values.name,
      vehicleModelId: values.vehicleModelId,
      enabled: values.enabled
    };

    updateVehicleTrim(Number(selectedTrim?.id), postObject)
      .then(() => {
        setTimeout(message.success("Trim updated"), 5000);
        setEditTrimModalVisible(false);
        handleModelView(parseInt(values.vehicleModelId));
      })
      .catch(error => message.error("Something went wrong"));
  };

  const handleTrimDelete = () => {
    deleteVehicleTrim(Number(selectedTrim?.id))
      .then(() => {
        setTimeout(message.success("Trim deleted"), 5000);
        setEditTrimModalVisible(false);
        handleModelView(parseInt(selectedModelId as string));
      })
      .catch(error => message.error("Something went wrong"));
  };

  const EditTrimModal = () => {
    return (
      <TrimModal
        isEditable
        isTrimModalVisible={isEditTrimModalVisible}
        hideTrimModal={hideEditTrimModal}
        handleTrimEdit={handleTrimEdit}
        handleTrimDelete={handleTrimDelete}
        selectedTrim={selectedTrim}
      />
    );
  };

  const formatSearchResult = data => {
    let resultName: string = data?.name;
    if (data?.vehicleMake?.name) resultName += ` (${data.vehicleMake.name})`;
    if (data?.vehicleModel?.vehicleMake?.name)
      resultName += ` (${data.vehicleModel.vehicleMake.name})`;
    return resultName;
  };

  return (
    <>
      {!!isAddMakeModalVisible && <AddMakeModal />}
      {!!selectedMake && !!isEditMakeModalVisible && <EditMakeModal />}
      {!!isAddModelModalVisible && <AddModelModal />}
      {!!selectedModel && !!isEditModelModalVisible && <EditModelModal />}
      {!!isAddTrimModalVisible && <AddTrimModal />}
      {!!selectedTrim && !!isEditTrimModalVisible && <EditTrimModal />}
      <div className={styles.vehicleSearchOverview}>
        <div className={styles.vehicleSearch}>
          {activeItem ? (
            <Button
              icon={<LeftOutlined />}
              type="default"
              className={styles.vehicleSearchBack}
              onClick={() => goBack()}
            >
              Back
            </Button>
          ) : null}
          <Form
            layout="horizontal"
            name="search_vehicles"
            onFinish={handleSearch}
          >
            <Item
              name="name"
              initialValue={
                searchQuery.searchString ? searchQuery.searchString : ""
              }
            >
              <Input
                placeholder="Search for make, model or trim"
                allowClear
                className={styles.searchInput}
                onChange={handleSearchClear}
              />
            </Item>
          </Form>
          <Button
            className={styles.vehicleSearchButton}
            form="search_vehicles"
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
        </div>
        {addModal && !isLoading && (
          <Button type="primary" onClick={addModal.onclick}>
            {addModal.label}
          </Button>
        )}
      </div>
      {activeItem?.name ? (
        <div className={styles.makeTitle}>
          {activeItem?.logo ? (
            <img
              src={activeItem?.logo}
              className={styles.makeLogo}
              alt={activeItem.name}
            />
          ) : null}
          <Title level={4} className={styles.makeHeading}>
            {activeItem?.make ? activeItem?.make + " > " : ""}
            {activeItem.name}
          </Title>
        </div>
      ) : null}
      {!!(searchQuery.searchType === VehicleSearchContentTypes.Search) && (
        <Table
          dataSource={data}
          loading={isLoading}
          rowKey="id"
          onChange={pagination => handleTableChanges(pagination)}
          pagination={pagination}
        >
          <Column
            key="result"
            title={"Result"}
            render={(data: any) => (
              <div
                className={
                  data?.type !== VehicleSearchContentTypes.Trim
                    ? styles.viewText
                    : ""
                }
                onClick={() => handleSearchView(data)}
              >
                {formatSearchResult(data)}
              </div>
            )}
          />
          <Column
            key="type"
            title={"Type"}
            render={(data: any) => data?.type}
          />
          <Column
            key="edit"
            render={(text, record, index) => (
              <div
                onClick={() => handleEditResult(index)}
                className={styles.editText}
              >
                Edit
              </div>
            )}
          />
        </Table>
      )}
      {!!(searchQuery.searchType === VehicleSearchContentTypes.Make) && (
        <Table
          dataSource={data}
          loading={isLoading}
          rowKey="id"
          onChange={pagination => handleTableChanges(pagination)}
          pagination={pagination}
        >
          <Column
            key="make"
            title={t("currentAuctions.makeTitle")}
            render={(data: vehicleMakeType) => (
              <div
                className={styles.viewText}
                onClick={() => handleMakeView(parseInt(data?.id))}
              >
                {data?.name}
              </div>
            )}
          />
          <Column
            key="edit"
            render={(text, record, index) => (
              <div
                onClick={() => showEditMakeModal(index)}
                className={styles.editText}
              >
                Edit
              </div>
            )}
          />
        </Table>
      )}
      {!!(searchQuery.searchType === VehicleSearchContentTypes.Model) && (
        <Table
          dataSource={data}
          loading={isLoading}
          rowKey="id"
          pagination={pagination}
          onChange={pagination => handleTableChanges(pagination)}
        >
          <Column
            key="model"
            title={t("currentAuctions.modelTitle")}
            render={data => (
              <div
                className={styles.viewText}
                onClick={() => handleModelView(parseInt(data?.id))}
              >
                {data?.name}
              </div>
            )}
          />
          <Column
            key="make"
            title={"Make"}
            render={data => data?.vehicleMake?.name}
          />
          <Column
            key="inspectionReportType"
            title={"Inspection report type"}
            render={data =>
              data?.categories?.map(category => category.name).join(",")
            }
          />
          <Column
            key="edit"
            render={(text, record, index) => (
              <div
                onClick={() => showEditModelModal(index)}
                className={styles.editText}
              >
                Edit
              </div>
            )}
          />
        </Table>
      )}
      {!!(searchQuery.searchType === VehicleSearchContentTypes.Trim) && (
        <Table
          dataSource={data}
          rowKey="id"
          loading={isLoading}
          pagination={pagination}
          onChange={pagination => handleTableChanges(pagination)}
        >
          <Column key="trim" title={"Trims"} render={data => data?.name} />
          <Column
            key="model"
            title={"Model"}
            render={(data: trimType) => data?.vehicleModel?.name}
          />
          <Column
            key="make"
            title={"Make"}
            render={(data: trimType) => data?.vehicleModel?.vehicleMake?.name}
          />
          <Column
            key="edit"
            render={(text, record, index) => (
              <div
                onClick={() => showEditTrimModal(index)}
                className={styles.editText}
              >
                Edit
              </div>
            )}
          />
        </Table>
      )}
    </>
  );
};

export default VehicleSearch;
