import { Button, Form, Input, InputNumber, Modal, message } from "antd";
import React from "react";
import * as feesApi from "app/api/feesApi";
import DebounceSelect from "app/components/DebounceSelect/DebounceSelect";
import { SelectItem } from "app/components/DebounceSelect/types";
import { getListings as getCategories } from "app/api/dynamicCategoryApi";

interface CommissionType {
  commissionPercent: number;
  currencyCode: string;
  id: number;
  maxPrice: number;
  minPrice: number;
}

interface FeesModalProps {
  isVisible: boolean;
  handleClose: () => void;
  selectedFee: any;
  onLoad: () => void;
  data: CommissionType[];
}

const ManageFeesModal = ({
  selectedFee,
  isVisible,
  handleClose,
  onLoad,
  data
}: FeesModalProps) => {
  const { Item } = Form;
  let [form] = Form.useForm();

  const onFinish = values => {
    const body = new FormData();
    body.append("currencyCode", "SAR");

    if (values.minPrice) {
      body.append("minPrice", values.minPrice);
    }

    if (values.maxPrice) {
      body.append("maxPrice", values.maxPrice);
    }
    if (values.listingTypeId) {
      body.append("listingTypeId", values.listingTypeId?.value);
    }

    if (values.commissionPercent) {
      const commissionPercentage = (
        parseFloat(values.commissionPercent) / 100
      ).toString();
      body.append("commissionPercent", commissionPercentage);
    }

    if (selectedFee?.id !== undefined) {
      feesApi
        .updateFee(selectedFee?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Fee updated");
            onLoad();
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      feesApi
        .createFee(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            handleClose();
            onLoad();
            return message.success("Fee created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  async function fetchCategoriesList(category: string): Promise<SelectItem[]> {
    return getCategories({
      name: category
    }).then(res => {
      return res.data.map(({ id, name }) => ({
        label: name,
        value: id
      }));
    });
  }

  return (
    <Modal
      title={selectedFee ? "Edit Fee" : "Create Fee"}
      visible={isVisible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <Button form="createFeeForm" type="primary" htmlType="submit">
          {selectedFee ? "Edit Fee" : "Create Fee"}
        </Button>
      }
    >
      <Form
        name="createFeeForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div>
          <Item
            name="minPrice"
            rules={[
              { required: true, message: "Please enter a minimum price" }
            ]}
            label={"Minimum Price:"}
            initialValue={selectedFee?.minPrice}
          >
            <InputNumber />
          </Item>
          <Item
            name="maxPrice"
            rules={[
              { required: true, message: "Please enter a maximum price" }
            ]}
            label={"Maximum Price:"}
            initialValue={selectedFee?.maxPrice}
          >
            <InputNumber />
          </Item>
          <Item
            name="listingTypeId"
            rules={[
              {
                required: true,
                message: "Please enter a category"
              }
            ]}
            label={"Category:"}
            initialValue={{
              label: selectedFee?.listingType?.name,
              value: selectedFee?.listingType?.id
            }}
          >
            <DebounceSelect
              placeholder="eg. Cars"
              fetchOptions={fetchCategoriesList}
            />
          </Item>
          <Item
            name="commissionPercent"
            rules={[
              {
                required: true,
                message: "Please enter a commission percentage"
              }
            ]}
            label={"Commission Percentage:"}
            initialValue={Math.round(selectedFee?.commissionPercent * 100)}
          >
            <Input addonAfter={"%"} min={0} max={100} type="number" />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ManageFeesModal;
