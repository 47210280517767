import packageJson from "../../../package.json";

const env: any = process.env;

export const Env = {
  BASE_URL: env.REACT_APP_BASE_URL,
  VERSION: packageJson.version,
  NSTACK_APP_ID: env.REACT_APP_NSTACK_APP_ID,
  NSTACK_API_KEY: env.REACT_APP_NSTACK_API_KEY,
  NODE_ENV: env.NODE_ENV,
};
