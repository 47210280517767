import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Typography, Input, message } from "antd";
import {
  deleteLiveAuction,
  deleteLiveAuctionLot
} from "app/api/liveAuctionsApi";
import { LiveAuctionsDataType, Tabs } from "app/types/LiveAuctionsType";
import { AuctionData } from "app/types/auctionShowsType";
import React from "react";

interface EmergencyDeleteModalProps {
  onClose: () => void;
  onCreate: () => void;
  isVisible: boolean;
  isAuction?: boolean;
  selectedLiveAuction?: LiveAuctionsDataType | AuctionData;
  selectedLot?: Tabs;
}

const EmergencyDeleteModal = ({
  isVisible,
  isAuction,
  onClose,
  selectedLiveAuction,
  selectedLot,
  onCreate
}: EmergencyDeleteModalProps) => {
  const { Title } = Typography;
  const { TextArea } = Input;
  const { Item } = Form;

  let [form] = Form.useForm();

  const deleteTitle = (
    <div>
      <Title type="danger" level={5}>
        <WarningOutlined
          style={{ color: "#FF0000", marginRight: "5px", fontSize: "1.2em" }}
        />
        Emergency Delete Live {isAuction ? "Auction" : "Lot"} in Progress
      </Title>
    </div>
  );

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleEmergencyDelete = (selectedLiveAuction: any, values: any) => {
    if (!selectedLiveAuction && !selectedLot) return;

    const id = selectedLot?.id || selectedLiveAuction.id;
    const body = {
      isFinished: true,
      reason: values.message || "Cancelled"
    };

    const deletePromise = selectedLot
      ? deleteLiveAuctionLot(id, body)
      : deleteLiveAuction(id, body);

    deletePromise
      .then(() => {
        setTimeout(
          () => message.success(`${isAuction ? "Auction" : "Lot"} deleted`),
          300
        );
        onCreate();
        onClose();
      })
      .catch(error => message.error("Something went wrong"));
  };

  return (
    <Modal
      title={deleteTitle}
      visible={isVisible}
      onCancel={handleClose}
      width={460}
      footer={[
        <Button key={"cancel"} type="text" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={"delete"}
          form="emergencyDeleteForm"
          icon={<DeleteOutlined />}
          type="primary"
          htmlType="submit"
          danger
        >
          Delete {isAuction ? "Auction" : "Lot"}
        </Button>
      ]}
    >
      <h3>Are you sure?</h3>
      <h4>
        Deleting a Live {isAuction ? "Auction" : "Lot"} while it’s in progress
        will destroy the streaming link. This action is not reversible.
      </h4>
      <p>
        Add a unique message to notify registered bidders of the emergency
        cancellation.
      </p>
      <Form
        name="emergencyDeleteForm"
        layout="vertical"
        form={form}
        onFinish={values =>
          handleEmergencyDelete(
            isAuction ? selectedLiveAuction : selectedLot,
            values
          )
        }
      >
        <Item
          name="message"
          rules={[{ required: true, message: "Please enter a message" }]}
          label={"Message:"}
          initialValue={""}
        >
          <TextArea rows={4} />
        </Item>
      </Form>
    </Modal>
  );
};

export default EmergencyDeleteModal;
