import { useLocation } from "react-router-dom";
import styles from "./PageLayoutTable.module.scss";
import React, { useEffect, useState } from "react";
import * as pageLayoutApi from "app/api/pageLayoutApi";
import { getListings } from "app/api/dynamicCategoryApi";
import TabsModal from "./Components/TabsModal/TabsModal";
import { getPageWidgets, sortWidgets } from "app/api/pageLayoutApi";
import { MenuOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Modal, PageHeader, Table, message } from "antd";
import WidgetCreateModal from "./Components/WidgetsModal/WidgetCreateModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  CategoriesType,
  TabType,
  Widget,
  WidgetType
} from "app/types/pageLayoutType";

const PageLayoutTable = () => {
  const location = useLocation();
  const path = location.pathname.slice(1);
  const [data, setData] = useState<WidgetType[]>([]);
  const [categories, setCategories] = useState<CategoriesType>();
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [selectedTab, setSelectedTab] = useState<TabType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreateWidget, setIsCreateWidget] = useState<boolean>(false);
  const [isCreateTab, setIsCreateTab] = useState<boolean>(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [widgetId, setWidgetId] = useState<string>();

  const columns = [
    {
      key: "sort",
      title: "Sort",
      dataIndex: "sort",
      render: () => <MenuOutlined className={styles.dragHandle} />
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
      render: text => text
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "widget_type",
      render: widgetType => widgetType?.replace(/-/g, " ") ?? "-"
    },
    {
      key: "category",
      title: "Category",
      dataIndex: ["items", 0, "attributes", "category"],
      render: (category, record) =>
        !Array.isArray(category) && record?.widget_type !== "multitab"
          ? getCategoryNameById(category)
          : "-"
    },
    {
      key: "listing",
      title: "Listing",
      dataIndex: ["items", 0, "attributes", "type"],
      render: (type, record) =>
        type && record?.widget_type !== "multitab"
          ? type?.replace(/-/g, " ")
          : "-"
    },
    {
      key: "featured",
      title: "Featured",
      dataIndex: ["items", 0, "attributes", "featured"],
      render: (featured, record) =>
        featured && record?.widget_type !== "multitab" ? featured : "-"
    },
    {
      key: "state",
      title: "State",
      dataIndex: ["items", 0, "attributes", "state"],
      render: (state, record) =>
        state && record?.widget_type !== "multitab" ? state : "-"
    },
    {
      key: "order",
      title: "Order",
      dataIndex: ["items", 0, "attributes", "order"],
      render: (order, record) =>
        order && record?.widget_type !== "multitab"
          ? order?.replace(/_/g, " ")
          : "-"
    },
    {
      key: "action",
      title: "Action",
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => toggleWidgetModal(record)}>
            Edit
          </Button>
          <Button danger type="link" onClick={() => handleDeleteWidget(record)}>
            Delete
          </Button>
        </>
      )
    }
  ];

  useEffect(() => {
    loadWidgetsList();
  }, []);

  const loadWidgetsList = async () => {
    try {
      setLoading(true);
      const payload = await getPageWidgets(path);
      setData(payload?.widgets);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListings()
      .then(payload => {
        setCategories(payload.data);
      })
      .catch(error => console.log(error));
  }, []);

  const getCategoryNameById = id => {
    const category = categories?.find((category: any) => category.id === +id);
    return category ? category.name : "-";
  };

  const toggleWidgetModal = record => {
    setIsCreateWidget(true);
    setSelectedWidget(record);
  };

  const toggleTabsModal = (record, widgetId) => {
    setIsCreateTab(true);
    setSelectedTab(record);
    setWidgetId(widgetId);
  };

  const handleDeleteWidget = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this widget?",
      content: `You’re about to delete ${record.title}.`,
      okText: "Yes, delete widget",
      okType: "danger",
      onOk() {
        return pageLayoutApi
          .deleteWidget(path, record.id)
          .then(p => {
            if (p.status === 200) {
              message.success("Widget Deleted");
              loadWidgetsList();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const handleDeleteTab = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this tab?",
      content: `You’re about to delete ${record.title}.`,
      okText: "Yes, delete tab",
      okType: "danger",
      onOk() {
        return pageLayoutApi
          .deleteTabbedWidget(path, record.id)
          .then(p => {
            if (p.status === 200) {
              message.success("Widget Deleted");
              loadWidgetsList();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    try {
      const items = [...data];

      const [movedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, movedItem);

      setData(items);

      const newData = items.map((item: any) => item.id);

      if (!newData.length) return;

      const body = { order: newData };

      const response = await sortWidgets(body, path);

      if (response.status === 200) {
        loadWidgetsList();
        message.success("Widgets Reordered");
      } else {
        message.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Header = () => (
    <PageHeader
      title={`${path} page`}
      className={styles.pageHeader}
      extra={[
        <Button
          type="primary"
          key="create"
          disabled={data.length >= 12}
          onClick={() => toggleWidgetModal(null)}
        >
          Create Widget
        </Button>
      ]}
    />
  );

  const DraggableContainer = (props: any) => (
    <Droppable droppableId="fieldsData" {...props}>
      {provided => (
        <tbody
          className={styles.draggableContainer}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.children}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { "data-row-key": dataRowKey } = restProps;
    const index = data.findIndex(x => x.id === Number(dataRowKey));
    return (
      <Draggable
        key={`${dataRowKey}`}
        draggableId={`${dataRowKey}`}
        index={index !== -1 ? index : 0}
      >
        {provided => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...restProps}
            className={data?.length > 0 ? styles.draggableRow : ""}
          />
        )}
      </Draggable>
    );
  };

  const childColumns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Listing", dataIndex: ["attributes", "type"], key: "listing" },
    {
      title: "Category",
      dataIndex: ["attributes", "category"],
      key: "category",
      render: category => (category ? getCategoryNameById(category) : "-")
    },
    {
      title: "State",
      dataIndex: ["attributes", "state"],
      key: "state"
    },
    {
      title: "Order",
      dataIndex: ["attributes", "order"],
      key: "order"
    },
    {
      title: "Featured",
      dataIndex: ["attributes", "featured"],
      key: "featured"
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: (text, record, index) => (
        <>
          <Button
            key={"edit"}
            type="link"
            onClick={() => toggleTabsModal(record, null)}
          >
            Edit
          </Button>
          <Button
            key={"delete"}
            danger
            type="link"
            onClick={() => handleDeleteTab(record)}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  const expandedRowRender = record => (
    <div key={record.id} className={styles.childWrapper}>
      <Button
        onClick={() => toggleTabsModal(null, record.id)}
        icon={<PlusCircleOutlined />}
        block
      >
        Add Tab
      </Button>
      <Table
        key={record.id}
        rowKey={childRecord => childRecord.childId}
        columns={childColumns}
        dataSource={record.items}
        pagination={false}
        showHeader={false}
        size="small"
        scroll={{ y: 170 }}
      />
    </div>
  );

  return (
    <>
      {isCreateWidget && (
        <WidgetCreateModal
          path={path}
          categories={categories}
          isVisible={isCreateWidget}
          selectedWidget={selectedWidget}
          onLoad={() => loadWidgetsList()}
          handleCancel={() => setIsCreateWidget(false)}
        />
      )}
      {isCreateTab && (
        <TabsModal
          isVisible={isCreateTab}
          handleCancel={() => setIsCreateTab(false)}
          selectedTab={selectedTab}
          categories={categories}
          onLoad={() => loadWidgetsList()}
          path={path}
          widgetId={widgetId}
        />
      )}

      <div className={styles.tableContainer}>
        <Card>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table
              key="index"
              title={Header}
              dataSource={data}
              columns={columns}
              loading={loading}
              pagination={false}
              rowKey={record => record.id}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow
                }
              }}
              expandable={{
                expandedRowRender,
                expandedRowKeys,
                rowExpandable: record => record?.widget_type === "multitab",
                childrenColumnName: "child",
                onExpandedRowsChange: (newExpandedRowKeys: any) => {
                  setExpandedRowKeys(newExpandedRowKeys);
                }
              }}
            />
          </DragDropContext>
        </Card>
      </div>
    </>
  );
};

export default PageLayoutTable;
