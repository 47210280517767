import React from "react";
import { auctionType } from "app/types/auctionType";
import { useTranslation } from "react-i18next";
import styles from "../AuctionFormItems.module.scss";
import PreviewItem from "../components/PreviewItem";

interface BuyerInformationPreviewProps {
  buyerInformation?: auctionType;
}

const BuyerInformationPreview = ({
  buyerInformation,
}: BuyerInformationPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.formPreviewItemsContainer}>
      <h3>Buyer Information</h3>

      <PreviewItem
        label={t("newAuctions.givenNameLabelTitle")}
        value={buyerInformation?.buyer?.name}
      />
      <PreviewItem
        label={t("newAuctions.phoneLabelTitle")}
        value={buyerInformation?.buyer?.mobile}
      />

      <PreviewItem
        label={t("newAuctions.emailLabelTitle")}
        value={buyerInformation?.buyer?.email}
      />

      <PreviewItem
        label="Total price:"
        value={
          buyerInformation?.buyer?.prices?.total?.amount +
          " " +
          buyerInformation?.buyer?.prices?.total?.currency?.code
        }
      />
    </div>
  );
};

export default BuyerInformationPreview;
