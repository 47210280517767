import { Card } from "antd";
import React from "react";
import VehicleSearch from "./components/VehicleSearch/VehicleSearch";
import styles from "./VehicleCategoriesScreen.module.scss";

export enum MenuTabs {
  Makes = "makes",
  Models = "models",
  Trims = "trims"
}

const VehicleCategoriesScreen = () => {
  return (
    <div className={styles.tableContainer}>
      <Card>
        <VehicleSearch />
      </Card>
    </div>
  );
};

export default VehicleCategoriesScreen;
