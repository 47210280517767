import { Button, Form, Input, Modal, Upload, UploadProps, message } from "antd";
import styles from "./ManageAdvertsModal.module.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  beforeUpload,
  dataURLtoFile,
  getBase64
} from "app/helpers/imageUploadHelper";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import * as adsApi from "app/api/advertsApi";

const ManageAdvertsModal = ({
  isVisible,
  handleClose,
  selectedAdvert,
  onLoad
}) => {
  const { Item } = Form;
  let [form] = Form.useForm();
  const imageToBase64 = useRef<HTMLCanvasElement>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [previewVisible, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const onFinish = values => {
    const body = new FormData();

    if (values.title) {
      body.append("title", values.title);
    }

    if (values.titleAr) {
      body.append("title_localized", values.titleAr);
    }

    if (values.link) {
      body.append("link", values.link);
    }

    if (values.descriptionAr) {
      body.append("descriptionAr", values.descriptionAr);
    }

    // Process and convert each image in array to a blob
    if (values.media) {
      let ins = values.media.length;
      for (let x = 0; x < ins; x++) {
        const image = values.media[x].originFileObj;

        if (!values.media[x].skip) body.append("media[]", image);
      }
    }

    if (selectedAdvert?.id !== undefined) {
      adsApi
        .updateAdvert(selectedAdvert?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            handleClose();
            message.success("Advert updated");
            onLoad();
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      adsApi
        .createAdvert(body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 201) {
            handleClose();
            onLoad();
            return message.success("Advert created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      media: fileList
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (
      !!imageToBase64 &&
      selectedAdvert?.media &&
      selectedAdvert?.media.length > 0
    ) {
      const formattedMediaImages = selectedAdvert?.media.map(
        (image: any): any => {
          imageToBase64.current?.setAttribute("src", image.url);
          const dataInBase64 = imageToBase64.current?.toDataURL(image.mimeType);
          const imageFile = dataURLtoFile(dataInBase64, image.fileName);

          return {
            uid: image.id.toString(),
            thumbUrl: image.url,
            url: image.url,
            size: image.size,
            name: image.fileName,
            type: image.mimeType,
            originFileObj: imageFile,
            status: "done",
            skip: true
          };
        }
      );
      setFileList(formattedMediaImages);
    }
  }, [selectedAdvert]);

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image or video</p>
      <p className={styles.uploadHint}>
        (Images or Videos must be less than 5MB, format png, jpg, jpeg or mp4
        and a ratio of 2:1 )
      </p>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Modal
      title={selectedAdvert ? "Edit Advert" : "Create Advert"}
      visible={isVisible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <Button form="createAdvertForm" type="primary" htmlType="submit">
          {selectedAdvert ? "Edit Advert" : "Create Advert"}
        </Button>
      }
    >
      <Form
        name="createAdvertForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div>
          <Item
            className={styles.inlineInput}
            name="title"
            rules={[{ required: true, message: "Please enter a title" }]}
            label={"Advert Title:"}
            initialValue={selectedAdvert?.title}
          >
            <Input type="text" />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="titleAr"
            rules={[{ required: true, message: "Please enter translation" }]}
            label={"Arabic Translation:"}
            initialValue={selectedAdvert?.title_localized}
          >
            <Input type="text" />
          </Item>
          <Item
            name="link"
            rules={[{ required: false, message: "Please enter a valid link" }]}
            label={"Link:"}
            initialValue={selectedAdvert?.link}
          >
            <Input type="text" placeholder={"Paste a link here"} />
          </Item>
          <Item name="media" label="Media:" valuePropName="fileList" extra={""}>
            <canvas className={styles.canvas} ref={imageToBase64}></canvas>
            <div className={styles.upload}>
              <Upload
                accept="image/png,image/jpeg,image/jpg,video/mp4"
                listType="picture-card"
                defaultFileList={[]}
                maxCount={1}
                beforeUpload={beforeUpload}
                fileList={fileList}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true
                }}
                customRequest={customUpload}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="AdsImage"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ManageAdvertsModal;
