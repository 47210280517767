import { UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { resetPassword } from "app/api/authenticationApi";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const onFinish = values => {
    resetPassword(values.email)
      .then(resp => message.success("Reset Password Link sent to your email."))
      .catch(err => message.error("Something Wrong happened, try again."));
  };

  const { t } = useTranslation();
  const { Item } = Form;

  return (
    <div>
      <Form name="login" layout="vertical" onFinish={onFinish}>
        <div>{t("login.requestPassword")}</div>
        <Item
          name="email"
          label="Email"
          rules={[{ required: true, message: t("login.emailValidation") }]}
        >
          <Input prefix={<UserOutlined />} type="email" placeholder="Email" />
        </Item>

        <Item>
          <Button htmlType="submit" type="primary">
            {t("login.emailSend")}
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default memo(ForgotPassword);
