import { useTranslation } from "react-i18next";
import React, { useState, useEffect, memo } from "react";
import { Input, Form, Select } from "antd";
import cx from "classnames";
import styles from "./NewAuctionFormItems.module.scss";
import { auctionType } from "app/types/auctionType";
import { getAppUsers } from "app/api/usersApi";
import { userType } from "app/types/userType";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { FormInstance } from "antd/lib/form";

interface FormItemProps {
  isActive: boolean;
  userInformationValue?: auctionType;
  isMachinery?: boolean;
  disableNext: (disabled: boolean) => void;
  form: FormInstance;
}

const SellerInformationFormItems = ({
  userInformationValue,
  isActive,
  isMachinery,
  disableNext,
  form
}: FormItemProps) => {
  const [appUsers, setAppUsers] = useState<[userType]>();

  const location = useLocation();
  const { t } = useTranslation();

  const fields = ["seller[saudiId]"];

  useEffect(() => {
    if (isMachinery) {
      disableNext(true);
      getAppUsers(qs.parse(location.search))
        .then(payload => {
          setAppUsers(payload.data);
        })
        .catch(error =>
          console.log(error, "Something went wrong fetching app users")
        );
    }
  }, [isMachinery, disableNext, location.search]);

  useEffect(() => {
    if (isActive) {
      form.validateFields(fields).catch(error => {
        disableNext(error.errorFields.length > 0);
      });
    }
  }, [isActive, disableNext, fields, form, userInformationValue]);

  /**
   * Check if the value is changed to re-enable the next button
   *
   * @param {number} value
   */
  const checkValueSelected = (value: number) => {
    if (value) {
      disableNext(false);
    }
  };

  const { Item } = Form;
  const { TextArea } = Input;
  const { Option } = Select;

  return (
    <div className={cx(styles.formItem, { [styles.isActive]: isActive })}>
      {!isMachinery && (
        <div>
          <Item
            name="givenName"
            required={true}
            label={t("newAuctions.givenNameLabelTitle")}
            initialValue={userInformationValue?.seller?.firstName}
          >
            <Input type="text" />
          </Item>
          <Item
            name="surName"
            required={true}
            label={t("newAuctions.surNameLabelTitle")}
            initialValue={userInformationValue?.seller?.lastName}
          >
            <Input type="text" />
          </Item>
          <Item
            name="address"
            required={true}
            label={t("newAuctions.addressLabelTitle")}
            initialValue={userInformationValue?.seller?.address}
          >
            <Input disabled={true} type="text" />
          </Item>
          <Item
            name="city"
            required={true}
            label={t("newAuctions.cityLabelTitle")}
            initialValue={userInformationValue?.seller?.city}
          >
            <Input disabled={true} type="text" />
          </Item>
          <Item
            name="region"
            required={true}
            label={t("newAuctions.regionLabelTitle")}
            initialValue={userInformationValue?.seller?.state}
          >
            <Input disabled={true} type="text" />
          </Item>
          <Item
            name="email"
            required={true}
            label={t("newAuctions.emailLabelTitle")}
            initialValue={userInformationValue?.seller?.email}
          >
            <Input disabled={true} type="email" />
          </Item>
          <Item
            name="areaCode"
            required={true}
            label={t("newAuctions.areaCodeLabelTitle")}
            // API needs to return area code attribute
            initialValue={userInformationValue?.seller?.areaCode}
          >
            <Input disabled={true} type="text" />
          </Item>
          <Item
            name="phone"
            required={true}
            label={t("newAuctions.phoneLabelTitle")}
            initialValue={userInformationValue?.seller?.mobile}
          >
            <Input disabled={true} type="text" />
          </Item>
        </div>
      )}

      {isMachinery && (
        <Item
          name="appUser"
          rules={[{ required: true, message: t("newAuctions.appUserText") }]}
          label={t("newAuctions.appUserLabelTitle")}
        >
          <Select size="middle" onChange={e => checkValueSelected(e as number)}>
            {appUsers?.map((user, index) => (
              <Option key={index} value={user?.id}>
                {user?.firstName}
              </Option>
            ))}
          </Select>
        </Item>
      )}
      <Item name="notes" label="Notes">
        <TextArea disabled={!isMachinery} />
      </Item>
    </div>
  );
};

export default memo(SellerInformationFormItems);
