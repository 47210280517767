import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "query-string";

import { PaginationConfig } from "antd/lib/pagination";
import { TablePaginationConfig } from "antd/lib/table";
import {
  Card,
  Table,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Checkbox
} from "antd";
import Column from "antd/lib/table/Column";

import { getAppUsers } from "app/api/usersApi";

import UploadIBANsModal from "./components/UploadIBANs/UploadIBANsModal";
import AssignDepositModal from "./components/AssignDepositModal/AssignDepositModal";
import BalanceHistoryModal from "./components/BalanceHistoryModal/BalanceHistoryModal";

import UserDetailsModal from "./components/UserDetailsModal/UserDetailsModal";

import { QueryHelper } from "app/helpers/QueryHelper";

import styles from "./AppUsersScreen.module.scss";
import { userType } from "app/types/userType";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as userApi from "app/api/usersApi";
import moment from "moment";

const dateFormat = "YYYY-MM-DD HH:mm:ss";

const AppUsersScreen = () => {
  const INITIAL_PAGINATION: PaginationConfig = {
    current: 0,
    pageSize: 0,
    total: 0,
    showSizeChanger: false
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<userType[]>([]);
  const [isIBANModalVisible, setIsIBANModalVisible] = useState<boolean>(false);
  const [isAssignDepositVisible, setIsAssignDepositVisible] = useState<boolean>(
    false
  );
  const [isViewDepositsVisible, setIsViewDepositsVisible] = useState<boolean>(
    false
  );
  const [isUserDetailsVisible, setIsUserDetailsVisible] = useState<boolean>(
    false
  );

  const [selectedUser, setSelectedUser] = useState<any>(undefined);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);

  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const [sellersChecked, setSellersChecked] = useState<boolean>();

  const fetchUsers = async () => {
    setLoading(true); // Setting loading state to true before fetching
    try {
      const payload = await getAppUsers(qs.parse(location.search));
      const {
        data: users,
        meta: { current_page, per_page, total }
      } = payload;
      setData(users);
      setPagination({
        ...pagination,
        current: current_page,
        pageSize: per_page,
        total
      });
    } catch (error) {
      console.error(error); // Using console.error for errors
    } finally {
      setLoading(false); // Setting loading state to false after fetching
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isAssignDepositVisible, isUserDetailsVisible]);

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    // Get value from formFields and add them to the values object
    values = {
      ...currentSearch,
      ...values,
      role: sellersChecked ? "seller" : ""
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const showUserDeleteConfirmationModal = (user: any) => {
    confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No, Cancel",
      onOk() {
        return userApi
          .deleteUser(user.id)
          .then(p => {
            if (p.status === 204) {
              message.success("User Deleted");
              fetchUsers();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const { Item } = Form;

  let [form] = Form.useForm();

  const handleSellersCheckboxChange = (checked: boolean) => {
    setSellersChecked(checked);
  };

  const handleReset = () => {
    // Reset form fields
    form.resetFields();
    setSellersChecked(false);
  };

  return (
    <div>
      <Card className={styles.filterAndSearchContainer}>
        <Form onFinish={(values: any) => handleSearch(values)} form={form}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6}>
              <Item name="userId" label="User ID">
                <Input placeholder="User ID" />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="mobile" label={t("users.phoneTitle")}>
                <Input placeholder={t("users.phonePlaceholder")} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="firstName" label={"First name"}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="lastName" label={"Last name"}>
                <Input placeholder={"e.g.Smith"} />
              </Item>
            </Col>
            <Col span={6}>
              <Item name="email" label={t("users.emailTitle")}>
                <Input type="email" placeholder={t("users.emailPlaceholder")} />
              </Item>
            </Col>
            {/* <Col span={6}>
              <Item name='virtualIban' label='Virtual Iban'>
                <Input placeholder='Virtual Iban' />
              </Item>
            </Col> */}
            <Col span={6}>
              <Item name="sellerName" label={"Shop Name"}>
                <Input placeholder={"e.g. John"} />
              </Item>
            </Col>
            <Col span={4}>
              <Item name="shipped">
                <Checkbox
                  value={sellersChecked ? true : false}
                  onChange={e => handleSellersCheckboxChange(e.target.checked)}
                >
                  Sellers
                </Checkbox>
              </Item>
              {/* <Item name='virtualIbanCustomerId' label='Customer ID'>
                <Input placeholder='Customer ID' />
              </Item> */}
            </Col>
            <Col span={6}>
              <Button
                className={styles.searchButton}
                type="primary"
                htmlType="submit"
              >
                {t("default.searchButton")}
              </Button>
              <Button onClick={handleReset}>{t("default.resetButton")}</Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <div className={styles.tableContainer}>
        <Card>
          <div>
            {!!isIBANModalVisible && (
              <UploadIBANsModal
                isVisible={isIBANModalVisible}
                onClose={() => setIsIBANModalVisible(false)}
              />
            )}
            {!!selectedUser && !!isAssignDepositVisible && (
              <AssignDepositModal
                isVisible={isAssignDepositVisible}
                onClose={() => setIsAssignDepositVisible(false)}
                selectedUser={selectedUser}
              />
            )}
            {!!selectedUser && !!isViewDepositsVisible && (
              <BalanceHistoryModal
                isVisible={isViewDepositsVisible}
                onClose={() => setIsViewDepositsVisible(false)}
                selectedUser={selectedUser}
              />
            )}
            {!!selectedUser && !!isUserDetailsVisible && (
              <UserDetailsModal
                isVisible={isUserDetailsVisible}
                onClose={() => {
                  setIsUserDetailsVisible(false);
                  setSelectedUser(undefined);
                }}
                selectedUser={selectedUser}
                onLoad={() => fetchUsers()}
              />
            )}
            <div className={styles.auctionRequestOverview}>
              <h2 className={styles.headerContainer}>
                {t("users.appUsersTitle")}
              </h2>
              {/* TODO: update translation */}
              <Button
                type="primary"
                onClick={() => setIsIBANModalVisible(true)}
              >
                Upload Virtual IBANs
              </Button>
            </div>
            <Table
              dataSource={data}
              loading={loading}
              rowKey={obj => obj.id.toString()}
              onChange={pagination => handleTableChanges(pagination)}
              pagination={pagination}
            >
              <Column
                key="id"
                title={t("users.userIdTitle")}
                dataIndex="id"
                render={(text, record: any, index) => (
                  <div
                    className={styles.assignDepositButton}
                    onClick={() => {
                      setSelectedUser(data[index]);
                      setIsUserDetailsVisible(true);
                    }}
                  >
                    {record.id}
                  </div>
                )}
              />
              <Column
                key="nameTitle"
                title={t("users.nameTitle")}
                render={record => record.firstName}
              />
              {/* <Column
                key="middleNameTitle"
                title={t("users.middleNameTitle")}
                render={record => record.middleName}
              /> */}
              <Column
                key="surnameTitle"
                title={t("users.surnameTitle")}
                render={record => record.lastName}
              />
              <Column
                key="shop"
                title={t("Shop Name")}
                render={record => record?.shop?.name}
              />
              {/* TODO: Replace with translation */}
              {/* <Column
                key='iban'
                title='Virtual IBAN'
                render={(record) => record.virtualIban}
              /> */}
              {/* <Column
                key='virtualIbanCustomerId'
                title=' Customer Id'
                render={(record) => record.virtualIbanCustomerId}
              /> */}
              <Column
                key="phone"
                title={t("users.phoneTitle")}
                render={record => record.mobile}
              />
              <Column
                key="balance"
                title={t("Wallet Amount")}
                render={(text, record: any, index) => (
                  <div
                    className={styles.viewDepositButton}
                    onClick={() => {
                      setSelectedUser(record);
                      setIsViewDepositsVisible(true);
                    }}
                  >
                    {record.deposit.currency.code +
                      " " +
                      record.deposit.amount || "SAR 0.00"}
                  </div>
                )}
              />
              <Column
                key="createdAt"
                title={"Created At"}
                dataIndex="createdAt"
                sorter={(a: any, b: any) =>
                  moment(a.createdAt).unix() - moment(b.createdAt).unix()
                }
                render={date => moment(date).format(dateFormat)}
              />
              {/* <Column
                key='lastDeposit'
                title={'Last Recharged'}
                dataIndex='lastDeposit'
                sorter={(a: any, b: any) =>
                  moment(a.lastDeposit.updatedAt).unix() -
                  moment(b.lastDeposit.updatedAt).unix()
                }
                render={(record: any) =>
                  record?.updatedAt
                    ? moment(record.updatedAt).format(dateFormat)
                    : 'N/A'
                }
              /> */}
              <Column
                key="action"
                title={t("users.actionsTitle")}
                render={(text, record: any, index) => (
                  <>
                    <Button
                      type="link"
                      onClick={() => {
                        setSelectedUser(record);
                        setIsAssignDepositVisible(true);
                      }}
                    >
                      {t("users.assignDeposit")}
                    </Button>
                    <Button
                      danger
                      type="link"
                      onClick={() => showUserDeleteConfirmationModal(record)}
                    >
                      Delete User
                    </Button>
                  </>
                )}
              />
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AppUsersScreen;
