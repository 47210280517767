import { CloseOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React from "react";

interface CategoryCardProps {
  title: any;
  cardContent?: any;
  visible?: () => void;
  extra?: boolean;
}

const CategoryCard = ({
  title,
  cardContent,
  visible,
  extra
}: CategoryCardProps) => {
  return (
    <Card
      title={title}
      extra={
        extra && (
          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={visible}
          />
        )
      }
    >
      {cardContent}
    </Card>
  );
};

export default CategoryCard;
