import React, { memo, useEffect } from "react";
import { Modal, Form, DatePicker, message } from "antd";
import moment from "moment";
import * as Api from "../../../../api/auctionVehiclesApi";

function range(start, end) {
  const result: any[] = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
const EditEndDateModal = ({ auction, visible, onCancel, onSave }) => {
  useEffect(() => {}, [visible]);
  const { Item } = Form;
  let [form] = Form.useForm();
  const saveDate = async () => {
    try {
      const res = await Api.updateAuctionVehicle(
        auction.id,
        {
          endAt: moment(form.getFieldValue("endDate")).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        },
        false
      );
      if (res.status !== 200) {
        throw new Error("Something wrong happened, try again.");
      }
      onSave();
      message.success("Auction End Date updated successfully.");
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      okText="Change Auction End Date"
      title="Update Auction"
      onOk={saveDate}
    >
      <Form onFinish={(values: any) => false} form={form}>
        <Item
          name="endDate"
          label="Auction End Date"
          rules={[{ required: true, message: "End date is required!" }]}
          initialValue={auction?.endAt ? moment.utc(auction?.endAt) : null}
        >
          <DatePicker
            showTime={{ format: "HH:mm" }}
            style={{ width: 300 }}
            format={"YYYY-MM-DD HH:mm:ss"}
            disabledDate={current => {
              return current && current <= moment();
            }}
            allowClear={false}
            disabledTime={(current: any) => {
              const now = moment();
              return {
                disabledHours: () =>
                  range(0, 60).splice(
                    0,
                    current.date() > now.date() ? 0 : now.hour()
                  ),
                disabledMinutes: () =>
                  range(0, 60).splice(
                    0,
                    current.date() > now.date() ? 0 : now.minute()
                  ),
                disabledSeconds: () => [55, 56]
              };
            }}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default memo(EditEndDateModal);
