import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";

import styles from "./SettingsAuctionModal.module.scss";
import { auctionType } from "app/types/auctionType";
import { updateDownPaymentAmount } from "../../../../api/auctionVehiclesApi";

interface SettingsAuctionModalProps {
  isVisible: boolean;
  isSuperAdmin?: boolean;
  onEdit?: () => void;
  onClose: () => void;
  isEnded?: boolean;
  downPaymentAmount?: number;
  buyerInformation?: auctionType;
}

interface FormItemsWithContextProps extends SettingsAuctionModalProps {
  form: FormInstance;
}
const { Item } = Form;

const SettingsAuctionModal = ({
  isVisible,
  downPaymentAmount,
  onClose
}: FormItemsWithContextProps) => {
  const [downPayment, setDownPayment] = useState(downPaymentAmount);
  const [form] = Form.useForm();
  useEffect(() => {}, [downPayment]);
  const handleClose = () => {
    onClose();
  };

  const onFinish = async values => {
    setDownPayment(values.amount);
    updateDownPaymentAmount(values.amount).then(payload => {
      onClose();
    });
  };

  return (
    <Modal
      title={`Auction Settings`}
      visible={isVisible}
      destroyOnClose
      width={700}
      onCancel={handleClose}
      footer={
        <div className={styles.modalFooter}>
          <Button form="updateDownPayment" htmlType="submit" type="primary">
            Save
          </Button>
        </div>
      }
    >
      <div className={styles.previewModalContainer}>
        <Form form={form} name="updateDownPayment" onFinish={onFinish}>
          <Item
            name="amount"
            label="Down Payment Amount"
            required={true}
            initialValue={downPayment}
          >
            <Input
              type="number"
              placeholder="Down Payment Amount"
              value={downPayment}
            />
          </Item>
        </Form>
      </div>
    </Modal>
  );
};

export default SettingsAuctionModal;
