import { getUserFromLocalStorage } from "app/helpers/authenticationHelper";
import React, { memo } from "react";
import { Redirect } from "react-router-dom";

const PERMISSIONS_KEY = "permissions";

interface IProps {
  /**
   * Wrapped elements to be outputted given the required
   * permissions are met
   */
  children: React.ReactNode;
  /**
   * Element to display in case of required permissions
   * haven't been met
   */
  fallback?: React.ReactNode;
  /**
   * Toggles whether a user must have all of the required
   * permissions, or default is at least one of them
   */
  hasAll?: boolean;
  /**
   * Where to direct the user, if required permissions
   * aren't met. If no path is supplied, the user will
   * not be redirected.
   */
  redirect?: string;
  /**
   * An array of required permissions
   */
  requiredPermissions: string[];
}

/**
 * The permission component allows you to only display
 * content to users that have the required permissions.
 * It can be used in two ways. First one is wrapping the
 * content that is restricted, and using it as a HOC. The
 * second way, is to use the hasPermission function, to then
 * enable or disable buttons, for example.
 */

const Permission = ({
  children,
  fallback,
  hasAll,
  redirect,
  requiredPermissions
}: IProps) => {
  const allowed = hasPermission(requiredPermissions, hasAll);

  if (allowed) {
    /**
     * In case there is more than one child element, we need
     * to wrap the whole thing in a fragment.
     */
    return <>{children}</>;
  } else if (!allowed && redirect) {
    return <Redirect to={redirect} />;
  } else if (!allowed && fallback) {
    return <>{fallback}</>;
  } else {
    return null;
  }
};

export default memo(Permission);

const getPermissions = fallback => {
  const permissions = sessionStorage.getItem(PERMISSIONS_KEY);

  return (permissions && permissions.split(",")) ?? fallback;
};

export const setPermissions = (permissions: string[]) => {
  sessionStorage.setItem(PERMISSIONS_KEY, permissions.toString());
};

export const hasPermission = (
  requiredPermissions: string[],
  hasAll?: boolean
) => {
  const userFromLocalStorage = getUserFromLocalStorage();
  const permissions = getPermissions([userFromLocalStorage?.role?.name]);

  let allowed = false;

  if (permissions) {
    if (hasAll) {
      allowed = requiredPermissions.every(permission =>
        permissions.includes(permission)
      );
    } else {
      allowed = requiredPermissions.some(permission =>
        permissions.includes(permission)
      );
    }
  } else {
    console.warn(
      "No permissions detected. Did you remember to use setPermissions() to set the permissions?"
    );
  }

  return allowed;
};

export const clearPermissions = () => {
  sessionStorage.removeItem(PERMISSIONS_KEY);
};
