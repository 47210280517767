import React, { useEffect, useState } from "react";
import styles from "./ManageAdverts.module.scss";
import ManageAdvertsModal from "./Components/ManageAdvertsModal";
import {
  Button,
  Card,
  Modal,
  PageHeader,
  Table,
  Image,
  TablePaginationConfig,
  message
} from "antd";
import { getAdverts } from "app/api/advertsApi";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { PaginationConfig } from "antd/lib/pagination";
import { QueryHelper } from "app/helpers/QueryHelper";
import * as adsApi from "app/api/advertisementsApi";
import { advertisementType } from "app/types/advertType";

interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const ManageAdverts = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<advertisementType[]>([]);
  const [selectedAdvert, setSelectedAdvert] = useState<advertisementType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreateAdvert, setIsCreateAdvert] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION);

  useEffect(() => {
    loadAdvertListings();
  }, [location.search]);

  const loadAdvertListings = async () => {
    try {
      setLoading(true);
      const payload = await getAdverts(qs.parse(location.search));
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Media",
      key: "media",
      dataIndex: ["media", 0],
      render: media =>
        media.type === "image" ? (
          <Image alt="Ads" height={70} src={media.url} />
        ) : (
          <video width={140} height={70} controls>
            <source src={media.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: text => <a>{text}</a>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <Button
            key="edit"
            type="link"
            onClick={() => toggleAdvertDetails(record)}
          >
            Edit
          </Button>
          <Button
            key="delete"
            danger
            type="link"
            onClick={() => handleDeleteAdvert(record)}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handleDeleteAdvert = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this Advert?",
      content: `You’re about to delete ${record.title}.`,
      okText: "Yes, delete category.",
      okType: "danger",
      onOk() {
        return adsApi
          .deleteAdvert(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Advert Deleted");
              loadAdvertListings();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const toggleAdvertDetails = record => {
    setSelectedAdvert(record);
    setIsCreateAdvert(true);
  };

  const Header = () => (
    <PageHeader
      title="Manage Adverts"
      className={styles.pageHeader}
      extra={[
        <Button type="primary" onClick={() => toggleAdvertDetails(null)}>
          Create Advert
        </Button>
      ]}
    />
  );

  return (
    <div className={styles.tableContainer}>
      {isCreateAdvert && (
        <ManageAdvertsModal
          isVisible={isCreateAdvert}
          handleClose={() => setIsCreateAdvert(false)}
          selectedAdvert={selectedAdvert}
          onLoad={() => loadAdvertListings()}
        />
      )}
      <Card>
        <Table
          title={Header}
          size="small"
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={pagination}
          onChange={pagination => handleTableChanges(pagination)}
        />
      </Card>
    </div>
  );
};

export default ManageAdverts;
