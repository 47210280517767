import React from "react";
import { Button, Form, Input, Modal, message } from "antd";
import { createOption, updateOption } from "app/api/dynamicCategoryApi";
import styles from "./OptionsCreateModal.module.scss";

const { Item } = Form;

const OptionsCreateModal = ({
  isVisible,
  onClose,
  selectedOption,
  selectedField,
  childOption,
  fieldId,
  onUpdate
}) => {
  let [form] = Form.useForm();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const onFinish = values => {
    const body = new FormData();

    if (selectedField?.id && childOption) {
      body.append("parentId", fieldId);
    }

    if (values.OptionTitle) {
      body.append("name", values.OptionTitle);
    }

    if (values.arabicTranslation) {
      body.append("nameAr", values.arabicTranslation);
    }

    if (selectedOption?.id !== undefined) {
      updateOption(selectedField?.id, body, selectedOption?.id)
        .then(resp => {
          if (resp.status === 200) {
            onUpdate();
            handleClose();
            message.success("Option updated");
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    } else {
      createOption(selectedField?.id, body)
        .then((response: any) => {
          const resp = response.response || response;
          if (resp.status === 200) {
            onUpdate();
            handleClose();
            return message.success("Option created");
          }
        })
        .catch(error => {
          return message.error("Something went wrong. Try again!");
        });
    }
  };
  return (
    <Modal
      title={selectedOption ? "Edit Option" : "Add New Option"}
      visible={isVisible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <Button form="createOptionsForm" type="primary" htmlType="submit">
          {selectedOption ? "Edit Option" : "Add Option"}
        </Button>
      }
    >
      <Form
        name="createOptionsForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div>
          <Item
            className={styles.inlineInput}
            name="OptionTitle"
            label={"Option Title:"}
            initialValue={selectedOption?.nameLocalized?.en}
            rules={[
              {
                required: true,
                message: "Please enter option title."
              }
            ]}
          >
            <Input type="text" />
          </Item>
          <Item
            className={`${styles.inlineInput} ${styles.margin}`}
            name="arabicTranslation"
            label={"Arabic translation:"}
            initialValue={selectedOption?.nameLocalized?.ar}
            rules={[
              {
                required: true,
                message: "Please enter Arabic translation."
              }
            ]}
          >
            <Input type="text" />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default OptionsCreateModal;
