import { getListingDetails } from "app/api/listingsApi";
import { ListingDetailsProps } from "app/types/listingDetails";
import React, { useEffect, useState } from "react";
import { Button, Card, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import styles from "./ListingDetailsScreen.module.scss";
import ListingsForm from "../ListingsForm/ListingsForm";

const ListingDetailsScreen = () => {
  const history = useHistory();
  const { id, action, path } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [selectedListing, setSelectedListing] = useState<ListingDetailsProps>();

  useEffect(() => {
    loadListingDetails();
  }, []);

  const loadListingDetails = () => {
    setLoading(true);

    getListingDetails(id)
      .then(payload => {
        setSelectedListing(payload);
      })
      .catch(error => {
        error("Something went wrong", error);
      })
      .finally(() => setLoading(false));
  };

  const formattedTitle = path.replace(/-/g, " ").replace(/s$/, "");

  return (
    <div className={styles.tableContainer}>
      {!loading && (
        <>
          <Card>
            <div className={styles.sellerDetails}>
              <h1>{selectedListing?.seller?.name}</h1>
              {selectedListing?.sellerType && (
                <Tag color="#2db7f5">{selectedListing?.sellerType}</Tag>
              )}
              <Button
                type="text"
                icon={!showUserDetails ? <DownOutlined /> : <UpOutlined />}
                onClick={() => setShowUserDetails(!showUserDetails)}
                className={styles.showMore}
              />
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => history.goBack()}
                className={styles.closeBtn}
              />
            </div>
            {showUserDetails ? (
              <div className={styles.listingDetailsWrapper}>
                {selectedListing?.shop?.id && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>
                        Customer/Shop ID:
                      </div>{" "}
                      {selectedListing?.shop?.id}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.email && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Email Address:</div>{" "}
                      {selectedListing?.seller?.email}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.name && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Username:</div>{" "}
                      {selectedListing?.seller?.name}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.mobile && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Phone Number:</div>{" "}
                      {selectedListing?.seller?.mobile}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.address && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>
                        Delivery Address:
                      </div>{" "}
                      {selectedListing?.seller?.address}
                    </div>
                  </div>
                )}
                {selectedListing?.sellerType && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Seller type:</div>{" "}
                      {selectedListing?.sellerType}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.commercialRegistration && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>
                        Company Registration:
                      </div>{" "}
                      {selectedListing?.seller?.commercialRegistration}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.companyVatNo && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>VAT number:</div>{" "}
                      {selectedListing?.seller?.companyVatNo}
                    </div>
                  </div>
                )}
                {selectedListing?.shop?.name && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Shop name:</div>{" "}
                      {selectedListing?.shop?.name}
                    </div>
                  </div>
                )}
                {selectedListing?.shop?.description && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>
                        Shop Description:
                      </div>{" "}
                      {selectedListing?.shop?.description}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.favouriteBank?.name && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Bank:</div>{" "}
                      {selectedListing?.seller?.favouriteBank?.name}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.favouriteBank?.beneficiaryName && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Account holder:</div>{" "}
                      {selectedListing?.seller?.favouriteBank?.beneficiaryName}
                    </div>
                  </div>
                )}
                {selectedListing?.seller?.favouriteBank?.accountNumber && (
                  <div className={styles.listingItemWrapper}>
                    <div className={styles.listingItem}>
                      <div className={styles.listingLabel}>Account number:</div>{" "}
                      {selectedListing?.seller?.favouriteBank?.accountNumber}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p>{selectedListing?.seller?.email}</p>
            )}
          </Card>
          <div className={styles.detailsWrapper}>
            <Card>
              <div className={styles.sellerDetails}>
                <h1>
                  {selectedListing?.saleType}&nbsp;
                  {formattedTitle} - {selectedListing?.lot}
                </h1>
                <Tag color="#1F63B4">{selectedListing?.state}</Tag>
              </div>
              <p>Created: {selectedListing?.createdAt}</p>
              <ListingsForm
                selectedListing={selectedListing}
                visible={() => history.goBack()}
                action={action}
                path={path}
                onLoad={() => loadListingDetails()}
              />
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default ListingDetailsScreen;
