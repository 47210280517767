import { trimType } from "./trimType";
import { vehicleMakeType } from "./vehicleMakeType";
import { vehicleModelType } from "./vehicleModelType";

export enum VehicleSearchContentTypes {
  Make = "makes",
  Model = "models",
  Trim = "trims",
  Search = "search",
}

export type vehicleSearchType = {
  makes: {
    currentPage: number;
    lastPage: number;
    result: vehicleMakeType[];
    total: number;
  };
  models: {
    currentPage: number;
    lastPage: number;
    result: vehicleModelType[];
    total: number;
  };
  trims: {
    currentPage: number;
    lastPage: number;
    result: trimType[];
    total: number;
  };
};
