import * as AUTH_ROUTES from "./public/router.authentication";
import * as AUCTION_ROUTES from "./private/router.auctions";
import * as LISTINGS_ROUTES from "./private/router.listings";
import * as USER_ROUTES from "./private/router.users";
import * as CATEGORY_ROUTES from "./private/router.categories";
import * as PAGE_LAYOUT_ROUTES from "./private/router.page-layout";
import * as BULK_UPLOADS_ROUTES from "./private/router.bulk-uploads";
import * as ADVERT_ROUTES from "./private/router.adverts";
import * as AUCTION_SHOWS_ROUTES from "./private/router.auction-shows";
import * as FEE_ROUTES from "./private/router.fees";
import * as OTP_ROUTES from "./private/router.otp";
import * as TRANSLATIONS_ROUTES from "./private/router.translations";

export const PRIVATE_ROUTES = {
  ...AUCTION_ROUTES,
  ...LISTINGS_ROUTES,
  ...USER_ROUTES,
  ...CATEGORY_ROUTES,
  ...PAGE_LAYOUT_ROUTES,
  ...ADVERT_ROUTES,
  ...OTP_ROUTES,
  ...TRANSLATIONS_ROUTES,
  ...FEE_ROUTES,
  ...BULK_UPLOADS_ROUTES,
  ...AUCTION_SHOWS_ROUTES
};

export const PUBLIC_ROUTES = { ...AUTH_ROUTES };

export const NAVIGATION = [
  LISTINGS_ROUTES.LISTINGS_SCREEN,
  LISTINGS_ROUTES.PAST_LISTINGS,
  PAGE_LAYOUT_ROUTES.PAGE_LAYOUT_SCREEN,
  ADVERT_ROUTES.MANAGE_ADVERTS_SCREEN,
  FEE_ROUTES.FEE_SCREEN,
  OTP_ROUTES.MANAGE_OTP_SCREEN,
  TRANSLATIONS_ROUTES.TRANSLATIONS_SCREEN,
  AUCTION_SHOWS_ROUTES.AUCTION_SHOWS_SCREEN,
  BULK_UPLOADS_ROUTES.BULK_UPLOADS_SCREEN,
  USER_ROUTES.USERS_SCREEN,
  CATEGORY_ROUTES.MANAGE_CATEGORIES_SCREEN,
  CATEGORY_ROUTES.INSPECTION_CATEGORIES_SCREEN
];
