import React, { useEffect, useState } from "react";
import styles from "./MainCategoriesTable.module.scss";
import {
  Button,
  Card,
  Table,
  Image,
  Modal,
  PageHeader,
  TablePaginationConfig,
  message
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
import { PaginationConfig } from "antd/lib/pagination";
import * as dynamicCategoriesApi from "app/api/dynamicCategoryApi";
import { getListings } from "app/api/dynamicCategoryApi";
import MainCategoryDetails from "../MainCategoryDetails/MainCategoryDetails";
import CategoryCard from "../../Components/CategoryCard/Cards/CategoryCard";
import CategoryCreateEditForm from "../../Components/CategoryCreateEditForm/CategoryCreateEditForm";

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const MainCategoriesTable = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [isCreateCategory, setIsCreateCategory] = useState<boolean>(false);
  const [isCategoryDetails, setIsCategoryDetails] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState();

  const columns = [
    {
      title: "Cover Image",
      key: "imagePath",
      dataIndex: "imagePath",
      render: item => <Image width={80} height={54} src={item} />
    },
    {
      title: "Title",
      dataIndex: "nameLocalized",
      key: "nameLocalized",
      render: (text, record) => record?.nameLocalized?.en
    },
    {
      title: "Description",
      dataIndex: "descriptionLocalized",
      key: "descriptionLocalized",
      render: (text, record) => <>{record?.descriptionLocalized?.en}</>
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <Button type="link" onClick={() => toggleCategoryDetails(record)}>
            Edit
          </Button>
          <Button
            danger
            type="link"
            onClick={() => handleDeleteListing(record)}
          >
            Delete
          </Button>
        </>
      )
    }
  ];

  useEffect(() => {
    loadCategoryListings();
  }, [location.search]);

  const loadCategoryListings = async () => {
    try {
      setLoading(true);
      const payload = await getListings(qs.parse(location.search));
      setData(payload.data);
      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handleDeleteListing = record => {
    Modal.confirm({
      title: "Are you sure you want to delete this category?",
      content: `You’re about to delete  ${record.name}.`,
      okText: "Yes, delete category.",
      okType: "danger",
      onOk() {
        return dynamicCategoriesApi
          .deleteListing(record.id)
          .then(p => {
            if (p.status === 204) {
              message.success("Category Deleted");
              loadCategoryListings();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const toggleCategoryDetails = record => {
    setSelectedCategory(record);
    setIsCategoryDetails(true);
  };

  const Header = () => (
    <PageHeader
      title="Manage Categories"
      className={styles.pageHeader}
      extra={[
        <Button
          key="create"
          type="primary"
          onClick={() => setIsCreateCategory(true)}
        >
          Create Category
        </Button>
      ]}
    />
  );

  return (
    <div className={styles.tableContainer}>
      {isCategoryDetails ? (
        <MainCategoryDetails
          visible={() => setIsCategoryDetails(false)}
          selectedCategory={selectedCategory}
          onLoad={loadCategoryListings}
        />
      ) : (
        <>
          {isCreateCategory ? (
            <CategoryCard
              visible={() => setIsCreateCategory(false)}
              title={"Create new category"}
              extra={true}
              cardContent={
                <CategoryCreateEditForm
                  visible={() => setIsCreateCategory(false)}
                  onLoad={loadCategoryListings}
                />
              }
            />
          ) : (
            <Card>
              <Table
                title={Header}
                size="small"
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={pagination}
                rowKey={record => record.id}
                onChange={pagination => handleTableChanges(pagination)}
                className={styles.mainCategoriesTable}
              />
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default MainCategoriesTable;
