import React, { memo } from "react";
import AuthenticationLayout from "app/layouts/AuthenticationLayout/AuthenticationLayout";
import { PUBLIC_ROUTES } from "app/routers/Router.config";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";

const AuthenticationScreen = (props) => {
  return (
    <AuthenticationLayout>
      {
        {
          [PUBLIC_ROUTES.LOGIN_SCREEN.path]: <Login />,
          [PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path]: <ForgotPassword />,
          [PUBLIC_ROUTES.RESET_PASSWORD_SCREEN.path]: <ResetPassword />,
        }[props.match.path]
      }
    </AuthenticationLayout>
  );
};

export default memo(AuthenticationScreen);
