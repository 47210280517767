import { CloseOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React from "react";
import ListingsForm from "../ListingsForm/ListingsForm";
import styles from "./ListingsCreate.module.scss";

const ListingsCreate = ({ visible }) => {
  return (
    <div className={styles.tableContainer}>
      <Card
        title="Create Listing"
        extra={
          <Button
            type="text"
            shape="circle"
            icon={<CloseOutlined />}
            onClick={visible}
          />
        }
      >
        <ListingsForm
          visible={visible}
          selectedListing={undefined}
          action={undefined}
          path={undefined}
          onLoad={undefined}
        />
      </Card>
    </div>
  );
};

export default ListingsCreate;
