import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";

/**
 * Get listings
 * @param search
 * @returns
 * @export
 */

export const getListings = (search: any, type: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES;
  return api
    .get(endpoint, {
      params: {
        sortBy: "createdAt",
        sortOrder: "DESC",
        type: type,
        ...search
      }
    })
    .then(resp => resp.data);
};

/**
 * Get buy now
 * @param search
 * @returns
 * @export
 */

export const getBuyNow = (search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_BUY_NOW;
  return api
    .get(endpoint, {
      params: {
        sortBy: "auctionLot",
        sortOrder: "DESC",
        ...search
      }
    })
    .then(resp => resp.data);
};

/**
/**
 * listing details
 *
 * @param
 * @returns
 * @export
 */

export const getListingDetails = async (id: string) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;
  const resp = await api.get(endpoint);
  return resp.data;
};

/**
 * create listing
 */
export const createListing = (body: any) => {
  return api.post(ENDPOINTS.AUCTION_VEHICLES, body);
};

/**
 * update listing
 */
export const updateListing = (id: any, body: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;

  return api.post(endpoint, body);
};

/**
 * cancel listing
 */
export const cancelListing = (body: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/pending-payment/cancel`;

  return api.post(endpoint, body);
};

/**
 * update payment complete
 */
export const updatePaymentComplete = (id: any, body: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;

  return api.post(endpoint, body);
};
/**
 * activate auction
 */
export const activateAuction = (id: any, body: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;

  return api.post(endpoint, body);
};

/**
 * delete listing
 * @param search
 */
export const deleteListing = (id: string) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;
  return api.delete(endpoint);
};

/**
/**
 * Get locations
 *
 * @param
 * @returns
 * @export
 */

export const getLocation = async (search: any) => {
  const endpoint = ENDPOINTS.LOCATIONS + `/search-address?address=${search}`;
  const resp = await api.post(endpoint, { params: search });
  return resp.data;
};

/**
 * Get OTP
 * @param search
 * @returns
 * @export
 */

export const getOTP = (search: any) => {
  const endpoint = ENDPOINTS.OTP;
  return api.get(endpoint, { params: search }).then(({ data }) => data);
};
