import { CloudUploadOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { PermissionEnum } from "app/constants/permissionConst";
import { IMenuItemType } from "app/types/routerType";
import BulkItems from "app/screens/BulkUploadScreen/BulkItems";

export const BULK_UPLOADS_SCREEN: IMenuItemType = {
  id: "uploads",
  path: "/uploads",
  title: "Bulk Uploads",
  icon: CloudUploadOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

export const VEHICLE_CATEGORIES: IMenuItemType = {
  id: "bulk-items",
  path: "/bulk-items",
  title: "Upload Items",
  component: BulkItems,
  icon: UnorderedListOutlined,
  permissions: [
    PermissionEnum.SUPER_ADMIN,
    PermissionEnum.ADMIN,
    PermissionEnum.AUCTIONEER,
    PermissionEnum.BACK_OFFICE_USER
  ]
};

BULK_UPLOADS_SCREEN.subMenuItems = [VEHICLE_CATEGORIES];
