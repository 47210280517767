import { api } from "app/helpers/api";
import { ENDPOINTS } from "app/constants/apiConst";
import { BuyNowRequestParams } from "app/constants/buyNowTypes";

export const getActiveAuctionVehicles = (search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES;

  return api
    .get(endpoint, { params: { type: "active", ...search } })
    .then(resp => resp.data);
};

export const getVehicleTypes = () => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLE_TYPES;

  return api.get(endpoint).then(resp => resp.data);
};

/**
 * Return Auction with Buy Now requests attached
 *
 * @param {BuyNowRequestParams} params
 * @param {*} search
 * @returns
 * @export
 */
export const getAuctionBuyNowRequests = (
  params?: BuyNowRequestParams,
  search?: any
) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_BUY_NOW;

  let parameters: BuyNowRequestParams | any = {
    sortBy: "auctionLot",
    sortOrder: "DESC",
    ...search
  };

  if (params?.type) parameters.type = params.type;
  if (params?.auction_vehicle_id)
    parameters.auction_vehicle_id = params.auction_vehicle_id;

  return api
    .get(endpoint, {
      params: parameters
    })
    .then(resp => resp.data);
};

/**
 * Cancel an existing buy now request by it's ID
 *
 * @param {number} requestId
 * @returns
 * @export
 */
export const cancelBuyNowRequest = (requestId: number) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_BUY_NOW + `/${requestId}/cancel`;

  return api.post(endpoint).then(resp => resp.data);
};

export const confirmBuyNowRequest = (
  id: number,
  body: any,
  isFormData: boolean = false
) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_BUY_NOW + `/${id}`;

  if (isFormData) {
    return api.post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  return api.post(endpoint, body);
};

/**
 * Buy Now request by request ID
 *
 * @param {number} id
 * @returns
 * @export
 */
export const getVehicleBuyNowRequest = (id: number) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_BUY_NOW + `/${id}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const getAuctionRequests = (search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES;

  return api
    .get(endpoint, {
      params: {
        sortBy: "createdAt",
        sortOrder: "DESC",
        type: "new",
        ...search
      }
    })
    .then(resp => resp.data);
};

export const getVehicleMake = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_MAKES + `/${id}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const getVehicleModel = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS + `/${id}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const getVehicleTrim = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_TRIMS + `/${id}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const updateAuctionVehicle = (
  id: number,
  body: any,
  isFormData: boolean = false
) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;

  if (isFormData) {
    body.append("currencyCode", "SAR");
    return api.post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  return api.post(endpoint, body);
};

export const getActiveAuctionPolling = (timestamp: number, search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES_POLLING_URL;

  return api
    .get(endpoint, {
      params: {
        sortBy: "endAt",
        sortOrder: "DESC",
        type: "ended",
        timestamp: timestamp,
        ...search
      }
    })
    .then(resp => resp.data);
};

export const getPriceEditStatus = (id: number) => {
  const endpoint =
    ENDPOINTS.AUCTION_VEHICLES + `/${id}` + ENDPOINTS.AUCTION_VEHICLE_BIDS_URI;

  return api.get(endpoint).then(resp => resp.data);
};

export const getEndedAuctions = (search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES;

  return api
    .get(endpoint, {
      params: {
        sortBy: "endedAt",
        sortOrder: "DESC",
        type: "ended",
        ...search
      }
    })
    .then(resp => resp.data);
};

export const getVehicleConfigs = () => {
  const endpoint = ENDPOINTS.VEHICLE_CONFIGS;

  return api.get(endpoint).then(resp => resp.data);
};

export const getVehicleBids = (id: number) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}/bids`;

  return api.get(endpoint).then(resp => resp.data);
};

export const getFinalizedTrades = (search: any) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES;

  return api
    .get(endpoint, {
      params: {
        sortBy: "endAt",
        sortOrder: "DESC",
        type: "finalized",
        ...search
      }
    })
    .then(resp => resp.data);
};

export const getAuctionVehicle = (id: number | undefined) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const updateAuctionVehicleState = (
  id: number | undefined,
  state: string
) => {
  const endpoint =
    ENDPOINTS.AUCTION_VEHICLES_UPDATE_STATE +
    `/?auctionVehicleId=${id}&action=${state}`;

  return api.post(endpoint);
};

/**
 *
 * CRUD FOR VEHICLE MAKES
 */

export const createVehicleMake = (
  body?: any | undefined,
  withLogo: boolean = false
) => {
  const URI = withLogo ? ENDPOINTS.VEHICLE_MAKES_LOGO_URI : "";
  const endpoint = ENDPOINTS.VEHICLE_MAKES + URI;

  return api.post(endpoint, body).then(resp => resp);
};

export const searchVehicleMakeModelTrim = (search: any) => {
  const endpoint = ENDPOINTS.VEHICLE_SEARCH;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const getVehicleMakes = (search: any) => {
  const endpoint = ENDPOINTS.VEHICLE_MAKES;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const getVehicleModelsByMake = (makeId: any) => {
  const endpoint = ENDPOINTS.VEHICLE_MAKES + `/${makeId}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const updateVehicleMake = (
  id: number,
  body: any,
  withLogo: boolean = false
) => {
  const URI = withLogo ? ENDPOINTS.VEHICLE_MAKES_LOGO_URI : "";
  const endpoint = ENDPOINTS.VEHICLE_MAKES + URI + `/${id}`;

  if (withLogo) {
    return api.post(endpoint, body);
  }
  return api.patch(endpoint, body);
};

export const deleteVehicleMake = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_MAKES + `/${id}`;
  return api.delete(endpoint);
};

/**
 *
 * CRUD FOR VEHICLE MODELS
 */

export const createVehicleModel = (body?: any | undefined) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS;

  return api.post(endpoint, body);
};

export const getVehicleModels = (search: any) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const getVehicleTrimsByModel = (modelId: any) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS + `/${modelId}`;

  return api.get(endpoint).then(resp => resp.data);
};

export const updateVehicleModel = (id: number, body: any) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS + `/${id}`;

  return api.patch(endpoint, body);
};

export const deleteVehicleModel = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_MODELS + `/${id}`;

  return api.delete(endpoint);
};

/**
 *
 * CRUD FOR VEHICLE TRIMS
 */

export const createVehicleTrim = (body?: any | undefined) => {
  const endpoint = ENDPOINTS.VEHICLE_TRIMS;

  return api.post(endpoint, body);
};

export const getVehicleTrims = (search: any) => {
  const endpoint = ENDPOINTS.VEHICLE_TRIMS;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const updateVehicleTrim = (id: number, body: any) => {
  const endpoint = ENDPOINTS.VEHICLE_TRIMS + `/${id}`;

  return api.patch(endpoint, body);
};

export const deleteVehicleTrim = (id: number) => {
  const endpoint = ENDPOINTS.VEHICLE_TRIMS + `/${id}`;

  return api.delete(endpoint);
};

/**
 *
 * CRUD FOR VEHICLE CATEGORIES
 */

export const createVehicleCategory = (body?: any | undefined) => {
  const endpoint = ENDPOINTS.VEHICLE_CATEGORIES;
  body.currencyCode = "SAR";
  return api.post(endpoint, body);
};

export const getVehicleCategories = (search: any) => {
  const endpoint = ENDPOINTS.VEHICLE_CATEGORIES;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const updateVehicleCategory = (id: number | undefined, body: any) => {
  body.currencyCode = "SAR";
  const endpoint = ENDPOINTS.VEHICLE_CATEGORIES + `/${id}`;
  return api.patch(endpoint, body);
};

export const deleteVehicleCategory = (id: number | undefined) => {
  const endpoint = ENDPOINTS.VEHICLE_CATEGORIES + `/${id}`;

  return api.delete(endpoint);
};

/**
 *
 * CRUD FOR INSPECTION REPORTS REQUESTS
 */

export const getInspectionReportRequests = (search: any, state: any) => {
  const endpoint =
    ENDPOINTS.INSPECTION_REPORTS_REQUESTS +
    `/inspection-report-request?state=${state}`;

  return api.get(endpoint, { params: search }).then(resp => resp.data);
};

export const updateInspectionReportstatus = (id: number | undefined) => {
  const endpoint =
    ENDPOINTS.INSPECTION_REPORTS_REQUESTS +
    `/inspection-report-request/change-state/${id}?state=confirmed`;
  return api.post(endpoint);
};

export const sendInspectionReportNotification = (id: number | undefined) => {
  const endpoint =
    ENDPOINTS.INSPECTION_REPORTS_REQUESTS +
    `/inspection-report-request/send-notification/${id}`;
  return api.post(endpoint);
};

export const deleteVehicleImage = (id: number) => {
  const endpoint = ENDPOINTS.AUCTION_VEHICLES + `/${id}/delete-media`;

  return api.delete(endpoint);
};
/**
 * Buy Now request by request ID
 *
 * @param {number} id
 * @returns
 * @export
 */
export const getDownPaymentAmount = () => {
  const endpoint = ENDPOINTS.AUCTION_SETTINGS + `/down-payment-settings/get`;

  return api.get(endpoint).then(resp => resp.data);
};

export const updateDownPaymentAmount = (amount: number) => {
  const endpoint =
    ENDPOINTS.AUCTION_SETTINGS +
    `/down-payment-settings/update?amount=${amount}`;

  return api.post(endpoint).then(resp => resp.data);
};

/**
 * Adverts API
 *
 * @param {number} id
 * @returns
 * @export
 */

export const getAdvertisements = (id: number) => {
  const endpoint = ENDPOINTS.ADVERTISEMENTS + `/?categoryId=${id}`;

  return api.get(endpoint).then(resp => resp.data);
};
