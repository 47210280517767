import {
  Card,
  Table,
  Image,
  Button,
  PageHeader,
  Modal,
  TablePaginationConfig,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import styles from "./AuctionShowsTable.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import AuctionShowsForm from "./Components/AuctionShowsForm/AuctionShowsForm";
import { approveEvent, getEvents } from "app/api/auctionShowsApi";
import { PaginationConfig } from "antd/lib/pagination";
import qs from "query-string";
import { QueryHelper } from "app/helpers/QueryHelper";
import moment from "moment";
import * as eventsApi from "app/api/auctionShowsApi";
import { AuctionData } from "app/types/auctionShowsType";

interface Pagination {
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
}

const INITIAL_PAGINATION: PaginationConfig = {
  current: 0,
  pageSize: 0,
  total: 0,
  showSizeChanger: false
};

const AuctionShowsTable = () => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.slice(1);
  const [data, setData] = useState<AuctionData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(INITIAL_PAGINATION);
  const [isCreateAuctionShow, setIsCreateAuctionShow] = useState<boolean>(
    false
  );

  const getTypeFromPath = path => {
    switch (path) {
      case "in-progress":
        return "live";
      case "new-events":
        return "requests";
      case "upcoming":
        return "upcoming";
      case "history":
        return "history";
      default:
        return "live";
    }
  };

  const type = getTypeFromPath(path);

  const formattedTitle = path.replace(/-/g, " ");

  useEffect(() => {
    loadEvents();
  }, [location.search]);

  const loadEvents = async () => {
    try {
      setLoading(true);
      const payload = await getEvents(qs.parse(location.search), type);

      setData(payload.data);

      setPagination(prevPagination => ({
        ...prevPagination,
        current: payload.meta.current_page,
        pageSize: payload.meta.per_page,
        total: payload.meta.total
      }));
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveEvent = async record => {
    if (!record) {
      return;
    }

    const body = {
      isApproved: true,
      isActive: true
    };

    try {
      await approveEvent(record, body).then(p => {
        if (p.status === 200) {
          message.success("Live Auction Approved");
          loadEvents();
        } else {
          message.error("Something Wrong happened, try again.");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Media",
      key: "path",
      dataIndex: "path",
      width: "12%",
      render: media => (
        <Image
          style={{
            aspectRatio: "16/9",
            objectFit: "cover",
            width: "100%"
          }}
          alt="Events"
          height={60}
          src={media}
        />
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Featured",
      dataIndex: "featured",
      key: "featured",
      render: featured => (featured ? "True" : "False")
    },
    {
      title: "Type",
      dataIndex: "auctionType",
      key: "auctionType"
    },
    {
      title: "Start Date",
      dataIndex: "startAt",
      key: "startAt",
      sorter: (a: any, b: any) =>
        moment(a.startAt).unix() - moment(b.startAt).unix(),
      render: startAt => moment(startAt).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "End Date",
      dataIndex: "endAt",
      key: "endAt",
      sorter: (a: any, b: any) =>
        moment(a.endAt).unix() - moment(b.endAt).unix(),
      render: endAt =>
        endAt ? moment(endAt).format("YYYY-MM-DD HH:mm:ss") : "-"
    },
    {
      title: "Lots",
      dataIndex: ["lotTabs", "length"],
      key: "lotTabs"
    },
    {
      hidden: path !== "history",
      title: "Bids",
      dataIndex: "totalBids",
      key: "totalBids"
    },
    {
      hidden: path !== "history",
      title: "Finalized",
      dataIndex: "totalBidsFinalized",
      key: "totalBidsFinalized"
    },
    {
      hidden: path !== "new-events",
      title: "Review",
      key: "state",
      dataIndex: "state",
      render: (text, record, index) => (
        <>
          <Button
            disabled={
              record?.lotTabs?.length <= 0 || record.auctionType === "show"
            }
            key="review"
            type="text"
            onClick={() => handleApproveEvent(record.id)}
          >
            Approve
          </Button>
        </>
      )
    },
    {
      title: "Action",
      key: "action",
      render: record => (
        <>
          <Button key="edit" type="link" onClick={() => toggleEvent(record)}>
            {path !== "in-progress" &&
            path !== "history" &&
            record.auctionType !== "show"
              ? "Edit"
              : "View"}
          </Button>
          <Button
            key="delete"
            danger
            type="link"
            hidden={
              path === "history" ||
              (record.auctionType === "live-auction" && path === "in-progress")
            }
            onClick={() => handleDeleteEvent(record)}
          >
            {path === "requests"
              ? "Delete"
              : path === "in-progress"
              ? "End"
              : "Archive"}
          </Button>
        </>
      )
    }
  ].filter(item => !item.hidden);

  const Header = () => (
    <PageHeader
      title={formattedTitle}
      className={styles.pageHeader}
      extra={
        path === "new-events" && [
          <Button
            key="create"
            type="primary"
            onClick={() => setIsCreateAuctionShow(true)}
          >
            Create new event
          </Button>
        ]
      }
    />
  );

  const toggleEvent = record => {
    history.push(`/${path}/${record.id}`);
  };

  const handleDeleteEvent = record => {
    const body = {
      state: "cancelled"
    };

    Modal.confirm({
      title: "Are you sure you want to archive this event?",
      content: `You’re about to archive ${record.title}.`,
      okText: "Yes, archive event",
      okType: "danger",
      onOk() {
        return eventsApi
          .updateEvent(record.id, body)
          .then(p => {
            if (p.status === 200) {
              message.success(`${record.auctionType} deleted`);
              loadEvents();
            } else {
              message.error("Something Wrong happened, try again.");
            }
          })
          .catch(e => {
            message.error(e.message);
          });
      }
    });
  };

  const handleSearch = (values: any) => {
    // Get current search from location
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values
    };

    QueryHelper.removeUndefined(values);

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, {
        arrayFormat: "comma"
      })
    });
  };

  const handleTableChanges = (newPagination: TablePaginationConfig) => {
    const values = {
      page: newPagination.current
    };

    handleSearch(values);
  };

  const handleClose = () => {
    setIsCreateAuctionShow(false);
    loadEvents();
  };

  return (
    <div className={styles.tableContainer}>
      <>
        {isCreateAuctionShow ? (
          <Card
            className={styles.cardWidth}
            title="Create Event"
            extra={
              <Button
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setIsCreateAuctionShow(false)}
              />
            }
          >
            <AuctionShowsForm
              handleClose={handleClose}
              path={path}
              onLoadDetails={loadEvents}
            />
          </Card>
        ) : (
          <Card>
            <Table
              key="index"
              title={Header}
              size="small"
              dataSource={data}
              columns={columns}
              loading={loading}
              pagination={pagination}
              rowKey={record => record.id}
              onChange={pagination => handleTableChanges(pagination)}
            />
          </Card>
        )}
      </>
    </div>
  );
};

export default AuctionShowsTable;
