import { Button, Form, Modal, Upload, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ImageUploadModal.module.scss";
import {
  beforeUpload,
  dataURLtoFile,
  getBase64
} from "app/helpers/imageUploadHelper";
import { UploadFile } from "antd/lib/upload/interface";
import { deleteVehicleImage } from "app/api/auctionVehiclesApi";
import { UploadOutlined } from "@ant-design/icons";
import { uploadEntryImages } from "app/api/bulkUploadsApi";
import { RawEntriesType } from "app/types/bulkUploadsType";

interface ImageUploadModalProps {
  selectedEntry?: RawEntriesType;
  visible: boolean;
  onCancel: () => void;
  onLoad: () => void;
}

const ImageUploadModal = ({
  visible,
  onCancel,
  selectedEntry,
  onLoad
}: ImageUploadModalProps) => {
  const { Item } = Form;
  let [form] = Form.useForm();
  const imageToBase64 = useRef<HTMLCanvasElement>(null);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");

  const onFinish = values => {
    const body = new FormData();

    // Process and convert each image in array to a blob
    if (values.imagePath) {
      let ins = values.imagePath.length;
      for (let x = 0; x < ins; x++) {
        const image = values.imagePath[x].originFileObj;

        if (!values.imagePath[x].skip) body.append("mediaPhotos[]", image);
      }
    }

    if (selectedEntry?.id !== undefined) {
      uploadEntryImages(selectedEntry?.id, body)
        .then(resp => {
          if (resp.status === 200) {
            onLoad();
            message.success("Image uploaded successfully");
            onCancel();
          }
        })
        .catch(error => {
          message.error("Something went wrong. Try again!");
        });
    }
  };

  const customUpload = options => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("done");
    }, 0);
  };

  useEffect(() => {
    if (fileList && fileList.length > 0) {
      form.setFieldsValue({
        imagePath: fileList
      });
    }
  }, [fileList, form]);

  useEffect(() => {
    if (
      !!imageToBase64 &&
      selectedEntry?.mediaPhotos &&
      selectedEntry?.mediaPhotos.length > 0
    ) {
      const formattedMediaImages = selectedEntry?.mediaPhotos.map(
        (image: any): any => {
          imageToBase64.current?.setAttribute("src", image.url);
          const dataInBase64 = imageToBase64.current?.toDataURL(image.mimeType);
          const imageFile = dataURLtoFile(dataInBase64, image.fileName);

          return {
            uid: image.id.toString(),
            thumbUrl: image.url,
            url: image.url,
            size: image.size,
            name: image.fileName,
            type: image.mimeType,
            originFileObj: imageFile,
            status: "done",
            skip: true
          };
        }
      );
      setFileList(formattedMediaImages);
    }
  }, [selectedEntry]);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList.filter(file => !!file.status));
  };

  const uploadButton = (
    <div>
      <UploadOutlined className={styles.icon} />
      <p className={styles.uploadText}>Upload image</p>
    </div>
  );

  const handleCancel = () => setPreviewVisible(false);

  const deleteImage = e => {
    deleteVehicleImage(Number(e.uid))
      .then(() => {
        onLoad();
        setTimeout(message.success("Image deleted!"), 5000);
        onCancel();
      })
      .catch(error => message.error("Something went wrong!"));
  };

  return (
    <Modal
      title="Upload Image"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <>
          <Button key="cancel" type="text" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            key="addLot"
            form="imagesUploadForm"
            type="primary"
            htmlType="submit"
          >
            Upload Images
          </Button>
        </>
      ]}
    >
      <Form
        name="imagesUploadForm"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <div>
          <canvas className={styles.canvas} ref={imageToBase64}></canvas>
          <Item
            name="imagePath"
            label="Upload Entry Images:"
            required={true}
            valuePropName="fileList"
            className={styles.uploadMediaText}
          >
            <Upload
              accept="image/jpg,image/jpeg,image/png"
              listType="picture-card"
              multiple
              beforeUpload={beforeUpload}
              fileList={fileList}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true
              }}
              customRequest={customUpload}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={deleteImage}
            >
              {uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="vehicleImage"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ImageUploadModal;
